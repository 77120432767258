import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a070370"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "agent-settings-content" }
const _hoisted_2 = { class: "header-content" }
const _hoisted_3 = { class: "row form-heading" }
const _hoisted_4 = { class: "col-md-9 col-sm-12" }
const _hoisted_5 = { id: "agent-settings-modal__title" }
const _hoisted_6 = { class: "col-md-3 col-sm-12 text-right p-0" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "card-wrapper" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "card-wrapper" }
const _hoisted_12 = {
  key: 2,
  class: "form-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")!
  const _component_ScrollLayout = _resolveComponent("ScrollLayout")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "agent-settings-modal",
    ref: "dialogRef",
    class: "agent-settings-modal",
    "aria-labelled-by": "agent-settings-modal__title"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          novalidate: "",
          class: "form-main",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
        }, [
          _createVNode(_component_ScrollLayout, null, {
            "header-left": _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.locales.agent_details), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        class: "btn btn-text-primary",
                        type: "button",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAgentSettingsModal(false)))
                      }, _toDisplayString(_ctx.locales.cancel), 1),
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        type: "submit",
                        disabled: !_ctx.isValidProfile || _ctx.isSaving
                      }, _toDisplayString(_ctx.locales.save), 9, _hoisted_7)
                    ])
                  ])
                ])
              ])
            ]),
            body: _withCtx(() => [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.locales.loading), 1)
                  ]))
                : (_ctx.isError)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.locales.error_content), 1)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_BsTextField, {
                        modelValue: _ctx.getEmployerName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getEmployerName) = $event)),
                        class: "mb-3",
                        label: _ctx.locales.employer_name,
                        disabled: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_BsTextField, {
                        modelValue: _ctx.getYourName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getYourName) = $event)),
                        class: "mb-3",
                        label: _ctx.locales.your_name,
                        disabled: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_BsTextField, {
                        modelValue: _ctx.formFields.phoneNumber,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formFields.phoneNumber) = $event)),
                        class: "mb-3",
                        label: _ctx.locales.phone_number,
                        disabled: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_BsTextField, {
                        modelValue: _ctx.formFields.emailAddress,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formFields.emailAddress) = $event)),
                        class: "mb-3",
                        label: _ctx.locales.email_address,
                        disabled: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_BsMultiSelect, {
                        modelValue: _ctx.formFields.officeLocations,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formFields.officeLocations) = $event)),
                        options: _ctx.officeLocations,
                        label: _ctx.locales.recruiter_locations,
                        validation: _ctx.v$.officeLocations,
                        class: "mb-4"
                      }, null, 8, ["modelValue", "options", "label", "validation"])
                    ]))
            ]),
            _: 1
          })
        ], 32)
      ])
    ]),
    _: 1
  }, 512))
}