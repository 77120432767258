
import { BsCheckbox } from '@/components/BsCheckbox/BsCheckbox.vue'
import { defineComponent, ref } from 'vue'
import { FilterOptions } from './Applicants.interface'

export default defineComponent({
  components: {
    BsCheckbox
  },
  props: {
    visible:  {
      type: Boolean,
      default: false
    }
  },
  emits: ['filterApplied', 'update:visible'],
  setup(props, { emit }) {
    const filterOptions = ref({} as FilterOptions)

    const handleFilterApplied = () => {
      emit('filterApplied', {
        reviewed: filterOptions.value.reviewed, 
        notReviewed: filterOptions.value.notReviewed, 
        interviewing: filterOptions.value.interviewing, 
        notHired: filterOptions.value.notHired
        } as FilterOptions)
    }

    const reset = ()=> {
      filterOptions.value = {} as FilterOptions
    }

    return {
      filterOptions,
      handleFilterApplied,
      reset
    }
  }
})
