import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12883842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-main-segment" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12 col-md-3 header-left-segment" }
const _hoisted_4 = { class: "logo-segment" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: require('~/icons/JobNetwork-Logo.png'),
            alt: "logo"
          }, null, 8, _hoisted_5)
        ])
      ]),
      (!_ctx.$route.meta.hideNavBar)
        ? (_openBlock(), _createElementBlock("nav", {
            key: 0,
            class: "col-sm-12 col-md-9 header-right-segment",
            "aria-label": _ctx.locales.main_menu
          }, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (menu, i) => {
                return (_openBlock(), _createElementBlock("li", { key: i }, [
                  _createVNode(_component_router_link, {
                    to: menu.path,
                    class: _normalizeClass({
                'link-option': true,
              })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(menu.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128)),
              _createElementVNode("li", null, [
                _createVNode(_component_HeaderDropdown, {
                  "name-details": _ctx.userDetails,
                  options: _ctx.dropdownOptions,
                  "is-authenticated": _ctx.authenticated,
                  "is-creating-session": _ctx.isCreatingSession,
                  onHandleUserLogin: _ctx.handleUserLogin
                }, null, 8, ["name-details", "options", "is-authenticated", "is-creating-session", "onHandleUserLogin"])
              ])
            ])
          ], 8, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}