
import { defineComponent, PropType, ref, computed } from 'vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import AccordionMenu from '@/components/Accordion/AccordionMenu.vue'
import AccordionExpander from '@/components/Accordion/AccordionExpander.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import { dropdownOptions } from './EmployerProfile.util'
import locales from './EmployerProfile.locales.en.json'
import { DropdownOptionModel } from '@/components/DropdownOptions/DropdownOptions.interfaces'
import { EmployerDetailsModel } from './EmployerProfile.interface'
import { BuildLocationKey } from '@/api/AzMapsApi'
import TextEditor from '@/components/Editor/TextEditor.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import { EmployerTypes, EmployerTypeKey } from '@/types/employer'
import { TextValue } from '@/types'
import BsButton from '@/components/BsButton/BsButton.vue'

export default defineComponent({
  components: {
    ProfilePicture,
    DropdownOptions,
    AccordionMenu,
    AccordionExpander,
    AccordionItem,
    TextEditor,
    BsButton
  },
  props: {
    employerDetails: {
      type: Object as PropType<EmployerDetailsModel>,
      required: true
    },
    personaView: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showAsDialog: {
      type: Boolean,
      default: false
    },
    logoImageSource: {
      type: String,
      default: () => undefined
    }
  },
  emits: ['viewAs', 'editProfile', 'close', 'updated-profilepic', 'logo-image-source-updated'],
  setup(props, { emit }) {
    const isExpanded = ref<boolean>(false)
    const showEditButton = ref(false)

    const aboutUs = computed((): string => {
      return props.employerDetails.description
    })

    const employerTypeValue = computed((): string => {
      const typeKey = props.employerDetails.employerType as EmployerTypeKey
      return EmployerTypes.find((et: TextValue<EmployerTypeKey>) => et.value === typeKey)?.text ?? ''
    })

    function toggleExpandedState() {
      isExpanded.value = !isExpanded.value
    }

    function handleOptionChange(dropdownItem: DropdownOptionModel) {
      switch (dropdownItem.key) {
        case 'view':
          emit('viewAs')
          break
        case 'edit':
          emit('editProfile')
          break
      }
    }

    function handleClose() {
      emit('close')
    }

    function triggerProfilePic() {
      if (props.showAsDialog) {
        return
      }
      let element = document.getElementById('employer-profile-item')!
      element.click()
    }

    function handleChangeProfilePic(e: Event) {
      const file = (e.target as HTMLInputElement).files
      if (!file || !file.length) {
        return
      }
      emit('updated-profilepic', file[0])
    }

    function handleProfilePicLoaded(imageSource: string) {
      showEditButton.value = true
      emit('logo-image-source-updated', imageSource)
    }

    return {
      isExpanded,
      dropdownOptions,
      locales,
      aboutUs,
      toggleExpandedState,
      handleOptionChange,
      BuildLocationKey,
      EmployerTypes,
      employerTypeValue,
      handleClose,
      handleChangeProfilePic,
      triggerProfilePic,
      showEditButton,
      handleProfilePicLoaded
    }
  }
})
