import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadFileCard = _resolveComponent("UploadFileCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UploadFileCard, {
      id: "resumesUpload",
      "file-items": _ctx.resumes,
      label: _ctx.locales.resume,
      "add-label": _ctx.locales.add_another_resume,
      "max-files": 5,
      category: _ctx.FileDescription.CandidateResume,
      "no-files-label": _ctx.locales.no_resume,
      accept: ".doc, .docx, .pdf",
      onFileUploadClicked: _ctx.handleUpload,
      onFileDeleteClicked: _ctx.handleDelete,
      onFileDownloadClicked: _ctx.handleDownload
    }, null, 8, ["file-items", "label", "add-label", "category", "no-files-label", "onFileUploadClicked", "onFileDeleteClicked", "onFileDownloadClicked"]),
    _createVNode(_component_UploadFileCard, {
      id: "transcriptsUpload",
      "file-items": _ctx.transcripts,
      label: _ctx.locales.transcript,
      "add-label": _ctx.locales.add_another_transcript,
      "max-files": 5,
      category: _ctx.FileDescription.CandidateTranscript,
      "no-files-label": _ctx.locales.no_transcript,
      accept: ".doc, .docx, .pdf",
      onFileUploadClicked: _ctx.handleUpload,
      onFileDeleteClicked: _ctx.handleDelete,
      onFileDownloadClicked: _ctx.handleDownload
    }, null, 8, ["file-items", "label", "add-label", "category", "no-files-label", "onFileUploadClicked", "onFileDeleteClicked", "onFileDownloadClicked"]),
    _createVNode(_component_UploadFileCard, {
      id: "writingSamplesUpload",
      "file-items": _ctx.writingSamples,
      label: _ctx.locales.writing_sample,
      "add-label": _ctx.locales.add_writing_sample,
      "max-files": 5,
      category: _ctx.FileDescription.CandidateWritingSample,
      "no-files-label": _ctx.locales.no_writing_sample,
      accept: ".doc, .docx, .pdf",
      onFileUploadClicked: _ctx.handleUpload,
      onFileDeleteClicked: _ctx.handleDelete,
      onFileDownloadClicked: _ctx.handleDownload
    }, null, 8, ["file-items", "label", "add-label", "category", "no-files-label", "onFileUploadClicked", "onFileDeleteClicked", "onFileDownloadClicked"])
  ], 64))
}