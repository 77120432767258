import { EmployerProfile } from '@/types/employer'
import { Candidate } from '@/types/candidate'
import { AgentProfile } from '@/types/agent'
import { Application } from '@/types/application'
import { JobOpportunity } from '@/types/opportunity'
import { createStore } from 'vuex'
import { ExtendedUserClaims, GetCandidateClaimsFromToken } from '@/okta'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export interface JobNetworkAppState {
  userDetails: ExtendedUserClaims | null
  authenticated: boolean
  jobOpportunity?: JobOpportunity
  candidate?: Candidate
  agentProfile?: AgentProfile
  application?: Application
  applicationInsights?: ApplicationInsights,
  employerNameMapping: Record<string, string>
  employerProfile?: EmployerProfile
}

export default createStore<JobNetworkAppState>({
  state: {
    userDetails: null,
    authenticated: false,
    employerNameMapping: {} as Record<string, string>
  },
  getters: {
    userFirstName(state) {
      return state.userDetails?.firstName ?? ''
    }
  },
  mutations: {
    setAuthenticated(state) {
      state.authenticated = true
    },
    resetAuthenticated(state) {
      state.authenticated = false
    },
    setUserDetails(state, userDetails: ExtendedUserClaims | null) {
      state.userDetails = userDetails
    }
  },
  actions: {
    async loadUserDetails({ commit }) {
      try {
        const candidateClaims = await GetCandidateClaimsFromToken()
        commit('setUserDetails', candidateClaims)
      } catch {
        throw new Error('Error getting user')
      }
    }
  },
  modules: {}
})
