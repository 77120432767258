
import { onMounted } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import { GetEmployerId, oktaAuth } from '@/okta'
import { GetCandidate } from '@/api/CandidateApi'
import { GetAgentProfile } from '@/api/AgentApi'
import store from '@/store'
import { AgentProfile } from '@/types/agent'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import locales from '@/views/LoginRedirect/LoginRedirect.locales.en.json'
import { logErrorMessage } from '@/logging'
import BsSpinner from '@/components/CustomBootstrap/BsSpinner/BsSpinner.vue'
import { EmployerProfile } from '@/types/employer'
import { GetEmployerProfile } from '@/api/EmployerApi'
import { IsValidEmployer } from '@/types/validation/employerValidation'

export default {
  components: {
    BsSpinner
  },
  setup() {
    const { showErrorToast } = useToast()
    const route = useRoute()
    const redirectUrl = route.query.redirectUrl as string

    onMounted(async () => {
      if(route.query.error as string){
        logErrorMessage(route.query.error_description as string ?? 'No description was provided')
        return HandleLoginError()
      }

      await getLoginToken()
    })

    const getLoginToken = async () => {
      try {
        const res = await oktaAuth.token.getWithoutPrompt()
        const tokens = res.tokens
        oktaAuth.tokenManager.setTokens(tokens)

        if(redirectUrl) {
          location.href = redirectUrl.toString()
        } else {
          HandleUser()
        }
      } catch {
        HandleLoginError()
      }
    }

    const HandleUser = async () => {
      const employerId = await GetEmployerId()

      if (employerId) {
        const agent = await GetAgentProfile() ?? {} as AgentProfile
        store.state.agentProfile = agent
        if (!agent.isVerified) {
          router.push('/ReverifyAgent')
          return
        }

        const employer = await GetEmployerProfile(employerId) ?? {} as EmployerProfile
        store.state.employerProfile = employer
        if (!IsValidEmployer(employer)) {
          router.push('/EmployerProfile')
          return
        }

        router.push('/EmployerJobOpportunitiesSummary')
      } else {
        const candidate = await GetCandidate()
        if (candidate.profileData.candidateType) {
          router.push('/JobOpportunitiesSummary')
          return
        }
        const pendingAgent = await GetAgentProfile(true)
        if (pendingAgent) {
          router.push({
            name: 'RegisterEmployer',
            params: {
              firstName: pendingAgent.firstName,
              lastName: pendingAgent.lastName,
              emailAddress: pendingAgent.emailAddress,
              phoneNumber: pendingAgent.phoneNumber,
              formSubmitted: 'true'
            }
          })
          return
        }
        router.push('/SelectUserType')
      }
    }

    const HandleLoginError = () => {
      showErrorToast({ message: locales.login_error})
      router.push('/JobOpportunitiesSummary')
    }

    return {}
  }
}
