
import { defineComponent, ref, watch, computed, nextTick } from 'vue'
import { injectToastQueueStore } from './ToastQueue.utils'
import BsToast from '@/components/CustomBootstrap/BsToast/BsToast.vue'

export const ToastQueue = defineComponent({
  name: 'ToastQueue',
  components: { BsToast },
  props: {
    pollDelay: {
      type: Number,
      default: () => 250
    }
  },
  setup(props) {
    const store = injectToastQueueStore()
    const isOpen = ref(false)
    const head = computed(() => store?.state.head)

    watch(isOpen, (isOpenValue) => {
      if (!isOpenValue) {
        setTimeout(() => {
          store?.actions.poll()
        }, props.pollDelay)
      }
    })

    watch(head, async (headValue) => {
      if (headValue) {
        await nextTick()
        isOpen.value = true
      }
    })

    return {
      head,
      isOpen
    }
  }
})

export default ToastQueue
