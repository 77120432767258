
import { defineComponent, PropType, computed, inject } from 'vue'
import {
  IBsTabContainerStoreSymbol,
  IBsTabContainerStore
} from './BsTab.interfaces'

export const BsTabPanel = defineComponent({
  name: 'BsTabPanel',
  props: {
    for: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const store = inject<IBsTabContainerStore>(IBsTabContainerStoreSymbol)
    const baseId = computed<string>(() => props.for)
    const selected = computed<boolean>(
      () => store?.state.selectedTabId === baseId.value
    )

    return {
      baseId,
      selected
    }
  }
})

export default BsTabPanel
