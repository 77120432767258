import type { ICountryService, ICountry, IPhoneNumber } from './CountryService.interfaces'
import { provideCountryService } from '@/providers/CountryService/CountryService.utils'

export * from '@/providers/CountryService/CountryService.interfaces'
export * from '@/providers/CountryService/CountryService.utils'

export function useCountryService(): ICountryService {

  async function getCountryLib() {
    return import('country-telephone-data')
  }

  async function getPhoneNumberLib() {
    return import('google-libphonenumber')
  }

  async function getCountries(): Promise<ICountry[]> {
    const data = await getCountryLib()
    const frequentCodes: Record<string, number> = { us: 1, gb: 2 }
    return data.default.allCountries.map((country) => {
      return {
        code: country.iso2.toUpperCase(),
        dialingCode: parseInt(country.dialCode, 10),
        name: country.name,
        frequent: frequentCodes[country.iso2] || 0
      }
    })
  }

  async function getPhoneNumberUtilInstance() {
    const { PhoneNumberUtil } = await getPhoneNumberLib()
    return PhoneNumberUtil.getInstance()
  }

  async function parseRawPhoneNumber(rawPhoneNumber: string) {
    const phoneUtil = await getPhoneNumberUtilInstance()
    const gPhoneNumber = phoneUtil.parse(rawPhoneNumber)
    const gNumber = gPhoneNumber.getNationalNumber()
    const gDialingCode = gPhoneNumber.getCountryCode()
    return {
      number: gNumber?.toString() ?? '',
      dialingCode: gDialingCode?.toString() ?? '1',
      countryCode: gDialingCode ? phoneUtil.getRegionCodeForCountryCode(gDialingCode) : 'US'
    }
  }

  async function isValidPhoneNumber({ dialingCode, number, countryCode }: IPhoneNumber) {
    const phoneUtil = await getPhoneNumberUtilInstance()
    try {
      return !!dialingCode && (phoneUtil.isValidNumberForRegion(phoneUtil.parse(number, countryCode), countryCode) ?? false)
    } catch {
      return false
    }
  }

  async function getPhoneNumberExample(countryCode: string) {
    const { PhoneNumberFormat } = await getPhoneNumberLib()
    const phoneUtil = await getPhoneNumberUtilInstance()
    try {
      const gPhoneNumber = phoneUtil.getExampleNumber(countryCode)
      return phoneUtil.format(gPhoneNumber, PhoneNumberFormat.NATIONAL)
    } catch {
      return ''
    }
  }

  return {
    getCountries,
    parseRawPhoneNumber,
    isValidPhoneNumber,
    getPhoneNumberExample
  }
}

export function autoProvideCountryService() {
  const instance = useCountryService()
  provideCountryService(instance)
  return instance
}
