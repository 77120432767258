
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import locales from './AgentProfile.locales.en.json'
import { AgentProfile } from '@/types/agent'
import { EmployerProfile } from '@/types/employer'
import { Models } from 'azure-maps-rest'
import { TextValue } from '@/types'

export default defineComponent({
  components: {
    BsTextField,
    BsMultiSelect,
    Dialog,
    ScrollLayout
  },
  props: {
    employerProfile: {
      type: Object as PropType<EmployerProfile>,
      required: true
    },
    agentProfile: {
      type: Object as PropType<AgentProfile>,
      required: true
    },
    officeLocations: {
      type: Array as PropType<TextValue<Models.SearchResultAddress>[]>,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    isValidProfile: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isError: {
      type: Boolean,
      required: true
    },
    isSaving: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:isOpen', 'handle-submit'],
  setup(props, { emit }) {
    const dialogRef = ref<InstanceType<typeof Dialog>>()
    const formFields = ref({})

    watch(()=> props.isLoading, (newVal)=>{
      if(!newVal){
        formFields.value = props.agentProfile
      }
    })

    watch(()=> props.isOpen, (newVal)=>{
      if(newVal){
        toggleAgentSettingsModal(true)
      }else{
        toggleAgentSettingsModal(false)
      }
    })

    const rules: ValidationArgs<any> = {
      officeLocations: { required }
    }

    const v$ = useVuelidate(rules, formFields.value)

    function toggleAgentSettingsModal(status: boolean) {
      if(status){
        dialogRef.value?.open()
        emit('update:isOpen', true)
      }else{
        dialogRef.value?.close()
        emit('update:isOpen', false)
      }
    }

    const getYourName = computed(()=>{
      return `${props.agentProfile.firstName} ${props.agentProfile.lastName}`
    })

    const getEmployerName = computed(()=>{
      return props.employerProfile.name
    })

    function handleSubmit() {
      emit('handle-submit', formFields.value)
    }

    return {
      formFields,
      v$,
      dialogRef,
      handleSubmit,
      toggleAgentSettingsModal,
      locales,
      getYourName,
      getEmployerName
    }
  }
})
