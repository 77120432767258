import { reactive } from 'vue'
import type {
  IBsAccordionContainerViewModel,
  IBsAccordionContainerActions
} from './Accordion.interface'

export function createBsAccordionStore(multiple: boolean = false) {
  const state: IBsAccordionContainerViewModel = reactive({
    menuState: multiple ? [] : null,
    isMultiple: multiple
  })

  const actions: IBsAccordionContainerActions = {
    selectAccordion
  }

  function selectAccordion(menuId: string) {
    if(state.isMultiple  && typeof state.menuState === 'object'){
      if(state.menuState?.includes(menuId)){
          state.menuState = state.menuState.filter(val=> val !== menuId)
      }else{
        (state.menuState || []).push(menuId)
      }
    }else{
      state.menuState = menuId === state.menuState ? null : menuId
    }
  }

  return { state, actions }
}

export default createBsAccordionStore
