
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import AccordionMenu from '@/components/Accordion/AccordionMenu.vue'
import AccordionExpander from '@/components/Accordion/AccordionExpander.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import locales from '../CandidateProfile.locales.en.json'
import { candidateMenuOptions, otherSubMenuOptions } from '../CandidateProfile.util'
import { PreviewDropdownModel, ProfileCategories } from '../CandidateProfile.interface'
import { CandidateProfile, DreamJobData, Languages } from '@/types/candidate'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { CandidateTypes } from '@/types/opportunity'
import TextEditor from '@/components/Editor/TextEditor.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import { EmployerTypeKey, EmployerTypes } from '@/types/employer'
import { useDownload } from '@/utils/download'

export default defineComponent({
  components: {
    DropdownOptions,
    AccordionMenu,
    AccordionExpander,
    AccordionItem,
    ProfilePicture,
    TextEditor
  },
  props: {
    details: {
      type: Object as PropType<CandidateProfile>,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    attachments: {
      type: Array as PropType<FileMetadata[]>,
      default: ()=> []
    },
    isValidProfile: {
      type: Boolean,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    },
    hideAlert: {
      type: Boolean
    },
    profilePictureSource: {
      type: String,
      default: () => ''
    }
  },
  emits: ['dropdown-change', 'profile-picture-updated', 'open-invite-to-apply-modal'],
  setup(props, { emit }) {
    const attachmentsLists = ref<FileMetadata[]>([])
    const candidateOptionsRef = ref(candidateMenuOptions)
    const { downloadFromCandidateFileMetadata: handleDownload } = useDownload()

    onMounted(()=>{
        attachmentsLists.value = props.attachments.filter(val=> val.fileDescription !== FileDescription.CandidateProfilePicture)
        updateProfileDropdownStatus()
    })

    const showPronouns = computed<boolean>((): boolean => {
      if(!props?.details?.candidateDiversityDisclosure?.usDisclosure?.pronouns) return false
      return (props.details.candidateDiversityDisclosure.usDisclosure.pronouns !== 'I do not wish to disclose')
    })

    watch(()=> props.details.hideProfile, ()=> {
        updateProfileDropdownStatus()
    })

      function updateProfileDropdownStatus() {
        candidateOptionsRef.value.forEach(val => {
          if (val.value === 'hide-profile') {
            val.label = props.details.hideProfile ? locales.show_profile : locales.hide_profile
          }
      })
      }

      function getLocation(dreamJob: DreamJobData){
        return (dreamJob.preferredLocations || []).map(val => `${val.municipality}, ${val.countrySubdivision} (${val.countryCode})`).join(' - ')
      }

      function getLanguages(languages: string[]= []){
          let getLangNames : string[] = []
          Languages.forEach(val=> languages.includes(val.value) && getLangNames.push(val.text))
          return getLangNames.join(', ')
      }

      function getCandidateType(name: string){
        return CandidateTypes.find(val=> val.value === name)?.text ?? ''
      }

      function handleDropdownOptionChange(val: PreviewDropdownModel){
          emit('dropdown-change', val, ProfileCategories.CandidateInfo)
      }

      function formatJobType(jobTypeValue: EmployerTypeKey) {
        return EmployerTypes.find(tvp => tvp.value === jobTypeValue)?.text ?? ''
      }

      const availableOptions = computed(() =>
        props.isValidProfile
        ? candidateOptionsRef.value
        : otherSubMenuOptions
      )

      function updateProfilePicture(imageSource: string) {
        emit('profile-picture-updated', imageSource)
      }

      function openInviteToApplyModal() {
        emit('open-invite-to-apply-modal')
      }

    return {
      availableOptions,
      locales,
      getLocation,
      getLanguages,
      getCandidateType,
      handleDropdownOptionChange,
      formatJobType,
      attachmentsLists,
      showPronouns,
      updateProfilePicture,
      handleDownload,
      openInviteToApplyModal
    }
  }
})
