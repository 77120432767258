
import { defineComponent, PropType } from 'vue'

type BsButtonType = 'button'|'submit'|'reset'
type BsButtonMode = 'regular'|'outline'|'text'|'link'
type BsButtonSize = 'sm'|'lg'

const modeClassMapping: Record<BsButtonMode, string> = {
  regular: 'btn-primary',
  outline: 'btn-outline-primary',
  text: 'btn-text-primary',
  link: 'btn-link'
}

const sizeClassMapping: Record<BsButtonSize, string> = {
  sm: 'btn-sm',
  lg: 'btn-lg'
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<BsButtonType>,
      default: 'button'
    },
    mode: {
      type: String as PropType<BsButtonMode>,
      default: 'regular'
    },
    size: {
      type: String as PropType<BsButtonSize>,
      default: () => undefined
    },
    icon: {
      type: String,
      default: () => undefined
    },
    label: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      modeClassMapping,
      sizeClassMapping
    }
  }
})
