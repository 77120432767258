import { logErrorMessage } from '@/logging'
import { GetEmployerId } from '@/okta'
import axios from 'axios'
import { GetUrlString } from './ApiBase'

async function SendInviteToApply(candidateId: string, opportunityId: string, message: string): Promise<boolean> {
  try{
    const empId = await GetEmployerId()
    const url = GetUrlString('employers/:empId/opportunities/:oppId/invitations/:invitationId', { empId: empId, oppId: opportunityId, invitationId: candidateId })

    await axios.put(url, message)
    return true
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      logErrorMessage('Current user cannot send invitations for this opportunity')
    } else {
      logErrorMessage('Error sending invitation')
    }
    return false
  }
}

export { SendInviteToApply }