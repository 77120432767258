import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d80654c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-end" }
const _hoisted_2 = ["id", "disabled", "onClick"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = ["id", "onClick"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = ["id", "onClick"]
const _hoisted_9 = ["disabled", "onClick"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { key: 2 }
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EducationForm = _resolveComponent("EducationForm")!
  const _component_WorkExperienceInputForm = _resolveComponent("WorkExperienceInputForm")!
  const _component_LawReviewJournalExperienceForm = _resolveComponent("LawReviewJournalExperienceForm")!
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (item, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        (_ctx.repeatType === _ctx.RepeatTypes.SchoolInformationComponent)
          ? (_openBlock(), _createElementBlock("div", {
              key: item.key
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("button", {
                  id: `remove-school-${i}`,
                  class: "bi bi-trash trash-icon delete-icon",
                  "aria-label": "delete icon",
                  disabled: _ctx.elements.length <= 1,
                  type: "button",
                  onClick: ($event: any) => (_ctx.removeElement(i))
                }, null, 8, _hoisted_2)
              ]),
              (_openBlock(), _createBlock(_component_EducationForm, {
                key: item.key,
                modelValue: item.value,
                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                "validation-scope": _ctx.validationScope,
                "id-index": i
              }, null, 8, ["modelValue", "onUpdate:modelValue", "validation-scope", "id-index"]))
            ]))
          : (_ctx.repeatType === _ctx.RepeatTypes.WorkExperience)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    id: `remove-work-experience-${i}`,
                    class: "bi bi-trash trash-icon delete-icon",
                    "aria-label": "delete icon",
                    type: "button",
                    onClick: ($event: any) => (_ctx.removeElement(i))
                  }, null, 8, _hoisted_5)
                ]),
                _createVNode(_component_WorkExperienceInputForm, {
                  modelValue: item.value,
                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                  "id-index": i,
                  "validation-scope": _ctx.validationScope
                }, null, 8, ["modelValue", "onUpdate:modelValue", "id-index", "validation-scope"])
              ]))
            : (_ctx.repeatType === _ctx.RepeatTypes.LawReviewJournalExperience)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      id: `remove-law-review-journal-experience-${i}`,
                      class: "bi bi-trash trash-icon delete-icon",
                      "aria-label": "delete icon",
                      type: "button",
                      onClick: ($event: any) => (_ctx.removeElement(i))
                    }, null, 8, _hoisted_8)
                  ]),
                  _createVNode(_component_LawReviewJournalExperienceForm, {
                    modelValue: item.value,
                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                    "id-index": i,
                    "validation-scope": _ctx.validationScope
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "id-index", "validation-scope"])
                ]))
              : (_openBlock(), _createBlock(_component_BsTextField, {
                  key: 3,
                  id: `${_ctx.label}-generator-${i}`,
                  modelValue: item.value,
                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                  class: "mb-3",
                  label: i <= 0 ? _ctx.label : null,
                  append: "true",
                  placeholder: _ctx.label,
                  maxlength: _ctx.maxLength
                }, {
                  append: _withCtx(() => [
                    _createElementVNode("button", {
                      class: _normalizeClass(['bi bi-trash trash-icon action-icon', {'text-danger' : _ctx.elements.length >= 2}]),
                      "aria-label": "delete icon",
                      disabled: _ctx.elements.length <= 1,
                      type: "button",
                      onClick: ($event: any) => (_ctx.removeElement(i))
                    }, null, 10, _hoisted_9),
                    _createElementVNode("button", {
                      class: "bi bi-plus-circle action-icon",
                      "aria-label": "add icon",
                      disabled: !(item.value || '').trim() || !_ctx.elementCountNotMaxed,
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addElement && _ctx.addElement(...args)))
                    }, null, 8, _hoisted_10)
                  ]),
                  _: 2
                }, 1032, ["id", "modelValue", "onUpdate:modelValue", "label", "placeholder", "maxlength"]))
      ]))
    }), 128)),
    (_ctx.repeatType === _ctx.RepeatTypes.SchoolInformationComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("button", {
            id: "education-add",
            class: "add-btn",
            type: "button",
            disabled: !_ctx.isValidInfoFilled || !_ctx.elementCountNotMaxed,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addElement && _ctx.addElement(...args)))
          }, _toDisplayString(_ctx.locales.add_option), 9, _hoisted_12)
        ]))
      : _createCommentVNode("", true),
    (_ctx.repeatType === _ctx.RepeatTypes.WorkExperience)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("button", {
            id: "work-experience-add",
            class: "add-btn",
            type: "button",
            disabled: !_ctx.isValidInfoFilled || !_ctx.elementCountNotMaxed,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addElement && _ctx.addElement(...args)))
          }, _toDisplayString(_ctx.locales.add_another_experience), 9, _hoisted_14)
        ]))
      : _createCommentVNode("", true),
    (_ctx.repeatType === _ctx.RepeatTypes.LawReviewJournalExperience)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("button", {
            id: "law-review-journal-experience-add",
            class: "add-btn",
            type: "button",
            disabled: !_ctx.isValidInfoFilled || !_ctx.elementCountNotMaxed,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addElement && _ctx.addElement(...args)))
          }, _toDisplayString(_ctx.locales.add_another_law_review_experience), 9, _hoisted_16)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}