
import { defineComponent } from 'vue'
import PostJobOpportunityForm from '@/components/PostJobOpportunityForm.vue'

export default defineComponent({
  name: 'PostJobOpportunity',
  components: {
    PostJobOpportunityForm
  }
})
