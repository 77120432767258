
import { defineComponent, ref, onMounted, watch } from 'vue'
import CandidateSearchTable from './CandidateSearchTable.vue'
import CandidateSearchFilter from './CandidateSearchFilter.vue'
import ListSavedFilters from '@/components/Candidates/ListSavedFilters.vue'
import PaginationBar from '@/components/PaginationBar.vue'
import { injectCandidateSearchStore } from './CandidateSearch.store'
import locales from './CandidateSearch.locales.en.json'

export default defineComponent({
  components: {
    CandidateSearchTable,
    CandidateSearchFilter,
    PaginationBar,
    ListSavedFilters
  },
  setup() {
    const store = injectCandidateSearchStore()
    const { state, actions } = store
    const filterDialogIsOpened = ref(false)

    onMounted(() => {
      actions.loadCandidates()
      actions.loadSavedFilters()
      actions.loadShowDiversityFields()
    })

    function openFiltersDialog() {
      filterDialogIsOpened.value = true
    }

    watch(() => state.currentPage, () => {
      actions.loadCandidates()
    })

    return {
      store,
      locales,
      state,
      actions,
      filterDialogIsOpened,
      openFiltersDialog
    }
  }
})
