
import { ref, Ref, computed, defineComponent, watch } from 'vue'
import AgentDetails from '@/components/AgentProfile/AgentProfile.vue'
import { GetAgentProfile, UpsertAgentProfile } from '@/api/AgentApi'
import { GetEmployerProfile } from '@/api/EmployerApi'
import { AgentProfile } from '@/types/agent'
import { EmployerProfile } from '@/types/employer'
import { BuildLocationKey } from '@/api/AzMapsApi'
import { Models } from 'azure-maps-rest'
import { TextValue } from '@/types'
import { IsValidAgentProfile } from '@/types/validation/agentValidation'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { GetEmployerId } from '@/okta'

export default defineComponent({
  components: {
    AgentDetails
  },
  props:{
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const loading = ref(true)
    const errorLoading = ref(false)
    const isSaving = ref(false)
    const agentProfile = ref({} as AgentProfile)
    const employerProfile = ref({} as EmployerProfile)
    const employerOfficeLocations = ref([]) as Ref<
      TextValue<Models.SearchResultAddress>[]
    >

    const { showErrorToast, showSuccessToast } = useToast()

    watch(()=> props.isOpen, async (newVal)=> {
      if(newVal){
        await getAgentProfile()
      }
    })

    const getAgentProfile = async () => {
      loading.value = true
      errorLoading.value = false
      try {
        agentProfile.value = await GetAgentProfile() ?? {} as AgentProfile
      } catch {
        loading.value = false
        errorLoading.value = true
      }

      if (!errorLoading.value && agentProfile.value.emailAddress) {
        await getEmployerProfile()
      }
    }

    const getEmployerProfile = async () => {
      try {
        employerProfile.value = await GetEmployerProfile()
        employerOfficeLocations.value =
          employerProfile.value.officeLocations.map((loc) => ({
            text: BuildLocationKey(loc),
            value: loc
          }))
        loading.value = false
      } catch {
        errorLoading.value = true
      }
    }

    const updateProfile = async (val: AgentProfile) => {
      isSaving.value = true
      try {
        const employerId = await GetEmployerId()
        await UpsertAgentProfile(val, employerId)
        emit('update:isOpen', false)
        showSuccessToast({
          message: 'Successfully updated agent profile',
          position: 'top-center'
        })
      } catch {
        showErrorToast({
          message: 'Error!'
        })
      } finally {
        isSaving.value = false
      }
    }

    const validProfile = computed((): boolean => {
      return IsValidAgentProfile(agentProfile.value)
    })

    const computeIsOpen = computed({
      get:()=> props.isOpen,
      set: (newVal)=> emit('update:isOpen', newVal)
    })

    return {
      loading,
      errorLoading,
      agentProfile,
      employerProfile,
      employerOfficeLocations,
      updateProfile,
      validProfile,
      computeIsOpen,
      isSaving
    }
  }
})
