import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { UserFriendlyError } from '@/utils/error'
import { logErrorMessage } from '@/logging'

export const useToastMessages = () => {
  const { showErrorToast } = useToast()

  const showToastFromError = (error: unknown) => {
    const isExpectedError = error instanceof UserFriendlyError
    showErrorToast({
      title: '',
      message: isExpectedError ? error.message : 'Unexpected error',
      position: 'top-center'
    })
    if (!isExpectedError && error instanceof Error) {
      logErrorMessage(error.message)
    }
  }

  return {
    showToastFromError
  }
}
