
import { computed, defineComponent, PropType } from 'vue'
import { UserClaims } from '@okta/okta-auth-js'
import locales from './Header.locales.en.json'

export default defineComponent({
  props: {
    nameDetails: {
      type: [Object, null] as PropType<UserClaims>,
      default: () => null
    }
  },
  setup(props) {
    const getNameAcronym = computed(() => {
      let nameAcronym = ''
      if (props.nameDetails) {
        let getName = (props.nameDetails.name || '').match(/\b(\w)/g)
        nameAcronym = (getName || []).slice(0,2).join('')
      }
      return nameAcronym
    })

    return {
      locales,
      getNameAcronym
    }
  }
})
