import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-354ce750"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-confirmation-segment" }
const _hoisted_2 = {
  id: "edit-confirmation__title",
  class: "heading"
}
const _hoisted_3 = { class: "btn-content" }
const _hoisted_4 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      id: "edit-confirmation-modal",
      ref: "dialogRef",
      class: "edit-confirmation-modal-main",
      "aria-labelled-by": "edit-confirmation__title"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          class: "modal-wrapper",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.locales.edit_confirmation_msg), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-outline-primary",
              type: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
            }, _toDisplayString(_ctx.locales.cancel), 1),
            _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.locales.agree), 1)
          ])
        ], 32)
      ]),
      _: 1
    }, 512)
  ]))
}