
import { Data } from '@/types'
import { PropType, ref, computed, watch } from 'vue'
import { AddressFields, CountryAddressDetails } from '@/types/opportunity'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import FieldRequiredMessage from './FieldRequiredMessage.vue'

export default {
  components: {
    BsSelect,
    BsTextField,
    FieldRequiredMessage
  },
  props: {
    addressFieldValues: {
      type: Object as PropType<AddressFields>,
      default: undefined
    },
    hideTextFields: {
      type: Boolean,
      default: false
    },
    formSubmitted: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:addressFieldValues'],
  setup(props: any, context: any): Data {
    const refCountry = ref('US')
    const refAddressFieldValues = ref({} as AddressFields)
    const blankAddressFieldValues = {
      addressLine1: '',
      addressLine2: '',
      locality: '',
      dependentLocality: '',
      administrativeArea: '',
      postalCode: ''
    } as AddressFields
    const textFieldsHidden = ref(false)
    const isFormSubmitted = ref(false)

    const addressFieldNames = computed<AddressFields>(() => {
      const selectedCountry = CountryAddressDetails.find(
        (details) => details.isoCode === refCountry.value
      )
      if (selectedCountry === null || selectedCountry === undefined) {
        return {} as AddressFields
      }
      return selectedCountry.addressFields
    })

    watch(refCountry, (newCountry: string) => {
      Object.assign(refAddressFieldValues, blankAddressFieldValues)
      refAddressFieldValues.value.country = newCountry
    })

    watch(
      refAddressFieldValues,
      (newValues: AddressFields) => {
        context.emit('update:addressFieldValues', newValues)
      },
      { deep: true }
    )

    watch(
      () => props.addressFieldValues,
      (newValues: AddressFields) => {
        if (newValues) {
          refCountry.value = newValues.country ?? 'US'
          refAddressFieldValues.value = newValues
        }
      }
    )

    watch(
      () => props.hideTextFields,
      (newValue: boolean) => {
        textFieldsHidden.value = newValue
      }
    )

    watch(
      () => props.formSubmitted,
      (newValue: boolean) => {
        isFormSubmitted.value = newValue
      }
    )

    return {
      refCountry,
      refAddressFieldValues,
      CountryAddressDetails,
      addressFieldNames,
      textFieldsHidden,
      isFormSubmitted
    }
  }
}
