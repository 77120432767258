
import { ref, onMounted, Ref, computed, watch, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { EmployerProfile } from '@/types/employer'
import { DeleteEmployerFile, GetEmployerFiles, GetEmployerProfile, PostEmployerFileUpload } from '@/api/EmployerApi'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import EmployerProfileComponent from '@/components/EmployerProfile/EmployerProfileComponent.vue'
import EmployerProfileEditComponent from '@/components/EmployerProfile/EmployerProfileEditComponent.vue'
import EmployerProfileCandidateView from '@/components/EmployerProfile/EmployerProfileCandidateView.vue'
import ProfilePictureUpload from '@/components/ProfilePictureUpload/ProfilePictureUpload.vue'
import { EmployerDetailsModel } from '@/components/EmployerProfile/EmployerProfile.interface'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { IsValidEmployer } from '@/types/validation/employerValidation'

export default {
  components: {
    EmployerProfileComponent,
    EmployerProfileEditComponent,
    EmployerProfileCandidateView,
    ProfilePictureUpload
  },
  setup () {
    const { showErrorToast } = useToast()
    const errorLoading = ref(false)
    const loading = ref(true)
    const route = useRoute()
    const optionalEmpId = route.params.id as string
    let empId = ''
    const employerProfile = ref({} as EmployerProfile)
    const employerImages = ref([]) as Ref<FileMetadata[]>
    const logoImage = ref<FileMetadata>()
    const logoImageSource = ref('')
    const viewingAs = ref(false)
    const editProfileDialog = ref<InstanceType<typeof EmployerProfileEditComponent>>()
    const candidateViewDialog = ref<InstanceType<typeof EmployerProfileCandidateView>>()
    const profilePictureUpload = ref<InstanceType<typeof ProfilePictureUpload>>()
    const isLoadingProfile = ref(false)
    const forceComplete = computed(() => !IsValidEmployer(employerProfile.value))

    const getEmployerProfile = async () => {
      isLoadingProfile.value = true
      try {
        if (!optionalEmpId){
          employerProfile.value = await GetEmployerProfile()
          empId = employerProfile.value.id
        } else {
          empId = optionalEmpId
          employerProfile.value = await GetEmployerProfile(empId)
          viewingAs.value = true
        }
      } catch {
        errorLoading.value = true
        showErrorToast({
          message: 'Cannot load employer profile'
        })
      } finally {
        isLoadingProfile.value = false
      }
    }

    const getEmployerImages = async (): Promise<void> => {
      const fileResponse = await GetEmployerFiles(empId)
      employerImages.value = fileResponse.filter(file => file.fileDescription === 'EmployerImage')
      logoImage.value = fileResponse.find(f => f.fileDescription === 'EmployerLogo')
    }

    const employerDetails = computed(() : EmployerDetailsModel => {
      return {
        companyName: employerProfile.value.name,
        locations: employerProfile.value.officeLocations ?? [],
        description: employerProfile.value.description ?? '',
        website: employerProfile.value.website,
        linkedinLink: employerProfile.value.linkedInLink ?? '',
        employerType: employerProfile.value.employerType ?? '',
        attachments: employerImages.value,
        employer: employerProfile.value
      }
    })

    const viewAsCandidate = async (): Promise<void> =>{
      candidateViewDialog.value?.toggleModal(true)
    }

    const editEmployerProfile = async (): Promise<void> => {
      await editProfileDialog.value?.assignProfileValues()
      editProfileDialog.value?.toggleModal(true)
    }

    const updateProfile = async(): Promise<void> =>{
      await getEmployerProfile()
      if (!errorLoading.value) {
        await getEmployerImages()
      }

      await nextTick()
      if (forceComplete.value && !viewingAs.value) {
        editEmployerProfile()
      }
    }

    async function handleUploadProfilePic(file: File) {
      profilePictureUpload.value?.openWithFile(file)
    }

    async function handleCroppedPicture(blob: Blob) {
      try {
        const file = new File([blob], FileDescription.EmployerLogo + '.jpg')
        if(logoImage.value?.domainObjectId){
          await DeleteEmployerFile(logoImage.value.domainObjectId)
          window.URL.revokeObjectURL(logoImageSource.value)
        }
        let result = await PostEmployerFileUpload(file, FileDescription.EmployerLogo)
        logoImage.value = { domainObjectId: result.data, fileDescription: FileDescription.EmployerLogo } as FileMetadata
        logoImageSource.value = window.URL.createObjectURL(blob)
      }
      catch{
        showErrorToast({
          message: 'Cannot change current profile picture'
        })
      }
    }

    watch(()=> route.params, (newValue) => {
        viewingAs.value = !!newValue.id
    }, {deep: true})

    onMounted(async () => {
      await updateProfile()
      loading.value = false
    })

    function updateLogoImageSource(imageSource: string) {
      logoImageSource.value = imageSource
    }

    return {
      employerProfile,
      employerImages,
      loading,
      errorLoading,
      employerDetails,
      viewingAs,
      editProfileDialog,
      logoImage,
      logoImageSource,
      isLoadingProfile,
      getEmployerProfile,
      getEmployerImages,
      viewAsCandidate,
      editEmployerProfile,
      updateProfile,
      candidateViewDialog,
      handleUploadProfilePic,
      handleCroppedPicture,
      profilePictureUpload,
      updateLogoImageSource,
      forceComplete
    }
  }
}
