import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { GetApplicationFileData, GetCandidateFileData } from '@/api/CandidateApi'
import { useToastMessages } from '@/utils/ToastMessages'

export const downloadFromBlob = (blob: Blob, fileName: string) => {
  const fileURL = window.URL.createObjectURL(blob)
  const fileLink = document.createElement('a')
  fileLink.href = fileURL
  fileLink.setAttribute('download', fileName)
  fileLink.click()
  setTimeout(() => fileLink.remove())
}

export const useDownload = () => {
  const { showToastFromError } = useToastMessages()

  const downloadFromCandidateFileMetadata = async (candidateId: string, fileMetadata: FileMetadata) => {
    try {
      const result = await GetCandidateFileData(candidateId, fileMetadata.domainObjectId)
      downloadFromBlob(result, fileMetadata.fileName)
    } catch (error) {
      showToastFromError(error)
    }
  }

  const downloadFromApplicationFileMetadata = async (opportunityId: string, applicationId: string, fileMetadata: FileMetadata) => {
    try {
      const result = await GetApplicationFileData(opportunityId, applicationId, fileMetadata.domainObjectId)
      downloadFromBlob(result, fileMetadata.fileName)
    } catch (error) {
      showToastFromError(error)
    }
  }

  return {
    downloadFromCandidateFileMetadata,
    downloadFromApplicationFileMetadata
  }
}
