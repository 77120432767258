
import { defineComponent, PropType } from 'vue'
import HeadingWrapper from './HeadingWrapper.vue'
import locales from '../CandidateProfile.locales.en.json'
import { otherSubMenuOptions } from '../CandidateProfile.util'
import { PreviewDropdownModel, ProfileCategories } from '../CandidateProfile.interface'
import { CandidateProfile } from '@/types/candidate'

export default defineComponent({
  components: {
    HeadingWrapper
  },
  props: {
    details: {
      type: Object as PropType<CandidateProfile>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    }
  },
  emits: ['dropdown-change'],
  setup(_, { emit }) {
    function handleOptionChange(val: PreviewDropdownModel) {
      emit('dropdown-change', val, ProfileCategories.Certifications)
    }

    return {
      locales,
      handleOptionChange,
      otherSubMenuOptions
    }
  }
})
