
import { defineComponent, PropType } from 'vue'
import CountryFlag from 'vue-country-flag-next'
import { ICountry } from '@/providers/CountryService/CountryService'

export const BsPhoneNumberDropdownItem = defineComponent({
  name: 'BsPhoneNumberDropdownItem',
  components: { CountryFlag },
  props: {
    country: {
      type: Object as PropType<ICountry>,
      required: true
    },
    focused: {
      type: Boolean,
      default: () => false
    },
    selected: {
      type: Boolean,
      default: () => false
    }
  }
})

export default BsPhoneNumberDropdownItem
