
import { defineComponent, reactive, ref, PropType, computed } from 'vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import locales from './ApplyOpportunityForm.locales.en.json'
import { ApplyToOpportunityFormModel } from './ApplyOpportunityForm.interfaces'
import { PostCandidateFileUpload, GetCandidate } from '@/api/CandidateApi'
import UploadFileCard from './UploadFileCard.vue'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { JobOpportunity } from '@/types/opportunity'
import { SubmitApplication } from '@/api/ApplicationApi'
import { useToast } from '../ToastQueue/ToastQueue.utils'
import { IsValidCandidate } from '@/types/validation/candidateValidation'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { useDownload } from '@/utils/download'

export default defineComponent({
  components: {
    Dialog,
    BsTextField,
    BsSelect,
    ScrollLayout,
    UploadFileCard
  },
  props: {
    opportunity: {
      type: Object as PropType<JobOpportunity>,
      required: true
    }
  },
  emits: ['apply', 'cancel'],
  setup(props, { emit }) {
    const { downloadFromCandidateFileMetadata: handleDownload } = useDownload()
    const { showErrorToast } = useToast()
    type DialogInstance = InstanceType<typeof Dialog>
    const isGracePeriod = ref(false)
    var currentTimeout: NodeJS.Timeout | undefined = undefined

    const dialogRef = ref<DialogInstance>()
    const isProfileComplete = ref<boolean>(true)
    const formFields = reactive<ApplyToOpportunityFormModel>({
      candidateName: '',
      phoneNumber: '',
      email: '',
      resume: [],
      selectedResume: null,
      sample: [],
      selectedSample: null,
      transcript: [],
      selectedTranscript: null
    })

    const maxCandidateFileUploads = 5

    const allFiles = ref([] as FileMetadata[])

    const disableResumeSelect = computed(() => {
      return resumes.value.length > 0
    })

    const disableSampleSelect = computed(() => {
      return writingSamples.value.length > 0
    })

    const disableTranscriptSelect = computed(() => {
      return transcripts.value.length > 0
    })

    const resumes = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(
        (f) => f.fileDescription === FileDescription.CandidateResume
      )
    })

    const writingSamples = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(
        (f) => f.fileDescription === FileDescription.CandidateWritingSample
      )
    })

    const transcripts = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(f => f.fileDescription === FileDescription.CandidateTranscript)
    })

    const computeResumeRequired = computed<boolean>(() =>
      props?.opportunity?.opportunityListing?.fileRequirementsToApply?.includes(
        FileDescription.CandidateResume
      )
      ?? false
    )

    const computeSampleRequired = computed<boolean>(() =>
      props?.opportunity?.opportunityListing?.fileRequirementsToApply?.includes(
        FileDescription.CandidateWritingSample
      )
      ?? false
    )

    const computeTranscriptRequired = computed<boolean>(() =>
      props?.opportunity?.opportunityListing?.fileRequirementsToApply?.includes(
        FileDescription.CandidateTranscript
      )
      ?? false
    )

    const rules = computed(() => {
      const ruleOptions: ValidationArgs<any> = {
        selectedResume: {
          required: requiredIf(computeResumeRequired.value)
        },
        selectedSample: {
          required: requiredIf(computeSampleRequired.value)
        },
        selectedTranscript: {
          required: requiredIf(computeTranscriptRequired.value)
        }
      }

      return ruleOptions
    })
    const v$ = useVuelidate(rules, formFields)

    const toggleModal = (status: boolean) => {
      status ? dialogRef.value?.open() : dialogRef.value?.close()
    }

    const setCandidateValues = async () => {
      reset()

      const candidate = await GetCandidate()

      currentTimeout = setTimeout(() => {
        isGracePeriod.value = true
      }, getGracePeriodExpiration())

      formFields.candidateName = `${candidate.profileData.firstName} ${candidate.profileData.lastName}`
      formFields.phoneNumber = candidate.profileData.phoneNumber
      formFields.email = candidate.profileData.emailAddress
      formFields.resume = candidate.candidateFileMetadata.filter(
        (cfm) => cfm.fileDescription === FileDescription.CandidateResume
      )
      formFields.sample = candidate.candidateFileMetadata.filter(
        (cfm) => cfm.fileDescription === FileDescription.CandidateWritingSample
      )
      formFields.transcript = candidate.candidateFileMetadata.filter(
        (cfm) => cfm.fileDescription === FileDescription.CandidateTranscript
      )

      if (
        (computeTranscriptRequired.value && !formFields.transcript.length) ||
        (computeResumeRequired.value && !formFields.resume.length) ||
        (computeSampleRequired.value && !formFields.sample.length) ||
        !IsValidCandidate(candidate)
      ) {
        isProfileComplete.value = false
      }
    }

    const getGracePeriodExpiration = () => {
      if (!props.opportunity.opportunityListing?.applicationDeadline) {
        return 0
      }
      const applicationDeadline = new Date(
        props.opportunity.opportunityListing.applicationDeadline
      )

      const MAX_TIMEOUT_MILLISECONDS = 2147483647
      const delay = applicationDeadline.getTime() - new Date().getTime()
      return Math.min(delay, MAX_TIMEOUT_MILLISECONDS)
    }

    const handleUpload = async (file: File, fileDescription: FileDescription): Promise<void> =>{
      let changeType = ''
      switch(fileDescription) {
        case FileDescription.CandidateResume:
          if(formFields.resume.length >= maxCandidateFileUploads) {
            showErrorToast({
              message: locales.upload_error + locales.resumes + ' ('
               + maxCandidateFileUploads + ')'
            })
            return
          }
          changeType = FileDescription.CandidateResume
          break
          case FileDescription.CandidateWritingSample:
            if(formFields.sample.length >= maxCandidateFileUploads) {
              showErrorToast({
                message: locales.upload_error + locales.writing_samples + ' ('
               + maxCandidateFileUploads + ')'
              })
              return
            }
            changeType = FileDescription.CandidateWritingSample
            break
            case FileDescription.CandidateTranscript:
              if(formFields.transcript.length >= maxCandidateFileUploads) {
              showErrorToast({
                message: locales.upload_error + locales.transcripts + ' ('
                 + maxCandidateFileUploads + ')'
              })
              return
            }
            changeType = FileDescription.CandidateTranscript
            break
      }
      const fileId = await PostCandidateFileUpload(file, fileDescription)

      const newFileMetadata: FileMetadata = {
        domainObjectId: fileId,
        fileName: file.name,
        fileUploadTimestamp: new Date(),
        fileType: file.type,
        fileDescription: fileDescription
      }

      allFiles.value.push(newFileMetadata)

      // Update Candidate Cache
      const candidate = await GetCandidate()
      candidate.candidateFileMetadata.push(newFileMetadata)

      switch(changeType) {
        case FileDescription.CandidateResume:
          formFields.resume.push(newFileMetadata)
          formFields.selectedResume = fileId
          break
        case FileDescription.CandidateWritingSample:
          formFields.sample.push(newFileMetadata)
          formFields.selectedSample = fileId
          break
        case FileDescription.CandidateTranscript:
          formFields.transcript.push(newFileMetadata)
          formFields.selectedTranscript = fileId
          break
      }
    }

    const handleSubmit = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }

      try {
        await SubmitApplication(
          props.opportunity.id,
          (resumes.value.length > 0) ? resumes.value[0].domainObjectId : formFields.selectedResume ?? '',
          (transcripts.value.length > 0) ? transcripts.value[0].domainObjectId : formFields.selectedTranscript ?? '',
          (writingSamples.value.length > 0) ? writingSamples.value[0].domainObjectId : formFields.selectedSample ?? ''
        )
        emit('apply')
        resetAndClose()
      } catch {
        showErrorToast({message: 'There was an error applying to this opportunity.  Please try again later.'})
      }
    }

    const cancel = () => {
      emit('cancel')
      resetAndClose()
    }

    const reset = () => {
      v$.value.$reset()
      isProfileComplete.value = true

      isGracePeriod.value = false
      if(currentTimeout){
        clearTimeout(currentTimeout)
      }

      formFields.selectedResume = null
      formFields.selectedSample = null
      formFields.selectedTranscript = null
      allFiles.value = []
    }

    const resetAndClose = () => {
      reset()
      dialogRef.value?.close()
    }

    return {
      dialogRef,
      locales,
      toggleModal,
      formFields,
      handleSubmit,
      v$,
      isProfileComplete,
      cancel,
      setCandidateValues,
      isGracePeriod,
      handleUpload,
      handleDownload,
      FileDescription,
      writingSamples,
      resumes,
      transcripts,
      disableResumeSelect,
      disableSampleSelect,
      disableTranscriptSelect
    }
  }
})
