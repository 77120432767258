import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0edc11d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "data-invalid", "data-disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["BsAutoComplete form-control-helper", { 'bs-invalid-field': _ctx.dirty && _ctx.invalid }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.selectId,
          class: "BsAutoComplete__label form-label bs-field-label",
          "data-invalid": _ctx.dirty && _ctx.invalid,
          "data-disabled": _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AutoComplete, {
        id: _ctx.selectId,
        ref: "autocomplete",
        modelValue: _ctx.computeModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computeModelValue) = $event)),
        multiple: _ctx.multiple,
        suggestions: _ctx.suggestions,
        field: _ctx.field,
        "append-to": _ctx.appendToSelector,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        "aria-describedby": _ctx.invalidMessage && _ctx.invalidMessageId,
        required: _ctx.dirty && _ctx.invalid,
        "force-selection": _ctx.forceSelection,
        class: _normalizeClass({
          'is-invalid': _ctx.dirty && _ctx.invalid,
          BsAutoComplete__select: true,
        }),
        "complete-on-focus": _ctx.completeOnFocus,
        onComplete: _ctx.handleUserSearch
      }, null, 8, ["id", "modelValue", "multiple", "suggestions", "field", "append-to", "placeholder", "disabled", "aria-describedby", "required", "force-selection", "class", "complete-on-focus", "onComplete"]),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: _ctx.invalidMessage && _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}