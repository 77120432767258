
import { Data } from '@/types'
import { onMounted, ref } from 'vue'
import { ChangeApplicationStatus, MarkApplicationAsViewedByAgent } from '@/api/ApplicationApi'
import { Application } from '@/types/application'
import store from '@/store'

export default {
  setup(): Data {
    const loading = ref(true)
    const errorMessage = ref('')
    const application = ref({} as Application | undefined)

    const markAsReviewed = async () => {
      if (application.value) {
        await ChangeApplicationStatus(application.value.opportunityId, application.value.id, 'Reviewed')
        application.value.status = 'Reviewed'
      }
    }

    onMounted(async () => {
      application.value = store.state.application
      if (!application.value) {
        errorMessage.value = 'Error loading application'
        loading.value = false
        return
      } 
      
      if (application.value.status === 'NotReviewed') {
        await markAsReviewed()
      }
      
      await MarkApplicationAsViewedByAgent(application.value.opportunityId, application.value.id)
      
      loading.value = false
    })

    return {
      loading,
      errorMessage,
      application
    }
  }
}
