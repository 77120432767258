import {
  CurrencyKey,
  JobOpportunity,
  RequirementToApplyKey,
  SalaryKey,
  AddressFields
} from '@/types/opportunity'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { IsValidString } from '@/types/validation/genericValidation'
import { IsValidUrl } from '@/types/validation/urlValidation'
import { validate } from 'validate.js'

function IsValidJobOpportunity(jobOpportunity : JobOpportunity) : boolean {
  return !!jobOpportunity.opportunityListing
    && IsValidString(jobOpportunity.opportunityListing.title)
    && IsValidString(jobOpportunity.opportunityListing.description)
    && IsValidString(jobOpportunity.opportunityListing.opportunityType)
    && IsValidString(jobOpportunity.opportunityListing.applyEmail)
    && IsValidString(jobOpportunity.opportunityListing.recruiterContact)
    && IsValidString(jobOpportunity.opportunityListing.recruiterTitle)
    && IsValidEmail(jobOpportunity.opportunityListing.recruiterEmail)
    && IsValidApplicationEmail(jobOpportunity.opportunityListing.applyType == 'EmailReceive', jobOpportunity.opportunityListing.applicationReceiveEmail)
    && IsValidExternalURL(jobOpportunity.opportunityListing.applyType == 'ExternalDirect', jobOpportunity.opportunityListing.externalApplicationUrl)
    && IsValidFutureDate(jobOpportunity.opportunityListing.startDate)
    && IsValidFutureDate(jobOpportunity.opportunityListing.applicationDeadline)
    && AreValidRequirements(jobOpportunity.opportunityListing.requirementsToApply, jobOpportunity.opportunityListing.fileRequirementsToApply)
    && IsValidSalaryInfo(jobOpportunity.opportunityListing.includeSalaryInfo,
                         jobOpportunity.opportunityListing.salaryType,
                         jobOpportunity.opportunityListing.currency,
                         jobOpportunity.opportunityListing.salary)
    && IsValidYouTubeId(jobOpportunity.opportunityListing.youTubeVideoId)
    && IsValidLocation(jobOpportunity.opportunityListing.remote,
                       !!jobOpportunity.opportunityListing.officeLocation,
                       jobOpportunity.opportunityListing.address)
}

function IsValidFutureDate(date: Date | undefined): boolean {
  return !!date && !isNaN(date.getTime()) && date > new Date()
}

function IsValidExternalURL(isExternalApplyType: boolean, url: string) {
  if (!isExternalApplyType) {
    return true
  }
  return IsValidUrl(url)
}

function IsValidApplicationEmail(isEmailApplyType: boolean, email: string) {
  if (!isEmailApplyType) {
    return true
  }
  return (
    validate({ email: email }, { email: { presence: true, email: true } }) ===
    undefined
  )
}

function IsValidEmail(email: string) {
  return (
    validate({ email: email }, { email: { presence: true, email: true } }) ===
    undefined
  )
}

function AreValidRequirements(requirements: Array<RequirementToApplyKey>, fileRequirements: Array<FileDescription>) {
  return (requirements.length > 0 || fileRequirements.length > 0)
    && !(fileRequirements.length > 1 && fileRequirements.includes(FileDescription.None))
}

function IsValidSalaryInfo(
  includeSalaryInfo: boolean,
  salaryType: SalaryKey | undefined,
  currency: CurrencyKey | undefined,
  salary: number | undefined
) {
  if (includeSalaryInfo) {
    return (
      !!salaryType &&
      IsValidString(salaryType) &&
      !!currency &&
      IsValidString(currency) &&
      (!!salary || salary === 0) &&
      salary >= 0
    )
  } else {
    return !salaryType && !currency && !salary
  }
}

function IsValidYouTubeId(youTubeId: string | undefined) {
  return !youTubeId || youTubeId.length === 11
}

function IsValidLocation(
  remote: boolean,
  hasOfficeLocation: boolean,
  address: AddressFields | undefined
) {
  if((remote && address?.country) || hasOfficeLocation) {
    return true
  } else {
    return (
      !!address?.addressLine1 &&
      !!address?.country &&
      !!address?.locality &&
      !!address?.administrativeArea &&
      !!address?.postalCode
    )
  }
}

export {
  IsValidJobOpportunity,
  IsValidSalaryInfo,
  IsValidExternalURL,
  IsValidApplicationEmail,
  IsValidString,
  IsValidFutureDate,
  AreValidRequirements,
  IsValidYouTubeId,
  IsValidLocation
}
