
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  computed,
  PropType,
  onUpdated,
  nextTick
} from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { RepeatTypes, TextValue } from '@/types'
import {
  DualDegrees,
  SchoolInformation,
  Candidate,
  CandidateProfile,
  CandidateWorkExperience,
  DiversityDisclosureUK,
  DiversityDisclosureUS,
  LawReviewJournalExperience
} from '@/types/candidate'
import { PutCandidateProfile } from '@/api/CandidateApi'
import {
  GetIncompleteRequiredFields,
  IsValidCandidate,
  RequiredFields
} from '@/types/validation/candidateValidation'
import { IsValidUserLinkedInUrl } from '@/types/validation/urlValidation'
import { Models } from 'azure-maps-rest'
import { BuildLocationKey } from '@/api/AzMapsApi'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import CandidateInfoForm from './CandidateInfoForm.vue'
import EducationCard from './EducationCard.vue'
import WorkExperienceForm from './WorkExperienceForm.vue'
import AwardsHonorsForm from './AwardsHonorsForm.vue'
import DiversityWrapper from '@/components/DiversityDisclosure/DiversityWrapper.vue'
import DiversityDisclosureCard from './DiversityDisclosureCard.vue'
import CertificationsForm from './CertificationsForm.vue'
import DynamicInputGenerator from './DynamicInputGenerator.vue'
import locales from '../CandidateProfile.locales.en.json'
import { stripHtmlTags } from '../CandidateProfile.util'
import { ProfileCategories } from '../CandidateProfile.interface'
import { logErrorMessage } from '@/logging'
import { containsValue } from '@/utils/general'
import { ContainsValidSchoolInformation } from '@/types/validation/schoolInformationValidation'

const validateLinkedinUrl = () =>
  helpers.withMessage(locales.enter_valid_linkedin_url, (value: string) =>
    IsValidUserLinkedInUrl(value)
  )

const validateEditorLength = () =>
  helpers.withMessage(
    locales.enter_below_2000,
    (value: string) => stripHtmlTags(value).length <= 2000
  )

export default defineComponent({
  components: {
    AwardsHonorsForm,
    CandidateInfoForm,
    DiversityWrapper,
    CertificationsForm,
    Dialog,
    DynamicInputGenerator,
    EducationCard,
    ScrollLayout,
    WorkExperienceForm,
    DiversityDisclosureCard
  },
  props: {
    candidateDetails: {
      type: Object as PropType<Candidate>,
      required: true
    },
    openedMenuItem: {
      type: [Number, null] as PropType<number | null>,
      required: true
    },
    validationScope: {
      type: String,
      default: () => 'edit-candidate-profile'
    },
    isValidProfile: {
      type: Boolean,
      default: true
    },
    forceComplete: {
      type: Boolean
    },
    profilePictureSource: {
      type: String,
      default: () => undefined
    }
  },
  emits: ['success-update', 'profile-picture-updated'],
  setup(props, { emit }) {
    const sectionIdPrefix = 'edit-candidate-profile-section-'
    const candidate = reactive({ profileData: {} as CandidateProfile } as Candidate)
    const dialogRef = ref<InstanceType<typeof Dialog>>()
    const selectedCities = ref()
    const editCandidateProfileContentRef = ref()
    const isLoading = ref<boolean>(false)
    const form = ref<HTMLElement>()
    const showEducationForm = ref(false)
    const showDiversityForm = ref(false)

    const { showErrorToast, showSuccessToast } = useToast()

    const rules = computed(() => {
      const ruleOptions: any = {
        profileData: {
          linkedInLink: {
            validateLinkedinUrl: validateLinkedinUrl()
          },
          candidateType: { required },
          languages: { required },
          dreamJobData: {
            practiceAreas: { required },
            dreamJobTypes: { required },
            preferredLocations: { required }
          },
          description: {
            validateLength: validateEditorLength()
          }
        }
      }

      return ruleOptions
    })

    const v$ = useVuelidate(rules, candidate, {
      $scope: props.validationScope || false
    })

    async function closeModal(cancelled: boolean) {
      if (cancelled) {
        await getCandidate()
      }
      showEducationForm.value = false
      showDiversityForm.value = false
      dialogRef.value?.close()
    }

    onMounted(async () => {
      await getCandidate()
      v$.value.$touch()
    })

    onUpdated(async () => {
        await nextTick() // have to wait to ensure DOM is fully loaded. Not waiting can lead to half-scroll issues with elements near bottom of the window
        let currentItem =
          editCandidateProfileContentRef.value?.querySelector(`#${sectionIdPrefix}` + props.openedMenuItem)
        if (currentItem) {
          currentItem.scrollIntoView()
        }
    })

    const getCandidate = async (): Promise<void> => {
      candidate.id = props.candidateDetails.id
      candidate.profileData = { ...props.candidateDetails.profileData } as CandidateProfile
      candidate.candidateFileMetadata = props.candidateDetails.candidateFileMetadata
      if (!candidate.profileData.schoolInformation?.length) {
        candidate.profileData.schoolInformation = [{} as SchoolInformation]
      }
      selectedCities.value =
        candidate.profileData.dreamJobData.preferredLocations?.map((loc) => ({
          text: BuildLocationKey(loc),
          value: loc
        }))
      if (!candidate.profileData.candidateDiversityDisclosure) {
        candidate.profileData.candidateDiversityDisclosure = {
          usDisclosure: {} as DiversityDisclosureUS,
          ukDisclosure: {} as DiversityDisclosureUK
        }
      }
    }

    const updateCandidateProfile = async () => {
      const cand = candidate as Candidate
      if (IsValidCandidate(cand)) {
        try {
          isLoading.value = true

          if (!cand.profileData.studentOrganizations?.includes('Other')) {
            cand.profileData.otherStudentOrganization = undefined
          }

          if(cand.profileData.lawReviewJournalExperiences.length > 0) {
            cand.profileData.lawReviewJournalExperiences = cand.profileData.lawReviewJournalExperiences.filter(
              (lr: LawReviewJournalExperience) => lr.lawReviewJournal && lr.titlePosition
            )
          }

          if(cand.profileData.workExperience.length > 0) {
            cand.profileData.workExperience = cand.profileData.workExperience.filter(
              (we: CandidateWorkExperience) => we.positionTitle && we.candidateEmployer && we.startYear
            )
          }

          await PutCandidateProfile(cand)
          emit('success-update', cand)
          closeModal(false)
          showSuccessToast({
            message: locales.success_onupdate,
            position: 'top-center'
          })
        } catch {
          showErrorToast({
            message: locales.error_onupdate,
            position: 'top-center'
          })
        } finally {
          isLoading.value = false
        }
      } else {
        showErrorToast({
          message: locales.fill_all_values,
          position: 'top-center'
        })
      }
    }

    const invalidProfile = computed((): boolean => {
      var candidateProfile = candidate as Candidate
      return !IsValidCandidate(candidateProfile)
    })

    const incompleteRequiredFields = computed(() => {
      return GetIncompleteRequiredFields(candidate)
    })

    const percentComplete = computed(() => {
      return Math.round(
        (100 * (RequiredFields.length - incompleteRequiredFields.value.length)) /
          RequiredFields.length
      )
    })

    const toggleShowEducationForm = () => showEducationForm.value = !showEducationForm.value

    const toggleShowDiversityForm = () => showDiversityForm.value = !showDiversityForm.value

    watch(selectedCities, (newVal: TextValue<Models.SearchResultAddress>[]) => {
      candidate.profileData.dreamJobData.preferredLocations =
        newVal.map((tvp) => tvp.value ?? {})
    })

    watch(
      () => candidate.profileData.barbriAffiliated,
      () => {
        if (!candidate.profileData.barbriAffiliated) {
          candidate.profileData.barbriAffiliations = []
        }
      }
    )

    const diversityDisclosureButtonText = computed(() => {
      if (showDiversityForm.value) {
        return locales.done_option
      } else if (containsValue(candidate.profileData.candidateDiversityDisclosure.usDisclosure) || containsValue(candidate.profileData.candidateDiversityDisclosure.ukDisclosure)) {
        return locales.edit
      } else {
        return locales.add_option
      }
    })

    async function handleSubmit() {
      v$.value.$touch()
      const isValid = await v$.value.$validate()
      if (!isValid) {
        handleInvalidForm()
        return
      }

      //handles the form values
      await updateCandidateProfile()
    }

    function handleInvalidForm() {
      const invalidElement = form.value?.querySelector('.bs-invalid-field')
      if (invalidElement instanceof HTMLElement) {
        checkInvalidDOMElements(invalidElement)
      } else {
        checkAPIRequiredFields()
      }
    }

    function checkInvalidDOMElements(invalidElement: HTMLElement) {
      invalidElement.dispatchEvent(new CustomEvent('accordion-expand'))
      setTimeout(() => invalidElement.scrollIntoView({ behavior: 'smooth' }), 500)
      const fieldLabel = invalidElement.querySelector<HTMLElement>('.bs-field-label')?.textContent ?? ''
      if (fieldLabel) {
        showErrorToast({
          message: locales.fill_specific_value.replace('{field}', fieldLabel),
          position: 'top-center'
        })
      } else {
        logErrorMessage('Cannot locate label related to invalid element')
      }
    }

    function checkAPIRequiredFields() {
      const firstIncompleteRequiredField = RequiredFields.find((field) => field.name === incompleteRequiredFields.value[0])
      const message = firstIncompleteRequiredField
        ? locales.fill_specific_value.replace('{field}', firstIncompleteRequiredField.displayName)
        : locales.fill_all_values

      showErrorToast({
        message,
        position: 'top-center'
      })
    }

    function generateSectionId(category: ProfileCategories){
      return sectionIdPrefix + category
    }

    function usDisclosureUpdated(disclosure: DiversityDisclosureUS) {
      candidate.profileData.candidateDiversityDisclosure.usDisclosure = disclosure
    }

    function ukDisclosureUpdated(disclosure: DiversityDisclosureUK) {
      candidate.profileData.candidateDiversityDisclosure.ukDisclosure = disclosure
    }

    function updateProfilePicture(imageSource: string) {
      emit('profile-picture-updated', imageSource)
    }

    return {
      dialogRef,
      locales,
      handleSubmit,
      v$,
      candidate,
      invalidProfile,
      RepeatTypes,
      selectedCities,
      DualDegrees,
      incompleteRequiredFields,
      percentComplete,
      closeModal,
      ProfileCategories,
      editCandidateProfileContentRef,
      isLoading,
      form,
      showEducationForm,
      showDiversityForm,
      sectionIdPrefix,
      diversityDisclosureButtonText,
      toggleShowEducationForm,
      toggleShowDiversityForm,
      generateSectionId,
      usDisclosureUpdated,
      ukDisclosureUpdated,
      ContainsValidSchoolInformation,
      updateProfilePicture
    }
  }
})
