
import { defineComponent, PropType, ref, watch } from 'vue'
import locales from './DiversityDisclosure.locales.en.json'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import {
  CandidateDiversityDisclosure,
  DiversityDisclosures,
  DiversityDisclosureUK
} from '@/types/candidate'
import { GetCandidate, PutCandidateProfile } from '@/api/CandidateApi'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import router from '@/router'

export default defineComponent({
  components: {
    BsSelect
  },
  props: {
    details: {
      type: Object as PropType<DiversityDisclosureUK>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const isInfoSaving = ref<boolean>(false)
    const diversityDisclosureRef = ref<DiversityDisclosureUK>(props.details)

    watch(
      () => props.details,
      (newVal) => {
        diversityDisclosureRef.value = newVal
      }
    )

    const { showSuccessToast, showErrorToast } = useToast()

    const submitDiversityDisclosure = async () => {
      isInfoSaving.value = true
      const currentCandidate = await GetCandidate()
      currentCandidate.profileData.candidateDiversityDisclosure ??= {
        ukDisclosure: {}
      } as CandidateDiversityDisclosure
      currentCandidate.profileData.candidateDiversityDisclosure.ukDisclosure =
        diversityDisclosureRef.value

      try {
        await PutCandidateProfile(currentCandidate)
        showSuccessToast({
          message:
            'Successfully completed diversity disclosure for the UK fields',
          position: 'top-center'
        })
        router.push('CandidateProfile')
      } catch {
        showErrorToast({
          message: 'Something went wrong! Please try again later.',
          position: 'top-center'
        })
      } finally {
        isInfoSaving.value = false
      }
    }

    const cancel = () => {
      router.go(-1)
    }

    return {
      locales,
      DiversityDisclosures,
      diversityDisclosureRef,
      submitDiversityDisclosure,
      isInfoSaving,
      cancel
    }
  }
})
