
import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from '../CandidateProfile.locales.en.json'
import { CandidateWorkExperience } from '@/types/candidate'
import { RepeatTypes } from '@/types'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'

export default defineComponent({
  components: {
    BsTextField,
    BsSelect
  },
  props: {
    modelValue: {
      type: Object as PropType<CandidateWorkExperience>,
      default: ()=> {}
    },
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    idIndex: {
      type: Number,
      required: true
    },
    validationScope: {
      type: String,
      default: () => ''
    }
  },
  emits:['update:modelValue'],
  setup(props, { emit }) {

    const reactiveWorkExperience = reactive<CandidateWorkExperience>(props.modelValue)

    const endYearIsValid = computed<boolean>(() => {
      if (!reactiveWorkExperience.startYear || !reactiveWorkExperience.endYear) {
        return true
      }
      return !(reactiveWorkExperience.endYear && reactiveWorkExperience.startYear > reactiveWorkExperience.endYear)
    })

    function isRequired(workExperience: CandidateWorkExperience): boolean {
      return !!workExperience.positionTitle || !!workExperience.candidateEmployer || !!workExperience.startYear
    }

    const rules = computed<any>(() => {
      return {
        endYearIsValid: {
          valid: helpers.withMessage(
            locales.end_date_before_start,
            (value: boolean) => value
          )
        },
        form: {
          positionTitle: {
            required: requiredIf(isRequired(reactiveWorkExperience))
          },
          candidateEmployer: {
            required: requiredIf(isRequired(reactiveWorkExperience))
          },
          startYear: {
            required: requiredIf(isRequired(reactiveWorkExperience))
          }
        }
      }
    })

    const v$ = useVuelidate(rules, {
      endYearIsValid,
      form: reactiveWorkExperience
    }, { $scope: props.validationScope || false })
    v$.value.$touch()

    onMounted(() => {
      assignInitialValues()
    })

    function assignInitialValues(){
      reactiveWorkExperience.positionTitle =
        props?.modelValue?.positionTitle ?? ''
      reactiveWorkExperience.candidateEmployer =
        props?.modelValue?.candidateEmployer ?? ''
      reactiveWorkExperience.startYear =
        props?.modelValue?.startYear ?? undefined
      reactiveWorkExperience.endYear =
        props?.modelValue?.endYear ?? undefined
    }

    watch(()=> props.modelValue, (newVal, oldVal)=>{
        if(newVal !== oldVal){
          assignInitialValues()
        }
    }, { deep: true })

    const GetValidYears = () => {
      const years = []
      for (let i = new Date().getFullYear(); i >= 1900; --i) {
        years.push({ text: i, value: i })
      }
      return years
    }

    watch(
      reactiveWorkExperience,
      (newVal) => {
        emit('update:modelValue', newVal)
      },
      { deep: true }
    )

    return {
      locales,
      RepeatTypes,
      reactiveWorkExperience,
      GetValidYears,
      v$
    }
  }
})
