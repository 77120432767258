import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import OktaVue from '@okta/okta-vue'
import { InitOktaAuth } from './okta'
import { authInterceptor } from './interceptors/authInterceptor'
import vueDebounce from 'vue-debounce'
import PrimeVue from 'primevue/config'
import MultiSelect from 'primevue/multiselect'
import Dialog from 'primevue/dialog'
import Slider from 'primevue/slider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'

import { InitConfiguration } from '@/config/config'
import AutoComplete from 'primevue/autocomplete'
import { redirectToSSU } from '@/signin'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/styles/barbri-bootstrap/barbri-bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import '@/styles/barbri-primevue.scss'
import 'animate.css'

(async () => {
  await InitConfiguration()
  InitOktaAuth()
  const { oktaAuth } = await import('@/okta')
  authInterceptor()

  createApp(App)
    .use(store)
    .use(router)
    .use(OktaVue, {
      oktaAuth,
      onAuthRequired: async () => {
        await redirectToSSU()
      },
      onAuthResume: async () => {
        await redirectToSSU()
      }
    })
    .use(vueDebounce)
    .use(PrimeVue)
    .component('multi-select', MultiSelect)
    .component('auto-complete', AutoComplete)
    .component('slider', Slider)
    .component('prime-dialog', Dialog)
    .component('data-table', DataTable)
    .component('column', Column)
    .component('toggle-button', ToggleButton)
    .directive('tooltip', Tooltip)
    .mount('#app')
})()
