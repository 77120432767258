
import { defineComponent, watch } from 'vue'

export const Toast = defineComponent({
  name: 'BsToast',
  props: {
    position: {
      type: String,
      default: () => 'top-center'
    },
    type: {
      type: String,
      default: () => 'success'
    },
    isOpen: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  emits: ['update:isOpen'],
  setup(props, { emit }) {
    let removeTimeout: ReturnType<typeof setTimeout>

    watch(
      () => props.isOpen,
      (newState) => {
        if (newState) {
          autoCloseToast()
        }
      }
    )

    function autoCloseToast() {
      removeTimeout = setTimeout(() => {
        emit('update:isOpen', false)
        clearTimeout(removeTimeout)
      }, props.duration)
    }
  }
})

export default Toast
