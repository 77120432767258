
import { defineComponent, PropType } from 'vue'
import { createAutoIncrementIdProp } from '@/utils/VueTools'

export const BsRadio = defineComponent({
  name: 'BsRadio',
  props: {
    id: createAutoIncrementIdProp('BsRadio'),
    value: null,
    name: String as PropType<string>,
    modelValue: null,
    label: String as PropType<string>,
    disabled: Boolean as PropType<boolean>
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function handleChange(event: InputEvent) {
      const target = event.target as HTMLInputElement
      if (target.checked) {
        emit('update:modelValue', props.value)
      }
    }

    return {
      handleChange
    }
  }
})

export default BsRadio
export type BsRadioInstance = InstanceType<typeof BsRadio>
