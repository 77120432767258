import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4917b072"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-column d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_DropdownOptions = _resolveComponent("DropdownOptions")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_invite_to_apply = _resolveComponent("invite-to-apply")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableView, {
      class: "candidate-list-segment",
      data: _ctx.store.state.rows,
      columns: _ctx.tableColumns,
      "is-loading": _ctx.store.state.isLoading,
      onRowClicked: _ctx.goToProfile
    }, {
      candidatePicture: _withCtx(({ rowData }) => [
        _createVNode(_component_ProfilePicture, {
          "candidate-id": rowData.candidateId,
          class: "profile-picture",
          alt: "Profile Picture"
        }, null, 8, ["candidate-id"])
      ]),
      actions: _withCtx(({ rowData }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_DropdownOptions, {
            options: _ctx.dropdownOptions,
            position: "left",
            "show-border": "",
            onOptionChange: ($event: any) => (_ctx.handleOptionChange($event, rowData))
          }, null, 8, ["options", "onOptionChange"])
        ])
      ]),
      _: 1
    }, 8, ["data", "columns", "is-loading", "onRowClicked"]),
    (_ctx.showInviteDialog)
      ? (_openBlock(), _createBlock(_component_invite_to_apply, {
          key: 0,
          "employer-opportunities": _ctx.employerOpportunities,
          candidate: _ctx.candidate,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInviteDialog = false)),
          onSend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendInvitation($event)))
        }, null, 8, ["employer-opportunities", "candidate"]))
      : _createCommentVNode("", true)
  ], 64))
}