import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf9b509c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container bg-primary text-white" }
const _hoisted_2 = { class: "lead row" }
const _hoisted_3 = { class: "col-3 svg" }
const _hoisted_4 = { class: "col-9 banner-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerImage = _resolveComponent("BannerImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_BannerImage)
      ]),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.locales.welcome), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.locales.banner_text), 1)
      ])
    ])
  ]))
}