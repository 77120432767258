import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-930c472c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-disabled"]
const _hoisted_2 = ["data-allow-upload"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "file-list" }
const _hoisted_5 = { class: "file-name" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "attachment-upload-main",
      "data-disabled": _ctx.disabled,
      onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("div", {
        class: "upload-container",
        onDrop: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragFile && _ctx.dragFile(...args)))
      }, [
        _createElementVNode("label", {
          htmlFor: "file-input",
          class: "input-label",
          "data-allow-upload": _ctx.allowUpload
        }, _toDisplayString(_ctx.allowUpload ? _ctx.locales.drag_file_label : _ctx.locales.upload_limit_reached), 9, _hoisted_2),
        _createElementVNode("input", {
          id: "file-input",
          type: "file",
          multiple: "",
          disabled: _ctx.disabled || !_ctx.allowUpload,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
        }, null, 40, _hoisted_3)
      ], 32)
    ], 40, _hoisted_1),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileItems, (file, i) => {
        return (_openBlock(), _createElementBlock("li", { key: i }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(file.name), 1),
          _createElementVNode("span", null, [
            _createElementVNode("em", {
              class: "bi bi-x-circle close-icon",
              onClick: ($event: any) => (_ctx.handleRemove(i))
            }, null, 8, _hoisted_6)
          ])
        ]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.fileItems.length]
    ])
  ], 64))
}