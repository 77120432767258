import type { IStore } from '@/interfaces/common/StoreEntities'

export interface IBsTabViewModel {
  id: string
}

export interface IBsTabContainerViewModel {
  tabs: IBsTabViewModel[]
  selectedTabId?: string
  selectedTab?: IBsTabViewModel
  focusedTabId?: string
  focusedTab?: IBsTabViewModel
  lastTab?: IBsTabViewModel
  active: boolean
}

export interface IBsTabContainerActions {
  addTab(tabId: string): void
  selectTab(tabId: string): void
  focusTab(tabId: string): void
  focusNextTab(): void
  focusPrevTab(): void
  focusReset(): void
  removeTab(tabId: string): void
  activate(): void
  deactivate(): void
}

export type IBsTabContainerStore = IStore<
  IBsTabContainerViewModel,
  IBsTabContainerActions
>

export const IBsTabContainerStoreSymbol = Symbol('IBsTabContainerStore')
