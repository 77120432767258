
import { defineComponent, PropType, ref, watch } from 'vue'
import DynamicInputGenerator from './DynamicInputGenerator.vue'
import locales from '../CandidateProfile.locales.en.json'
import { CandidateProfile } from '@/types/candidate'
import { RepeatTypes } from '@/types'
import ExperienceCard from './ExperienceCard.vue'

export default defineComponent({
  components: {
    DynamicInputGenerator,
    ExperienceCard
  },
  props: {
    modelValue: {
      type: Object as PropType<CandidateProfile>,
      default: ()=> Object as PropType<CandidateProfile>
    },
    validationScope: {
      type: String,
      default: () => ''
    }
  },
  emits:['Update:modelValue'],
  setup(props, { emit }) {
    const formFields = ref(props.modelValue)
    const showForm = ref(false)

    const toggleForm = () => showForm.value = !showForm.value

    watch(()=> props.modelValue, (newVal, oldVal)=>{
      if(newVal !== oldVal){
      formFields.value = newVal
      }
    })

        watch(
      formFields,
      (newValue) => {
        emit('Update:modelValue', newValue)
      },
      { deep: true }
    )

    return {
      locales,
      formFields,
      RepeatTypes,
      showForm,
      toggleForm
    }
  }
})
