import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "card p-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_slider = _resolveComponent("slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString($props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BsTextField, {
          modelValue: $setup.range[0],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.range[0]) = $event)),
          class: "mb-3",
          type: "number",
          label: $props.minLabel,
          step: $props.step,
          min: $props.min,
          max: $setup.range[1]
        }, null, 8, ["modelValue", "label", "step", "min", "max"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BsTextField, {
          modelValue: $setup.range[1],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.range[1]) = $event)),
          class: "mb-3",
          type: "number",
          label: $props.maxLabel,
          step: $props.step,
          min: $setup.range[0],
          max: $props.max
        }, null, 8, ["modelValue", "label", "step", "min", "max"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_slider, {
        modelValue: $setup.range,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.range) = $event)),
        range: "",
        min: $props.min,
        max: $props.max,
        step: $props.step
      }, null, 8, ["modelValue", "min", "max", "step"])
    ])
  ], 64))
}