import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aab3d73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row recruiter-wrapper" }
const _hoisted_2 = { class: "col-md-6 col-sm-12 mb-3" }
const _hoisted_3 = { class: "col-md-6 col-sm-12 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecruiterCard = _resolveComponent("RecruiterCard")!
  const _component_DownloadAttachments = _resolveComponent("DownloadAttachments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RecruiterCard, {
        name: _ctx.summaryDetails.recruiter.name,
        designation: _ctx.summaryDetails.recruiter.designation,
        email: _ctx.summaryDetails.recruiter.email
      }, null, 8, ["name", "designation", "email"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DownloadAttachments, {
        "opportunity-id": _ctx.summaryDetails.opportunityId,
        attachments: _ctx.summaryDetails.attachments
      }, null, 8, ["opportunity-id", "attachments"])
    ])
  ]))
}