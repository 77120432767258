
import { Data } from '@/types'
import { ref } from 'vue'
import { IsValidOtp } from '@/types/validation/userValidation'
import SingleDigitInput from '@/components/SingleDigitInput.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

export default {
  components: {
    SingleDigitInput
  },
  props: {
    userEmail: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['verifyOtp', 'generateOtp'],
  setup(props: any, context: any): Data {
    const Otp = ref({
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
      digit5: '',
      digit6: ''
    })

    const { showErrorToast } = useToast()

    const verifyOtp = async () => {
      const otp = Otp.value
      const otpToValidate = `${otp.digit1}${otp.digit2}${otp.digit3}${otp.digit4}${otp.digit5}${otp.digit6}`
      
      if(!IsValidOtp(otpToValidate)) {
        showErrorToast({
          message: 'Please ensure that the code is complete.',
          position: 'top-center'
        })
        return
      }

      context.emit('verifyOtp', otpToValidate)
    }

    const generateOtp = async () => {
      context.emit('generateOtp', props.userEmail)
    }

    return {
      verifyOtp,
      generateOtp,
      Otp
    }
  }
}
