
import {
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  ref,
  computed,
  watch
} from 'vue'
import {
  IBsTabContainerStoreSymbol,
  IBsTabContainerStore,
  IBsTabViewModel
} from './BsTab.interfaces'
import { createAutoIncrementId } from '@/utils/VueTools'

export const BsTab = defineComponent({
  name: 'BsTab',
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsTab')
    }
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const store = inject<IBsTabContainerStore>(IBsTabContainerStoreSymbol)
    const tab = ref<IBsTabViewModel>()
    const selected = computed<boolean>(
      () => store?.state.selectedTab === tab.value ?? false
    )
    const focused = computed<boolean>(
      () => store?.state.focusedTab === tab.value ?? false
    )
    const button = ref<HTMLButtonElement>()

    onMounted(() => {
      store?.actions.addTab(props.id)
      tab.value = store?.state.lastTab
    })

    onUnmounted(() => {
      if (tab.value) {
        store?.actions.removeTab(tab.value.id)
      }
    })

    watch(focused, (focusedItem) => {
      if (focusedItem && store?.state.active) {
        button.value?.focus()
      }
    })

    watch(selected, (selectedItem) => {
      if (selectedItem) {
        emit('selected')
      }
    })

    function handleButtonClick() {
      if (tab.value) {
        store?.actions.selectTab(tab.value.id)
      }
    }

    function handleButtonFocus() {
      if (tab.value) {
        store?.actions.focusTab(tab.value.id)
      }
    }

    function handleButtonKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        case 'ArrowLeft':
          store?.actions.focusPrevTab()
          break
        case 'ArrowRight':
          store?.actions.focusNextTab()
          break
      }
    }

    return {
      tab,
      selected,
      focused,
      button,
      handleButtonClick,
      handleButtonFocus,
      handleButtonKeyDown
    }
  }
})

export default BsTab
