
import { defineComponent, PropType, computed } from 'vue'
import { createAutoIncrementId } from '@/utils/VueTools'
import { IEmployerJobPostViewModel } from './EmployerJobPost.interfaces'
import locales from './EmployerJobPost.locales.en.json'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'

export const EmployerJobPostCard = defineComponent({
  name: 'EmployerJobPostCard',
  components: { DropdownOptions },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('JobPostBoard')
    },
    headingLevel: {
      type: Number as PropType<number>,
      default: 5
    },
    post: {
      type: Object as PropType<Readonly<IEmployerJobPostViewModel>>,
      default: undefined
    }
  },
  emits: ['post-selected', 'edit-post'],
  setup(props, { emit }) {

    const postAge = computed<string>(() => {
      if (!props.post) {
        return ''
      }
      if (props.post.daysAgo === 0) {
        return locales.today
      }
      const text = props.post.daysAgo === 1 ? locales.day_ago : locales.days_ago
      return props.post.daysAgo + ' ' + text
    })

    function handleJobPostClick() {
      if (props.post) {
        emit('post-selected', props.post)
      }
    }

    const optionSelected = (option: Record<string, string>) => {
      if (option.key === 'edit') {
        emit('edit-post', props.post)
      } else if (option.key === 'view') {
        emit('post-selected', props.post)
      }
    }

    const dropdownOptions = [
      {
        label: 'View',
        key: 'view',
        icon: 'arrow-up-right-square'
      },
      {
        label: 'Edit',
        key: 'edit',
        icon: 'pencil'
      }
    ]

    return {
      postAge,
      locales,
      dropdownOptions,
      handleJobPostClick,
      optionSelected
    }
  }
})

export default EmployerJobPostCard
