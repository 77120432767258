import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf8a61ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "save-filter-main-segment" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = {
  id: "save-filter__title",
  class: "heading"
}
const _hoisted_4 = { class: "btn-content" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      id: "save-filter-modal",
      ref: "dialogRef",
      class: "save-filter-modal-main",
      "aria-labelled-by": "save-filter__title"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.locales.locales) + " " + _toDisplayString(_ctx.locales.search_filter), 1),
          _createElementVNode("form", {
            novalidate: "",
            class: "form-content",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_BsTextField, {
              modelValue: _ctx.formFields.filterName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.filterName) = $event)),
              class: "mb-3",
              label: _ctx.locales.search_filter + ' ' + _ctx.locales.name,
              placeholder: _ctx.locales.search_name,
              validation: _ctx.v$.filterName
            }, null, 8, ["modelValue", "label", "placeholder", "validation"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn btn-text-primary",
                type: "button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, _toDisplayString(_ctx.locales.cancel), 1),
              _createElementVNode("button", {
                class: "btn btn-primary",
                type: "submit",
                disabled: _ctx.isSaving
              }, _toDisplayString(_ctx.locales.save), 9, _hoisted_5)
            ])
          ], 32)
        ])
      ]),
      _: 1
    }, 512)
  ]))
}