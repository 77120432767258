
import { computed, defineComponent, PropType, unref, ref, watch, onUnmounted, onMounted, nextTick } from 'vue'
import { Editor, EditorContent, EditorEvents } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import BubbleMenu from './Menus/BubbleMenu.vue'
import FloatingMenu from './Menus/FloatingMenu.vue'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'

export default defineComponent({
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: () => ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    id: createAutoIncrementIdProp('floatingEditor'),
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 2000
    }
  },
  emits: ['update:modelValue', 'update:character-count'],
  setup(props, { emit }) {
    const count = ref<number>(0)
    const editor = ref<Editor | null>(null)

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })
    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })
    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })
    const limitExist = computed<boolean>(() => {
      return typeof props.limit === 'number'
    })

    watch([
      () => props.limit,
      () => props.editable,
      () => props.disabled
    ], refreshEditor)

    watch(() => props.modelValue, (value) => {
      if (editor.value && value !== editor.value.getHTML()) {
        editor.value?.commands.setContent(value, false)
        updateCharacterCount()
      }
    })

    onMounted(() => {
      editor.value = createEditor()
    })

    onUnmounted(() => {
      if (editor.value) {
        editor.value.destroy()
        editor.value = null
      }
    })

    function createEditor() {
      return new Editor({
        extensions: [
          StarterKit,
          Link,
          CharacterCount.configure({
            limit: props.limit
          })
        ],
        onCreate: () => {
          updateCharacterCount()
        },
        onUpdate: (val) => {
          updateModelValue(val)
          updateCharacterCount()
        },
        content: props.modelValue,
        editable: props.editable && !props.disabled
      })
    }

    async function refreshEditor() {
      if (editor.value) {
        editor.value.destroy()
        editor.value = null
      }
      // Needs to await to create new instances of BubbleMenu and FloatingMenu that targets the correct editor
      await nextTick()
      editor.value = createEditor()
    }

    function updateCharacterCount() {
      count.value = editor.value?.storage.characterCount.characters()
      emit('update:character-count', count.value)
    }

    function updateModelValue(val: EditorEvents['update']) {
      const modelValue = val.editor.isEmpty ? '' : val.editor.getHTML()
      emit('update:modelValue', modelValue)
    }

    return {
      editor,
      dirty,
      invalid,
      invalidMessage,
      count,
      limitExist
    }
  }
})
