import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BsAutoComplete, {
      modelValue: $setup.localVal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localVal) = $event)),
      label: $props.label,
      multiple: true,
      suggestions: $setup.filteredCities,
      field: "text",
      placeholder: "Begin typing to search",
      onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchCities($event)))
    }, null, 8, ["modelValue", "label", "suggestions"])
  ]))
}