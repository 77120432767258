import { renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1277f415"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "slide",
    onEnter: _ctx.onContentStart,
    onAfterEnter: _ctx.onAfterEnter,
    onBeforeLeave: _ctx.onBeforeEnd
  }, {
    default: _withCtx(() => [
      (_ctx.keepContentOnClose || _ctx.expandedState)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            id: 'expander-' + _ctx.id,
            ref: "expander"
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 8, _hoisted_1)), [
            [_vShow, _ctx.expandedState]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["onEnter", "onAfterEnter", "onBeforeLeave"]))
}