
import { ApplicationStatusKey } from '@/types/application'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    quantity: {
      type: Number,
      default: () => 0
    }
  },
  emits: ['statusChange'],
  setup(props, context) {

    const handleStatusChange = (status: ApplicationStatusKey) => {
      context.emit('statusChange', status)
    }

    return {
      handleStatusChange
    }
  }
})
