
import { defineComponent, ref, PropType } from 'vue'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import BsButton from '@/components/BsButton/BsButton.vue'
import { EmployerProfile } from '@/types/employer'
import { UpdateEmployerStatus } from '@/api/EmployerApi'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import locales from './EmployerVerifications.locales.en.json'

export default defineComponent({
  components: {
    Dialog,
    BsButton
  },
  props: {
    employerInfo: {
      type: Object as PropType<EmployerProfile>,
      required: true
    }
  },
  emits: ['updated-status'],
  setup(_, { emit }) {
    const dialogRef = ref<InstanceType<typeof Dialog>>()
    const { showSuccessToast, showErrorToast } = useToast()

    const toggleModal = (status: boolean) => {
      if (status) {
        dialogRef.value?.open()
      } else {
        dialogRef.value?.close()
      }
    }

    const updateEmployerStatus = async (id: string, isApproved: boolean) => {
      try {
        await UpdateEmployerStatus(id, isApproved)
        emit('updated-status')
        toggleModal(false)
        showSuccessToast({
          message: locales.success_update_status,
          position: 'top-center'
        })
      } catch (err) {
        showErrorToast({
          message: locales.error_update_status,
          position: 'top-center'
        })
      }
    }

    return {
      dialogRef,
      toggleModal,
      updateEmployerStatus,
      locales
    }
  }
})
