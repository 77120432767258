
import {
  ApplicationStatusKey,
  Applicant,
  ApplicationStatuses,
  GetApplicantsResponse,
  SummaryDetailsModel,
  RowData,
  mapOpportunityToSummaryDetails
  } from '@/types/application'
import { Data } from '@/types'
import { computed, onMounted, ref, watch } from 'vue'
import { GetApplicantsByOpportunity, ChangeApplicationStatus, MarkApplicationAsViewedByAgent } from '@/api/ApplicationApi'
import { GetOpportunityById } from '@/api/OpportunityApi'
import { useRoute } from 'vue-router'
import { ShortlistCandidate, UnshortlistCandidate, GetShortlistedCandidates, GetCandidateById } from '@/api/CandidateApi'
import store from '@/store'
import router from '@/router'
import OpportunitySummaryCard from '@/views/Applicants/OpportunitySummary/OpportunitySummaryCard.vue'
import ApplicantList from '@/views/Applicants/ApplicantLists.vue'
import { JobOpportunity} from '@/types/opportunity'
import { formatApplicantStatus } from './Applicants/Applicants.util'

export default {
  components: {
    OpportunitySummaryCard,
    ApplicantList
  },
  setup(): Data {
    const perPage = 10
    const currentPage = ref(1)
    const totalPages = ref(1)
    const loading = ref(true)
    const errorMessage = ref('')
    const applicants = ref([] as Applicant[])
    const rowDataApplicants = ref([] as RowData[])
    const selectedApplicants = ref([] as Applicant[])
    const shortlistedCandidateIds = ref([] as string[])
    const shortlistOnly = ref(false)
    const route = ref(useRoute())
    const opportunityId = ref(route.value.query.opportunityId as string)
    const bulkUpdateStatus = ref(undefined as ApplicationStatusKey | undefined)
    const opportunity = ref({} as JobOpportunity | undefined)
    const statusFilter = ref(undefined as ApplicationStatusKey | undefined)
    const summaryDetails = ref<SummaryDetailsModel>({ recruiter: {}} as SummaryDetailsModel)

    const mapApplicantsToRowData = async (applicantsToMap: Applicant[]): Promise<RowData[]> => {
      const rowData: RowData[] = []
      for(let applicant of applicantsToMap) {
        const candidateProfile = await GetCandidateById(applicant.candidateId)
        if (!candidateProfile) {
          continue
        }
        let applicantLocation = 'Unavailable'
        if (candidateProfile.profileData.dreamJobData.preferredLocations?.length) {
          applicantLocation = `${candidateProfile.profileData.dreamJobData.preferredLocations[0].municipality}, ` +
            `${candidateProfile.profileData.dreamJobData.preferredLocations[0].countrySubdivision} ` +
            `(${candidateProfile.profileData.dreamJobData.preferredLocations[0].countryCode})`
        }
        rowData.push({
          id: applicant.applicationId,
          title: applicant.candidateName,
          candidateId: applicant.candidateId,
          opportunityId: applicant.opportunityId,
          location: applicantLocation,
          status: formatApplicantStatus(applicant.status),
          school: candidateProfile.profileData.schoolInformation?.length > 0 ? candidateProfile.profileData.schoolInformation[0].schoolName : '',
          favorite: applicant.shortlisted,
          candidate: candidateProfile,
          applicant: applicant
        } as RowData)
      }

      return rowData
    }

    const getApplicants = async () => {
      const appResponse = await GetApplicantsByOpportunity(opportunityId.value, currentPage.value, perPage)
      totalPages.value = Math.ceil(appResponse.totalApplicants / perPage)

      if (appResponse.totalApplicants === 0) {
        errorMessage.value = 'No one has applied for this opportunity'
        loading.value = false
        return
      }

      const shortlistResponse = await GetShortlistedCandidates(opportunityId.value)
      shortlistedCandidateIds.value = shortlistResponse.data

      MapResponseToApplicants(appResponse)

      loading.value = false
    }

    function MapResponseToApplicants(response: GetApplicantsResponse): void {
      applicants.value = response.applicants.map(app => ({
        ...app,
        shortlisted: shortlistedCandidateIds.value.includes(app.candidateId)
      }))
    }

    const applicantsToDisplay = computed(() => {
      return shortlistOnly.value ?
        applicants.value.filter(app => app.shortlisted) :
        applicants.value
    })

    onMounted(async () : Promise<void> => {
      if (opportunityId.value) {
        opportunity.value = await GetOpportunityById(opportunityId.value)
      }

      summaryDetails.value = await mapOpportunityToSummaryDetails(opportunity.value)
      await getApplicants()
      rowDataApplicants.value = await mapApplicantsToRowData(applicants.value)
      applicants.value.forEach(async app => {
        if (app.isNew) {
          await MarkApplicationAsViewedByAgent(app.opportunityId, app.applicationId)
        }
      })
    })

    watch(currentPage, async () => {
      await getApplicants()
    })

    const updateShortlistStatus = async (applicant: RowData) => {
      if (applicant.favorite) {
        await ShortlistCandidate(applicant.opportunityId, applicant.candidateId)
        shortlistedCandidateIds.value.push(applicant.candidateId)
      } else {
        await UnshortlistCandidate(applicant.opportunityId, applicant.candidateId)
        const index = shortlistedCandidateIds.value.indexOf(applicant.candidateId)
        shortlistedCandidateIds.value.splice(index, 1)
      }
    }

    const toggleShortlistOnly = () => {
      shortlistOnly.value = !shortlistOnly.value
    }

    const changeApplicationStatus = async (applicant: Applicant) => {
      await ChangeApplicationStatus(applicant.opportunityId, applicant.applicationId, applicant.status)
      if (statusFilter.value && applicant.status != statusFilter.value) {
        const index = applicants.value.indexOf(applicant)
        applicants.value.splice(index, 1)
      }
    }

    watch(bulkUpdateStatus, (newValue) => {
      if (!newValue) {
        return
      }

      if (!selectedApplicants.value?.length) {
        bulkUpdateStatus.value = undefined
        return
      }

      selectedApplicants.value.forEach(async application => {
        // setting this value will trigger changeApplicationStatus() due to the @update:modelValue binding on the text-value-single-select
        application.status = newValue
      })

      selectedApplicants.value = []
      bulkUpdateStatus.value = undefined
    })

    watch(statusFilter, async (newVal: ApplicationStatusKey | undefined) => {
      const appResponse = await GetApplicantsByOpportunity(opportunityId.value, currentPage.value, perPage, newVal)
      MapResponseToApplicants(appResponse)
    })

    const viewApplication = (applicant: Applicant) => {
      store.state.application = {
        id: applicant.applicationId,
        opportunityId: applicant.opportunityId,
        candidateId: applicant.candidateId,
        status : applicant.status
      }
      router.push({path: '/Application'})
    }

    function editOpportunity() {
      store.state.jobOpportunity = opportunity.value
      router.push({ path: 'PostJobOpportunity', query: { opportunityId: opportunityId.value } })
    }

    return {
      currentPage,
      totalPages,
      loading,
      errorMessage,
      opportunity,
      applicantsToDisplay,
      selectedApplicants,
      shortlistOnly,
      updateShortlistStatus,
      toggleShortlistOnly,
      changeApplicationStatus,
      ApplicationStatuses,
      bulkUpdateStatus,
      viewApplication,
      statusFilter,
      summaryDetails,
      rowDataApplicants,
      editOpportunity
    }
  }
}
