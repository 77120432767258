
import { defineComponent, provide } from 'vue'
import { createBsAccordionStore } from './Accordion.store'
import {
  IBsAccordionContainerStore,
  IBsAccordionStoreSymbol
} from './Accordion.interface'

export default defineComponent({
  props: {
    multiple: {
      type: Boolean,
      default: ()=> false
    }
  },
  setup(props) {
    const store = createBsAccordionStore(props.multiple)
    provide<IBsAccordionContainerStore>(IBsAccordionStoreSymbol, store)
  }
})
