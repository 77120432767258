import { TextValue } from '..'

enum FileDescription {
  None = 'None',
  CandidateResume = 'CandidateResume',
  CandidateWritingSample = 'CandidateWritingSample',
  CandidateTranscript = 'CandidateTranscript',
  OpportunitySupportingDocument = 'OpportunitySupportingDocument',
  EmployerLogo = 'EmployerLogo',
  CandidateProfilePicture = 'CandidateProfilePicture',
  EmployerImage = 'EmployerImage'
}

const FileDescriptionTextValues: Array<TextValue<FileDescription>> = [
  { text: 'Law School Transcript (if available)', value: FileDescription.CandidateTranscript },
  { text: 'Cover Letter and Resume', value: FileDescription.CandidateResume },
  { text: 'Writing Sample', value: FileDescription.CandidateWritingSample }
]

export { FileDescription, FileDescriptionTextValues }
