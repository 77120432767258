import { AgentProfile } from '@/types/agent'
// @ts-ignore
import { IDToken, OktaAuth, UserClaims } from '@okta/okta-auth-js'
import { Configuration } from '@/config/config'
import * as constants from '@/constants'

interface ExtendedUserClaims extends UserClaims {
  sub: string,
  empId: string,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  groups: string[]
  organization: string
}

interface ExtendedIDToken extends IDToken {
  claims: ExtendedUserClaims
}

// ts-ignore
let oktaAuth: OktaAuth = {} as OktaAuth

const InitOktaAuth = () => {
  oktaAuth = new OktaAuth({
    issuer: Configuration.OKTA_DOMAIN + 'oauth2/default',
    clientId: Configuration.CLIENT_ID,
    redirectUri: window.location.origin + '/completeLogin',
    scopes: ['openid', 'profile', 'email', 'groups']
  })
}

const GetUserId = async () => {
  if(!await oktaAuth.isAuthenticated()){
    return ''
  }

  const idToken = await oktaAuth.tokenManager.get(constants.idToken) as IDToken
  return idToken?.claims?.sub ?? ''
}

const GetEmployerId = async () => {
  if(!await oktaAuth.isAuthenticated()){
    return ''
  }

  const idToken = await oktaAuth.tokenManager.get(constants.idToken) as IDToken
  const claims = idToken?.claims as ExtendedUserClaims
  return claims?.empId ?? ''
}

const BuildAgentProfileFromToken = async () => {
  const idToken = await oktaAuth.tokenManager.get(constants.idToken) as IDToken
  const claims = idToken.claims as ExtendedUserClaims
  return {
    emailAddress: claims.email ?? '',
    firstName: claims.firstName ?? '',
    lastName: claims.lastName ?? '',
    phoneNumber: claims.phoneNumber ?? ''
  } as AgentProfile
}

const GetUserGroups = async () => {
  if(!await oktaAuth.isAuthenticated()){
    return []
  }

  const idToken = await oktaAuth.tokenManager.get(constants.idToken) as IDToken
  const claims = idToken?.claims as ExtendedUserClaims
  return claims?.groups ?? []
}

const GetCandidateClaimsFromToken = async () => {
  const idToken = await oktaAuth.tokenManager.get(constants.idToken) as IDToken
  return idToken.claims as ExtendedUserClaims
}

export {
  InitOktaAuth,
  oktaAuth,
  GetEmployerId,
  GetUserId,
  BuildAgentProfileFromToken,
  ExtendedIDToken,
  ExtendedUserClaims,
  GetUserGroups,
  GetCandidateClaimsFromToken
}
