export interface PreviewDropdownModel {
  label: string,
  value: string
}

export enum ProfileCategories {
  CandidateInfo,
  Education,
  Experience,
  AwardsHonors,
  Certifications
}