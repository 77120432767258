
import { computed, defineComponent, PropType, ref, Ref, watch } from 'vue'
import { debounce } from 'vue-debounce'
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'
import BsButton from '@/components/BsButton/BsButton.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import ProfilePictureUpload from '@/components/ProfilePictureUpload/ProfilePictureUpload.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import UploadFileContainer from './UploadFileContainer.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import locales from '../CandidateProfile.locales.en.json'
import { Languages as LanguagesOptions, PracticeAreaOptions, BarbriAffiliations, Candidate } from '@/types/candidate'
import { EmployerTypes as EmployerTypeOptions } from '@/types/employer'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { CandidateTypes } from '@/types/opportunity'
import { GetUniqueCityCountryResults } from '@/api/AzMapsApi'
import { DeleteCandidateFile, PostCandidateFileUpload } from '@/api/CandidateApi'
import { http, https } from '@/constants'

export default defineComponent({
  components: {
    BsAutoComplete,
    BsButton,
    BsCheckbox,
    BsMultiSelect,
    BsSelect,
    BsTextField,
    ProfilePicture,
    ProfilePictureUpload,
    TextEditor,
    UploadFileContainer
  },
  props: {
    modelValue: {
      type: Object as PropType<Candidate>,
      default: () => Object as PropType<Candidate>
    },
    dreamJobLocations: {
      type: Array,
      default: () => []
    },
    validation: {
      type: Object,
      default: () => undefined
    },
    profilePictureSource: {
      type: String,
      default: () => ''
    }
  },
  emits: ['Update:modelValue', 'Update:dreamJobLocations', 'profile-picture-updated'],
  setup(props, { emit }) {
    const formFields = ref(props.modelValue.profileData)
    const filteredCities = ref([]) as Ref<any[]>
    const candidateFullName = ref(`${formFields.value?.firstName} ${formFields.value?.lastName}` ?? '')
    const linkedInUrl = ref(formFields.value?.linkedInLink ?? '')
    const profilePicture = ref<FileMetadata | undefined>(undefined)
    const profilePictureUpload = ref<InstanceType<typeof ProfilePictureUpload>>()
    const showProfile = ref(!formFields.value?.hideProfile ?? true)
    const { showErrorToast } = useToast()
    const profilePictureSourceRef = ref(props.profilePictureSource)

    const handleLocationSearch = debounce(async (event: any) => {
      const resultDict = await GetUniqueCityCountryResults(event.query)
      filteredCities.value = Object.keys(resultDict).map((key) => ({
        text: key,
        value: resultDict[key]
      }))
    }, 300)

    watch(()=> props.modelValue,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          formFields.value = newVal.profileData
          profilePicture.value = newVal.candidateFileMetadata?.find(fm => fm.fileDescription === FileDescription.CandidateProfilePicture)
        }
      },
      { deep: true }
    )

    watch(
      formFields,
      (newValue) => {
        const candidate = { ...props.modelValue }
        candidate.profileData = newValue
        emit('Update:modelValue', candidate)
      },
      { deep: true }
    )

    watch(
      profilePicture,
      (newValue) => {
        const candidate = { ...props.modelValue }
        const index = candidate.candidateFileMetadata.findIndex(cfm => cfm.fileDescription === FileDescription.CandidateProfilePicture)
        if (newValue && index >= 0) {
          candidate.candidateFileMetadata[index] = newValue
          emit('Update:modelValue', candidate)
        }
      },
      { deep: true }
    )

    const computeDreamJobLocations = computed({
      get: () => props.dreamJobLocations,
      set: (newValue) => emit('Update:dreamJobLocations', newValue)
    })

    watch(linkedInUrl, (newVal: string) => {
      if (!newVal || newVal.startsWith(http) || newVal.startsWith(https)) {
        formFields.value.linkedInLink = newVal
      } else {
        formFields.value.linkedInLink = https + newVal
      }
    })

    watch(showProfile, (newVal: boolean) => formFields.value.hideProfile = !newVal)

    const handleChangeProfilePic = (e: Event) => {
      const element = e.target as HTMLInputElement
      const files = element.files
      if (!files?.length) {
        return
      }
      profilePictureUpload.value?.openWithFile(files[0])
      element.value = ''
    }

    async function handleCroppedPicture(blob: Blob) {
      try {
        const file = new File([blob], FileDescription.CandidateProfilePicture + '.jpg')
        if(profilePicture.value?.domainObjectId){
          await DeleteCandidateFile(profilePicture.value.domainObjectId)
          window.URL.revokeObjectURL(profilePictureSourceRef.value)
        }
        let result = await PostCandidateFileUpload(file, FileDescription.CandidateProfilePicture)
        profilePicture.value = { domainObjectId: result, fileDescription: FileDescription.CandidateProfilePicture } as FileMetadata
        profilePictureSourceRef.value = window.URL.createObjectURL(blob)
      }
      catch{
        showErrorToast({
          message: 'Cannot change current profile picture'
        })
      }
    }

    function updateProfilePicture(imageSource: string) {
      emit('profile-picture-updated', imageSource)
    }

    return {
      locales,
      LanguagesOptions,
      PracticeAreaOptions,
      EmployerTypeOptions,
      BarbriAffiliations,
      CandidateTypes,
      handleLocationSearch,
      filteredCities,
      computeDreamJobLocations,
      formFields,
      candidateFullName,
      linkedInUrl,
      profilePictureUpload,
      showProfile,
      profilePictureSourceRef,
      handleChangeProfilePic,
      handleCroppedPicture,
      updateProfilePicture
    }
  }
})
