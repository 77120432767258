import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c04677"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "JobOpportunitiesSummary mt-3" }
const _hoisted_2 = {
  key: 0,
  role: "alert",
  class: "welcome-title mb-5"
}
const _hoisted_3 = { class: "summary-content-card p-5 mb-3" }
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "col-6 text-end" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_candidate_opportunity_status_selector = _resolveComponent("candidate-opportunity-status-selector")!
  const _component_job_opportunities_table = _resolveComponent("job-opportunities-table")!
  const _component_pagination_bar = _resolveComponent("pagination-bar")!
  const _component_BsTabPanel = _resolveComponent("BsTabPanel")!
  const _component_BsTabContainer = _resolveComponent("BsTabContainer")!
  const _component_job_opportunities_table_filter = _resolveComponent("job-opportunities-table-filter")!
  const _component_OpportunityDetails = _resolveComponent("OpportunityDetails")!
  const _component_ApplyOpportunityForm = _resolveComponent("ApplyOpportunityForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BsTabContainer, null, {
      default: _withCtx(({ selectedTabId }) => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.state.authenticated && _ctx.userFirstName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.locales.title_welcome) + ", " + _toDisplayString(_ctx.userFirstName) + "! ", 1))
            : _createCommentVNode("", true),
          (_ctx.state.authenticated)
            ? (_openBlock(), _createBlock(_component_candidate_opportunity_status_selector, {
                key: 1,
                selected: _ctx.state.selectedStatus,
                "card-options": _ctx.state.cardOptions,
                "is-loading": _ctx.state.isLoading,
                class: "details-card-segment",
                onHandleSelect: _ctx.handleStatusSelected
              }, null, 8, ["selected", "card-options", "is-loading", "onHandleSelect"]))
            : _createCommentVNode("", true),
          _createVNode(_component_BsTabPanel, { for: selectedTabId }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h1", _hoisted_6, [
                      (!_ctx.state.isLoading)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(" (" + _toDisplayString(_ctx.state.totalFilteredOpportunities) + ") ", 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.state.authenticated ? _ctx.locales.title_auth : _ctx.locales.title_no_auth), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      class: "btn btn-outline-primary",
                      type: "button",
                      disabled: _ctx.state.isLoading,
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.actions.openFiltersDialog && _ctx.actions.openFiltersDialog(...args)))
                    }, _toDisplayString(_ctx.locales.filters), 9, _hoisted_8)
                  ])
                ]),
                _createVNode(_component_job_opportunities_table, {
                  opportunities: _ctx.state.rows,
                  "logged-in-as-candidate": _ctx.state.authenticated,
                  "is-loading": _ctx.state.isLoadingOpportunities,
                  onApply: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openOpportunityDetailsToApply($event))),
                  onFavorite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.favoriteOpportunity($event))),
                  onUnfavorite: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actions.unFavoriteOpportunity($event)))
                }, null, 8, ["opportunities", "logged-in-as-candidate", "is-loading"])
              ]),
              (_ctx.state.totalPages > 1)
                ? (_openBlock(), _createBlock(_component_pagination_bar, {
                    key: 0,
                    modelValue: _ctx.state.currentPage,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.currentPage) = $event)),
                    "total-pages": _ctx.state.totalPages,
                    spread: 2,
                    disabled: _ctx.state.isLoading
                  }, null, 8, ["modelValue", "total-pages", "disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["for"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_job_opportunities_table_filter, {
      modelValue: _ctx.state.filter,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.filter) = $event)),
      visible: _ctx.state.filterDialogIsOpened,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.filterDialogIsOpened) = $event)),
      authenticated: _ctx.state.authenticated,
      onResetFilter: _ctx.actions.resetFilter
    }, null, 8, ["modelValue", "visible", "authenticated", "onResetFilter"]),
    _createVNode(_component_OpportunityDetails, {
      id: "OpportunityDetails",
      ref: "opportunityDetails",
      opportunity: _ctx.opportunityToApplyTo,
      applied: _ctx.opportunityToApplyTo.hasAppliedTo,
      onApplyClicked: _ctx.openApplyDialog
    }, null, 8, ["opportunity", "applied", "onApplyClicked"]),
    _createVNode(_component_ApplyOpportunityForm, {
      ref: "applyOpportunityForm",
      opportunity: _ctx.opportunityToApplyTo,
      onApply: _ctx.applyToOpportunity
    }, null, 8, ["opportunity", "onApply"])
  ], 64))
}