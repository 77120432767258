import { IsValidString } from '@/types/validation/genericValidation'
import { EmployerProfile, EmployerTypes } from '@/types/employer'
import { IsValidCompanyLinkedInUrl, IsValidUrl } from '@/types/validation/urlValidation'
import { Models } from 'azure-maps-rest'

function IsValidEmployer(employer : EmployerProfile) : boolean {
  return IsValidString(employer.name)
      && IsValidCompanyLinkedInUrl(employer.linkedInLink)
      && IsValidWebsite(employer.website)
      && IsValidOfficeLocationList(employer.officeLocations)
      && IsValidEmployerType(employer.employerType)
}

function IsValidEmployerType(type?: string): boolean {
  return !!type && EmployerTypes.some((v) => v.value === type)
}

function IsValidWebsite(website: string) {
  return website?.length <= 256 && IsValidUrl(website)
}

function IsValidOfficeLocationList(locations: Array<Models.SearchResultAddress> | undefined) {
  return !!locations && locations.length > 0 && locations.length <= 50
}

export {
  IsValidEmployer,
  IsValidEmployerType,
  IsValidWebsite,
  IsValidOfficeLocationList
}
