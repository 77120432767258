
import { defineComponent, PropType, computed, unref, ref, watch, inject, DefineComponent, onMounted } from 'vue'
import MultiSelect from 'primevue/multiselect'
import type { Validation } from '@vuelidate/core'
import type { MultiSelectOptionsModel, MultiSelectEventModel } from './BsMultiSelect.interfaces'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { AppendToSelectorKey } from '@/types/ui'
import { useDropdownOffsetPatch } from '@/utils/PrimeVuePatch'

const SHOW_FILTER_LIMIT = 10

const BsMultiSelect = defineComponent({
  name: 'BsMultiSelect',
  components: {
    MultiSelect
  },
  props: {
    id: createAutoIncrementIdProp('BsMultiSelect'),
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    label: {
      type: String,
      default: () => ''
    },
    disabled: Boolean,
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    },
    options: {
      type: Array as PropType<MultiSelectOptionsModel[]>,
      required: true
    },
    display: {
      type: String as PropType<'chip' | 'comma'>,
      default: () => 'chip'
    },
    placeholder: {
      type: String,
      default: () => 'Select...'
    },
    optionLabel: {
      type: String,
      default: ()=> 'text'
    },
    optionValue: {
      type: String,
      default: ()=> 'value'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const appendToSelector = inject(AppendToSelectorKey, ref<string>('body'))
    const multiselect = ref<InstanceType<DefineComponent & typeof MultiSelect>>()
    const value = ref(props.modelValue)
    const selectId = computed(() => props.id + '__multiselect')
    const invalidMessageId = computed(() => props.id + '__invalid_message')
    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    onMounted(()=> {
      addAttributes()
    })

    function addAttributes() {
      const container = multiselect.value?.$refs.container
      if (container instanceof HTMLDivElement) {
        const triggerElement = container.querySelector('.p-multiselect-trigger-icon')
        if(triggerElement){
          triggerElement.setAttribute('aria-hidden', 'true')
        }
      }
    }

    watch(
      () => props.modelValue, 
      (newVal) => {
        if (newVal !== value.value) {
          value.value = newVal
        }
      },
      { deep: true }
    )

    watch(value, (newVal) => {
      emit('update:modelValue', newVal)
    })

    useDropdownOffsetPatch(multiselect, appendToSelector, '.p-multiselect-panel')

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    const showFilter = computed<boolean>(() => {
      return props.options.length >= SHOW_FILTER_LIMIT
    })

    function handleChange(event: MultiSelectEventModel) {
      emit('update:modelValue', event.value)
    }

    function formatOptionLabel(item: MultiSelectOptionsModel | string): string {
      if (typeof item === 'string') {
        return item
      }
      return item.text ?? item.label ?? ''
    }

    function handleMultiselectBeforeHide() {
      // "filterInput" is part of MultiSelect.vue at PrimeVue v3.12.2
      // https://github.com/primefaces/primevue/blob/3.12.2/src/components/multiselect/MultiSelect.vue#L44
      const filterInput = multiselect.value?.$refs['filterInput']
      if (!(filterInput instanceof HTMLInputElement)) {
        if (showFilter.value) {
          console.error('[BsMultiSelect] Missing "filterInput"')
        }
        return
      }
      filterInput.value = ''
      const event = new Event('input')
      filterInput.dispatchEvent(event)
    }

    function deleteSelected(val: string) {
      value.value = props.modelValue.filter(vl=> val !== vl)
    }

    function getLabel(val: string) {
      const opt = props.options as any
       return opt.find((item: any)=> {
         return JSON.stringify(item[props.optionValue] || '') === JSON.stringify(val || '')
       })?.[props.optionLabel]
    }

    return {
      value,
      selectId,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      showRequiredAttr,
      showFilter,
      multiselect,
      appendToSelector,
      handleChange,
      formatOptionLabel,
      handleMultiselectBeforeHide,
      deleteSelected,
      getLabel
    }
  }
})

export default BsMultiSelect
