import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Loading... "))
      : ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.errorMessage), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, " Application ID: " + _toDisplayString($setup.application.id), 1))
  ]))
}