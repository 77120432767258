
import { defineComponent } from 'vue'
import locales from '../TableView.locales.en.json'

export default defineComponent({
  name: 'TableTopHeader',
  emits: ['showFilters'],
  setup(_, { emit }) {
    const showFilters = () => {
      emit('showFilters')
    }

    return {
      locales,
      showFilters
    }
  }
})
