
import { ref, onMounted } from 'vue'
import router from '@/router'
import { GetPendingEmployers } from '@/api/EmployerApi'
import { EmployerProfile } from '@/types/employer'
import { GetUserGroups } from '@/okta'
import { Configuration } from '@/config/config'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import BsSpinner from '@/components/CustomBootstrap/BsSpinner/BsSpinner.vue'
import locales from '@/views/EmployerVerifications/EmployerVerifications.locales.en.json'

export default {
  components: {
    BsSpinner
  },
  setup() {
    const pendingEmployers = ref<EmployerProfile[]>([])
    const isLoading = ref(true)
    const { showErrorToast } = useToast()

    onMounted(async () => {
      try {
        pendingEmployers.value = await GetPendingEmployers()
      } catch (err) {
        showErrorToast({
          message: locales.error_retrieve_employers,
          position: 'top-center'
        })
      } finally {
        isLoading.value = false
      }
    })

    const handleRedirect = async () => {
      const userGroups: string[] = await GetUserGroups()

      if (userGroups.includes(Configuration.EMPLOYER_VERIFICATION_GROUP)) {
        router.push({
          name: 'Employer Verifications'
        })
      }
    }

    return {
      pendingEmployers,
      isLoading,
      handleRedirect,
      locales
    }
  }
}
