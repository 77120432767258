import { CandidateFilterMetadata } from '@/types/candidateFilterMetadata'
import { reactive, computed, inject, provide, watch } from 'vue'
import { ICandidateSearchStore, ICandidateSearchViewModel, ICandidateSearchActions, ICandidateSearchRowViewModel } from './CandidateSearch.interfaces'
import { Candidate } from '@/types/candidate'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import locales from './CandidateSearch.locales.en.json'
import { GetCandidates, GetCandidatesFromSavedFilter } from  '@/api/CandidateApi'
import { CandidateFilter } from '@/types/candidateFilter'
import { useStore } from 'vuex'
import { logErrorMessage } from '@/logging'
import { DeleteCandidateFilter, GetAgentProfile, GetSavedFilters, SaveCandidateFilter } from '@/api/AgentApi'
import { GetEmployerId } from '@/okta'

interface IPrivateCandidateSearchViewModel extends ICandidateSearchViewModel {
  candidates: Candidate[]
  candidatesById: Record<string, Candidate>
}

export function createCandidateSearchStore(): ICandidateSearchStore {
  const { showErrorToast } = useToast()
  const globalStore = useStore()

  const state: IPrivateCandidateSearchViewModel = reactive({
    candidateFilter: {},
    candidates: [],
    savedFilters: [],
    totalCandidateQuantity: 0,
    candidatesById: computed(computeCandidatesById),
    rows: [],
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    isLoading: computed(computeLoading),
    isReady: false,
    isLoadingFilters: false,
    isLoadingCandidates: false,
    isLoadingShowDiversityFields: false,
    showDiversityFields: false
  })

  const actions: ICandidateSearchActions = {
    loadCandidates,
    selectCandidate,
    loadFirstPage,
    loadCandidatesByCandidateFilterId,
    loadSavedFilters,
    saveFilter,
    deleteFilter,
    loadShowDiversityFields,
    addInvitationToCandidate
  }

  async function loadShowDiversityFields() {
    try {
      state.isLoadingShowDiversityFields = true
      const agentProfile = await GetAgentProfile()
      state.showDiversityFields = !!(agentProfile?.officeLocations?.find(ol => ol.country === 'United States'))
    } catch {
      logErrorMessage('failed to load show diversity fields')
    } finally {
      state.isLoadingShowDiversityFields = false
    }
  }

  async function loadCandidatesByCandidateFilterId(candidateFilterId: string) {
    try {
      state.isLoadingCandidates = true
      state.currentPage = 1
      const response = await GetCandidatesFromSavedFilter(candidateFilterId, state.currentPage, state.perPage)
      state.totalCandidateQuantity = response.totalCandidates
      state.totalPages = Math.ceil(response.totalCandidates / state.perPage)
      state.candidates = response.candidates
      state.candidateFilter = response.rawFilterData
    } catch {
      logErrorMessage('failed to retrieve candidates')
      showErrorToast({message: locales.message_load_candidates_error})
    } finally {
      state.isLoadingCandidates = false
    }
  }

  async function loadSavedFilters() {
    try {
      state.isLoadingFilters = true
      state.savedFilters = await GetSavedFilters(await GetEmployerId())
    } catch (e) {
      logErrorMessage('error retrieving candidate filters')
    } finally {
      state.isLoadingFilters = false
    }
  }

  async function loadCandidates() {
    state.isLoadingCandidates = true
    try {
      state.candidates = []
      const response = await GetCandidates(state.candidateFilter as CandidateFilter, state.currentPage, state.perPage)
      state.candidates = response.candidates
      state.totalCandidateQuantity = response.totalCandidates
      state.totalPages = Math.ceil(response.totalCandidates / state.perPage)
      state.isReady = true
    } catch {
      showErrorToast({
        message: locales.message_load_candidates_error
      })
    } finally {
      state.isLoadingCandidates = false
    }
  }

  async function saveFilter(filterName: string): Promise<string> {
    if (!state.candidateFilter || !Object.keys(state.candidateFilter).length)
    {
      return ''
    }
    const candidateFilterId = await SaveCandidateFilter(state.candidateFilter as CandidateFilter, filterName, await GetEmployerId())
    loadCandidatesByCandidateFilterId(candidateFilterId)
    loadSavedFilters()
    return candidateFilterId
  }

  async function deleteFilter(candidateFilterMetadata: CandidateFilterMetadata) {
    try {
      await DeleteCandidateFilter(candidateFilterMetadata.candidateFilterId, await GetEmployerId())
      state.savedFilters = state.savedFilters.filter(f => f !== candidateFilterMetadata)
    } catch (e) {
      showErrorToast({
        message: locales.message_candidate_filter_delete_error
      })
    }
  }

  async function loadFirstPage() {
    const prev = state.currentPage
    state.currentPage = 1
    if (prev === 1) {
      loadCandidates()
    }
  }

  function selectCandidate(candidateRow: ICandidateSearchRowViewModel) {
    globalStore.state.candidate = state.candidatesById[candidateRow.candidateId]
  }

  async function favoriteCandidate(candidateRow: ICandidateSearchRowViewModel) {
    const candidate = state.candidatesById[candidateRow.candidateId]
    candidateRow.isLoading = true
    try {
      // TODO: Call API here
      console.log('favoriteCandidate', candidate)
    } catch {
      showErrorToast({
        message: locales.message_candidate_modify_error
      })
    } finally {
      candidateRow.isLoading = false
    }
  }

  async function unFavoriteCandidate(candidateRow: ICandidateSearchRowViewModel) {
    const candidate = state.candidatesById[candidateRow.candidateId]
    candidateRow.isLoading = true
    try {
      // TODO: Call API here
      console.log('unFavoriteCandidate', candidate)
    } catch {
      showErrorToast({
        message: locales.message_candidate_modify_error
      })
    } finally {
      candidateRow.isLoading = false
    }
  }

  async function createCandidateSearchRow(candidate: Candidate): Promise<ICandidateSearchRowViewModel> {
    let invitations = ''
    if (candidate.opportunityInvitationNames) {
      invitations = `${candidate.opportunityInvitationNames.slice(0, 3).join(', ')}${candidate.opportunityInvitationNames.length > 3 ? '...' : ''}`
    }
    return reactive({
      candidateId: candidate.id ?? '',
      candidateFullName: candidate.profileData.firstName + ' ' + candidate.profileData.lastName,
      candidateFirstName: candidate.profileData.firstName,
      schoolOrUniversity: formatSchoolOrUniversity(candidate),
      isReady: true,
      isLoading: false,
      invitations
    })
  }

  function formatSchoolOrUniversity(candidate: Candidate): string {
    return candidate.profileData.schoolInformation.map(school => school.schoolName).join(', ')
  }

  watch(() => state.candidates, computeCandidateSearchRows, { deep: true })

  async function computeCandidateSearchRows() {
    state.rows = await Promise.all(state.candidates.map((candidate) => createCandidateSearchRow(candidate)))
  }

  function computeCandidatesById(): Record<string, Candidate> {
    const candidatesById: Record<string, Candidate> = {}
    for (const candidate of state.candidates) {
      if (typeof candidate.id === 'string') {
        candidatesById[candidate.id] = candidate
      }
    }
    return candidatesById
  }

  function computeLoading(): boolean {
    return state.isLoadingCandidates || state.isLoadingFilters || state.isLoadingShowDiversityFields
  }

  function addInvitationToCandidate(invitationName: string, candidateId: string) {
    state.candidates.find(c => c.id === candidateId)!.opportunityInvitationNames!.push(invitationName)
  }

  return { state, actions }
}

export const ICandidateSearchStoreSymbol = Symbol('ICandidateSearchStore')

export function injectCandidateSearchStore(): ICandidateSearchStore {
  return inject(ICandidateSearchStoreSymbol, () => createCandidateSearchStore(), true)
}

export function provideCandidateSearchStore(store: ICandidateSearchStore) {
  provide(ICandidateSearchStoreSymbol, store)
}
