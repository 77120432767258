import UrlAssembler from 'url-assembler'
import { Configuration } from '@/config/config'
import { Models } from 'azure-maps-rest'

interface OrderBy {
  fieldName: string,
  ascending: boolean
}

/*
  Just doing 'const api = UrlAssembler(baseUrl)'
  makes setting the environment variable for tests tricky. Seems like
  it gets set using the existing environment variable before jest can
  change the environment variable
*/
function GetUrlString(path: string, params: any = null, perPage: number | null = null, currentPage: number | null = null, orderBy: OrderBy | null = null) : string {
  return GetUrlAssembler({ path, params, perPage, currentPage, orderBy }).toString()
}

function GetUrlAssembler(urlParams: { path: string, params?: any, perPage?: number | null, currentPage?: number | null, orderBy?: OrderBy | null }) {
  let assembler = UrlAssembler(Configuration.API_URL_BASE).template(urlParams.path)

  // When passinng arrays as query params, the default behavior is to
  // send the array as ?param[0]=val&param[1]=val but our apis can only
  // translate if they are passed in as ?param=val&param=val
  // https://github.com/ljharb/qs#stringifying
  assembler.qsConfig({arrayFormat: 'repeat'})

  if (urlParams.params !== null) {
    assembler = assembler.param(urlParams.params)
  }

  const top = urlParams.perPage
  let skip = null
  if (urlParams.currentPage && urlParams.perPage) {
    skip = (urlParams.currentPage - 1) * urlParams.perPage
  }

  if (top != null) {
    assembler = assembler.query('$top', top)
  }

  if (skip != null) {
    assembler = assembler.query('$skip', skip)
  }

  if (urlParams.orderBy != null) {
    const asc = urlParams.orderBy.ascending ? ' asc' : ' desc'
    assembler = assembler.query('$orderby', urlParams.orderBy.fieldName + asc)
  }

  return assembler
}

function GetSessionUrlString(path: string, params: any = null): string {
  const baseUrl: string = Configuration.SSU_DOMAIN
  let assembler = UrlAssembler(baseUrl).template(path)
  if (params !== null) {
    assembler = assembler.param(params)
  }

  return assembler.toString()
}

/**
 * Returns an odata string equivalent to checking if a property's value equals a value in a list
 * @param property The name of the domain object property whose value will be compared (subproperties should be / delimited. ex: 'profileData/dreamJobData/dreamJobTypes')
 * @param values The list of values that the property's value will be compared with
 * @returns The odata string representing the comparison
 */
function GetPropertyEqualsValueInListConditions(property: string, values: string[]): string[] {
  const conditions: string[] = []

  if (!values?.length) {
    return conditions
  }

  for (const val of values) {
    conditions.push(`cast(${property}, Edm.String) eq '${val}'`)
  }
  return [`(${conditions.join(' or ')})`]
}

function GetOpportunityLocationFilterConditions(locations: Models.SearchResultAddress[]): string {
  const azureConditions: string[] = []
  const addressConditions: string[] = []

  for (const location of locations) {
    const azureStrings: string[] = []
    const addressStrings: string[] = []

    if (location.municipality) {
      azureStrings.push(`toLower(opportunityListing/officeLocation/municipality) eq toLower('${location.municipality}')`)
      addressStrings.push(`toLower(opportunityListing/address/locality) eq toLower('${location.municipality}')`)
    }
    if (location.countrySubdivisionName) {
      // If this is a location formed from the azure maps autocomplete
      // Make sure the office location state name AND abbreviation match up properly
      // Make sure the manually entered address state matches either the state name OR abbreviation
      if (location.countrySubdivisionName !== location.countrySubdivision) {
        azureStrings.push(`toLower(opportunityListing/officeLocation/countrySubdivisionName) eq toLower('${location.countrySubdivisionName}')`)
        azureStrings.push(`toLower(opportunityListing/officeLocation/countrySubdivision) eq toLower('${location.countrySubdivision}')`)
        addressStrings.push(
          `(toLower(opportunityListing/address/administrativeArea) eq toLower('${location.countrySubdivisionName}') or ` +
          `toLower(opportunityListing/address/administrativeArea) eq toLower('${location.countrySubdivision}'))`
        )
      // If this is a manually entered state
      // Make sure the office location state name OR abbreviation matches the text entered by the user
      // Make sure the manually entered address state matches the text entered by the user
      } else {
        azureStrings.push(
          `(toLower(opportunityListing/officeLocation/countrySubdivisionName) eq toLower('${location.countrySubdivisionName}') or ` +
          `toLower(opportunityListing/officeLocation/countrySubdivision) eq toLower('${location.countrySubdivisionName}'))`
        )
        addressStrings.push(`toLower(opportunityListing/address/administrativeArea) eq toLower('${location.countrySubdivisionName}')`)
      }
    }
    if (location.countryCode) {
      azureStrings.push(`opportunityListing/officeLocation/countryCode eq '${location.countryCode}'`)
      addressStrings.push(`opportunityListing/address/country eq '${location.countryCode}'`)
    }

    if (azureStrings.length && addressStrings.length) {
      azureConditions.push(`(${azureStrings.join(' and ')})`)
      addressConditions.push(`(${addressStrings.join(' and ')})`)
    }
  }

  if (azureConditions.length && addressConditions.length) {
    return `(${azureConditions.join(' or ')} or ${addressConditions.join(' or ')})`
  }

  return ''
}

function GetPropEqualsValueCondition(
  property: string,
  value: string
): string {
  return (`(${property} eq '${value}')`)
}

/**
 * Returns the odata equivalent of checking if a list property contains a value from another list
 * @param property The name of the domain object list property whose values will be compared
 * @param values The list of values that the list property's values will be compared with
 * @returns The odata string representing the comparison
 */
function GetPropertyContainsValueInListConditions(property: string, values: string[]): string[] {
  const conditions: string[] = []

  if (!values?.length) {
    return conditions
  }

  for (const val of values) {
    conditions.push(`${property}/any(v: cast(v, Edm.String) eq '${val}')`)
  }
  return [`(${conditions.join(' or ')})`]
}

// Returns the odata equivalent of obj[propName].Any(subObj => subObj.arrayObjectPropertyName in arr)
function GetSearchArraySubPropertyConditions(arr: Array<string>, propName: string, arrayObjectPropertyName: string): Array<string> {
  const conditions: Array<string> = []

  if (!arr?.length) {
    return conditions
  }

  for (const val of arr) {
    conditions.push(
      `${propName}/any(subObj: subObj/${arrayObjectPropertyName} eq '${val}')`
    )
  }
  return [`(${conditions.join(' or ')})`]
}

function GetMinCondition(min: number, prop: string): string {
  return `${prop} ge ${min}`
}

function GetMaxCondition(max: number, prop: string): string {
  return `${prop} le ${max}`
}
export {
  GetUrlString,
  GetUrlAssembler,
  GetSessionUrlString,
  GetPropertyEqualsValueInListConditions,
  GetPropertyContainsValueInListConditions,
  GetSearchArraySubPropertyConditions,
  GetMinCondition,
  GetMaxCondition,
  GetOpportunityLocationFilterConditions,
  GetPropEqualsValueCondition,
  OrderBy
 }
