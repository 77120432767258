import { createSession } from '@/api/SessionApi'
import UrlAssembler from 'url-assembler'

async function redirectToSSU(forceRedirect?: boolean, registerOnly?: boolean) {
  let redirectUrl

  if(!forceRedirect) {
    redirectUrl = UrlAssembler(window.location.origin)
      .template('completeLogin')
  } else {
    redirectUrl = UrlAssembler(window.location.origin)
      .template('completeLogin')
      .param('redirectUrl', window.location.toString())
  }

  const SsuUrl = await createSession(redirectUrl.toString(), registerOnly?? false)
  location.assign(SsuUrl)
}

export { redirectToSSU }
