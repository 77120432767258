import ExtendableError from 'es6-error'

export class UserFriendlyError extends ExtendableError {
  private _innerError?: unknown

  get innerError() {
    return this._innerError
  }

  constructor(message?: string, error?: unknown) {
    super(message)
    this._innerError = error
  }
}
