
import { defineComponent, nextTick, PropType, ref } from 'vue'
import { useOutsideClick } from '@/utils/HandleOutsideClick'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { useDropdownBehavior } from '@/utils/DropdownBehavior'
import { useOutsideWheel } from '@/utils/HandleOutsideWheel'
import { DropdownOptionModel } from './DropdownOptions.interfaces'
import locales from './DropdownOptions.locales.en.json'

export default defineComponent({
  props: {
    id: createAutoIncrementIdProp('dropdown-options'),
    buttonId: createAutoIncrementIdProp('hamburger-button'),
    options: {
      type: Array as PropType<DropdownOptionModel[]>,
      default: () => []
    },
    position: {
      type: String as PropType<'default' | 'left'>,
      default: () => 'default'
    },
    highlightTrigger: {
      type: Boolean,
      default: () => true
    },
    showBorder: Boolean,
    disabled: Boolean
  },
  emits: ['option-change'],
  setup(props, { emit }) {
    const dropdownContainer = ref<HTMLDivElement>()
    const dropdownToggle = ref<HTMLDivElement>()
    const dropdown = ref<HTMLDivElement>()

    const { isOpen, handleOpen, handleClose } = useOutsideClick(dropdownContainer)
    const {
      dropdownTopOffset,
      dropdownLeftOffset,
      dropdownVerticalDirection,
      updateDropdownPositionAndDimension
    } = useDropdownBehavior(dropdownToggle, dropdownContainer, dropdown)

    useOutsideWheel(isOpen, dropdownToggle, handleClose)

    function handleOptionClick(_: Event, val: Record<string, string>) {
      emit('option-change', val)
      nextTick(handleClose)
    }

    function handleOpenAndPosition() {
      handleOpen()
      updateDropdownPositionAndDimension()
    }

    return {
      isOpen,
      dropdownContainer,
      dropdownToggle,
      handleOptionClick,
      handleOpenAndPosition,
      locales,
      dropdown,
      dropdownTopOffset,
      dropdownLeftOffset,
      dropdownVerticalDirection
    }
  }
})
