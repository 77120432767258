
import { PropType, ref, watch, computed, onMounted, defineComponent } from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import EducationForm from './EducationForm.vue'
import LawReviewJournalExperienceForm from './LawReviewJournalExperienceForm.vue'
import WorkExperienceInputForm from './WorkExperienceInputForm.vue'
import locales from '../CandidateProfile.locales.en.json'
import { RepeatTypes } from '@/types'
import { IsValidSchoolInformation } from '@/types/validation/schoolInformationValidation'
import { IsValidWorkExperience } from '@/types/validation/workExperienceValidation'
import { CandidateWorkExperience, LawReviewJournalExperience, SchoolInformation } from '@/types/candidate'
import { IsValidLawReviewJournalExperience } from '@/types/validation/candidateValidation'

export default defineComponent({
  components: { EducationForm, WorkExperienceInputForm, BsTextField, LawReviewJournalExperienceForm },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true
    },
    repeatType: {
      type: Number,
      default: ()=> RepeatTypes.AutoTrimTextInput
    },
    label: {
      type: String,
      default: ''
    },
    maxEntries: {
      type: Number,
      default: undefined
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    validationScope: {
      type: String,
      default: () => ''
    }
  },
  emits: ['Update:modelValue'],
  setup(props, { emit }) {
    const elements = ref<any[]>([])

    const elementCountNotMaxed = computed(() => {
      return !props.maxEntries || elements.value.length < props.maxEntries
    })

    const isValidInfoFilled = computed(()=>{
      const lastEntry = elements.value[elements.value.length - 1]
      let isValid = false

      if(lastEntry){
        if(props.repeatType === RepeatTypes.SchoolInformationComponent){
          isValid = IsValidSchoolInformation(lastEntry.value as SchoolInformation)
        }
        else if(props.repeatType === RepeatTypes.WorkExperience){
          isValid = IsValidWorkExperience(lastEntry.value as CandidateWorkExperience)
        }
        else if(props.repeatType === RepeatTypes.LawReviewJournalExperience){
          isValid = IsValidLawReviewJournalExperience(lastEntry.value as LawReviewJournalExperience)
        }
      }

      return isValid
    })

    const addElement = () => {
      const lastEntry = elements.value[elements.value.length - 1]
      switch (props.repeatType) {
        case RepeatTypes.AutoTrimTextInput: {
          if (lastEntry.value) {
            elements.value.push({ value: '', key: lastEntry.key++ })
          }
          break
        }
        case RepeatTypes.WorkExperience: {
          if (isValidInfoFilled.value) {
            elements.value.push({
              value: {
                positionTitle: '',
                candidateEmployer: '',
                startYear: undefined,
                endYear: undefined
              } as CandidateWorkExperience,
              key: lastEntry.key + 100
            })
          }
          break
        }
        case RepeatTypes.SchoolInformationComponent: {
          if (isValidInfoFilled.value) {
            elements.value.push({
              value: {
                startDate: null,
                endDate: null
              } as unknown as SchoolInformation,
              key: lastEntry.key + 100
            })
          }
          break
        }
        case RepeatTypes.LawReviewJournalExperience: {
          if (isValidInfoFilled.value) {
            elements.value.push({
              value: {
                lawReviewJournal: '',
                titlePosition: ''
              } as LawReviewJournalExperience,
              key: lastEntry.key + 100
            })
          }
        }
      }
    }

    const removeElement = (index: number) => {
      const lastEntry = elements.value[elements.value.length - 1]
      elements.value.splice(index, 1)
      if(props.repeatType === RepeatTypes.WorkExperience && elements.value.length < 1) {
        elements.value.push({
          value: {
            positionTitle: '',
            candidateEmployer: '',
            startYear: undefined,
            endYear: undefined
          } as CandidateWorkExperience,
          key: lastEntry.key + 100
        })
      }
      else if(props.repeatType === RepeatTypes.LawReviewJournalExperience && elements.value.length < 1) {
        elements.value.push({
          value: {
            lawReviewJournal: '',
            titlePosition: ''
          } as LawReviewJournalExperience,
          key: lastEntry.key + 100
        })
      }
    }

    watch(
      elements,
      (newValue) => {
        emit(
          'Update:modelValue',
          newValue.map((nv: any) => nv.value)
        )
      },
      { deep: true }
    )

    onMounted(() => {
      handleInitialAssign()
    })

    function handleInitialAssign(){
      if (!props.modelValue || props.modelValue.length < 1) {
        elements.value = []
        switch (props.repeatType) {
          case RepeatTypes.AutoTrimTextInput: {
            elements.value.push({ value: '', key: 0 })
            break
          }
          case RepeatTypes.WorkExperience: {
            elements.value.push({
              value: {
                positionTitle: '',
                candidateEmployer: '',
                startYear: undefined,
                endYear: undefined
              } as CandidateWorkExperience,
              key: 0
            })
            break
          }
          case RepeatTypes.SchoolInformationComponent: {
            elements.value.push({ value: {
              startDate: null,
              endDate: null
            } as unknown as SchoolInformation, key: 0 })
            break
          }
          case RepeatTypes.LawReviewJournalExperience: {
            elements.value.push({
              value: {
                lawReviewJournal: '',
                titlePosition: ''
              } as LawReviewJournalExperience,
              key: 0
            })
            break
          }
          default: {
            throw new Error('Invalid repeat type')
          }
        }
      }else{
          elements.value = props.modelValue.map((val: string, i: number) => ({
          value: val,
          key: i
        }))
      }
    }

    return {
      elements,
      elementCountNotMaxed,
      addElement,
      removeElement,
      RepeatTypes,
      locales,
      isValidInfoFilled
    }
  }
})
