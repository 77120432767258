import {computed, reactive, inject, provide } from 'vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { useStore } from 'vuex'
import {
  IJobOpportunitiesSummaryActions,
  IJobOpportunitiesSummaryStore,
  IJobOpportunitiesSummaryViewModel,
  IJobOpportunityRowViewModel
} from './JobOpportunitiesSummary.interface'
import { OpportunitiesDetailsOptions } from '@/components/CandidateOpportunityStatusSelector/CandidateOpportunityStatusSelector.interfaces'
import { CandidateOpportunityStatus } from '@/types/candidate'
import locales from './JobOpportunitiesSummary.locales.en.json'
import {
  FavoriteOpportunity,
  GetOpportunityCountsByStatus,
  GetPublishedOpportunities,
  UnfavoriteOpportunity
} from '@/api/OpportunityApi'
import { GetCandidate } from '@/api/CandidateApi'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { JobOpportunity } from '@/types/opportunity'
import { formatOpportunityLocation, formatOpportunityCompensation, formatOpportunityAge, formatOpportunityType } from '@/utils/opportunity'
import { formatDate } from '@/utils/date'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { GetEmployerNamesByIds } from '@/api/EmployerApi'

export function createJobOpportunitiesSummaryStore(): IJobOpportunitiesSummaryStore {
  const { showErrorToast } = useToast()
  const globalStore = useStore()

  const state: IJobOpportunitiesSummaryViewModel = reactive({
    rows: computed(computeJobOpportunityRows),
    isLoading: false,
    isLoadingOpportunities: false,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    totalFilteredOpportunities: 0,
    opportunities: [],
    opportunityCountsByStatus: reactive({
      available: 0,
      applied: 0,
      shortlisted: 0,
      interviewing: 0
    }),
    selectedStatus: 'Available',
    filter: reactive({
      postedWithinHours: 0,
      opportunityTypes: [],
      requirementsToApply: [],
      remote: false,
      countryCode: '',
      locations: []
    }),
    userFileDescriptions: [],
    filterDialogIsOpened: false,
    cardOptions: computed(computeCardOptions),
    authenticated: computed(computeAuthenticated)
  })

  const actions: IJobOpportunitiesSummaryActions = {
    loadOpportunities,
    loadOpportunityCounts,
    loadCandidateFileDescriptions,
    loadFirstPage,
    resetFilter,
    favoriteOpportunity,
    unFavoriteOpportunity,
    openFiltersDialog
  }

  function computeCardOptions(): OpportunitiesDetailsOptions<CandidateOpportunityStatus>[] {
    return [
      {
        label: locales.option_available,
        key: 'Available',
        count: state.opportunityCountsByStatus.available
      },
      {
        label: locales.option_applied,
        key: 'Applied',
        count: state.opportunityCountsByStatus.applied
      },
      {
        label: locales.option_shortlisted,
        key: 'Shortlisted',
        count: state.opportunityCountsByStatus.shortlisted
      },
      {
        label: locales.option_interviewing,
        key: 'Interviewing',
        count: state.opportunityCountsByStatus.interviewing
      }
    ]
  }

  function computeAuthenticated() {
    return globalStore.state.authenticated
  }

  function computeJobOpportunityRows() {
    return state.opportunities.map(createJobOpportunityRow)
  }

  function createJobOpportunityRow(jobOpportunity: JobOpportunity): IJobOpportunityRowViewModel {
    return reactive({
      opportunityId: jobOpportunity.id,
      title: jobOpportunity.opportunityListing?.title ?? '',
      company: jobOpportunity?.employerName ?? '',
      location: formatOpportunityLocation(jobOpportunity.opportunityListing),
      opportunityType: formatOpportunityType(jobOpportunity.opportunityListing?.opportunityType),
      compensation: formatOpportunityCompensation(jobOpportunity.opportunityListing),
      postedDate: jobOpportunity.opportunityListing
        ? formatDate(jobOpportunity.opportunityListing.postedDate)
        : 'undetermined',
      daysAgo: formatOpportunityAge(jobOpportunity.opportunityListing),
      isFavorite: jobOpportunity.isFavorited,
      hasApplied: (jobOpportunity.opportunityListing?.applyType ?? 'InternalManage') === 'InternalManage' && jobOpportunity.hasAppliedTo,
      isLoadingProfilePicture: false,
      profilePictureDomainObjectId: extractProfilePictureDomainObjectId(jobOpportunity.supportingFileMetadata),
      isLoadingFavorite: false,
      applyType: jobOpportunity.opportunityListing?.applyType ?? 'InternalManage',
      employerId: jobOpportunity.employerId,
      employerName: jobOpportunity.employerName
    })
  }

  async function loadOpportunities() {
    state.isLoadingOpportunities = true
    state.filterDialogIsOpened = false
    try {
      const response = await GetPublishedOpportunities(state.selectedStatus, state.currentPage, state.perPage, state.filter)
      state.opportunities = await PopulateEmployerName(response.opportunities)
      state.totalPages = Math.ceil(response.totalFilteredOpportunities / state.perPage)
      state.totalFilteredOpportunities = response.totalFilteredOpportunities
    } catch {
      if (state.currentPage !== 1) {
        await loadFirstPage()
      } else {
        showErrorToast({
          message: locales.message_opportunities_error
        })
      }
    } finally {
      state.isLoadingOpportunities = false
    }
  }

  async function PopulateEmployerName(opportunities: JobOpportunity[]): Promise<JobOpportunity[]> {
    const uniqueEmployers = [ ...new Set(opportunities.map(opportunity => opportunity.employerId)) ]

    const missingEmployerIds = uniqueEmployers.filter(employerId =>
    {
      return employerId && !globalStore.state.employerNameMapping[employerId]
    })

    if (missingEmployerIds.length) {
      const employers = await GetEmployerNamesByIds(missingEmployerIds)
      globalStore.state.employerNameMapping = { ...globalStore.state.employerNameMapping, ...employers }
    }

    opportunities.forEach(opportunity =>
      opportunity.employerName = globalStore.state.employerNameMapping[opportunity.employerId])
    return opportunities
  }

  async function loadOpportunityCounts() {
    state.isLoading = true
    try {
      state.opportunityCountsByStatus = await GetOpportunityCountsByStatus()
    } catch {
      showErrorToast({
        message: locales.message_opportunities_count_error
      })
    } finally {
      state.isLoading = false
    }
  }

  async function loadCandidateFileDescriptions() {
    const candidate = await GetCandidate()
    state.userFileDescriptions = candidate.candidateFileMetadata.map(cfm => cfm.fileDescription)
  }

  function extractProfilePictureDomainObjectId(fileItems?: FileMetadata[]) {
    if (!fileItems) {
      return
    }
    return fileItems.find(fileItem => fileItem.fileDescription === FileDescription.CandidateProfilePicture)?.domainObjectId
  }

  function resetFilter() {
    Object.assign(state.filter, {
      postedWithinHours: 0,
      opportunityTypes: [],
      requirementsToApply: [],
      remote: false,
      countryCode: '',
      locations: []
    })
  }

  async function loadFirstPage() {
    state.currentPage = 1
    await loadOpportunities()
  }

  async function favoriteOpportunity(row: IJobOpportunityRowViewModel) {
    try {
      row.isLoadingFavorite = true
      await FavoriteOpportunity(row.opportunityId)
      row.isFavorite = true
      state.opportunityCountsByStatus.shortlisted += 1
    } catch {
      showErrorToast({
        message: locales.message_favorite_error
      })
    } finally {
      row.isLoadingFavorite = false
    }
  }

  async function unFavoriteOpportunity(row: IJobOpportunityRowViewModel) {
    try {
      row.isLoadingFavorite = true
      await UnfavoriteOpportunity(row.opportunityId)
      row.isFavorite = false
      state.opportunityCountsByStatus.shortlisted -= 1
    } catch {
      showErrorToast({
        message: locales.message_favorite_error
      })
    } finally {
      row.isLoadingFavorite = false
    }
  }

  function openFiltersDialog() {
    state.filterDialogIsOpened = true
  }

  return {
    state,
    actions
  }
}

const IJobOpportunitiesSummaryStoreSymbol = Symbol('IJobOpportunitiesSummaryStore')

export function injectJobOpportunitiesSummaryStore() {
  return inject(IJobOpportunitiesSummaryStoreSymbol, () => createJobOpportunitiesSummaryStore(), true)
}

export function provideJobOpportunitiesSummaryStore(store: IJobOpportunitiesSummaryStore) {
  provide(IJobOpportunitiesSummaryStoreSymbol, store)
}
