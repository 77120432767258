import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f796dac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "diversity-disclosure-uk-form" }
const _hoisted_2 = { class: "disability-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "btn-segment" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsSelect = _resolveComponent("BsSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsSelect, {
      modelValue: _ctx.diversityDisclosureRef.ethnicity,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.diversityDisclosureRef.ethnicity) = $event)),
      class: "mb-3",
      label: _ctx.locales.ethnicity,
      disabled: _ctx.viewOnly,
      options: [
        {
          text: _ctx.locales.choose_an_option,
          value: '',
          disabled: true
        },
        ..._ctx.DiversityDisclosures.UK.ethnicity
      ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "disabled", "options"]),
    _createVNode(_component_BsSelect, {
      modelValue: _ctx.diversityDisclosureRef.sex,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.diversityDisclosureRef.sex) = $event)),
      class: "mb-3",
      label: _ctx.locales.sex,
      disabled: _ctx.viewOnly,
      options: [
        {
          text: _ctx.locales.choose_an_option,
          value: '',
          disabled: true
        },
        ..._ctx.DiversityDisclosures.UK.sex
      ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "disabled", "options"]),
    _createVNode(_component_BsSelect, {
      modelValue: _ctx.diversityDisclosureRef.sexualOrientation,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.diversityDisclosureRef.sexualOrientation) = $event)),
      class: "mb-3",
      label: _ctx.locales.sexual_orientation,
      disabled: _ctx.viewOnly,
      options: [
        {
          text: _ctx.locales.choose_an_option,
          value: '',
          disabled: true
        },
        ..._ctx.DiversityDisclosures.UK.sexualOrientation
      ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "disabled", "options"]),
    _createVNode(_component_BsSelect, {
      modelValue: _ctx.diversityDisclosureRef.socioEconomic,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.diversityDisclosureRef.socioEconomic) = $event)),
      class: "mb-3",
      label: _ctx.locales.socio_economic,
      disabled: _ctx.viewOnly,
      options: [
        {
          text: _ctx.locales.choose_an_option,
          value: '',
          disabled: true
        },
        ..._ctx.DiversityDisclosures.UK.socioEconomic
      ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "disabled", "options"]),
    _createVNode(_component_BsSelect, {
      modelValue: _ctx.diversityDisclosureRef.age,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.diversityDisclosureRef.age) = $event)),
      class: "mb-3",
      label: _ctx.locales.age,
      disabled: _ctx.viewOnly,
      options: [
        {
          text: _ctx.locales.choose_an_option,
          value: '',
          disabled: true
        },
        ..._ctx.DiversityDisclosures.UK.age
      ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "disabled", "options"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.viewOnly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.locales.disability_content_one), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.locales.disability_content_two), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_BsSelect, {
        modelValue: _ctx.diversityDisclosureRef.disabilityStatus,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.diversityDisclosureRef.disabilityStatus) = $event)),
        class: "mb-3",
        label: _ctx.locales.disability,
        disabled: _ctx.viewOnly,
        options: [
          {
            text: _ctx.locales.choose_an_option,
            value: '',
            disabled: true
          },
          ..._ctx.DiversityDisclosures.UK.disabilityStatus
        ],
        "option-label": "text",
        "option-value": "value",
        "option-disabled": "disabled",
        "default-selected-value": ""
      }, null, 8, ["modelValue", "label", "disabled", "options"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "btn btn-outline-primary mr-3 me-3",
        disabled: _ctx.isInfoSaving,
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
      }, _toDisplayString(_ctx.locales.cancel), 9, _hoisted_7),
      (!_ctx.viewOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-primary",
            disabled: _ctx.isInfoSaving,
            onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.submitDiversityDisclosure()), ["prevent"]))
          }, _toDisplayString(_ctx.locales.save), 9, _hoisted_8))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.showButtons]
    ])
  ]))
}