
import { computed, defineComponent, PropType } from 'vue'
import HeadingWrapper from './HeadingWrapper.vue'
import locales from '../CandidateProfile.locales.en.json'
import { otherSubMenuOptions } from '../CandidateProfile.util'
import { PreviewDropdownModel, ProfileCategories } from '../CandidateProfile.interface'
import { CandidateProfile, StudentOrganizations } from '@/types/candidate'


export default defineComponent({
  components: {
    HeadingWrapper
  },
  props: {
    details: {
      type: Object as PropType<CandidateProfile>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    }
  },
  emits: ['dropdown-change'],
  setup(props, { emit }) {
    function handleOptionChange(val: PreviewDropdownModel) {
      emit('dropdown-change', val, ProfileCategories.AwardsHonors)
    }

    const getStudentOrganizations = computed(()=>{
      let items : string[] = []

      StudentOrganizations.forEach(val=>
      {
        if((props.details.studentOrganizations || []).includes(val.value) && val.value !== 'Other' ) {
          items.push(val.text)
        }
      })

      if(props.details.otherStudentOrganization) {
        items.push(props.details.otherStudentOrganization)
      }

      return items
    })

    return {
      locales,
      handleOptionChange,
      otherSubMenuOptions,
      getStudentOrganizations
    }
  }
})
