
import { defineComponent, PropType } from 'vue'
import type { IEmployerJobPostStatusViewModel, IEmployerJobPostViewModel } from './EmployerJobPost.interfaces'
import EmployerJobPostCard from './EmployerJobPostCard.vue'
import locales from './EmployerJobPost.locales.en.json'

export const EmployerJobPostGrid = defineComponent({
  name: 'EmployerJobPostGrid',
  components: { EmployerJobPostCard },
  props: {
    jobStatus: {
      type: Object as PropType<IEmployerJobPostStatusViewModel>,
      required: true
    }
  },
  emits: ['post-selected', 'edit-post'],
  setup(props, { emit }) {

    const handleJobPostSelected = (jobPost: IEmployerJobPostViewModel) => {
      emit('post-selected', jobPost)
    }

    const handleEditJobPost = (jobPost: IEmployerJobPostViewModel) => {
      emit('edit-post', jobPost)
    }

    return {
      locales,
      handleJobPostSelected,
      handleEditJobPost
    }
  }
})

export default EmployerJobPostGrid
