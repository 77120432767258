import type { IStore } from '@/interfaces/common/StoreEntities'

export interface IBsAccordionContainerViewModel {
  menuState: null | string | string[]
  isMultiple: boolean
}
export interface IBsAccordionContainerActions {
  selectAccordion(menuId: string): void
}

export type IBsAccordionContainerStore = IStore<
  IBsAccordionContainerViewModel,
  IBsAccordionContainerActions
>

export const IBsAccordionStoreSymbol = Symbol('IBsAccordionStore')
