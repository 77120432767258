import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_focus_trap = _resolveComponent("focus-trap")!

  return (_openBlock(), _createBlock(_component_focus_trap, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref: "target",
        class: "FocusTrapWrapper",
        tabindex: "-1",
        onAnimationend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAnimationEnd && _ctx.handleAnimationEnd(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 544)
    ]),
    _: 3
  }))
}