import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8c9f97a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "username-icon",
    role: "img",
    "aria-label": _ctx.nameDetails && _ctx.nameDetails.name + ' ' + _ctx.locales.acronym
  }, [
    (_ctx.nameDetails)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.getNameAcronym), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}