import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ebb840"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row header-wrapper" }
const _hoisted_2 = { class: "col-md-6 col-sm-12" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "col-md-6 col-sm-12" }
const _hoisted_5 = { class: "dropdown-segment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownOptions = _resolveComponent("DropdownOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.heading), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.hideOptions)
          ? (_openBlock(), _createBlock(_component_DropdownOptions, {
              key: 0,
              options: _ctx.dropdownOptions,
              position: "left",
              onOptionChange: _ctx.handleOptionChange
            }, null, 8, ["options", "onOptionChange"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}