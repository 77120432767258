
import { LawReviewJournalExperience } from '@/types/candidate'
import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue'
import locales from '../CandidateProfile.locales.en.json'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  components: {
    BsTextField
  },
  props: {
    modelValue: {
      type: Object as PropType<LawReviewJournalExperience>,
      default: ()=> {}
    },
    idIndex: {
      type: Number,
      required: true
    },
    validationScope: {
      type: String,
      default: () => ''
    }
  },
  emits:['update:modelValue'],
  setup(props, { emit }) {

    const lawReviewJournalExperience = reactive<LawReviewJournalExperience>(props.modelValue)

    watch(()=> props.modelValue, (newVal, oldVal)=>{
      if(newVal !== oldVal){
        assignInitialValues()
      }
    }, { deep: true })

    watch(lawReviewJournalExperience, (newVal) => {
        emit('update:modelValue', newVal)
      }, { deep: true })

    onMounted(() => {
      assignInitialValues()
    })

    function assignInitialValues(){
      lawReviewJournalExperience.lawReviewJournal =
        props?.modelValue?.lawReviewJournal ?? ''
      lawReviewJournalExperience.titlePosition =
        props?.modelValue?.titlePosition ?? ''
    }

    function isRequired(lawExperience: LawReviewJournalExperience): boolean {
      return !!lawExperience.lawReviewJournal || !!lawExperience.titlePosition
    }

    const rules = computed<any>(() => {
      return {
        form: {
          lawReviewJournal: {
            required: requiredIf(isRequired(lawReviewJournalExperience))
          },
          titlePosition: {
            required: requiredIf(isRequired(lawReviewJournalExperience))
          }
        }
      }
    })

    const v$ = useVuelidate(rules, {
      form: lawReviewJournalExperience
    }, { $scope: props.validationScope || false })
    v$.value.$touch()

    return {
      lawReviewJournalExperience,
      locales,
      v$
    }
  }
})
