export function containsValue(object: any) {
  if (!object) {
    return false
  }
  for (const value of Object.values(object)) {
    if (value) {
      return true
    }
    return false
  }
}