import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00b66499"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-wrapper" }
const _hoisted_2 = { novalidate: "" }
const _hoisted_3 = {
  id: "customize-invite-modal__title",
  class: "title-label"
}
const _hoisted_4 = { class: "form-content" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 dialog-footer" }
const _hoisted_7 = { class: "include-link-item" }
const _hoisted_8 = {
  for: "include-link__input",
  class: "include-link-label"
}
const _hoisted_9 = { class: "link-btn" }
const _hoisted_10 = { class: "col-md-6 col-sm-12 dialog-footer" }
const _hoisted_11 = { class: "form-btn-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_ToastQueue = _resolveComponent("ToastQueue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      id: "customize-invite-modal",
      ref: "dialogRef",
      class: "customize-invite-modal",
      "aria-labelled-by": "customize-invite-modal__title"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("form", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.locales.customize_invitation), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BsSelect, {
                modelValue: _ctx.formFields.opportunityId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.opportunityId) = $event)),
                class: "mb-3",
                label: _ctx.locales.select_opportunity,
                placeholder: _ctx.locales.select_opportunity,
                validation: _ctx.v$.opportunityId,
                options: [
              {
                label: _ctx.locales.opportunity_name,
                value: '',
                disabled: true
              },
              ..._ctx.invitableOpportunities
            ],
                "option-label": "label",
                "option-value": "value",
                "option-disabled": "disabled",
                "default-selected-value": ""
              }, null, 8, ["modelValue", "label", "placeholder", "validation", "options"]),
              _createVNode(_component_TextEditor, {
                modelValue: _ctx.formFields.messageContent,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formFields.messageContent) = $event)),
                label: _ctx.locales.personal_message,
                validation: _ctx.v$.messageContent
              }, null, 8, ["modelValue", "label", "validation"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_BsCheckbox, {
                      id: "include-link",
                      modelValue: _ctx.formFields.includeLink,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formFields.includeLink) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.locales.include) + " ", 1),
                      _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.locales.link), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.locales.to_job_invitation), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      class: "btn btn-text-primary",
                      type: "button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleDialog(false)))
                    }, _toDisplayString(_ctx.locales.cancel), 1),
                    _createElementVNode("button", {
                      class: "btn btn-primary",
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)))
                    }, _toDisplayString(_ctx.locales.send), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_ToastQueue, {
      isOpen: _ctx.isToastOpen,
      "onUpdate:isOpen": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isToastOpen) = $event)),
      title: _ctx.locales.invitation_sent,
      "sub-text": _ctx.locales.invitation_sent_subtext
    }, null, 8, ["isOpen", "title", "sub-text"])
  ], 64))
}