import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { GetActiveOpportunitiesResponse, JobOpportunity, OpportunityFilter, OpportunityListing, RequirementsToApply } from '@/types/opportunity'
import axios, { AxiosResponse, Method } from 'axios'
import UrlAssembler from 'url-assembler'
import { CandidateFeedFilter } from '@/types/candidateFilter'
import {
  GetUrlString,
  GetUrlAssembler,
  GetPropertyEqualsValueInListConditions,
  OrderBy,
  GetPropEqualsValueCondition,
  GetPropertyContainsValueInListConditions,
  GetOpportunityLocationFilterConditions
} from './ApiBase'
import { GetEmployerId, GetUserId } from '@/okta'
import { CandidateOpportunityStatus } from '@/types/candidate'
import { FileDescriptionTextValues } from '@/types/fileMetadata/fileDescription'

async function UpsertJobOpportunity(jobOpportunity: JobOpportunity) {
  const empId = await GetEmployerId()
  let verb: Method = 'post'
  let urlAssembler = GetUrlAssembler({path: 'employers/:empId/opportunities', params: { empId }})

  if(jobOpportunity.id){
    verb = 'put'
    urlAssembler = urlAssembler.segment('/:opportunityId').param('opportunityId', jobOpportunity.id)
  }

  const response = await axios({
    method: verb,
    url: urlAssembler.toString(),
    data: {
      opportunityListing: jobOpportunity.opportunityListing,
      status: jobOpportunity.status
    }
  })

  return response.data
}

async function AbandonJobOpportunityDraft(jobOpportunityId: string) {
  const empId = await GetEmployerId()
  await axios.delete(GetUrlString('employers/:empId/opportunities/:jobOpportunityId', { empId, jobOpportunityId }))
}

async function GetOpportunityCountsByStatus(): Promise<{ available: number, applied: number, shortlisted: number, interviewing: number }> {
  let counts = {
    available: 0,
    applied: 0,
    shortlisted: 0,
    interviewing: 0
  }
  const response = await axios.get(GetUrlString('candidates/self/opportunity-feed-counts'))
  if (response.data) {
    counts = response.data
  }
  return counts
}

async function GetPublishedOpportunities(status: CandidateOpportunityStatus | undefined, currentPage = 1, perPage = 10, filter: OpportunityFilter | undefined = undefined): Promise<GetActiveOpportunitiesResponse> {
  const userId = await GetUserId()
  let path = 'anon/published-opportunities'
  if (userId && status === 'Available') {
    path = 'candidates/self/opportunity-feeds/available'
  } else if (userId) {
    path = 'candidates/self/opportunity-feeds/:status'
  } else {
    status = undefined
  }
  const orderBy: OrderBy = {
    fieldName: 'opportunityListing/postedDate',
    ascending: false
  }
  let assembler = ApplyOpportunityFilter(GetUrlAssembler({ path, perPage, currentPage, orderBy }), filter, undefined)
  if (!!status && status !== 'Available') {
    assembler = assembler.param('status', status)
  }
  const response = await axios.get(assembler.toString())

  return {
    opportunities: response.data.map((opportunity: Record<keyof JobOpportunity, any>) => {
      const mappedOpportunity = { ...opportunity }
      if (opportunity.opportunityListing) {
        mappedOpportunity.opportunityListing = mapOpportunityListing(opportunity.opportunityListing)
      }
      return mappedOpportunity
    }),
    totalFilteredOpportunities: parseInt(response.headers['x-total-count'])
  } as GetActiveOpportunitiesResponse
}

function ApplyOpportunityFilter(assembler: UrlAssembler, filter: OpportunityFilter | undefined, status: CandidateOpportunityStatus | undefined): UrlAssembler {
  let oDataConditions: string[] = []

  if (filter) {
    oDataConditions = GetPropertyEqualsValueInListConditions('opportunityListing/opportunityType', filter.opportunityTypes)

    // Since filter.requirementsToApply may contain values of two different types, we need to make sure to use the correct property name
    let requirementConditions = GetPropertyContainsValueInListConditions(
      'opportunityListing/fileRequirementsToApply',
      filter.requirementsToApply?.filter(req => FileDescriptionTextValues.find(tvp => tvp.value === req)))

    requirementConditions = requirementConditions.concat(GetPropertyContainsValueInListConditions(
      'opportunityListing/requirementsToApply',
      filter.requirementsToApply?.filter(req => RequirementsToApply.find(tvp => tvp.value === req))))

    if (requirementConditions.length) {
      oDataConditions.push(requirementConditions.join(' or '))
    }
  }

  if (filter?.remote) {
    oDataConditions.push('(opportunityListing/remote eq true)')
    if (filter?.countryCode) {
      oDataConditions.push(`(opportunityListing/officeLocation/countryCode eq '${filter.countryCode}' or opportunityListing/address/country eq '${filter.countryCode}')`)
    }
  } else if (filter?.locations?.length) {
    const locationConditions = GetOpportunityLocationFilterConditions(filter.locations)
    if (locationConditions) {
      oDataConditions.push(locationConditions)
    }
  }

  if (oDataConditions.length) {
    assembler = assembler.query('$filter', oDataConditions.join(' and '))
  }

  if (status) {
    assembler = assembler.query('candidateOpportunityStatus', status)
  }

  if (filter?.postedWithinHours) {
    assembler = assembler.query('postedWithinHours', filter.postedWithinHours)
  }

  return assembler
}

async function GetPublishedOpportunity(opportunityId: string): Promise<JobOpportunity> {
  const response = await axios.get(
    GetUrlString('anon/published-opportunities/:opportunityId', {opportunityId})
  )
  
  const opportunity = { ...response.data }
  opportunity.opportunityListing = mapOpportunityListing(opportunity.opportunityListing)
  opportunity.candidatesInvitedToApply ??= []

  return opportunity
}

async function GetOpportunityById(opportunityId: string): Promise<JobOpportunity> {
  const employerId = await GetEmployerId()
  const response = await axios.get(
    GetUrlString('employers/:employerId/opportunities/:opportunityId', { employerId, opportunityId })
  )

  const opportunity = { ...response.data }
  opportunity.opportunityListing = mapOpportunityListing(opportunity.opportunityListing)
  opportunity.candidatesInvitedToApply ??= []

  return opportunity
}

async function GetPublishedOpportunitiesByCandidateFeedFilter(candidateFeedFilter: CandidateFeedFilter): Promise<GetActiveOpportunitiesResponse> {
  const assembler = ApplyCandidateFeedFilter(GetUrlAssembler({ path: 'anon/published-opportunities', perPage: 10 }), candidateFeedFilter)
  const response = await axios.get(
    assembler.toString()
  )

  return {
    opportunities: response.data,
    totalFilteredOpportunities: parseInt(response.headers['x-total-count'])
  } as GetActiveOpportunitiesResponse
}

function ApplyCandidateFeedFilter(assembler: UrlAssembler, candidateFilter: CandidateFeedFilter): UrlAssembler {
  const conditions: string[] = []
  if (!candidateFilter || !Object.keys(candidateFilter).length) {
    return assembler
  }

  if (candidateFilter.preferredLocations?.length) {
    conditions.push(GetOpportunityLocationFilterConditions(candidateFilter.preferredLocations))
  }

  if (conditions.length) {
    return assembler.query('$filter', conditions.join(' and '))
  }

  if (candidateFilter.employerId) {
    conditions.push(GetPropEqualsValueCondition('EmployerId', candidateFilter.employerId))
  }

  return assembler
}

async function GetOpportunitiesByEmployer(status: 'Published' | 'Draft' | 'Expired', currentPage: number = 1, perPage = 10, filter: OpportunityFilter | undefined = undefined): Promise<JobOpportunity[]> {
  const empId = await GetEmployerId()
  const path = `employers/:empId/${status === 'Expired' ? 'archived': status}-opportunities`
  const orderBy = {
    fieldName: 'opportunityListing/postedDate',
    ascending: false
  }
  const assembler = ApplyOpportunityFilter(GetUrlAssembler({ path, orderBy, perPage, currentPage, params: { empId } }), filter, undefined)

  const response = await axios.get(assembler.toString())

  const responseData = response.data as JobOpportunity[]

  if (!responseData) {
    return []
  }

  return responseData
    .filter(opportunity => opportunity.status === status)
    .map(jofa => ({
      id: jofa.id,
      employerId: jofa.employerId,
      opportunityListing: mapOpportunityListing(jofa.opportunityListing),
      hasAppliedTo: jofa.hasAppliedTo,
      isFavorited: jofa.isFavorited,
      supportingFileMetadata: jofa.supportingFileMetadata,
      views: jofa.views,
      status: jofa.status,
      numberOfApplications: jofa.numberOfApplications,
      numberOfApplicationsNotReviewed: jofa.numberOfApplicationsNotReviewed,
      candidatesInvitedToApply: jofa.candidatesInvitedToApply ?? []
    } as JobOpportunity))
}

async function GetAllEmployerOpportunities(currentPage: number, perPage: number): Promise<GetActiveOpportunitiesResponse> {
  const empId = await GetEmployerId()

  const requestString = GetUrlAssembler({
    path: 'employers/:empId/opportunities',
    params: { empId },
    orderBy: {
      fieldName: 'opportunityListing/postedDate',
      ascending: false
    },
    currentPage,
    perPage
  }).toString()

  const response = await axios.get(requestString)

  const responseData = response.data as JobOpportunity[]
  const count = parseInt(response.headers['x-total-count'])

  if (!responseData) {
    return {
      opportunities: [],
      totalFilteredOpportunities: 0
    }
  }

  const jobOpportunities = responseData
    .map(jofa => ({
      id: jofa.id,
      employerId: jofa.employerId,
      opportunityListing: mapOpportunityListing(jofa.opportunityListing),
      hasAppliedTo: jofa.hasAppliedTo,
      isFavorited: jofa.isFavorited,
      supportingFileMetadata: jofa.supportingFileMetadata,
      views: jofa.views,
      status: jofa.status,
      numberOfApplications: jofa.numberOfApplications,
      numberOfApplicationsNotReviewed: jofa.numberOfApplicationsNotReviewed,
      candidatesInvitedToApply: jofa.candidatesInvitedToApply ?? []
    } as JobOpportunity))

  return {
    opportunities: jobOpportunities,
    totalFilteredOpportunities: count
  } as GetActiveOpportunitiesResponse
}

const mapOpportunityListing = (listingFromApi?: OpportunityListing): OpportunityListing | undefined => {
  if (!listingFromApi) {
    return undefined
  }

  const mappedListingFromApi = { ...listingFromApi }

  if (listingFromApi.startDate) {
    mappedListingFromApi.startDate = new Date(listingFromApi.startDate)
  }
  if (listingFromApi.applicationDeadline) {
    mappedListingFromApi.applicationDeadline = new Date(listingFromApi.applicationDeadline)
  }
  if (listingFromApi.postedDate) {
    mappedListingFromApi.postedDate = new Date(listingFromApi.postedDate)
  }

  return mappedListingFromApi
}

//todo: these 3 are probably in the wrong place; should probably be in candidate
async function FavoriteOpportunity(
  opportunityId: string
): Promise<AxiosResponse> {
  return axios.put(
    GetUrlString('candidates/self/favorited-opportunities/:opportunityId', {
      opportunityId
    })
  )
}

async function UnfavoriteOpportunity(
  opportunityId: string
): Promise<AxiosResponse> {
  return axios.delete(
    GetUrlString('candidates/self/favorited-opportunities/:opportunityId', {
      opportunityId
    })
  )
}

async function GetFavoriteOpportunities(): Promise<AxiosResponse> {
  return axios.get(GetUrlString('candidates/self/favorited-opportunities'))
}

async function PostOpportunitySupportingFile(file: File, opportunityId: string): Promise<string> {
  const empId = await GetEmployerId()
  const formData = new FormData()
  formData.append('file', file)
  const url = GetUrlString('employers/:empId/opportunities/:opportunityId/files', { empId, opportunityId })

  const response = await axios.post(url, formData)

  return response.data
}

async function DeleteOpportunitySupportingFile(fileMetadataId: string, opportunityId: string): Promise<void> {
  const empId = await GetEmployerId()
  const url = GetUrlString('employers/:empId/opportunities/:opportunityId/files/:fileMetadataId', { empId, opportunityId, fileMetadataId })
  const response = await axios.delete(url)

  return response.data
}

async function GetOpportunitySupportingFileData(opportunityId: string, fileMetadata: FileMetadata): Promise<Blob | undefined> {
  return GetOpportunitySupportingFileDataByDomainObjectId(opportunityId, fileMetadata.domainObjectId)
}

async function GetOpportunitySupportingFileDataByDomainObjectId(opportunityId: string, domainObjectId: string): Promise<Blob | undefined> {
  try {
    const response = await axios.get(GetUrlString('anon/published-opportunities/:opportunityId/supporting-files/:fileMetadataId', {fileMetadataId: domainObjectId, opportunityId}),
      { responseType: 'blob'}
    )
    return response.data
  }
  catch {
    return undefined
  }
}

export {
  UpsertJobOpportunity,
  AbandonJobOpportunityDraft,
  GetOpportunityCountsByStatus,
  GetPublishedOpportunities,
  GetAllEmployerOpportunities,
  GetOpportunitiesByEmployer,
  FavoriteOpportunity,
  UnfavoriteOpportunity,
  GetFavoriteOpportunities,
  PostOpportunitySupportingFile,
  DeleteOpportunitySupportingFile,
  GetOpportunitySupportingFileData,
  GetPublishedOpportunitiesByCandidateFeedFilter,
  GetPublishedOpportunity,
  GetOpportunityById,
  GetOpportunitySupportingFileDataByDomainObjectId
}
