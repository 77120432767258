import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_opportunity_summary_card = _resolveComponent("opportunity-summary-card")!
  const _component_ApplyOpportunityForm = _resolveComponent("ApplyOpportunityForm")!

  return ($setup.errorLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " There was an error loading the page. Please refresh. "))
    : ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Loading... "))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_opportunity_summary_card, {
            "summary-details": $setup.summaryDetails,
            "preview-mode": true,
            "allow-apply": true,
            applied: $setup.hasApplied,
            onApplyToOpportunity: _cache[0] || (_cache[0] = ($event: any) => ($setup.openOpportunityDetailsToApply()))
          }, null, 8, ["summary-details", "applied"]),
          _createVNode(_component_ApplyOpportunityForm, {
            ref: "applyOpportunityForm",
            opportunity: $setup.opportunityPreview,
            onApply: $setup.applyToOpportunity
          }, null, 8, ["opportunity", "onApply"])
        ]))
}