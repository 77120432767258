
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { CountryAddressDetails, NonUSOpportunityTypeKey, OpportunityFilter, OpportunityTypes, RequirementsToApply, RequirementToApplyKey, USOpportunityTypeKey } from '@/types/opportunity'
import { Models } from 'azure-maps-rest'
import { FileDescription, FileDescriptionTextValues } from '@/types/fileMetadata/fileDescription'
import { RepeatTypes, TextValue } from '@/types'
import LocationFilter from '@/components/LocationFilter.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import locales from './JobOpportunitiesTableFilter.locales.en.json'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

export default defineComponent({
  components: {
    LocationFilter,
    BsSelect,
    BsMultiSelect,
    BsCheckbox
  },
  props: {
    modelValue: {
      type: Object as PropType<OpportunityFilter>,
      required: true
    },
    visible: Boolean,
    authenticated: {
      type: Boolean,
      default: false
    },
    validationScope: {
      type: String,
      default: 'job-opportunity-table-filter'
    }
  },
  emits: ['update:modelValue', 'update:visible', 'reset-filter'],
  setup(props, { emit }) {
    const { showErrorToast } = useToast()
    const postedWithinHours = ref(0)
    const datePostedFilterOptions: { text: string, value: number }[] = [
      {text: locales.option_last_hour, value: 1 },
      {text: locales.option_last_24_hours, value: 24 },
      {text: locales.option_last_7_days, value: 7*24},
      {text: locales.option_last_14_days, value: 14*24},
      {text: locales.option_last_30_days, value: 30*24},
      {text: locales.option_all, value: 0}
    ]
    const opportunityTypes = ref<(USOpportunityTypeKey | NonUSOpportunityTypeKey)[]>([])
    const requirementsToApply = ref<(RequirementToApplyKey | FileDescription)[]>([])
    const requirementsToApplyOptions = ref<TextValue<RequirementToApplyKey | FileDescription>[]>([])
    const isRemote = ref(false)
    const selectedLocations = ref<Models.SearchResultAddress[]>([])
    const selectedCountryCode = ref('')
    const form = ref<HTMLElement>()

    const v$ = useVuelidate({ $scope: props.validationScope || false })

    onMounted(() => {
      requirementsToApplyOptions.value = FileDescriptionTextValues.filter(tvp => tvp.value !== 'None')
      requirementsToApplyOptions.value = requirementsToApplyOptions.value.concat(RequirementsToApply)
    })

    watch(() => props.modelValue, unpackOpportunityFilter)

    async function handleFilterApplied() {
      v$.value.$touch()
      const isValid = await v$.value.$validate()
      if (!isValid) {
        handleInvalidForm()
        return
      }
      emit('update:modelValue', {
        postedWithinHours: postedWithinHours.value,
        opportunityTypes: opportunityTypes.value,
        requirementsToApply: requirementsToApply.value,
        remote: isRemote.value,
        countryCode: isRemote.value ? selectedCountryCode.value : '',
        locations: isRemote.value ? [] : selectedLocations.value
      })
      emit('update:visible', false)
    }

    function handleInvalidForm() {
      scrollToInvalidElement()
      showErrorToast({
        message: locales.message_validation_error
      })
    }

    function scrollToInvalidElement() {
      form.value?.querySelector('.is-invalid')?.scrollIntoView()
    }

    function reset() {
      emit('reset-filter')
      unpackOpportunityFilter(props.modelValue)
    }

    function unpackOpportunityFilter(opportunityFilter: OpportunityFilter) {
      postedWithinHours.value = opportunityFilter.postedWithinHours ?? 0
      opportunityTypes.value = opportunityFilter.opportunityTypes
      requirementsToApply.value = opportunityFilter.requirementsToApply
      isRemote.value = opportunityFilter.remote ?? false
      selectedCountryCode.value = opportunityFilter.countryCode ?? ''
      selectedLocations.value = opportunityFilter.locations ?? []
    }

    return {
      locales,
      form,
      reset,
      postedWithinHours,
      datePostedFilterOptions,
      opportunityTypes,
      OpportunityTypes,
      requirementsToApply,
      requirementsToApplyOptions,
      isRemote,
      selectedLocations,
      selectedCountryCode,
      CountryAddressDetails,
      RepeatTypes,
      handleFilterApplied
    }
  }
})
