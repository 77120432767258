import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-bottom mb-3" }
const _hoisted_2 = { class: "form-label mb-2" }
const _hoisted_3 = { class: "border-bottom" }
const _hoisted_4 = { class: "form-label mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString($setup.locales.us), 1),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.pronouns) + " " + _toDisplayString($setup.usDisclosure.pronouns), 513), [
        [_vShow, $setup.usDisclosure.pronouns]
      ]),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.gender) + " " + _toDisplayString($setup.usDisclosure.gender), 513), [
        [_vShow, $setup.usDisclosure.gender]
      ]),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.heritage) + " " + _toDisplayString($setup.usDisclosure.heritage), 513), [
        [_vShow, $setup.usDisclosure.heritage]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.usDisclosure.other, (option, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("p", null, _toDisplayString(option), 1)
        ]))
      }), 128))
    ], 512), [
      [_vShow, $setup.containsValue($setup.usDisclosure)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString($setup.locales.other), 1),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.ethnicity) + " " + _toDisplayString($setup.ukDisclosure.ethnicity), 513), [
        [_vShow, $setup.ukDisclosure.ethnicity]
      ]),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.sex) + " " + _toDisplayString($setup.ukDisclosure.sex), 513), [
        [_vShow, $setup.ukDisclosure.sex]
      ]),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.sexual_orientation) + " " + _toDisplayString($setup.ukDisclosure.sexualOrientation), 513), [
        [_vShow, $setup.ukDisclosure.sexualOrientation]
      ]),
      _createElementVNode("p", null, _toDisplayString($setup.ukDisclosure.socioEconomic), 1),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.age) + " " + _toDisplayString($setup.ukDisclosure.age), 513), [
        [_vShow, $setup.ukDisclosure.age]
      ]),
      _withDirectives(_createElementVNode("p", null, _toDisplayString($setup.locales.disability) + " " + _toDisplayString($setup.ukDisclosure.disabilityStatus), 513), [
        [_vShow, $setup.ukDisclosure.disabilityStatus]
      ])
    ], 512), [
      [_vShow, $setup.containsValue($setup.ukDisclosure)]
    ])
  ]))
}