import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!

  return (_openBlock(), _createBlock(_component_BsAutoComplete, {
    ref: "autoCompleteInput",
    "model-value": $props.modelValue,
    label: $props.label,
    multiple: "",
    field: "text",
    placeholder: $props.placeholder,
    suggestions: $setup.suggestions,
    "force-selection": "",
    "complete-on-focus": "completeOnFocus",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.search($event)))
  }, null, 8, ["model-value", "label", "placeholder", "suggestions"]))
}