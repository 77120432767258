
import { Data } from '@/types'
import router from '@/router'
import { logErrorMessage } from '@/logging'
import SelectUserTypeCard from '@/components/SelectUserType/SelectUserTypeCard.vue'

export default {
  components: {
    SelectUserTypeCard
  },
  setup (): Data {

    const redirectUser = async (selection: string) : Promise<void> => {

      switch(selection)
      {
        case 'candidate':
          router.push('/CandidateProfile')
          break
        case 'agent':
          router.push('/AssociateAgent')
          break
        default:
          logErrorMessage('invalid path selection')
      }
    }

    return {
      redirectUser
      }
  }
}
