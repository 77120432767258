
import { defineComponent, ref, PropType, watch } from 'vue'
import {
  CandidateDiversityDisclosure,
  DiversityDisclosures,
  DiversityDisclosureUS
} from '@/types/candidate'
import { GetCandidate, PutCandidateProfile } from '@/api/CandidateApi'
import locales from './DiversityDisclosure.locales.en.json'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    BsSelect,
    BsMultiSelect
  },
  props: {
    details: {
      type: Object as PropType<DiversityDisclosureUS>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const isInfoSaving = ref<boolean>(false)
    const diversityDisclosureRef = ref<DiversityDisclosureUS>(props.details)
    const router = useRouter()
    const { showSuccessToast, showErrorToast } = useToast()

    watch(
      () => props.details,
      (newVal) => {
        diversityDisclosureRef.value = newVal
      }
    )

    const submitDiversityDisclosure = async () => {
      isInfoSaving.value = true
      const currentCandidate = await GetCandidate()
      currentCandidate.profileData.candidateDiversityDisclosure ??= {
        usDisclosure: {}
      } as CandidateDiversityDisclosure
      currentCandidate.profileData.candidateDiversityDisclosure.usDisclosure =
        diversityDisclosureRef.value

      try {
        await PutCandidateProfile(currentCandidate)
        showSuccessToast({
          message:
            'Successfully completed diversity disclosure for the US fields',
          position: 'top-center'
        })
        router.push('CandidateProfile')
      } catch {
        showErrorToast({
          message: 'Something went wrong! Please try again later.',
          position: 'top-center'
        })
      } finally {
        isInfoSaving.value = false
      }
    }

    const cancel = () => {
      router.go(-1)
    }

    return {
      locales,
      DiversityDisclosures,
      diversityDisclosureRef,
      submitDiversityDisclosure,
      isInfoSaving,
      cancel
    }
  }
})
