
import { onMounted, PropType, ref, watch } from 'vue'
import { TextValue } from '@/types'
import { Models } from 'azure-maps-rest'
import { debounce } from 'vue-debounce'
import { GetUniqueCityCountryResults } from '@/api/AzMapsApi'
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'

export default {
  components: { BsAutoComplete },
  props: {
    modelValue: {
      type: Object as PropType<Models.SearchResultAddress[]>,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  //@ts-ignore no-implicit-any
  setup(props, context) {
    const localVal = ref<Array<TextValue<any>>>([])
    const filteredCities = ref<any[]>([])

    const searchCities = debounce(async (event: any) => {
      const resultDict = await GetUniqueCityCountryResults(event.query)
      filteredCities.value = Object.keys(resultDict).map(key => ({text: key, value: resultDict[key] }))
    }, 300)

    const setLocalValueFromProp = () => {
      localVal.value = props.modelValue.map((location: Models.SearchResultAddress) => {
          return {
            text: `${location.municipality}, ${location.countrySubdivisionName}, ${location.country}`,
            value: location
          }
        }) as Array<TextValue<Models.SearchResultAddress>>
    }

    watch(localVal, (newVal, oldVal) => {
      if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
        return
      }
      context.emit('update:modelValue', newVal.map(val => val.value))
    }, { deep: true })
    watch(() => props.modelValue, setLocalValueFromProp, { deep: true })

    onMounted(() => {
      setLocalValueFromProp()
    })

    return {
      localVal,
      filteredCities,
      searchCities,
      setLocalValueFromProp
    }
  }
}
