
import { GetAgentProfile } from '@/api/AgentApi'
import { GetEmployerId } from '@/okta'
import router from '@/router'
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const redirect = async () => {
      const empId = await GetEmployerId()
      if(!empId){
        router.push('JobOpportunitiesSummary')
        return
      }

      let agent = store.state.agentProfile
      if (!agent) {
        agent = await GetAgentProfile()
        store.state.agentProfile = agent
      }

      if(!agent){
        router.push('JobOpportunitiesSummary')
        return
      }

      router.push('/EmployerJobOpportunitiesSummary')
    }

    redirect()
  }
})
