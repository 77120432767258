import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fd459dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text text-center mb-2" }
const _hoisted_2 = { class: "circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "rectangle",
    class: _normalizeClass(["py-4", { 'selected': $props.selected }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.text), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.count), 1)
  ], 2))
}