import { logErrorMessage } from '@/logging'
import axios from 'axios'
import { Models } from 'azure-maps-rest'
import UrlAssembler from 'url-assembler'
import { Configuration } from '@/config/config'

async function GetUniqueCityCountryResults(
  query: string
): Promise<{ [key: string]: Models.SearchResultAddress }> {
  // This prevents querying Azure Maps and returning very low quality results unlikely to match user input
  if ((query.length ?? 0) < 3) {
    return {}
  }

  const assembler = UrlAssembler(Configuration.SEARCH_BASE)
    .segment('/search')
    .segment('/fuzzy')
    .segment('/json')
    .query('api-version', '1.0')
    .query('typeahead', 'true')
    .query('limit', '15')
    .query('countrySet', 'US,GB')
    .query('idxSet', 'Geo')
    .query('view', 'Auto')
    .query('query', query)

  let cityResults

  try {
    cityResults = (await axios.get(assembler.toString())).data.results
  } catch (e) {
    logErrorMessage(JSON.stringify(e))
    return {}
  }

  if (!cityResults?.length) {
    return {}
  }

  const resultDict: { [key: string]: Models.SearchResultAddress } = {}
  cityResults.forEach((result: Models.SearchFuzzyResult) => {
    const location = result.address
    if (
      location &&
      location.municipality &&
      location.countrySubdivisionName &&
      location.country
    ) {
      resultDict[BuildLocationKey(location)] = location
    }
  })

  return resultDict
}

function BuildLocationKey(location: Models.SearchResultAddress): string {
  return `${location.municipality}, ${location.countrySubdivisionName}, ${location.country}`
}

export { GetUniqueCityCountryResults, BuildLocationKey }
