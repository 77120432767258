export const NotListed = 'Not Listed/Other'

const sna = [
  'A.T. Still University',
  'Aalborg University',
  'Aalto University',
  'Aarhus University',
  'Aberystwyth University',
  'Abilene Christian University',
  'Åbo Akademi University',
  'Abraham Baldwin Agricultural College',
  'Abraham Lincoln University School of Law',
  'Academy of Art University',
  'Adam Mickiewicz University in Poznań',
  'Adams State University',
  'Adelphi University',
  'Adler Graduate School',
  'Adler University',
  'Adrian College',
  'AdventHealth University',
  'Aga Khan University',
  'AGH University of Science and Technology',
  'Agnes Scott College',
  'AgroParisTech',
  'Ain Shams University',
  'Air Force Institute of Technology',
  'Aix-Marseille University',
  'Ajou University',
  'Alabama A&M University',
  'Alabama State University',
  'Alaska Bible College',
  'Alaska Pacific University',
  'Albany College of Pharmacy and Health Sciences',
  'Albany Law School',
  'Albany Medical College',
  'Albany State University',
  'Albertus Magnus College',
  'Albion College',
  'Albright College',
  'Alcorn State University',
  'Alderson Broaddus University',
  'Alexandria University',
  'Alfred State College',
  'Alfred University',
  'Alice Lloyd College',
  'All India Institute of Medical Sciences, Delhi',
  'Allegheny College',
  'Allen College',
  'Allen University',
  'Alliant International University',
  'Alma College',
  'Alvernia University',
  'Alverno College',
  'Amberton University',
  'American Baptist College',
  'American College of Financial Services',
  'American College of Law',
  'American Film Institute Conservatory',
  'American Heritage University of Southern California',
  'American Institute of Law',
  'American International College',
  'American International School of Law',
  'American Jewish University',
  'American University Washington College of Law',
  'American University',
  'American University of Beirut',
  'Amherst College',
  'Amirkabir University of Technology',
  'Anderson University',
  'Anderson University, Indiana',
  'Andrews University',
  'Angelo State University',
  'Anhui Medical University',
  'Ankara University',
  'Anna Maria College',
  'Antioch School of Law (Closed)',
  'Antioch University',
  'Antioch University Los Angeles',
  'Antioch University New England',
  'Antioch University Santa Barbara',
  'Antioch University Seattle',
  'Appalachian Bible College',
  'Appalachian College of Pharmacy',
  'Appalachian School of Law',
  'Appalachian State University',
  'Aquinas College',
  'Aquinas College, Tennessee',
  'Arcadia University',
  'Aristotle University College of Law',
  'Aristotle University of Thessaloniki',
  'Arizona Christian University',
  'Arizona State University Oconnor College of Law',
  'Arizona State University',
  'Arizona Summit Law School',
  'Arkansas Baptist College',
  'Arkansas State University',
  'Arkansas Tech University',
  'Arlington Baptist University',
  'Army Medical University',
  'Art Academy of Cincinnati',
  'ArtCenter College of Design',
  'Asbury University',
  'Ashland University',
  'Asia Bar Review',
  'Assumption College',
  'Atatürk University',
  'Athens State University',
  'Atlanta Metropolitan State College',
  'Auburn University',
  'Auburn University at Montgomery',
  'Augsburg University',
  'Augusta University',
  'Augusta University',
  'Augustana College',
  'Augustana University',
  'Aurora University',
  'Austin College',
  'Austin Peay State University',
  'Australian National University',
  'Autonomous University of Barcelona',
  'Autonomous University of Madrid',
  'Ave Maria School of Law',
  'Ave Maria University',
  'Averett University',
  'Avila University',
  'Azusa Pacific University',
  'Babeș-Bolyai University',
  'Babson College',
  'Bacone College',
  'Baker College',
  'Baker University',
  'Baldwin Wallace University',
  'Ball State University',
  'Banaras Hindu University',
  'Bangor University',
  'Bank Street College of Education',
  'Baptist Bible College',
  'Baptist College of Florida',
  'Baptist Health Sciences University',
  'Baptist University of the Americas',
  'Barclay College',
  'Bard College',
  'Bard College at Simon\'s Rock',
  'Bar-Ilan University',
  'Barkley School of Law  (Closed)',
  'Barnard College',
  'Barry University School of Law',
  'Barry University',
  'Barton College',
  'Baruch College, CUNY',
  'Bastyr University',
  'Bates College',
  'Bay Path University',
  'Bay State College',
  'Baylor College of Medicine',
  'Baylor University School of Law',
  'Baylor University',
  'Beacon College',
  'Becker College',
  'Beihang University',
  'Beijing Forestry University',
  'Beijing Institute of Technology',
  'Beijing Jiaotong University',
  'Beijing Normal University',
  'Beijing University of Chemical Technology',
  'Beijing University of Posts and Telecommunications',
  'Beijing University of Technology',
  'Belhaven University',
  'Bellarmine University',
  'Bellevue College',
  'Bellevue University',
  'Bellin College',
  'Belmont Abbey College',
  'Belmont University',
  'Belmont University College of Law',
  'Beloit College',
  'Bemidji State University',
  'Benedict College',
  'Benedictine College',
  'Benedictine University',
  'Ben-Gurion University of the Negev',
  'Benjamin Franklin Institute of Technology',
  'Bennett College',
  'Bennington College',
  'Bentley University',
  'Berea College',
  'Berkeley College',
  'Berklee College of Music',
  'Berry College',
  'Bethany College',
  'Bethany College, Kansas',
  'Bethany Lutheran College',
  'Bethel College',
  'Bethel University',
  'Bethel University',
  'Bethel University, Tennessee',
  'Bethesda University',
  'Bethune-Cookman University',
  'Beulah Heights University',
  'Bielefeld University',
  'Bilkent University',
  'Binghamton University, State University of New York',
  'Biola University',
  'Birkbeck, University of London',
  'Birmingham School of Law',
  'Birmingham-Southern College',
  'Bismarck State College',
  'Black Hills State University',
  'Blackburn College',
  'Blessing-Rieman College of Nursing and Health Sciences',
  'Bloomfield College',
  'Bloomsburg University of Pennsylvania',
  'Blue Mountain College',
  'Bluefield College',
  'Bluefield State College',
  'Bluffton University',
  'Bob Jones University',
  'Boğaziçi University',
  'Boise Bible College',
  'Boise State University',
  'Boricua College',
  'Boston Architectural College',
  'Boston College',
  'Boston College Law School',
  'Boston Graduate School of Psychoanalysis',
  'Boston University School of Law',
  'Boston University',
  'Bowdoin College',
  'Bowie State University',
  'Bowling Green State University',
  'Bradley University',
  'Brandeis University',
  'Brandman University',
  'Braunschweig University of Technology',
  'Brazosport College',
  'Brenau University',
  'Brescia University',
  'Brevard College',
  'Brewton-Parker College',
  'Briar Cliff University',
  'Bridgewater College',
  'Bridgewater State University',
  'Brigham Young University J Reuben Clark Law School',
  'Brigham Young University',
  'Brigham Young University',
  'Brigham Young University-Hawaii',
  'Brigham Young University-Idaho',
  'Brooklyn College',
  'Brooklyn Law School',
  'Broward College',
  'Brown University',
  'Brunel University London',
  'Bryan College',
  'Bryan College of Health Sciences',
  'Bryant and Stratton College',
  'Bryant University',
  'Bryn Athyn College',
  'Bryn Mawr College',
  'Bucknell University',
  'Budapest University of Technology and Economics',
  'Buena Vista University',
  'Buffalo Law School',
  'Buffalo State College',
  'Bushnell University',
  'Butler University',
  'Cabarrus College of Health Sciences',
  'Cabrini University',
  'Cairn University',
  'Cairo University',
  'Cal Southern University',
  'Cal Southern University',
  'Caldwell University',
  'California Baptist University',
  'California College of the Arts',
  'California Desert Trial Academy College of Law',
  'California Institute of Integral Studies',
  'California Institute of Technology',
  'California Institute of Technology',
  'California Institute of the Arts',
  'California Lutheran University',
  'California Northern School of Law',
  'California Pacific',
  'California Polytechnic State University, San Luis Obispo',
  'California School of Law',
  'California Southern Law School',
  'California State Polytechnic University, Pomona',
  'California State University Channel Islands',
  'California State University Maritime Academy',
  'California State University San Marcos',
  'California State University, Bakersfield',
  'California State University, Chico',
  'California State University, Dominguez Hills',
  'California State University, East Bay',
  'California State University, Fresno',
  'California State University, Fullerton',
  'California State University, Long Beach',
  'California State University, Los Angeles',
  'California State University, Monterey Bay',
  'California State University, Northridge',
  'California State University, Sacramento',
  'California State University, San Bernardino',
  'California State University, Stanislaus',
  'California University of Pennsylvania',
  'California Western School of Law',
  'Calumet College of St. Joseph',
  'Calvary University',
  'Calvin University',
  'Cambridge College',
  'Cameron University',
  'Campbell University Wiggins School of Law',
  'Campbell University',
  'Campbellsville University',
  'Canisius College',
  'Capital Medical University',
  'Capital Normal University',
  'Capital University Law School',
  'Capital University',
  'Capitol Technology University',
  'Cardiff University',
  'Cardinal Stritch University',
  'Cardozo Law School',
  'Carl von Ossietzky University of Oldenburg',
  'Carleton College',
  'Carleton University',
  'Carlos III University of Madrid',
  'Carlow University',
  'Carnegie Mellon University',
  'Carolina University',
  'Carroll College',
  'Carroll University',
  'Carson-Newman University',
  'Carthage College',
  'Case Western Reserve University School of Law',
  'Case Western Reserve University',
  'Castleton University',
  'Catawba College',
  'Catholic University of America Columbus School of Law',
  'Catholic University of America',
  'Catholic University of Korea',
  'Catholic University of the Sacred Heart',
  'Cazenovia College',
  'CBN University College of Law (Closed)',
  'Cedar Crest College',
  'Cedarville University',
  'Centenary College of Louisiana',
  'Centenary University',
  'Central Baptist College',
  'Central China Normal University',
  'Central Christian College of Kansas',
  'Central Christian College of the Bible',
  'Central College',
  'Central Connecticut State University',
  'Central Methodist University',
  'Central Michigan University',
  'Central Penn College',
  'Central South University',
  'Central State University',
  'Central Washington University',
  'CentraleSupélec',
  'Centralia College',
  'Centre College',
  'Chadron State College',
  'Chalmers University of Technology',
  'Chamberlain University',
  'Chaminade University of Honolulu',
  'Champlain College',
  'Chang Gung University',
  'Chapman University School of Law',
  'Chapman University',
  'Charles R. Drew University of Medicine and Science',
  'Charles University in Prague',
  'Charleston School of Law',
  'Charleston Southern University',
  'Charlotte School of Law',
  'Chatham University',
  'Chestnut Hill College',
  'Cheyney University of Pennsylvania',
  'Chiang Mai University',
  'Chiba University',
  'Chicago Kent College of Law IIT',
  'Chicago School of Professional Psychology',
  'Chicago State University',
  'China Agricultural University',
  'China Europe International Business School',
  'China Medical University, PRC',
  'China Medical University, Taiwan',
  'China Pharmaceutical University',
  'China University of Geosciences',
  'China University of Mining and Technology',
  'China University of Petroleum',
  'Chinese Academy of Medical Sciences & Peking Union Medical College',
  'Chinese University of Hong Kong',
  'Chipola College',
  'Chonbuk National University',
  'Chongqing Medical University',
  'Chongqing University',
  'Chonnam National University',
  'Chowan University',
  'Christian Brothers University',
  'Christopher Newport University',
  'Chulalongkorn University',
  'Chung Shan Medical University',
  'Chung Yuan Christian University',
  'Chung-Ang University',
  'Chungbuk National University',
  'Chungnam National University',
  'CINVESTAV',
  'Citadel, Military College of South Carolina',
  'City College of New York',
  'City University of Hong Kong',
  'City University of Seattle',
  'City, University of London',
  'Claflin University',
  'Claremont Graduate University',
  'Claremont McKenna College',
  'Clarion University of Pennsylvania',
  'Clark Atlanta University',
  'Clark University',
  'Clarke University',
  'Clarks Summit University',
  'Clarkson College',
  'Clarkson University',
  'Claude Bernard University Lyon 1',
  'Clayton State University',
  'Clear Creek Baptist Bible College',
  'Cleary University',
  'Clemson University',
  'Cleveland Institute of Art',
  'Cleveland Institute of Music',
  'Cleveland State University College of Law',
  'Cleveland State University',
  'Cleveland University-Kansas City',
  'Coastal Carolina University',
  'Coe College',
  'Coker University',
  'Colby College',
  'Colby-Sawyer College',
  'Colgate University',
  'College for Creative Studies',
  'College of Biblical Studies',
  'College of Central Florida',
  'College of Charleston',
  'College of Coastal Georgia',
  'College of Mount Saint Vincent',
  'College of Our Lady of the Elms',
  'College of Saint Benedict/Saint John\'s University',
  'College of Saint Mary',
  'College of Southern Nevada',
  'College of Staten Island',
  'College of the Atlantic',
  'College of the Holy Cross',
  'College of the Ozarks',
  'College of William & Mary',
  'Colorado Christian University',
  'Colorado College',
  'Colorado Mesa University',
  'Colorado School of Mines',
  'Colorado School of Mines',
  'Colorado State University',
  'Colorado State University-Pueblo',
  'Colorado Technical University',
  'Columbia Basin College',
  'Columbia College',
  'Columbia College Chicago',
  'Columbia College Hollywood',
  'Columbia College, South Carolina',
  'Columbia International University',
  'Columbia Law School',
  'Columbia University in the City of New York',
  'Columbus College of Art and Design',
  'Columbus State University',
  'Comenius University in Bratislava',
  'Complutense University of Madrid',
  'COMSATS Institute of Information Technology',
  'Concord Law School of Kaplan University',
  'Concord University',
  'Concordia College',
  'Concordia College-New York',
  'Concordia University',
  'Concordia University Ann Arbor',
  'Concordia University Chicago',
  'Concordia University Irvine',
  'Concordia University School of Law',
  'Concordia University Texas',
  'Concordia University Wisconsin',
  'Concordia University, Nebraska',
  'Concordia University, St. Paul',
  'Connecticut College',
  'Converse College',
  'Conway School of Landscape Design',
  'Coppin State University',
  'Corban University',
  'Cornell College',
  'Cornell Law School',
  'Cornell University',
  'Cornerstone University',
  'Cornish College of the Arts',
  'Cottey College',
  'Covenant College',
  'Cox College',
  'Cranbrook Academy of Art',
  'Cranfield University',
  'Creighton University School of Law',
  'Creighton University',
  'Criswell College',
  'Crowley\'s Ridge College',
  'Crown College',
  'Çukurova University',
  'Culver-Stockton College',
  'Cumberland University',
  'Cuny Law School',
  'Curry College',
  'Curtin University',
  'Curtis Institute of Music',
  'Czech Technical University in Prague',
  'Daemen College',
  'Dakota State University',
  'Dakota Wesleyan University',
  'Dalhousie University Faculty of Law',
  'Dalhousie University',
  'Dalian University of Technology',
  'Dallas Baptist University',
  'Dallas Christian College',
  'Dalton State College',
  'Dankook University',
  'Dartmouth College',
  'Davenport University',
  'Davidson College',
  'Davis and Elkins College',
  'Davis College',
  'Daytona State College',
  'Deakin University',
  'Dean College',
  'Defiance College',
  'Delaware State University',
  'Delaware Valley University',
  'Delft University of Technology',
  'Delta State University',
  'Denison University',
  'Denver College of Nursing',
  'Depaul University College of Law',
  'DePaul University',
  'DePauw University',
  'Des Moines University',
  'DeSales University',
  'DeVry University',
  'Dharma Realm Buddhist University',
  'Dickinson College',
  'Dickinson State University',
  'Dillard University',
  'Dixie State University',
  'Doane University',
  'Dokuz Eylül University',
  'Dominican College',
  'Dominican School of Philosophy & Theology',
  'Dominican University',
  'Dominican University of California',
  'Dongguk University',
  'Donghua University',
  'Donnelly College',
  'Dordt University',
  'Drake University Law School',
  'Drake University',
  'Drew University',
  'Drexel University College of Law',
  'Drexel University',
  'Drury University',
  'Dublin City University',
  'Duke University School of Law',
  'Duke University',
  'Dunwoody College of Technology',
  'Duquesne University School of Law',
  'Duquesne University',
  'Durham University',
  'D\'Youville College',
  'Earlham College',
  'East Carolina University',
  'East Central University',
  'East China Normal University',
  'East China University of Science and Technology',
  'East Georgia State College',
  'East Stroudsburg University',
  'East Tennessee State University',
  'East Texas Baptist University',
  'Eastern Connecticut State University',
  'Eastern Illinois University',
  'Eastern Kentucky University',
  'Eastern Mennonite University',
  'Eastern Michigan University',
  'Eastern Nazarene College',
  'Eastern New Mexico University',
  'Eastern Oregon University',
  'Eastern University',
  'Eastern Virginia Medical School',
  'Eastern Washington University',
  'East-West University',
  'Ecclesia College',
  'Eckerd College',
  'École des hautes études en sciences sociales',
  'École des ponts ParisTech',
  'École nationale d\'administration',
  'École normale supérieure',
  'École normale supérieure de Lyon',
  'École normale supérieure Paris-Saclay',
  'École Polytechnique',
  'École Polytechnique Fédérale de Lausanne',
  'École pratique des hautes études',
  'ECPI University',
  'Edgewood College',
  'Edinboro University of Pennsylvania',
  'Edward Waters College',
  'Ege University',
  'Ehime University',
  'Eindhoven University of Technology',
  'Elizabeth City State University',
  'Elizabethtown College',
  'Elmhurst University',
  'Elmira College',
  'Elon University School of Law',
  'Elon University',
  'Embry-Riddle Aeronautical University',
  'Emerson College',
  'Emmanuel College',
  'Emmanuel College, Georgia',
  'Emmaus Bible College',
  'Emory & Henry College',
  'Emory University School of Law',
  'Emory University',
  'Empire College School of Law',
  'Emporia State University',
  'Endicott College',
  'Eötvös Loránd University',
  'Erasmus University Rotterdam',
  'Erciyes University',
  'Erskine College',
  'ESPCI Paris',
  'ETH Zurich',
  'Eureka College',
  'Evangel University',
  'Everglades University',
  'Ewha Womans University',
  'Fairfield University',
  'Fairleigh Dickinson University',
  'Fairmont State University',
  'Faith Baptist Bible College and Theological Seminary',
  'Farmingdale State College',
  'Fashion Institute of Technology',
  'Faulkner University Jones School of Law',
  'Faulkner University',
  'Fayetteville State University',
  'Federal University of Bahia',
  'Federal University of Ceará',
  'Federal University of Goiás',
  'Federal University of Minas Gerais',
  'Federal University of Paraná',
  'Federal University of Pelotas',
  'Federal University of Pernambuco',
  'Federal University of Rio de Janeiro',
  'Federal University of Rio Grande do Sul',
  'Federal University of Santa Catarina',
  'Federal University of Santa Maria',
  'Federal University of São Carlos',
  'Federal University of São Paulo',
  'Federal University of Viçosa',
  'Federico Santa María Technical University',
  'Felician University',
  'Ferdowsi University of Mashhad',
  'Ferris State University',
  'Ferrum College',
  'Fielding Graduate University',
  'Finlandia University',
  'Fisher College',
  'Fisk University',
  'Fitchburg State University',
  'Five Towns College',
  'Flagler College',
  'Flinders University',
  'Florida A&M University College of Law Legal Advocacy',
  'Florida Agricultural and Mechanical University',
  'Florida Atlantic University',
  'Florida Coastal School of Law',
  'Florida College',
  'Florida Gateway College',
  'Florida Gulf Coast University',
  'Florida Institute of Technology',
  'Florida International University College of Law',
  'Florida International University',
  'Florida International University',
  'Florida Memorial University',
  'Florida National University',
  'Florida Southern College',
  'Florida SouthWestern State College',
  'Florida State College at Jacksonville',
  'Florida State University College of Law',
  'Florida State University',
  'Florida State University',
  'Fluminense Federal University',
  'Fontbonne University',
  'Fordham Law School',
  'Fordham University',
  'Fort Hays State University',
  'Fort Lewis College',
  'Fort Valley State University',
  'Fourth Military Medical University',
  'Framingham State University',
  'Francis Marion University',
  'Franciscan Missionaries of Our Lady University',
  'Franciscan University of Steubenville',
  'Franklin and Marshall College',
  'Franklin College',
  'Franklin Pierce University',
  'Franklin University',
  'Franklin W. Olin College of Engineering',
  'Free University of Berlin',
  'Freed-Hardeman University',
  'Fresno Pacific University',
  'Friarylaw',
  'Friends University',
  'Frostburg State University',
  'Fudan University',
  'Furman University',
  'Fuzhou University',
  'Gabriele d’Annunzio University',
  'Gachon University',
  'Gallaudet University',
  'Gannon University',
  'Gardner-Webb University',
  'Garza State University (Closed)',
  'Gazi University',
  'Geisinger Commonwealth School of Medicine',
  'Geneva College',
  'George Fox University',
  'George Mason University School of Law',
  'George Mason University',
  'George Washington University Law School',
  'George Washington University',
  'Georgetown College',
  'Georgetown University Law Center',
  'Georgetown University',
  'Georgia College and State University',
  'Georgia Gwinnett College',
  'Georgia Institute of Technology',
  'Georgia Southern University',
  'Georgia Southwestern State University',
  'Georgia State University College of Law',
  'Georgia State University',
  'Georgian Court University',
  'Gettysburg College',
  'Ghent University',
  'Gifu University',
  'Glendale University College of Law',
  'Glenville State College',
  'Goddard College',
  'God\'s Bible School and College',
  'Goethe University Frankfurt',
  'Golden Gate University School of Law',
  'Golden Gate University',
  'Goldey-Beacom College',
  'Goldfarb School of Nursing at Barnes-Jewish College',
  'Gonzaga University School of Law',
  'Gonzaga University',
  'Good Samaritan College of Nursing and Health Science',
  'Goodwin University',
  'Gordon College',
  'Gordon State College',
  'Goshen College',
  'Goucher College',
  'Governors State University',
  'Grace Christian University',
  'Grace College',
  'Graceland University',
  'Graduate University for Advanced Studies',
  'Grambling State University',
  'Grand Canyon University',
  'Grand Valley State University',
  'Grand View University',
  'Granite State College',
  'Gratz College',
  'Graz University of Technology',
  'Great Basin College',
  'Great Lakes Christian College',
  'Greensboro College',
  'Greenville University',
  'Grenoble Alpes University',
  'Grenoble Institute of Technology',
  'Griffith University',
  'Grinnell College',
  'Grove City College',
  'Guangxi University',
  'Guangzhou Medical University',
  'Guilford College',
  'Gulf Coast State College',
  'Gunma University',
  'Gustavus Adolphus College',
  'Gwangju Institute of Science and Technology',
  'Gwynedd Mercy University',
  'Gyeongsang National University',
  'Hacettepe University',
  'Hallym University',
  'Hamilton College',
  'Hamline University School of Law',
  'Hamline University',
  'Hampden-Sydney College',
  'Hampshire College',
  'Hampton University',
  'Handong International Law School',
  'Hannibal-LaGrange University',
  'Hannover Medical School',
  'Hanover College',
  'Hanyang University',
  'Harbin Engineering University',
  'Harbin Institute of Technology',
  'Harbin Medical University',
  'Harding University',
  'Hardin-Simmons University',
  'Harrisburg University of Science and Technology',
  'Harris-Stowe State University',
  'Hartwick College',
  'Harvard Law School',
  'Harvard University',
  'Harvey Mudd College',
  'Hastings College',
  'Hastings College of Law',
  'Haverford College',
  'Hawaii Pacific University',
  'Hebrew College',
  'Hebrew University of Jerusalem',
  'HEC Paris',
  'Hefei University of Technology',
  'Heidelberg University',
  'Heidelberg University',
  'Helene Fuld College of Nursing',
  'Henderson State University',
  'Hendrix College',
  'Heriot-Watt University',
  'Heritage Christian University',
  'Heritage University',
  'Herzing University',
  'High Point University',
  'Hilbert College',
  'Hillsdale College',
  'Hiram College',
  'Hiroshima University',
  'Hitotsubashi University',
  'Hobart and William Smith Colleges',
  'Hodges University',
  'Hofstra Law School',
  'Hofstra University',
  'Hohai University',
  'Hokkaido University',
  'Hollins University',
  'Holy Apostles College and Seminary',
  'Holy Cross College',
  'Holy Family University',
  'Holy Names University',
  'Hong Kong Baptist University',
  'Hong Kong Polytechnic University',
  'Hong Kong University of Science and Technology',
  'Hood College',
  'Hope College',
  'Hope International University',
  'Houghton College',
  'Houston Baptist University',
  'Howard Payne University',
  'Howard University School of Law',
  'Howard University',
  'Huazhong Agricultural University',
  'Huazhong University of Science and Technology',
  'Huffman College of Law (Closed)',
  'Hult International Business School',
  'Humboldt State University',
  'Humboldt University of Berlin',
  'Humphreys College Drivon School of Law',
  'Humphreys University',
  'Hunan University',
  'Hunter College',
  'Hunter College, CUNY',
  'Huntingdon College',
  'Huntington University',
  'Husson University',
  'Huston-Tillotson University',
  'Icahn School of Medicine at Mount Sinai',
  'Icahn School of Medicine at Mount Sinai',
  'Idaho State University',
  'Illinois College',
  'Illinois College of Optometry',
  'Illinois Institute of Technology',
  'Illinois Institute of Technology',
  'Illinois State University',
  'Illinois Wesleyan University',
  'Immaculata University',
  'Imperial College London',
  'Indian Institute of Management Ahmedabad',
  'Indian Institute of Science',
  'Indian Institute of Technology Bombay',
  'Indian Institute of Technology Delhi',
  'Indian Institute of Technology Kanpur',
  'Indian Institute of Technology Kharagpur',
  'Indian Institute of Technology Madras',
  'Indian Institute of Technology Roorkee',
  'Indian River State College',
  'Indiana Institute of Technology',
  'Indiana State University',
  'Indiana Tech Law School',
  'Indiana University at Bloomington School of Law',
  'Indiana University at Indianapolis School of Law',
  'Indiana University - Purdue University Indianapolis',
  'Indiana University Bloomington',
  'Indiana University East',
  'Indiana University Fort Wayne',
  'Indiana University Kokomo',
  'Indiana University Northwest',
  'Indiana University of Pennsylvania',
  'Indiana University South Bend',
  'Indiana University Southeast',
  'Indiana Wesleyan University',
  'Inha University',
  'Inje University',
  'INSA Lyon',
  'INSA Toulouse',
  'INSEAD',
  'Inter American University of PR School of Law',
  'International 10',
  'International 6',
  'International School for Advanced Studies',
  'Iona College',
  'Iowa State University',
  'Iowa Wesleyan University',
  'Iran University of Science and Technology',
  'Irell and Manella Graduate School of Biological Sciences',
  'Irvine University College of Law',
  'Isfahan University of Technology',
  'Islamic Azad University',
  'Istanbul Technical University',
  'Istanbul University',
  'Ithaca College',
  'Jackson State University',
  'Jacksonville State University',
  'Jacksonville University',
  'Jacobs University Bremen',
  'Jadavpur University',
  'Jagiellonian University',
  'James Cook University',
  'James Madison University',
  'Jamestown Business College',
  'Jarvis Christian College',
  'Jaume I University',
  'Jawaharlal Nehru Centre for Advanced Scientific Research',
  'Jawaharlal Nehru University',
  'Jean Monnet University',
  'Jiangnan University',
  'Jiangsu University',
  'Jichi Medical University',
  'Jilin University',
  'Jinan University',
  'Johannes Gutenberg University Mainz',
  'Johannes Kepler University Linz',
  'John Brown University',
  'John Carroll University',
  'John F Kennedy School of Law at Northcentral University',
  'John F. Kennedy University',
  'John Jay College of Criminal Justice',
  'John Marshall Atlanta Law School',
  'John Paul the Great Catholic University',
  'Johns Hopkins University',
  'Johnson and Wales University',
  'Johnson C. Smith University',
  'Johnson University',
  'Judge Advocate Generals School US Army',
  'Judson College',
  'Judson University',
  'Juniata College',
  'Juntendo University',
  'Kagoshima University',
  'KAIST',
  'Kalamazoo College',
  'Kanazawa University',
  'Kangwon National University',
  'Kansas City Art Institute',
  'Kansas City University',
  'Kansas State University',
  'Kansas Wesleyan University',
  'Kaohsiung Medical University',
  'Karlsruhe Institute of Technology',
  'Karolinska Institute',
  'Kean University',
  'Keck Graduate Institute',
  'Keele University',
  'Keene State College',
  'Keio University',
  'Keiser University',
  'Kennesaw State University',
  'Kent State University',
  'Kentucky Christian University',
  'Kentucky State University',
  'Kentucky Wesleyan College',
  'Kenyon College',
  'Kern County College of Law',
  'Kettering College',
  'Kettering University',
  'Keuka College',
  'Keystone College',
  'Khon Kaen University',
  'Kindai University',
  'King Abdulaziz University',
  'King Abdullah University of Science and Technology',
  'King Fahd University of Petroleum and Minerals',
  'King Juan Carlos University',
  'King Saud University',
  'King University',
  'King\'s College',
  'King\'s College London',
  'Kitasato University',
  'Knox College',
  'Kobe University',
  'Kōchi University',
  'Konkuk University',
  'Korea University',
  'KU Leuven',
  'Kumamoto University',
  'Kutztown University of Pennsylvania',
  'Kuyper College',
  'Kyoto Prefectural University of Medicine',
  'Kyoto University',
  'Kyung Hee University',
  'Kyungpook National University',
  'Kyushu University',
  'La Roche University',
  'La Salle University',
  'La Sierra University',
  'La Trobe University',
  'Labouré College',
  'Lafayette College',
  'LaGrange College',
  'Laguna College of Art and Design',
  'Lake Erie College',
  'Lake Forest College',
  'Lake Forest Graduate School of Management',
  'Lake Superior State University',
  'Lake Washington Institute of Technology',
  'Lakeland University',
  'Lakeview College of Nursing',
  'Lamar University',
  'Lancaster Bible College',
  'Lancaster University',
  'Lander University',
  'Landmark College',
  'Lane College',
  'Langston University',
  'Lanzhou University',
  'Lasell University',
  'Laval University Faculty of Law',
  'Laval University',
  'Lawrence Technological University',
  'Lawrence University',
  'Le Moyne College',
  'Lebanon Valley College',
  'Lee University',
  'Lees-McRae College',
  'Lehigh University',
  'Lehman College, CUNY',
  'Leiden University',
  'Leipzig University',
  'LeMoyne-Owen College',
  'Lenoir-Rhyne University',
  'Lesley University',
  'LeTourneau University',
  'Lewis & Clark College',
  'Lewis & Clark Law School',
  'Lewis University',
  'Lewis-Clark State College',
  'Liberty University School of Law',
  'Liberty University',
  'Life Pacific College',
  'Life University',
  'LIM College',
  'Limestone College',
  'Lincoln Christian University',
  'Lincoln College',
  'Lincoln Law School Sacramento',
  'Lincoln Law School San Jose',
  'Lincoln Memorial University',
  'Lincoln Memorial University Duncan School of Law',
  'Lincoln University',
  'Lincoln University the Law School (Closed)',
  'Lincoln University, Missouri',
  'Lindenwood University',
  'Lindsey Wilson College',
  'Linfield University',
  'Linköping University',
  'Lipscomb University',
  'Liverpool John Moores University',
  'Liverpool School of Tropical Medicine',
  'Livingstone College',
  'Lock Haven University',
  'Logan University',
  'Loma Linda University',
  'Loma Linda University',
  'Loma Linda University (Closed)',
  'London School of Economics',
  'London School of Hygiene & Tropical Medicine',
  'Long Island University',
  'Longwood University',
  'Loras College',
  'Loughborough University',
  'Louisiana College',
  'Louisiana State University Law Center',
  'Louisiana State University',
  'Louisiana State University Health Sciences Center',
  'Louisiana State University Health Sciences Center-Shreveport',
  'Louisiana State University in Shreveport',
  'Louisiana State University of Alexandria',
  'Louisiana Tech University',
  'Lourdes University',
  'Loyola Law School',
  'Loyola Marymount University',
  'Loyola University Chicago School of Law',
  'Loyola University New Orleans School of Law',
  'Loyola University Chicago',
  'Loyola University Chicago',
  'Loyola University Maryland',
  'Loyola University New Orleans',
  'Lubbock Christian University',
  'Ludwig Maximilian University of Munich',
  'Lund University',
  'Luther College',
  'Lycoming College',
  'Lynn University',
  'Lyon College',
  'Maastricht University',
  'Macalester College',
  'Machshavot . Israel',
  'MacMurray College',
  'Macquarie University',
  'Madonna University',
  'Magdalen College of the Liberal Arts',
  'Maharishi International University',
  'Mahidol University',
  'Maine College of Art',
  'Maine Maritime Academy',
  'Makerere University',
  'Malone University',
  'Manchester Metropolitan University',
  'Manchester University',
  'Manhattan Christian College',
  'Manhattan College',
  'Manhattan School of Music',
  'Manhattanville College',
  'Mansfield University of Pennsylvania',
  'Mansoura University',
  'Maranatha Baptist University',
  'Marche Polytechnic University',
  'Maria College',
  'Marian University',
  'Marian University, Wisconsin',
  'Marietta College',
  'Marist College',
  'Marquette University Law School',
  'Marquette University',
  'Mars Hill University',
  'Marshall B. Ketchum University',
  'Marshall University',
  'Martin Luther College',
  'Martin Luther University of Halle-Wittenberg',
  'Martin University',
  'Mary Baldwin University',
  'Maryland Institute College of Art',
  'Maryland University of Integrative Health',
  'Marymount California University',
  'Marymount Manhattan College',
  'Marymount University',
  'Maryville College',
  'Maryville University',
  'Marywood University',
  'Masaryk University',
  'Massachusetts College of Art and Design',
  'Massachusetts College of Liberal Arts',
  'Massachusetts Institute of Technology',
  'Massachusetts Maritime Academy',
  'Massachusetts School of Law',
  'Massey University',
  'Mayo Clinic College of Medicine and Science',
  'Mayville State University',
  'McDaniel College',
  'Mcgill University Faculty of Law',
  'McGill University',
  'McKendree University',
  'McMaster University',
  'McMurry University',
  'McNeese State University',
  'McPherson College',
  'MCPHS University',
  'Medaille College',
  'Medgar Evers College',
  'Medical College of Wisconsin',
  'Medical College of Wisconsin',
  'Medical University of Graz',
  'Medical University of Innsbruck',
  'Medical University of South Carolina',
  'Medical University of South Carolina',
  'Medical University of Vienna',
  'Medical University of Warsaw',
  'Meharry Medical College',
  'Meijo University',
  'Memorial University of Newfoundland',
  'Memphis College of Art',
  'Menlo College',
  'Mercer University Law School',
  'Mercer University',
  'Mercy College',
  'Mercy College of Health Sciences',
  'Mercy College of Ohio',
  'Mercyhurst University',
  'Meredith College',
  'Merrimack College',
  'Messiah University',
  'Methodist College',
  'Methodist University',
  'Metropolitan Autonomous University',
  'Metropolitan College of New York',
  'Metropolitan State University',
  'Metropolitan State University of Denver',
  'MGH Institute of Health Professions',
  'Miami Dade College',
  'Miami University',
  'Michigan School of Psychology',
  'Michigan State University',
  'Michigan State University College of Law',
  'Michigan Technological University',
  'Mid-America Christian University',
  'MidAmerica Nazarene University',
  'Mid-Atlantic Christian University',
  'Middle East Technical University',
  'Middle Georgia State University',
  'Middle Tennessee School of Anesthesia',
  'Middle Tennessee State University',
  'Middlebury College',
  'Midland College',
  'Midland University',
  'Midway University',
  'Midwestern State University',
  'Midwestern University',
  'Mie University',
  'Miguel Hernández University of Elche',
  'Miles College',
  'Miles Law School',
  'Millersville University of Pennsylvania',
  'Milligan University',
  'Millikin University',
  'Mills College',
  'Millsaps College',
  'Milwaukee Institute of Art and Design',
  'Milwaukee School of Engineering',
  'Mines ParisTech',
  'Minneapolis College of Art and Design',
  'Minnesota State University Moorhead',
  'Minnesota State University, Mankato',
  'Minot State University',
  'Miscellaneous',
  'Misericordia University',
  'Mississippi College',
  'Mississippi College School of Law',
  'Mississippi State University',
  'Mississippi University for Women',
  'Mississippi Valley State University',
  'Missouri Baptist University',
  'Missouri Southern State University',
  'Missouri State University',
  'Missouri University of Science and Technology',
  'Missouri Valley College',
  'Missouri Western State University',
  'Mitchell College',
  'Mitchell Hamline School of Law',
  'Molloy College',
  'Monash University',
  'Monmouth College',
  'Monmouth University',
  'Monroe College',
  'Montana State University',
  'Montana State University - Bozeman',
  'Montana State University Billings',
  'Montana State University-Northern',
  'Montana Tech',
  'Montclair State University',
  'Monterey College of Law',
  'Montreat College',
  'Montserrat College of Art',
  'Moody Bible Institute',
  'Moore College of Art and Design',
  'Moravian College',
  'Morehead State University',
  'Morehouse College',
  'Morehouse School of Medicine',
  'Morgan State University',
  'Morningside College',
  'Morris College',
  'Morrisville State College',
  'Moscow Institute of Physics and Technology',
  'Moscow State University',
  'Mount Aloysius College',
  'Mount Carmel College of Nursing',
  'Mount Holyoke College',
  'Mount Marty University',
  'Mount Mary University',
  'Mount Mercy University',
  'Mount Saint Mary College',
  'Mount Saint Mary\'s University',
  'Mount St. Joseph University',
  'Mount St. Mary\'s University',
  'Mount Vernon Nazarene University',
  'Muhlenberg College',
  'Multnomah University',
  'Murdoch University',
  'Murray State University',
  'Muskingum University',
  'Nagasaki University',
  'Nagoya City University',
  'Nagoya University',
  'Nanchang University',
  'Nanjing Agricultural University',
  'Nanjing Medical University',
  'Nanjing Normal University',
  'Nanjing Tech University',
  'Nanjing University',
  'Nanjing University of Aeronautics and Astronautics',
  'Nanjing University of Information Science and Technology',
  'Nanjing University of Science and Technology',
  'Nankai University',
  'Nanyang Technological University',
  'Nara Institute of Science and Technology',
  'Naropa University',
  'Nashville School of Law',
  'National American University',
  'National and Kapodistrian University of Athens',
  'National Autonomous University of Mexico',
  'National Central University',
  'National Cheng Kung University',
  'National Chiao Tung University',
  'National Chung Hsing University',
  'National Defense University',
  'National Louis University',
  'National Polytechnic Institute',
  'National Polytechnic Institute of Toulouse',
  'National Research Nuclear University MEPhI',
  'National Sun Yat-sen University',
  'National Taiwan Normal University',
  'National Taiwan Ocean University',
  'National Taiwan University',
  'National Taiwan University of Science and Technology',
  'National Technical University of Athens',
  'National Tsing Hua University',
  'National University',
  'National University of Colombia',
  'National University of Córdoba',
  'National University of Defense Technology',
  'National University of Health Sciences',
  'National University of La Plata',
  'National University of Malaysia, UKM',
  'National University of Singapore',
  'National University of Singapore',
  'National Yang-Ming University',
  'Naval Postgraduate School',
  'Nazarene Bible College',
  'Nazareth College',
  'Nebraska Christian College',
  'Nebraska Methodist College',
  'Nebraska Wesleyan University',
  'Neumann University',
  'Nevada State College',
  'New College of California School of Law (Closed)',
  'New College of Florida',
  'New England College',
  'New England College of Optometry',
  'New England Conservatory',
  'New England Institute of Technology',
  'New England School of Law',
  'New Hope Christian College',
  'New Jersey City University',
  'New Jersey Institute of Technology',
  'New Mexico Highlands University',
  'New Mexico Institute of Mining and Technology',
  'New Mexico State University',
  'New University of Lisbon',
  'New York Academy of Art',
  'New York City College of Technology, CUNY',
  'New York College of Podiatric Medicine',
  'New York Institute of Technology',
  'New York Law School',
  'New York Medical College',
  'New York Medical College',
  'New York School of Interior Design',
  'New York University',
  'Newberry College',
  'Newcastle University',
  'Newman University',
  'NewSchool of Architecture and Design',
  'Niagara University',
  'Nicholls State University',
  'Nichols College',
  'Nicolaus Copernicus University in Toruń',
  'Nihon University',
  'Niigata University',
  'Ningbo University',
  'No Law School Rule 6',
  'Norfolk State University',
  'North Carolina Agricultural and Technical State University',
  'North Carolina Central University School of Law',
  'North Carolina Central University',
  'North Carolina State University',
  'North Carolina Wesleyan College',
  'North Central College',
  'North Central University',
  'North China Electric Power University',
  'North Dakota State University',
  'North Greenville University',
  'North Park University',
  'Northeast Normal University',
  'Northeast Ohio Medical University',
  'Northeastern Illinois University',
  'Northeastern State University',
  'Northeastern University School of Law',
  'Northeastern University',
  'Northeastern University, China',
  'Northern Arizona University',
  'Northern California Patino Law School',
  'Northern Illinois University College of Law',
  'Northern Illinois University',
  'Northern Kentucky University Chase College of Law',
  'Northern Kentucky University',
  'Northern Michigan University',
  'Northern New Mexico College',
  'Northern State University',
  'Northern Vermont University',
  'Northland College',
  'Northpoint Bible College',
  'Northrop University School of Law (Closed)',
  'Northwest A&F University',
  'Northwest Florida State College',
  'Northwest Missouri State University',
  'Northwest Nazarene University',
  'Northwest University',
  'North-West University',
  'Northwest University, China',
  'Northwestern California University School of Law',
  'Northwestern College',
  'Northwestern Health Sciences University',
  'Northwestern Oklahoma State University',
  'Northwestern Polytechnical University',
  'Northwestern State University of Louisiana',
  'Northwestern University School of Law',
  'Northwestern University',
  'Northwood University',
  'Norwegian University of Life Sciences',
  'Norwegian University of Science and Technology',
  'Norwich University',
  'Notre Dame College',
  'Notre Dame de Namur University',
  'Notre Dame Law School',
  'Notre Dame of Maryland University',
  'Nova Southeastern University Broad Law Center',
  'Nova Southeastern University',
  'Novosibirsk State University',
  'NUI Galway',
  'Nyack College',
  'NYU Law School',
  'Oak Brook College of Law',
  'Oak Hills Christian College',
  'Oak Point University',
  'Oakland City University',
  'Oakland College of Law (Closed)',
  'Oakland University',
  'Oakwood University',
  'Oberlin College',
  'Occidental College',
  'Ocean University of China',
  'Oglethorpe University',
  'Ohio Christian University',
  'Ohio Dominican University',
  'Ohio Northern University Pettit College of Law',
  'Ohio Northern University',
  'Ohio State University Moritz College of Law',
  'Ohio State University',
  'Ohio State University',
  'Ohio University',
  'Ohio University Southern',
  'Ohio University-Chillicothe',
  'Ohio Valley University',
  'Ohio Wesleyan University',
  'Okayama University',
  'Oklahoma Baptist University',
  'Oklahoma Christian University',
  'Oklahoma City University School of Law',
  'Oklahoma City University',
  'Oklahoma Panhandle State University',
  'Oklahoma State University',
  'Oklahoma Wesleyan University',
  'Old Dominion University',
  'Olivet College',
  'Olivet Nazarene University',
  'Open University, UK',
  'Oral Roberts University',
  'Oral Roberts University (Closed)',
  'Orange County School of Law (Closed)',
  'Örebro University',
  'Oregon Health & Science University',
  'Oregon Institute of Technology',
  'Oregon State University',
  'Osaka City University',
  'Osaka Prefecture University',
  'Osaka University',
  'Otis College of Art and Design',
  'Ottawa University',
  'Otterbein University',
  'Otto von Guericke University Magdeburg',
  'Ouachita Baptist University',
  'Our Lady of the Lake University',
  'Ozark Christian College',
  'Pace Law School',
  'Pace University',
  'Pacific Coast University School of Law',
  'Pacific Lutheran University',
  'Pacific Northwest College of Art',
  'Pacific Oaks College',
  'Pacific Union College',
  'Pacific University',
  'Pacific West College of Law',
  'Pacifica Graduate Institute',
  'Paine College',
  'Palacký University Olomouc',
  'Palm Beach Atlantic University',
  'Palm Beach State College',
  'Palo Alto University',
  'Panjab University',
  'Pardee RAND Graduate School',
  'Paris 13 University',
  'Paris Dauphine University',
  'Paris Descartes University',
  'Paris Diderot University',
  'Paris Nanterre University',
  'Park University',
  'Parker University',
  'Paul Quinn College',
  'Paul Sabatier University - Toulouse III',
  'Paul Smith\'s College',
  'Paul Valéry University, Montpellier III',
  'Peirce College',
  'Peking University',
  'Peninsula University College of Law',
  'Penn State College of Medicine',
  'Penn State Dickinson Carlisle',
  'Penn State University',
  'Penn State University Park',
  'Pennsylvania Academy of the Fine Arts',
  'Pennsylvania College of Art and Design',
  'Pennsylvania College of Health Sciences',
  'Pennsylvania College of Technology',
  'Pensacola State College',
  'Peoples College of Law',
  'Pepperdine University School of Law',
  'Pepperdine University',
  'Peru State College',
  'Pfeiffer University',
  'Philander Smith College',
  'Piedmont College',
  'Pillar College',
  'Pine Manor College',
  'Pittsburg State University',
  'Pitzer College',
  'Plaza College',
  'Plymouth State University',
  'Pohang University of Science and Technology',
  'Point Loma Nazarene University',
  'Point Park University',
  'Point University',
  'Polk State College',
  'Polytechnic University of Bari',
  'Polytechnic University of Bucharest',
  'Polytechnic University of Catalonia',
  'Polytechnic University of Madrid',
  'Polytechnic University of Milan',
  'Polytechnic University of Turin',
  'Polytechnic University of Valencia',
  'Pomona College',
  'Pompeu Fabra University',
  'Pontifical Catholic University of PR School of Law',
  'Pontifical Catholic University of Chile',
  'Pontifical Faculty of the Immaculate Conception',
  'Portland State University',
  'Portland State University',
  'Post University',
  'Prairie View A&M University',
  'Pratt Institute',
  'Presbyterian College',
  'Prescott College',
  'Presentation College',
  'Princeton University',
  'Principia College',
  'Providence Christian College',
  'Providence College',
  'Purchase College, State University of New York',
  'Purdue University',
  'Purdue University Fort Wayne',
  'Purdue University Northwest',
  'Purdue University, West Lafayette',
  'Pusan National University',
  'Putra University, Malaysia',
  'Qingdao University',
  'Quaid-i-Azam University',
  'Queen Mary University of London',
  'Queens College, City University of New York',
  'Queen\'s University',
  'Queen\'s University Belfast',
  'Queen\'s University Faculty of Law (Closed)',
  'Queens University of Charlotte',
  'Queensland University of Technology',
  'Quincy University',
  'Quinnipiac University School of Law',
  'Quinnipiac University',
  'Radboud University Nijmegen',
  'Radford University',
  'Ramapo College of New Jersey',
  'Randolph College',
  'Randolph-Macon College',
  'Rasmussen College',
  'Reed College',
  'Regent University School of Law',
  'Regent University',
  'Regis College',
  'Regis University',
  'Reinhardt University',
  'Relay Graduate School of Education',
  'Renmin University of China',
  'Rensselaer Polytechnic Institute',
  'Research College of Nursing',
  'Rhode Island College',
  'Rhode Island School of Design',
  'Rhodes College',
  'Rice University',
  'Richmont Graduate University',
  'Rider University',
  'Ridgecrest School of Law (Closed)',
  'Ringling College of Art and Design',
  'Rio de Janeiro State University',
  'Ripon College',
  'Ritsumeikan University',
  'Rivier University',
  'RMIT University',
  'Roanoke College',
  'Robert Morris University',
  'Roberts Wesleyan College',
  'Rochester Institute of Technology',
  'Rochester University',
  'Rockefeller University',
  'Rockford University',
  'Rockhurst University',
  'Rocky Mountain College',
  'Rocky Mountain College of Art and Design',
  'Rocky Mountain University of Health Professions',
  'Rocky Vista University',
  'Roger Williams University School of Law',
  'Roger Williams University',
  'Rogers State University',
  'Rollins College',
  'Roma Tre University',
  'Roosevelt University',
  'Rosalind Franklin University of Medicine and Science',
  'Rose-Hulman Institute of Technology',
  'Roseman University of Health Sciences',
  'Rosemont College',
  'Rouen Normandy University',
  'Rovira i Virgili University',
  'Rowan University',
  'Royal College of Surgeons in Ireland',
  'Royal Holloway, University of London',
  'Royal Institute of Technology',
  'Royal Veterinary College',
  'Ruhr University Bochum',
  'Rush University',
  'Rust College',
  'Rutgers School of Law - Camden',
  'Rutgers University at Newark College of Law',
  'Rutgers, State University of New Jersey, New Brunswick',
  'Rutgers, State University of New Jersey, Newark',
  'RWTH Aachen University',
  'Ryerson University',
  'Saarland University',
  'Sacred Heart University',
  'Saginaw Valley State University',
  'Saint Anselm College',
  'Saint Anthony College of Nursing',
  'Saint Augustine College',
  'Saint Augustine\'s University',
  'Saint Elizabeth University',
  'Saint Francis Medical Center College of Nursing',
  'Saint Francis University',
  'Saint Joseph\'s College of Maine',
  'Saint Joseph\'s University',
  'Saint Leo University',
  'Saint Louis University',
  'Saint Martin\'s University',
  'Saint Mary-of-the-Woods College',
  'Saint Mary\'s College',
  'Saint Mary\'s College of California',
  'Saint Mary\'s University of Minnesota',
  'Saint Michael\'s College',
  'Saint Peter\'s University',
  'Saint Petersburg State University',
  'Saint Vincent College',
  'Saint Xavier University',
  'Salem College',
  'Salem State University',
  'Salem University',
  'Salisbury University',
  'Salus University',
  'Salve Regina University',
  'Sam Houston State University',
  'Samford University Cumberland School of Law',
  'Samford University',
  'Samuel Merritt University',
  'San Antonio School of Law (Closed)',
  'San Diego Christian College',
  'San Diego Law School',
  'San Diego State University',
  'San Francisco Art Institute',
  'San Francisco Conservatory of Music',
  'San Francisco International University College of Law',
  'San Francisco Law School',
  'San Francisco State University',
  'San Joaquin College of Law',
  'San José State University',
  'San Luis Obispo College of Law',
  'Santa Barbara College of Law',
  'Santa Clara University School of Law',
  'Santa Clara University',
  'Santa Fe College',
  'Sapienza University of Rome',
  'Sarah Lawrence College',
  'Savannah College of Art and Design',
  'Savannah Law School',
  'Savannah State University',
  'Saybrook University',
  'School of the Art Institute of Chicago',
  'School of Visual Arts',
  'Schreiner University',
  'Sciences Po',
  'Scripps College',
  'Scuola Normale Superiore',
  'Seattle Central College',
  'Seattle Pacific University',
  'Seattle University School of Law',
  'Seattle University',
  'Second Military Medical University',
  'Sejong University',
  'Seminole State College of Florida',
  'Semmelweis University',
  'Seoul National University',
  'Seton Hall University School of Law',
  'Seton Hall University',
  'Seton Hill University',
  'Sewanee: University of the South',
  'Shaanxi Normal University',
  'Shahid Beheshti University',
  'Shahid Beheshti University of Medical Sciences',
  'Shandong University',
  'Shanghai Jiao Tong University',
  'Shanghai University',
  'Shantou University',
  'Shanxi University',
  'Sharif University of Technology',
  'Shaw University',
  'Shawnee State University',
  'Shenandoah University',
  'Shenzhen University',
  'Shepherd University',
  'Shinshu University',
  'Shippensburg University of Pennsylvania',
  'Shiraz University',
  'Shizuoka University',
  'Shorter University',
  'Sichuan University',
  'Siena College',
  'Siena Heights University',
  'Sierra Nevada University',
  'Simmons University',
  'Simon Fraser University',
  'Simpson College',
  'Simpson University',
  'SIT Graduate Institute',
  'Skidmore College',
  'Slippery Rock University of Pennsylvania',
  'Smith College',
  'Snow College',
  'Sofia University',
  'Sogang University',
  'Soka University of America',
  'Sonoma State University',
  'Soochow University, China',
  'Soonchunhyang University',
  'Sorbonne University',
  'South Carolina State University',
  'South China Agricultural University',
  'South China Normal University',
  'South China University of Technology',
  'South College',
  'South Dakota School of Mines and Technology',
  'South Dakota State University',
  'South Georgia State College',
  'South Texas College',
  'South Texas College of Law Houston',
  'South University',
  'Southeast Missouri State University',
  'Southeast University',
  'Southeastern Baptist College',
  'Southeastern Louisiana University',
  'Southeastern Oklahoma State University',
  'Southeastern University',
  'Southern Adventist University',
  'Southern Arkansas University',
  'Southern CA Institute of Law - Santa Barbara',
  'Southern CA Institute of Law - Ventura',
  'Southern California Institute of Architecture',
  'Southern College of Optometry',
  'Southern Connecticut State University',
  'Southern Illinois University School of Law',
  'Southern Illinois University Carbondale',
  'Southern Illinois University Edwardsville',
  'Southern Medical University',
  'Southern Methodist University Dedman School of Law',
  'Southern Methodist University',
  'Southern Nazarene University',
  'Southern New Hampshire University',
  'Southern Oregon University',
  'Southern University Law Center',
  'Southern University and A&M College',
  'Southern University at New Orleans',
  'Southern Utah University',
  'Southern Virginia University',
  'Southern Wesleyan University',
  'Southwest Baptist University',
  'Southwest Jiaotong University',
  'Southwest Minnesota State University',
  'Southwest University',
  'Southwestern Adventist University',
  'Southwestern Assemblies of God University',
  'Southwestern Christian University',
  'Southwestern College',
  'Southwestern College, Santa Fe',
  'Southwestern Oklahoma State University',
  'Southwestern University School of Law',
  'Southwestern University',
  'Spalding University',
  'Spelman College',
  'Sponsors for Educational Opportunity',
  'Spring Arbor University',
  'Spring Hill College',
  'Springfield College',
  'St Andrews University',
  'St George\'s, University of London',
  'St John\'s College',
  'St Louis University School of Law',
  'St Marys University School of Law',
  'St Petersburg College',
  'St Thomas University School of Law',
  'St. Ambrose University',
  'St. Bonaventure University',
  'St. Catherine University',
  'St. Cloud State University',
  'St. Edward\'s University',
  'St. Francis College',
  'St. Francis School of Law',
  'St. John Fisher College',
  'St. John\'s Law School',
  'St. Johns River State College',
  'St. John\'s University',
  'St. Joseph\'s College',
  'St. Lawrence University',
  'St. Louis Christian College',
  'St. Mary\'s College of Maryland',
  'St. Mary\'s University',
  'St. Norbert College',
  'St. Olaf College',
  'St. Thomas Aquinas College',
  'St. Thomas University',
  'Stanford Law School',
  'Stanford University',
  'State College of Florida-Manatee-Sarasota',
  'State University of New York at Fredonia',
  'State University of New York at New Paltz',
  'State University of New York at Oswego',
  'State University of New York at Potsdam',
  'State University of New York College at Plattsburgh',
  'Stellenbosch University',
  'Stephen F. Austin State University',
  'Stephens College',
  'Sterling College',
  'Sterling College, Vermont',
  'Stetson University College of Law',
  'Stetson University',
  'Stevens Institute of Technology',
  'Stevenson University',
  'Stillman College',
  'Stockholm School of Economics',
  'Stockholm University',
  'Stockton University',
  'Stonehill College',
  'Stony Brook University',
  'Strayer University',
  'Suffolk University Law School',
  'Suffolk University',
  'Sul Ross State University',
  'Sullivan University',
  'Sun Yat-sen University',
  'Sungkyunkwan University',
  'SUNY Canton',
  'SUNY Cobleskill',
  'SUNY College at Old Westbury',
  'SUNY College at Oneonta',
  'SUNY College of Environmental Science and Forestry',
  'SUNY College of Optometry',
  'SUNY Cortland',
  'SUNY Delhi',
  'SUNY Downstate Medical Center',
  'SUNY Downstate Medical Center',
  'SUNY Empire State College',
  'SUNY Geneseo',
  'SUNY Maritime College',
  'SUNY Polytechnic Institute',
  'SUNY Upstate Medical University',
  'Susquehanna University',
  'Swansea University',
  'Swarthmore College',
  'Swedish University of Agricultural Sciences',
  'Sweet Briar College',
  'Swinburne University of Technology',
  'Syracuse Law School',
  'Syracuse University',
  'Syracuse University',
  'Tabor College',
  'Taft Law School',
  'Taipei Medical University',
  'Talladega College',
  'Tampere University of Technology',
  'Tarbiat Modares University',
  'Tarleton State University',
  'Tata Institute of Fundamental Research',
  'Taylor University',
  'Technical University of Berlin',
  'Technical University of Denmark',
  'Technical University of Dortmund',
  'Technical University of Munich',
  'Technion – Israel Institute of Technology',
  'Tehran University of Medical Sciences',
  'Tel Aviv University',
  'Télécom ParisTech',
  'Temple University Beasley School of Law',
  'Temple University',
  'Temple University',
  'Tennessee State University',
  'Tennessee Tech University',
  'Tennessee Wesleyan University',
  'Texas A&M International University',
  'Texas A&M School of Law',
  'Texas A&M University at Galveston',
  'Texas A&M University, College Station',
  'Texas A&M University-Commerce',
  'Texas A&M University-Corpus Christi',
  'Texas A&M University-Kingsville',
  'Texas A&M University-San Antonio',
  'Texas A&M University-Texarkana',
  'Texas Christian University',
  'Texas College',
  'Texas Lutheran University',
  'Texas Southern University Marshall School of Law',
  'Texas Southern University',
  'Texas State University',
  'Texas Tech University School of Law',
  'Texas Tech University',
  'Texas Tech University Health Sciences Center',
  'Texas Wesleyan University',
  'Texas Woman\'s University',
  'The City College of New York',
  'The College at Brockport',
  'The College of Idaho',
  'The College of New Jersey',
  'The College of Saint Rose',
  'The College of St. Scholastica',
  'The College of Westchester',
  'The College of Wooster',
  'The Cooper Union for the Advancement of Science and Art',
  'The Culinary Institute of America',
  'The Evergreen State College',
  'The Graduate Center, CUNY',
  'The Institute of World Politics',
  'The Juilliard School',
  'The King\'s College',
  'The King\'s University',
  'The Master\'s University',
  'The New School',
  'The Rockefeller University',
  'The Sage Colleges',
  'The School of Architecture',
  'The University of the Arts',
  'The Wright Institute',
  'Thiel College',
  'Thomas Aquinas College',
  'Thomas College',
  'Thomas Edison State University',
  'Thomas Jefferson School of Law',
  'Thomas Jefferson University',
  'Thomas Jefferson University',
  'Thomas M Cooley Law School Ann Arbor',
  'Thomas M Cooley Law School Auburn Hills',
  'Thomas M Cooley Law School Grand Rapids',
  'Thomas M Cooley Law School Lansing',
  'Thomas M Cooley Law School Tampa Bay - INACTIVE',
  'Thomas More College of Liberal Arts',
  'Thomas More University',
  'Thomas University',
  'Tianjin Medical University',
  'Tianjin University',
  'Tiffin University',
  'Tilburg University',
  'Toccoa Falls College',
  'Toho University',
  'Tohoku University',
  'Tokai University',
  'Tokushima University',
  'Tokyo Institute of Technology',
  'Tokyo Medical and Dental University',
  'Tokyo Metropolitan University',
  'Tokyo University of Agriculture and Technology',
  'Tokyo University of Science',
  'Tokyo Women\'s Medical University',
  'Tongji University',
  'Tottori University',
  'Tougaloo College',
  'Toulouse 1 Capitole University',
  'Touro College',
  'Touro Law School',
  'Touro University California',
  'Towson University',
  'Transylvania University',
  'Trevecca Nazarene University',
  'Trine University',
  'Trinity Bible College',
  'Trinity Christian College',
  'Trinity College',
  'Trinity College of Florida',
  'Trinity College of Nursing & Health Sciences',
  'Trinity College, Dublin',
  'Trinity International University',
  'Trinity Law School',
  'Trinity University',
  'Trinity Washington University',
  'Tri-State Bible College',
  'Trocaire College',
  'Troy University',
  'Truett McConnell University',
  'Truman State University',
  'Tsinghua University',
  'TU Darmstadt',
  'TU Dresden',
  'TU Wien',
  'Tufts University',
  'Tulane University School of Law',
  'Tulane University',
  'Tunis El Manar University',
  'Tusculum University',
  'Tuskegee University',
  'Ulm University',
  'Ulsan National Institute of Science and Technology',
  'Ulster University',
  'UMASS Law',
  'Umeå University',
  'UNESP, São Paulo State University',
  'UNH Law School',
  'Uniformed Services University of the Health Sciences',
  'Uniformed Services University of the Health Sciences',
  'Union College',
  'Union College, Kentucky',
  'Union College, Nebraska',
  'Union Institute & University',
  'Union University',
  'United States Air Force Academy',
  'United States Coast Guard Academy',
  'United States Merchant Marine Academy',
  'United States Military Academy',
  'United States Naval Academy',
  'United States Sports Academy',
  'United States University',
  'Unity College',
  'University of Akron Mcdowell Law Center',
  'University of Alabama School of Law',
  'University of Alberta Faculty of Law',
  'University of Arizona Rogers College of Law',
  'University of Arkansas Fayetteville Leflar Law Center',
  'University of Arkansas Little Rock Bowen School of Law',
  'University of Baltimore School of Law',
  'University of British Columbia Faculty of Law',
  'University of Calgary Faculty of Law',
  'University of California Berkeley School of Law',
  'University of California Davis School of Law',
  'University of California Irvine School of Law',
  'University of California Los Angeles School of Law',
  'University of Chicago Law School',
  'University of Cincinnati College of Law',
  'University of Colorado School of Law',
  'University of Connecticut School of Law',
  'University of Dayton School of Law',
  'University of Denver College of Law',
  'University of Detroit Mercy School of Law',
  'University of District of Columbia School of Law',
  'University of Florida Levin College of Law',
  'University of Georgia School of Law',
  'University of Hawaii Richardson School of Law',
  'University of Houston Law Center',
  'University of Idaho College of Law',
  'University of Illinois Chicago School of Law',
  'University of Illinois College of Law',
  'University of Iowa College of Law',
  'University of Kansas School of Law',
  'University of Kentucky College of Law',
  'University of Laval Faculty of Law',
  'University of Laverne College of Law',
  'University of Louisville Brandeis School of Law',
  'University of Maine School of Law',
  'University of Manitoba Faculty of Law',
  'University of Maryland School of Law',
  'University of Memphis Humphreys School of Law',
  'University of Miami School of Law',
  'University of Michigan Law School',
  'University of Minnesota Law School',
  'University of Mississippi School of Law',
  'University of Missouri Columbia School of Law',
  'University of Missouri Kansas City School of Law',
  'University of Moncton Law School',
  'University of Montana School of Law',
  'University of Montreal Faculty of Law',
  'University of Nebraska College of Law',
  'University of New Brunswick Faculty of Law',
  'University of New Mexico School of Law',
  'University of North Carolina School of Law',
  'University of North Dakota School of Law',
  'University of North Texas Dallas College of Law',
  'University of Oklahoma Law Center',
  'University of Oregon School of Law',
  'University of Ottawa School of Law',
  'University of Pennsylvania Law School',
  'University of Pittsburgh School of Law',
  'University of Puerto Rico School of Law',
  'University of Richmond School of Law',
  'University of San Diego School of Law',
  'University of San Francisco School of Law',
  'University of Saskatchewan College of Law',
  'University of Sherbrooke Faculty of Law',
  'University of South Carolina School of Law',
  'University of South Dakota School of Law',
  'University of Southern California Gould School of Law',
  'University of St Thomas School of Law',
  'University of Tennessee College of Law',
  'University of Texas School of Law',
  'University of The Pacific McGeorge School of Law',
  'University of Toledo College of Law',
  'University of Toronto Faculty of Law',
  'University of Tulsa College of Law',
  'University of Utah Quinney College of Law',
  'University of Victoria Faculty of Law',
  'University of Virginia School of Law',
  'University of Washington School of Law',
  'University of West Los Angeles',
  'University of West Los Angeles - San Fernando Valley',
  'University of Western Ontario Faculty of Law',
  'University of Windsor Faculty of Law',
  'University of Wisconsin Law School',
  'University of Wyoming College of Law',
  'University of York Osgoode Hall Law School',
  'Université catholique de Louvain',
  'Université libre de Bruxelles',
  'University at Albany, State University of New York',
  'University at Albany, SUNY',
  'University at Buffalo',
  'University at Buffalo, State University of New York',
  'University College Cork',
  'University College Dublin',
  'University College London',
  'University of A Coruña',
  'University of Aberdeen',
  'University of Adelaide',
  'University of Advancing Technology',
  'University of Akron',
  'University of Alabama',
  'University of Alabama - Tuscaloosa',
  'University of Alabama at Birmingham',
  'University of Alabama in Huntsville',
  'University of Alaska Anchorage',
  'University of Alaska Fairbanks',
  'University of Alaska Southeast',
  'University of Alberta',
  'University of Alcalá',
  'University of Alicante',
  'University of Amsterdam',
  'University of Angers',
  'University of Antioquia',
  'University of Antwerp',
  'University of Arizona',
  'University of Arkansas',
  'University of Arkansas - Fayetteville',
  'University of Arkansas - Fort Smith',
  'University of Arkansas at Little Rock',
  'University of Arkansas at Monticello',
  'University of Arkansas at Pine Bluff',
  'University of Arkansas for Medical Sciences',
  'University of Auckland',
  'University of Aveiro',
  'University of Baltimore',
  'University of Barcelona',
  'University of Bari',
  'University of Basel',
  'University of Bath',
  'University of Bayreuth',
  'University of Belgrade',
  'University of Bergen',
  'University of Bern',
  'University of Birmingham',
  'University of Bologna',
  'University of Bonn',
  'University of Bordeaux',
  'University of Brasília',
  'University of Bremen',
  'University of Brescia',
  'University of Bridgeport',
  'University of Brighton',
  'University of Bristol',
  'University of British Columbia',
  'University of Bucharest',
  'University of Buenos Aires',
  'University of Burgundy',
  'University of Cádiz',
  'University of Caen Normandy',
  'University of Cagliari',
  'University of Calabria',
  'University of Calcutta',
  'University of Calgary',
  'University of California, Berkeley',
  'University of California, Davis',
  'University of California, Irvine',
  'University of California, Los Angeles',
  'University of California, Merced',
  'University of California, Riverside',
  'University of California, San Diego',
  'University of California, San Francisco',
  'University of California, Santa Barbara',
  'University of California, Santa Cruz',
  'University of Cambridge',
  'University of Campania Luigi Vanvitelli',
  'University of Campinas',
  'University of Cantabria',
  'University of Canterbury',
  'University of Cape Town',
  'University of Castilla-La Mancha',
  'University of Catania',
  'University of Central Arkansas',
  'University of Central Florida',
  'University of Central Missouri',
  'University of Central Oklahoma',
  'University of Charleston',
  'University of Chicago',
  'University of Chile',
  'University of Chinese Academy of Sciences',
  'University of Cincinnati',
  'University of Clermont Auvergne',
  'University of Coimbra',
  'University of Cologne',
  'University of Colorado Boulder',
  'University of Colorado Colorado Springs',
  'University of Colorado Denver',
  'University of Colorado Denver | Anschutz Medical Campus',
  'University of Concepción',
  'University of Connecticut',
  'University of Copenhagen',
  'University of Córdoba, Spain',
  'University of Crete',
  'University of Cyprus',
  'University of Dallas',
  'University of Dayton',
  'University of Debrecen',
  'University of Delaware',
  'University of Delhi',
  'University of Denver',
  'University of Detroit Mercy',
  'University of Dubuque',
  'University of Duisburg-Essen',
  'University of Dundee',
  'University of Düsseldorf',
  'University of East Anglia',
  'University of Eastern Finland',
  'University of Eastern Piedmont',
  'University of Edinburgh',
  'University of Electronic Science and Technology of China',
  'University of Erlangen-Nuremberg',
  'University of Essex',
  'University of Evansville',
  'University of Exeter',
  'University of Extremadura',
  'University of Ferrara',
  'University of Findlay',
  'University of Florence',
  'University of Florida',
  'University of Franche-Comté',
  'University of Freiburg',
  'University of Fribourg',
  'University of Geneva',
  'University of Genoa',
  'University of Georgia',
  'University of Giessen',
  'University of Girona',
  'University of Glasgow',
  'University of Gothenburg',
  'University of Göttingen',
  'University of Granada',
  'University of Graz',
  'University of Greifswald',
  'University of Groningen',
  'University of Guelph',
  'University of Haifa',
  'University of Hamburg',
  'University of Hanover',
  'University of Hartford',
  'University of Hasselt',
  'University of Hawaii at Hilo',
  'University of Hawaii at Manoa',
  'University of Hawaii-West Oahu',
  'University of Health Sciences and Pharmacy in St. Louis',
  'University of Helsinki',
  'University of Hertfordshire',
  'University of Hohenheim',
  'University of Holy Cross',
  'University of Hong Kong',
  'University of Houston',
  'University of Houston-Clear Lake',
  'University of Houston-Downtown',
  'University of Houston-Victoria',
  'University of Hull',
  'University of Hyogo',
  'University of Ibadan',
  'University of Iceland',
  'University of Idaho',
  'University of Illinois at Chicago',
  'University of Illinois at Springfield',
  'University of Illinois at Urbana-Champaign',
  'University of Indianapolis',
  'University of Innsbruck',
  'University of Insubria',
  'University of Ioannina',
  'University of Iowa',
  'University of Jaén',
  'University of Jamestown',
  'University of Jena',
  'University of Jinan',
  'University of Johannesburg',
  'University of Jyväskylä',
  'University of Kaiserslautern',
  'University of Kansas',
  'University of Kent',
  'University of Kentucky',
  'University of Kiel',
  'University of Konstanz',
  'University of KwaZulu-Natal',
  'University of La Laguna',
  'University of La Verne',
  'University of L\'Aquila',
  'University of Lausanne',
  'University of Leeds',
  'University of Leicester',
  'University of Liège',
  'University of Lille',
  'University of Limerick',
  'University of Lisbon',
  'University of Liverpool',
  'University of Ljubljana',
  'University of Lorraine',
  'University of Louisiana at Lafayette',
  'University of Louisiana at Monroe',
  'University of Louisville',
  'University of Lübeck',
  'University of Lynchburg',
  'University of Macau',
  'University of Maine',
  'University of Maine at Augusta',
  'University of Maine at Farmington',
  'University of Maine at Fort Kent',
  'University of Maine at Machias',
  'University of Maine at Presque Isle',
  'University of Maine, Orono',
  'University of Málaga',
  'University of Malaya',
  'University of Manchester',
  'University of Manitoba',
  'University of Marburg',
  'University of Maribor',
  'University of Mary',
  'University of Mary Hardin-Baylor',
  'University of Mary Washington',
  'University of Maryland Eastern Shore',
  'University of Maryland, Baltimore',
  'University of Maryland, Baltimore County',
  'University of Maryland, College Park',
  'University of Massachusetts Amherst',
  'University of Massachusetts Boston',
  'University of Massachusetts Dartmouth',
  'University of Massachusetts Lowell',
  'University of Massachusetts Medical School',
  'University of Melbourne',
  'University of Memphis',
  'University of Messina',
  'University of Miami',
  'University of Michigan, Ann Arbor',
  'University of Michigan-Dearborn',
  'University of Michigan-Flint',
  'University of Milan',
  'University of Milano-Bicocca',
  'University of Minho',
  'University of Minnesota-Twin Cities',
  'University of Mississippi',
  'University of Mississippi Medical Center',
  'University of Missouri',
  'University of Missouri–Columbia',
  'University of Missouri-Kansas City',
  'University of Missouri–Kansas City',
  'University of Missouri-St. Louis',
  'University of Mobile',
  'University of Modena and Reggio Emilia',
  'University of Mons',
  'University of Montana',
  'University of Montana Western',
  'University of Montevallo',
  'University of Montpellier',
  'University of Montreal',
  'University of Mount Olive',
  'University of Mount Union',
  'University of Münster',
  'University of Murcia',
  'University of Nantes',
  'University of Naples Federico II',
  'University of Natural Resources and Life Sciences, Vienna',
  'University of Navarra',
  'University of Nebraska at Kearney',
  'University of Nebraska at Omaha',
  'University of Nebraska Medical Center',
  'University of Nebraska-Lincoln',
  'University of Nevada, Las Vegas',
  'University of Nevada, Reno',
  'University of New Brunswick',
  'University of New England',
  'University of New Hampshire',
  'University of New Haven',
  'University of New Mexico',
  'University of New Orleans',
  'University of New South Wales',
  'University of Newcastle',
  'University of Nice Sophia Antipolis',
  'University of North Alabama',
  'University of North Carolina at Asheville',
  'University of North Carolina at Chapel Hill',
  'University of North Carolina at Charlotte',
  'University of North Carolina at Greensboro',
  'University of North Carolina at Pembroke',
  'University of North Carolina School of the Arts',
  'University of North Carolina Wilmington',
  'University of North Dakota',
  'University of North Florida',
  'University of North Georgia',
  'University of North Texas',
  'University of North Texas Health Science Center',
  'University of North Texas, Denton',
  'University of Northern Colorado',
  'University of Northern Iowa',
  'University of Northwestern - St. Paul',
  'University of Northwestern Ohio',
  'University of Notre Dame',
  'University of Nottingham',
  'University of Oklahoma',
  'University of Oklahoma Health Sciences Center',
  'University of Oklahoma Health Sciences Center',
  'University of Oklahoma, Norman',
  'University of Oregon',
  'University of Orléans',
  'University of Oslo',
  'University of Osnabrück',
  'University of Otago',
  'University of Ottawa',
  'University of Oulu',
  'University of Oviedo',
  'University of Oxford',
  'University of Padua',
  'University of Palermo',
  'University of Paris-Sud',
  'University of Parma',
  'University of Patras',
  'University of Pavia',
  'University of Pécs',
  'University of Pennsylvania',
  'University of Perpignan Via Domita',
  'University of Perugia',
  'University of Picardie Jules Verne',
  'University of Pikeville',
  'University of Pisa',
  'University of Pittsburgh',
  'University of Plymouth',
  'University of Poitiers',
  'University of Portland',
  'University of Porto',
  'University of Portsmouth',
  'University of Potsdam',
  'University of Pretoria',
  'University of Providence',
  'University of Puget Sound',
  'University of Québec at Montreal',
  'University of Queensland',
  'University of Reading',
  'University of Redlands',
  'University of Regensburg',
  'University of Regina',
  'University of Rennes 1',
  'University of Rhode Island',
  'University of Richmond',
  'University of Rio Grande',
  'University of Rochester',
  'University of Rome Tor Vergata',
  'University of Rostock',
  'University of Saint Francis',
  'University of Saint Joseph',
  'University of Saint Mary',
  'University of Salamanca',
  'University of Salento',
  'University of Salerno',
  'University of San Diego',
  'University of San Francisco',
  'University of Santiago de Compostela',
  'University of São Paulo',
  'University of Saskatchewan',
  'University of Sassari',
  'University of Savoy',
  'University of Science and Arts of Oklahoma',
  'University of Science and Technology Beijing',
  'University of Science and Technology of China',
  'University of Science and Technology, Korea',
  'University of Science, Malaysia',
  'University of Scranton',
  'University of Seville',
  'University of Sheffield',
  'University of Sherbrooke',
  'University of Siegen',
  'University of Siena',
  'University of Silesia in Katowice',
  'University of Silicon Valley',
  'University of Sioux Falls',
  'University of South Alabama',
  'University of South Australia',
  'University of South Bohemia in České Budějovice',
  'University of South Carolina',
  'University of South Carolina - Columbia',
  'University of South Carolina-Aiken',
  'University of South Carolina-Beaufort',
  'University of South Carolina-Upstate',
  'University of South Dakota',
  'University of South Florida',
  'University of Southampton',
  'University of Southern California',
  'University of Southern Denmark',
  'University of Southern Indiana',
  'University of Southern Maine',
  'University of Southern Mississippi',
  'University of Split',
  'University of St Andrews',
  'University of St. Augustine for Health Sciences',
  'University of St. Francis',
  'University of St. Gallen',
  'University of St. Thomas',
  'University of St. Thomas',
  'University of Stirling',
  'University of Strasbourg',
  'University of Strathclyde',
  'University of Stuttgart',
  'University of Surrey',
  'University of Sussex',
  'University of Sydney',
  'University of Szeged',
  'University of Tampa',
  'University of Tampere',
  'University of Tartu',
  'University of Tasmania',
  'University of Technology Sydney',
  'University of Technology, Malaysia',
  'University of Tehran',
  'University of Tennessee at Chattanooga',
  'University of Tennessee at Martin',
  'University of Tennessee Health Science Center',
  'University of Tennessee Health Science Center',
  'University of Tennessee Southern',
  'University of Tennessee, Knoxville',
  'University of Texas at Arlington',
  'University of Texas at Austin',
  'University of Texas at Dallas',
  'University of Texas at El Paso',
  'University of Texas at San Antonio',
  'University of Texas at Tyler',
  'University of Texas Health Science Center at Houston',
  'University of Texas Health Science Center at San Antonio',
  'University of Texas Health Science Center at Tyler',
  'University of Texas MD Anderson Cancer Center',
  'University of Texas Medical Branch',
  'University of Texas Medical Branch at Galveston',
  'University of Texas Permian Basin',
  'University of Texas Rio Grande Valley',
  'University of Texas Southwestern Medical Center',
  'University of Texas Southwestern Medical Center',
  'University of the Andes, Colombia',
  'University of the Balearic Islands',
  'University of the Basque Country',
  'University of the Cumberlands',
  'University of the District of Columbia',
  'University of the Incarnate Word',
  'University of the Ozarks',
  'University of the Pacific',
  'University of the Potomac',
  'University of the Republic, Uruguay',
  'University of the Sciences in Philadelphia',
  'University of the Southwest',
  'University of the West',
  'University of the Witwatersrand',
  'University of Tokyo',
  'University of Toledo',
  'University of Toronto',
  'University of Tours',
  'University of Toyama',
  'University of Trento',
  'University of Trieste',
  'University of Tromsø – Arctic University of Norway',
  'University of Tsukuba',
  'University of Tübingen',
  'University of Tulsa',
  'University of Turin',
  'University of Turku',
  'University of Twente',
  'University of Udine',
  'University of Ulsan',
  'University of Utah',
  'University of Valencia',
  'University of Valladolid',
  'University of Valley Forge',
  'University of Vermont',
  'University of Verona',
  'University of Veterinary Medicine Vienna',
  'University of Victoria',
  'University of Vienna',
  'University of Vigo',
  'University of Virginia',
  'University of Virginia\'s College at Wise',
  'University of Warsaw',
  'University of Warwick',
  'University of Washington',
  'University of Waterloo',
  'University of West Alabama',
  'University of West Florida',
  'University of West Georgia',
  'University of West Los Angeles',
  'University of Western Australia',
  'University of Western Brittany',
  'University of Western States',
  'University of Windsor',
  'University of Wisconsin-Eau Claire',
  'University of Wisconsin-Green Bay',
  'University of Wisconsin-La Crosse',
  'University of Wisconsin-Madison',
  'University of Wisconsin–Madison',
  'University of Wisconsin-Milwaukee',
  'University of Wisconsin–Milwaukee',
  'University of Wisconsin-Oshkosh',
  'University of Wisconsin-Parkside',
  'University of Wisconsin-Platteville',
  'University of Wisconsin-River Falls',
  'University of Wisconsin-Stevens Point',
  'University of Wisconsin-Stout',
  'University of Wisconsin-Superior',
  'University of Wisconsin-Whitewater',
  'University of Wollongong',
  'University of Wrocław',
  'University of Wuppertal',
  'University of Würzburg',
  'University of Wyoming',
  'University of York',
  'University of Zagreb',
  'University of Zaragoza',
  'University of Zurich',
  'University Paris 1 Panthéon-Sorbonne',
  'University Paris-Est Créteil',
  'UNLV William S Boyd School of Law',
  'UNT Dallas College of Law',
  'Upper Iowa University',
  'Uppsala University',
  'Ursinus College',
  'Ursuline College',
  'Utah State University',
  'Utah State University',
  'Utah Valley University',
  'Utica College',
  'Utrecht University',
  'Valdosta State University',
  'Valencia College',
  'Valley City State University',
  'Valparaiso University School of Law',
  'Valparaiso University',
  'Vanderbilt University Law School',
  'Vanderbilt University',
  'VanderCook College of Music',
  'Vanguard University of Southern California',
  'Vassar College',
  'Vaughn College of Aeronautics and Technology',
  'Ventura College of Law',
  'Vermont College of Fine Arts',
  'Vermont Law School',
  'Vermont Technical College',
  'Versailles Saint-Quentin-en-Yvelines University',
  'Victoria University of Wellington',
  'Villa Maria College',
  'Villanova University School of Law',
  'Villanova University',
  'Vilnius University',
  'Vincennes University',
  'Virginia Commonwealth University',
  'Virginia Military Institute',
  'Virginia Polytechnic Institute and State University',
  'Virginia State University',
  'Virginia Tech',
  'Virginia Union University',
  'Virginia Wesleyan University',
  'Vita-Salute San Raffaele University',
  'Viterbo University',
  'Voorhees College',
  'Vrije Universiteit Brussel',
  'VU University Amsterdam',
  'Wabash College',
  'Wade College',
  'Wageningen University and Research',
  'Wagner College',
  'Wake Forest University School of Law',
  'Wake Forest University',
  'Waldorf University',
  'Walla Walla University',
  'Walsh College',
  'Walsh University',
  'Warner Pacific College',
  'Warner University',
  'Warren Wilson College',
  'Warsaw University of Technology',
  'Wartburg College',
  'Waseda University',
  'Washburn University School of Law',
  'Washburn University',
  'Washburn University School of Law',
  'Washington & Jefferson College',
  'Washington Adventist University',
  'Washington and Lee University School of Law',
  'Washington and Lee University',
  'Washington College',
  'Washington State University',
  'Washington University School of Law',
  'Washington University in St. Louis',
  'Watkins College of Art',
  'Wayland Baptist University',
  'Wayne State College',
  'Wayne State University Law School',
  'Wayne State University',
  'Waynesburg University',
  'Webb Institute',
  'Webber International University',
  'Weber State University',
  'Webster University',
  'Weizmann Institute of Science',
  'Welch College',
  'Wellesley College',
  'Wells College',
  'Wentworth Institute of Technology',
  'Wenzhou Medical University',
  'Wesleyan College',
  'Wesleyan University',
  'West Chester University of Pennsylvania',
  'West Coast University-Los Angeles',
  'West Liberty University',
  'West Texas A&M University',
  'West Virginia School of Osteopathic Medicine',
  'West Virginia State University',
  'West Virginia University College of Law',
  'West Virginia University',
  'West Virginia University at Parkersburg',
  'West Virginia Wesleyan College',
  'Western Carolina University',
  'Western Colorado University',
  'Western Connecticut State University',
  'Western Illinois University',
  'Western Kentucky University',
  'Western Michigan University',
  'Western Michigan University - Cooley Law School - Michigan Campuses',
  'Western Michigan University - Cooley Law School - Tampa Bay',
  'Western Nevada College',
  'Western New England College School of Law',
  'Western New England University',
  'Western New Mexico University',
  'Western Oregon University',
  'Western Sierra Law School',
  'Western State University College of Law - Irvine',
  'Western State University College of Law (Closed)',
  'Western Sydney University',
  'Western University',
  'Western University of Health Sciences',
  'Western Washington University',
  'Westfield State University',
  'Westminster College',
  'Westminster College, Missouri',
  'Westminster College, Pennsylvania',
  'Westmont College',
  'Wheaton College',
  'Wheaton College, Massachusetts',
  'Wheeling University',
  'Whitman College',
  'Whittier College',
  'Whittier Law School',
  'Whitworth University',
  'Wichita State University',
  'Widener University Commonwealth Law School',
  'Widener University Delaware Law School',
  'Widener University',
  'Wilberforce University',
  'Wiley College',
  'Wilkes University',
  'Willamette University College of Law',
  'Willamette University',
  'William and Mary Law School',
  'William Carey University',
  'William James College',
  'William Jessup University',
  'William Jewell College',
  'William Marsh Rice University',
  'William Mitchell College of Law (inactive)',
  'William Paterson University',
  'William Peace University',
  'William Penn University',
  'William Woods University',
  'Williams Baptist University',
  'Williams College',
  'Wilmington College',
  'Wilmington University',
  'Wilson College',
  'Wingate University',
  'Winona State University',
  'Winston-Salem State University',
  'Winthrop University',
  'Wisconsin Lutheran College',
  'Wisconsin School of Professional Psychology',
  'Wittenberg University',
  'Wofford College',
  'WON Institute of Graduate Studies',
  'Woodbury University',
  'Worcester Polytechnic Institute',
  'Worcester State University',
  'Wright State University',
  'Wrocław University of Science and Technology',
  'Wuhan University',
  'Wuhan University of Technology',
  'Xavier University',
  'Xavier University of Louisiana',
  'Xiamen University',
  'Xi\'an Jiaotong University',
  'Xiangtan University',
  'Xidian University',
  'Yale Law School',
  'Yale University',
  'Yamagata University',
  'Yamaguchi University',
  'Yangzhou University',
  'Yeshiva University',
  'Yeungnam University',
  'Yokohama City University',
  'Yokohama National University',
  'Yonsei University',
  'York College',
  'York College of Pennsylvania',
  'York College, City University of New York',
  'York University',
  'Young Harris College',
  'Youngstown State University',
  'Yunnan University',
  'Zhejiang Normal University',
  'Zhejiang University',
  'Zhejiang University of Technology',
  'Zhengzhou University'
]

export const SchoolNameSet: Set<string> = new Set<string>(sna)
export const SchoolNameArray: Array<string> = Array.from(SchoolNameSet)
