import { ref, watch } from 'vue'

enum documentEvents {
  click = 'click',
  keydown = 'keydown'
}

export const useOutsideClick = (elementRef: any) => {
  const isOpen = ref<boolean>(false)

  watch(isOpen, (newState) => {
    if (newState) {
      window.addEventListener(documentEvents.click, (e) =>
        handleOutsideClick(e, documentEvents.click)
      )
      window.addEventListener(documentEvents.keydown, (e) =>
        handleOutsideClick(e, documentEvents.keydown)
      )
    } else {
      window.removeEventListener(documentEvents.click, (e) =>
        handleOutsideClick(e, documentEvents.click)
      )
      window.addEventListener(documentEvents.keydown, (e) =>
        handleOutsideClick(e, documentEvents.keydown)
      )
    }
  })

  function handleClose() {
    isOpen.value = false
  }

  function handleOutsideClick(e: MouseEvent | KeyboardEvent, from: string) {
    if (from === documentEvents.keydown) {
      if ('key' in e && (e.key || '').toLowerCase().includes('esc')) {
        handleClose()
      }
    } else {
      const target = e.target as HTMLDivElement
      if (elementRef.value && !elementRef.value.contains(target)) {
        handleClose()
      }
    }
  }

  function handleOpen() {
    if (isOpen.value) {
      isOpen.value = false
      return false
    }
    isOpen.value = true
  }

  return {
    handleOpen,
    isOpen,
    handleClose
  }
}
