import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsTextField, {
      id: `honors-law-review-journal-${_ctx.idIndex}`,
      modelValue: _ctx.lawReviewJournalExperience.lawReviewJournal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lawReviewJournalExperience.lawReviewJournal) = $event)),
      class: "mb-3",
      label: _ctx.locales.law_review_journal_experience,
      placeholder: _ctx.locales.law_review_journal_experience,
      "max-length": 100,
      validation: _ctx.v$.form.lawReviewJournal
    }, null, 8, ["id", "modelValue", "label", "placeholder", "validation"]),
    _createVNode(_component_BsTextField, {
      id: `honors-title-position-${_ctx.idIndex}`,
      modelValue: _ctx.lawReviewJournalExperience.titlePosition,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lawReviewJournalExperience.titlePosition) = $event)),
      class: "mb-3",
      label: _ctx.locales.title_position,
      placeholder: _ctx.locales.title_position,
      "max-length": 100,
      validation: _ctx.v$.form.titlePosition
    }, null, 8, ["id", "modelValue", "label", "placeholder", "validation"])
  ]))
}