<template>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    BARBRI Inc., and its BARBRI subsidiaries and affiliates (&ldquo;BARBRI&rdquo;, &ldquo;us,&rdquo; or &ldquo;we&rdquo;) are committed to respecting your privacy and protecting your personal data. We define personal data broadly as information that directly identifies an individual or that makes an individual identifiable when combined with other information.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    This <strong>Recruiting Privacy Notice</strong> (&ldquo;Privacy Notice&rdquo;) describes how we handle and protect your personal data in connection with BARBRI&rsquo;s recruiting processes and programs. In case of a conflict between this Privacy Notice and applicable law, applicable law will govern.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    This Privacy Notice only applies to the personal data of job applicants, potential candidates for employment or partnership, and those who participate in our recruiting programs and events. This personal data is submitted directly to BARBRI through the online application process and follow-up communications and/or through alternative channels (e.g., via professional recruiting firms). BARBRI may also collect and process your personal data regarding employment. This Privacy Notice does not apply to the personal data of our employees, partners, clients, vendors or any other person from whom BARBRI collects personal data for other purposes.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    By submitting your personal data to us, you acknowledge that:
  </p>
  <ul style="list-style-type: disc; text-align:justify;">
    <li>You have read and understood this Privacy Notice and agree to the use of your personal data as set out herein.</li>
    <li>Your personal data may be transferred and processed worldwide, including in countries that may not be deemed to provide the same level of data protection as your home country, for the purposes and in the manner specified in this Privacy Notice.</li>
    <li>You are not required to provide any requested information to us, but your failure to do so may result in our not being able to continue your candidacy for the job for which you have applied.</li>
    <li>All of your representations are true and correct to the best of your knowledge and belief, and you have not knowingly omitted any related information of an adverse nature. Providing any inaccurate information may make you ineligible for employment.</li>
    <li>This Privacy Notice does not form part of any contract of employment offered to candidates hired by BARBRI or another third-party.</li>
  </ul>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>PERSONAL DATA WE COLLECT</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    The types of personal data that we request from you and the ways that we process it are determined by the requirements of the country in which the role you apply for is located, and not the country in which you reside. Should you apply to more than one location or should the role to which you apply be available in more than one country, the types of personal data we request from you and the ways that we process it are determined by the requirements of all the countries in which the position is located.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We usually collect personal data directly from you when you apply for a role with us, such as your name, address, contact information, photographs and videos, work and educational history, achievements, identity documents, and test results. If you receive an offer from a third-party, they may then conduct a background check and, to the extent permitted by applicable law, they may also collect data related to criminal offences and proceedings. We also collect similar personal data about you for third parties, such as, your references, prior employers, or individuals who recommended your candidacy, and, to the extent permitted by applicable law, employment background check providers. We may also collect personal data about you online to the extent that you have chosen to make this information publicly available. .
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Sensitive personal data is a subset of personal data that includes ethnicity, health, trade union membership, philosophical beliefs, sexual orientation, and other categories as prescribed by law. We may collect sensitive personal data about a candidate to the extent permitted to do so by applicable laws (e.g., U.S. equal opportunity laws) and to support our efforts to create an inclusive and diverse work environment. We may also collect sensitive personal data to the extent that a candidate chooses, without being asked, to voluntarily disclose it during the recruiting process. Under limited circumstances and to the extent permitted by applicable law, we may also collect sensitive personal information relating to health and medical characteristics, such as status of infection, test results, and vaccination status, to address our public health and workplace safety obligations and to protect BARBRI and its employees, clients, and third parties.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>USE OF YOUR PERSONAL DATA</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We collect and use your personal data for legitimate human resources and business management reasons, including:
  </p>
  <ul style="list-style-type: disc; text-align:justify;">
    <li>Identifying and evaluating candidates for potential employment, as well as for future roles that may become available;</li>
    <li>Maintaining records in relation to recruiting and hiring;</li>
    <li>Ensuring compliance with legal requirements, including those relating to public health and workplace safety;</li>
    <li>Fostering our diversity and inclusion programs and practices;</li>
    <li>Conducting background checks, including, to the extent permitted by applicable law, and if you receive an offer from us or a third-party, criminal history checks;</li>
    <li>Protecting our legal rights to the extent authorized or permitted by law; and</li>
    <li>Protecting the workplace and communicating with medical professionals, law enforcement, or other public authorities in the event of an emergency or public health event, such as when the health or safety of you or one or more individuals may be endangered, including, to the extent permitted by applicable law, sharing data about the status of an infection, test results, and vaccination status.</li>
  </ul>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We may also use your personal data for BARBRI analytics purposes, including in aggregated/pseudonymized form, to improve our recruitment and hiring process and augment our ability to attract successful candidates.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Our processing of your personal data for the purposes mentioned above is based:
  </p>
  <ul style="list-style-type: disc; text-align:justify;">
    <li>In part, on our legitimate business interests in evaluating your application to manage our relationship with you, to ensure that we recruit appropriate employers, and to evaluate and maintain the efficacy of our recruiting process more generally; and in operating our business and protecting BARBRI and its employees, clients, and third parties.</li>
    <li>In part, on our performing contractual and precontractual measures relating to our potential relationship with you;</li>
    <li>In part, on our complying with applicable law with regard to personal data necessary to satisfy our legal and regulatory obligations, including with regard to public health and workplace safety;</li>
    <li>In part, on your consent, if we offer you the opportunity to participate in our optional recruiting programs or if we collect sensitive personal data for legally permitted purposes other than compliance with our legal obligations regarding public health and workplace safety.</li>
  </ul>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>BACKGROUND SCREENING</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If you receive an offer from a third-party, they may conduct a background check on you or instruct a third party to do so on their behalf. Background screening will only be done where permitted by the law applicable to the location where the position is located and to the extent necessary and proportionate to the role that you are being offered. A background check will only involve criminal background data to the extent permitted by law. If a background screening is required, you may be contacted by a third-party background screening service provider to request authorization for the release of your information, and at that time you will be provided with further information about the process and what personal data it might involve.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>DATA RECIPIENTS AND INTERNATIONAL DATA TRANSFERS</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Your personal data may be accessed by recruiters and interviewers working in the country where the position for which you are applying is based, as well as by recruiters and interviewers working in different countries. Individuals performing administrative functions and IT personnel within BARBRI may also have limited access to your personal data to the extent necessary perform their jobs. In some countries, you may have fewer rights under local law than you do in your country of residence, but we have put in place legal mechanisms designed to ensure adequate data protection for your personal data when it is processed by BARBRI subsidiaries and affiliates within the BARBRI global organization and by BARBRI&rsquo;s service providers, including the transfer of your personal data to countries other than the one in which you reside.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We also share your personal data with other third-party service providers that may assist us in identifying and recruiting talent, administering and evaluating pre-employment screening and testing, and improving our recruiting practices.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Except to the extent necessary to accomplish the BARBRI uses and purposes described in this Privacy Notice, we do not disclose your personal data to third parties. We also prohibit our service providers from using your personal data for non-BARBRI purposes. We do not otherwise share or sell your personal data to third parties.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We maintain processes designed to help ensure that any processing of personal data by third-party service providers is consistent with this Privacy Notice and protects the confidentiality, availability, and integrity of your personal data in compliance with applicable law. Where required by law, we put in place additional legal mechanisms designed to help ensure adequate data protection for your personal data when transferred to another country. If you would like more information about these legal mechanisms, which may include the EU&rsquo;s Standard Contractual Clauses, please contact us at the address provided at the end of this Privacy Notice.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    In addition, we may disclose or transfer your personal data in the event of a re-organization, merger, sale, joint venture, assignment, or other transfer or disposition of all or any portion of our business.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>SORTING OF APPLICATIONS</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    In certain jurisdictions, our third-party partners may use data analytics and algorithms to help us to review the large quantities of candidates and application data that we receive. Our partners prioritize the application review process and sort candidates on the basis of professional characteristics that suggest strengths and capabilities necessary to perform the relevant role.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    The results are always considered in tandem with, and not in lieu of, human judgement. Our partners evaluate each individual candidate on their own merits.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Certain roles may require specific prerequisites or skills (for example, fluency in a certain language, particular professional qualifications or certifications, or number of years in a similar role). Applications that do not meet those requirements might be automatically rejected.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>DATA RETENTION</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If you accept an offer of employment with one of our partners, any relevant personal data collected during your pre-employment period will become part of your personnel records and will be retained in accordance with specific country requirements and with the privacy notice applicable to our partners employees, which will be provided during the on-boarding process.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If they do not employ you, they may nevertheless continue to retain and use your personal data for a period of time (which may vary depending on the country) for system administration purposes, to consider you for potential future roles, and to perform research. Thereafter, they may retain a minimal amount of your personal data to record your recruiting activity.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    To the extent that they have collected personal data, including sensitive personal data, for the specific purpose of fulfilling their legal obligations regarding public health or workplace safety, they will retain that data for the duration of those legal obligations. Thereafter, they may retain a minimal amount of your personal data to establish their compliance with those obligations.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    They may want to remain in contact with you and consider you for future employment opportunities. In such an event, they will seek your consent to include you in one of their recruiting programs that provides you ways to further learn about and stay in touch out partner, either prior to or after you formally apply for a job opportunity. Participation in these recruiting programs is entirely optional.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If you join a recruiting retention program, they may retain your personal data for a period of time specific to that program, but if you wish to withdraw at any time, please contact them
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>SECURITY</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We use generally accepted standards of technical and operational security to secure your personal data. Only authorized personnel of BARBRI, our partners and third-party service providers are permitted to access personal data, and these employees and third-party service providers are required to treat this information as confidential. Despite these precautions, we cannot guarantee that unauthorized persons will not obtain access to your personal data.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>YOUR RIGHTS</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We take reasonable steps that are designed to keep your personal data accurate, complete, and up-to-date for the purposes for which it is collected and used. We also have implemented measures that are designed to help ensure that our processing of your personal data complies with this Privacy Notice and applicable law.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    In accordance with applicable law, you may have one or more of the following rights:
  </p>
  <ul style="list-style-type: disc; text-align:justify;">
    <li>A right to request information about the personal data we hold about you, including the details of how we use that information and who we share it with;</li>
    <li>A right to request a copy of the personal data that we hold about you;</li>
    <li>A right to amend or rectify your personal data if any of the information held about you is incorrect or out of date;</li>
    <li>A right to portability of your personal data;</li>
    <li>A right to request erasure of your personal data;</li>
    <li>A right to demand that we cease the processing of your personal data or that we restrict the processing of your personal data;</li>
    <li>A right to withdraw your consent to the processing of your personal data, to the extent our processing relies on your consent as the lawful basis for processing. This right may not apply if there are other legal justifications to continue processing or we need to retain certain personal data where required or permitted under applicable law; and/or</li>
    <li>A right to provide us with instructions as to the processing of your personal data in case of death.</li>
  </ul>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    In addition, and where granted by applicable law, you may have the right to lodge a complaint with a competent data protection authority.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If you would like to make a request to access, review, correct, delete or port the personal data we have collected about you, to assert a right with regard to your personal data, or to discuss how we process your personal data, please complete this form. If you are unable to access this form for any reason, you may contact us using the information at the end of this Privacy Notice.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    To help protect your privacy and security, we will take reasonable steps to verify your identity before granting you access to your personal data. We will make reasonable attempts to promptly investigate, comply with, or otherwise respond to your requests as may be required by applicable law. Depending upon the circumstances and the request, we may not be permitted to provide access to personal data or otherwise fully comply with your request; for example, where producing your information may reveal the identity of someone else. We reserve the right to charge an appropriate fee for complying with your request where allowed by applicable law, and/or to deny your requests where, in the Firm&rsquo;s discretion, they may be unfounded, excessive, or otherwise unacceptable under applicable law.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    We may use first party and third-party cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively &ldquo;Cookies and Other Tracking Technologies&rdquo;) to identify you and your interests, to remember your preferences, and to track your use of our websites. We also use Cookies and Other Tracking Technologies to control access to certain content on our websites, to protect the websites, and to process any requests that you make of us.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Some of our online recruiting activities are hosted by third parties. When you access sites operated by these third parties, they may, consistent with our Cookie Policy, place their own Cookies or Other Tracking Technologies on your device. You can learn more about our use of Cookies and other tracking technologies by reading our Privacy Policy and Cookie Policy.
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>FOR CANDIDATES FROM OTHER COUNTRIES</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    This Privacy Notice is also available in:
  </p>
  <ul style="list-style-type: disc; text-align:justify;">
    <li>Arabic</li>
    <li>Chinese (Simplified)</li>
    <li>Chinese (Traditional)</li>
    <li>English</li>
    <li>French (Canada)</li>
    <li>French (France)</li>
    <li>Korean</li>
    <li>Polish</li>
    <li>Portuguese (Brazil)</li>
    <li>Portuguese (Portugal)</li>
    <li>Russian</li>
    <li>Spanish (Spain)</li>
    <li>Spanish (Latin America)</li>
  </ul>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    <strong>CONTACTS</strong>
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    If you have any questions about this Privacy Notice or if you would like to communicate with any of our Data Protection Officers or the Data Privacy Team, please contact us at legal@BARBRI.com or
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    BARBRI &amp; Company
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Legal Department, Attn: Privacy
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    12222 Merit Drive
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    Dallas, Texas 75251
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
&nbsp;
  </p>
  <p style="margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;">
    BARBRI reserves the right to modify this Privacy Notice. We will post any changes to our Privacy Notice on this page. Please check this page regularly to keep up-to-date.
  </p>
</template>
