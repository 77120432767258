
import { defineComponent, PropType } from 'vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import RecruiterDetails from './RecruiterDetails.vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import AccordionMenu from '@/components/Accordion/AccordionMenu.vue'
import AccordionExpander from '@/components/Accordion/AccordionExpander.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import StartDateIcon from '~/icons/Applicants/Opportunity-StartDate.svg'
import DeadlineIcon from '~/icons/Applicants/Opportunity-Deadline.svg'
import PostedDateIcon from '~/icons/Applicants/Opportunity-PostedDate.svg'
import CategoryIcon from '~/icons/Applicants/Opportunity-Category.svg'
import LocationIcon from '~/icons/Applicants/Opportunity-Location.svg'
import SalaryIcon from '~/icons/Applicants/Opportunity-Salary.svg'
import TypeIcon from '~/icons/Applicants/Opportunity-Type.svg'
import JobtypeIcon from '~/icons/Applicants/Opportunity-Jobtype.svg'
import { SummaryDetailsModel } from '../Applicants.interface'
import { dropdownOptions } from '../Applicants.util'
import locales from '@/locales/en/locales.json'
import applyLocales from '@/components/JobOpportunitiesTable/JobOpportunitiesTable.locales.en.json'
import YoutubeEmbed from '@/components/YoutubeEmbed.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'

export default defineComponent({
  components: {
    StartDateIcon,
    DeadlineIcon,
    PostedDateIcon,
    CategoryIcon,
    LocationIcon,
    SalaryIcon,
    TypeIcon,
    JobtypeIcon,
    RecruiterDetails,
    DropdownOptions,
    TextEditor,
    AccordionMenu,
    AccordionExpander,
    AccordionItem,
    YoutubeEmbed,
    ProfilePicture
  },
  props: {
    summaryDetails: {
      type: Object as PropType<SummaryDetailsModel>,
      required: true
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    allowApply: {
      type: Boolean,
      default: false
    },
    applied: {
      type: Boolean,
      default: false
    }
  },
  emits: ['editOpportunity', 'applyToOpportunity'],
  setup(props, { emit }) {

    const opportunityOptionClicked = (e: any) => {
      if (e.label === 'Edit')
      {
        emit('editOpportunity')
      }
    }

    const applyButtonClicked = () => {
      emit('applyToOpportunity')
    }

    const applyButtonText = (applyType: string) => {
      return applyType === 'Internal-Manage' ?
        applyLocales.button_apply :
        applyLocales.button_external_apply
    }

    return {
      opportunityOptionClicked,
      dropdownOptions,
      locales,
      applyLocales,
      applyButtonText,
      applyButtonClicked
    }
  }
})
