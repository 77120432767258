
import { defineComponent, PropType, reactive, ref } from 'vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { required } from '@vuelidate/validators'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from './Candidates.locales.en.json'
import { ICandidateSearchStore } from '@/views/CandidateSearchView/CandidateSearch.interfaces'

type DialogInstance = InstanceType<typeof Dialog>

export default defineComponent({
  components: {
    Dialog,
    BsTextField
  },
  props: {
    store: {
      type: Object as PropType<ICandidateSearchStore>,
      required: true
    }
  },
  setup(props) {
    const dialogRef = ref<DialogInstance>()
    const formFields = reactive({
      filterName: ''
    })
    const isToastOpen = ref<boolean>(false)
    const isSaving = ref<boolean>(false)
    const { showSuccessToast, showErrorToast } = useToast()

    const rules: ValidationArgs<any> = {
      filterName: { required }
    }
    const v$ = useVuelidate(rules, formFields)

    async function handleSubmit() {
      //handles the form values
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      isSaving.value = true
      const candidateFilterId = await props.store.actions.saveFilter(formFields.filterName)
      isSaving.value = false
      if (!candidateFilterId) {
        showErrorToast({ message: 'Unable to save filter' })
        return
      }
      props.store.actions.loadCandidatesByCandidateFilterId(candidateFilterId)
      props.store.actions.loadSavedFilters()
      close()
      isToastOpen.value = true
      showSuccessToast({
        title: locales.search_filter + ' ' + locales.saved + '!',
        message: locales.parameters_saved
      })
    }

    function open() {
      dialogRef.value?.open()
    }

    function close() {
      dialogRef.value?.close()
    }

    return {
      dialogRef,
      formFields,
      handleSubmit,
      open,
      close,
      v$,
      isToastOpen,
      locales,
      isSaving
    }
  }
})
