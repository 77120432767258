import {NonUSOpportunityTypeKey, OpportunityListing, OpportunityTypes, SalaryTypes, USOpportunityTypeKey} from '@/types/opportunity'
import { formatDateToDays } from './date'
import locales from '@/components/EmployerJobPost/EmployerJobPost.locales.en.json'

export function formatOpportunityLocation(opportunityListing?: OpportunityListing): string {
  let location = locales.location_unavailable
  if (!opportunityListing) {
    return location
  }
  if (opportunityListing.remote) {
    const country = opportunityListing.address?.country ?? opportunityListing.officeLocation?.country
    location = country ? `Remote - ${country}` : 'Remote'
  } else if (opportunityListing.address) {
    const address = opportunityListing.address
    location = [
      address.locality,
      address.administrativeArea
    ].filter(text => text).join(', ')
  } else if (opportunityListing.officeLocation) {
    const address = opportunityListing.officeLocation
    location = [
      address.municipality,
      address.countrySubdivisionName
    ].filter(text => text).join(', ')
  }
  return location
}

export function formatOpportunityCompensation(opportunityListing?: OpportunityListing) {
  if (!opportunityListing || !opportunityListing.salary) {
    return ''
  }
  const { salary, currency, salaryType } = opportunityListing
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    compactDisplay: 'long'
  })
  let compensation = `${formatter.format(salary)} (${currency})`
  const salaryTypeText = SalaryTypes.find(type => type.value === salaryType)?.text
  if (salaryTypeText) {
    compensation += ` / ${salaryTypeText}`
  }
  return compensation
}

export function formatOpportunityAge(opportunityListing?: OpportunityListing) {
  if (!opportunityListing || !opportunityListing.postedDate) {
    return ''
  }
  const age = formatDateToDays(new Date()) - formatDateToDays(opportunityListing.postedDate)

  if (age === 0) {
    return 'Today'
  }
  const text = age === 1 ? 'day ago' : 'days ago'
  return age + ' ' + text
}

export function formatOpportunityType(opportunityType: string | undefined): string {
  if (!opportunityType) {
    return ''
  }

  const opportunityTyped = opportunityType as USOpportunityTypeKey | NonUSOpportunityTypeKey

  return OpportunityTypes.find(type => type.value === opportunityTyped)?.text ?? ''
}
