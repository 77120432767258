
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import DynamicInputGenerator from './DynamicInputGenerator.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from '../CandidateProfile.locales.en.json'
import { StudentOrganizations as StudentOrganizationsOptions, CandidateProfile } from '@/types/candidate'
import { RepeatTypes } from '@/types'
import BsRadio from '@/components/BsRadio/BsRadio.vue'

export default defineComponent({
  components: {
    BsMultiSelect,
    DynamicInputGenerator,
    BsTextField,
    BsRadio
  },
  props: {
    modelValue: {
      type: Object as PropType<CandidateProfile>,
      default: ()=> Object as PropType<CandidateProfile>
    }
  },
  emits:['Update:modelValue'],
  setup(props, { emit }) {
    const formFields = ref(props.modelValue)
    const showForm = ref(false)
    const displayOption = ref(locales.law_review_journal)

    watch(()=> props.modelValue, (newVal, oldVal)=>{
      if(newVal !== oldVal){
      formFields.value = newVal
      }
    })

    watch(
      formFields,
      (newValue) => {
        emit('Update:modelValue', newValue)
      },
      { deep: true }
    )

    const toggleForm = () => showForm.value = !showForm.value

    const studentOrganizations = computed(()=>{
      let items : string[] = []

      StudentOrganizationsOptions.forEach(val=>
      {
        if((formFields.value.studentOrganizations || []).includes(val.value) && val.value !== 'Other' ) {
          items.push(val.text)
        }
      })

      if(formFields.value.otherStudentOrganization) {
        items.push(formFields.value.otherStudentOrganization)
      }

      return items
    })

    return {
      locales,
      StudentOrganizationsOptions,
      formFields,
      studentOrganizations,
      showForm,
      RepeatTypes,
      toggleForm,
      displayOption
    }
  }
})
