import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2a3c740"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "AssociateAgent"
}
const _hoisted_2 = { class: "AssociateAgent__title mb-5 animate__animated animate__fadeIn" }
const _hoisted_3 = { class: "card shadow p-5 mb-4 animate__animated animate__fadeInUp" }
const _hoisted_4 = { class: "AssociateAgent__subtitle mb-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-lg-6 mb-4" }
const _hoisted_7 = { class: "col-lg-6 mb-4" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-6 mb-4" }
const _hoisted_10 = { class: "col-lg-6 mb-4" }
const _hoisted_11 = {
  key: 0,
  class: "card shadow p-5 mb-4 animate__animated animate__fadeInUp"
}
const _hoisted_12 = { class: "AssociateAgent__subtitle mb-4" }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 1,
  class: "AssociateAgent"
}
const _hoisted_16 = { class: "d-flex justify-content-center" }
const _hoisted_17 = { class: "card shadow p-5 mb-4 animate__animated animate__fadeInUp" }
const _hoisted_18 = { class: "AssociateAgentDialog__title text-center" }
const _hoisted_19 = { class: "AssociateAgentDialog" }
const _hoisted_20 = { class: "AssociateAgentDialog__content" }
const _hoisted_21 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsPhoneNumber = _resolveComponent("BsPhoneNumber")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CodeValidation = _resolveComponent("CodeValidation")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.employerSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString($setup.locales.welcome), 1),
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString($setup.locales.enterAssociation), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agentProfile.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.agentProfile.firstName) = $event)),
                  label: $setup.locales.firstName,
                  placeholder: $setup.locales.firstName,
                  "auto-trim": true,
                  disabled: $setup.generatingOtp,
                  validation: $setup.agentProfileValidity.firstName
                }, null, 8, ["modelValue", "label", "placeholder", "disabled", "validation"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agentProfile.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.agentProfile.lastName) = $event)),
                  label: $setup.locales.lastName,
                  placeholder: $setup.locales.lastName,
                  "auto-trim": true,
                  disabled: $setup.generatingOtp,
                  validation: $setup.agentProfileValidity.lastName
                }, null, 8, ["modelValue", "label", "placeholder", "disabled", "validation"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agentProfile.emailAddress,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.agentProfile.emailAddress) = $event)),
                  label: $setup.locales.emailAddress,
                  placeholder: $setup.locales.emailAddress,
                  debounce: 1000,
                  "auto-trim": true,
                  validation: $setup.agentProfileValidity.emailAddress,
                  disabled: $setup.loadingEmployers || $setup.generatingOtp,
                  onChange: $setup.handleEmailInputChange
                }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BsPhoneNumber, {
                  modelValue: $setup.agentProfile.phoneNumber,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.agentProfile.phoneNumber) = $event)),
                  label: $setup.locales.phoneNumber,
                  "validation-scope": "associate-agent",
                  disabled: $setup.generatingOtp,
                  validation: $setup.agentProfileValidity.phoneNumber
                }, null, 8, ["modelValue", "label", "disabled", "validation"])
              ])
            ])
          ]),
          (!$setup.manualRegistration && !$setup.loadingEmployers)
            ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
                _createElementVNode("h4", _hoisted_12, _toDisplayString($setup.locales.selectEmployer), 1),
                _createVNode(_component_BsSelect, {
                  modelValue: $setup.selectedEmployer,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.selectedEmployer) = $event)),
                  class: "mb-4",
                  label: $setup.locales.employerFirm,
                  disabled: $setup.employersMatchingDomain.length === 0 || $setup.generatingOtp,
                  validation: $setup.employerValidity.selectedEmployer,
                  options: $setup.employersMatchingDomain,
                  "option-label": "text",
                  "option-value": "value"
                }, null, 8, ["modelValue", "label", "disabled", "validation", "options"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_router_link, {
              to: "/SelectUserType",
              class: "btn btn-outline-primary me-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.locales.back), 1)
              ]),
              _: 1
            }),
            _createElementVNode("button", {
              class: "btn btn-primary",
              disabled: $setup.loadingEmployers || $setup.generatingOtp,
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.createAssociation && $setup.createAssociation(...args)))
            }, _toDisplayString($setup.locales.next), 9, _hoisted_14)
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.employerSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("section", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_CodeValidation, {
                class: "m-md-5",
                email: $setup.agentProfile.emailAddress,
                disabled: $setup.disableOptInput,
                onValidationChallenge: $setup.verifyOtp,
                onValidated: $setup.handleValidOtp,
                onInvalidated: $setup.handleInvalidOtp,
                onResend: $setup.generateOtp
              }, null, 8, ["email", "disabled", "onValidationChallenge", "onValidated", "onInvalidated", "onResend"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_prime_dialog, {
      visible: $setup.displayManualDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (($setup.displayManualDialog) = $event)),
      modal: true,
      style: { width: '640px' },
      breakpoints: { '640px': '100vw' }
    }, {
      header: _withCtx(() => [
        _createElementVNode("h2", _hoisted_18, _toDisplayString($setup.locales.newDomainMessage), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            class: "btn btn-outline-primary me-2",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($setup.handleCloseDialog && $setup.handleCloseDialog(...args)))
          }, _toDisplayString($setup.locales.cancel), 1),
          _createElementVNode("button", {
            class: "btn btn-primary ms-2",
            target: "_blank",
            autofocus: "",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($setup.handleRegisterEmployer && $setup.handleRegisterEmployer(...args)))
          }, _toDisplayString($setup.locales.continue), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("p", _hoisted_20, _toDisplayString($setup.locales.questionsMessage), 1)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}