import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpportunityDetails = _resolveComponent("OpportunityDetails")!

  return (_openBlock(), _createBlock(_component_OpportunityDetails, {
    id: _ctx.id + '_OpportunityDetails',
    ref: "opportunityDetails",
    opportunity: _ctx.jobOpportunity,
    "preview-mode": true
  }, null, 8, ["id", "opportunity"]))
}