

export default {
  props: {
    youTubeVideoId : {
      type: String,
      required: true,
      default: null
    }
  },
  setup() {
    return { }
  }
}
