
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch } from 'vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Dialog, { DialogInstance } from '@/components/Shared/DialogWrapper.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import ToastQueue from '@/components/ToastQueue/ToastQueue.vue'
import locales from './Candidates.locales.en.json'
import { InviteCandidateFormFields } from './Candidates.interface'
import { JobOpportunity } from '@/types/opportunity'
import { Configuration } from '@/config/config'
import { Candidate } from '@/types/candidate'
import { GetEmployerProfile } from '@/api/EmployerApi'
import { EmployerProfile } from '@/types/employer'

export default defineComponent({
  components: {
    Dialog,
    BsSelect,
    BsCheckbox,
    TextEditor,
    ToastQueue
  },
  props: {
    candidate: {
      type: Object as PropType<Candidate>,
      required: true
    },
    employerOpportunities: {
      type: Object as PropType<Array<JobOpportunity>>,
      required: true
    }
  },
  emits: ['send', 'cancel'],
  //@ts-ignore no-implicit-any
  setup(props, { emit }) {
    const dialogRef = ref<DialogInstance>()
    const isToastOpen = ref<boolean>(false)
    const formFields = reactive<InviteCandidateFormFields>({
      opportunityId: '',
      messageContent: '',
      includeLink: false
    })
    const employer = ref({ name: '[Organization Name]' } as EmployerProfile)
    const opportunity = ref(
      {
        id: '[Opportunity Id]',
        opportunityListing: {
          title: '[Opportunity Title]',
          description: '[Opportunity Description]',
          recruiterContact: '[Recruiter Name]',
          recruiterTitle: '[Recruiter Title]',
          recruiterEmail: '[Recruiter Email]'
        }
      } as JobOpportunity
    )

    const rules = {
      opportunityId: { required },
      messageContent: { required }
    } as ValidationArgs<any>

    const v$ = useVuelidate(rules, formFields)

    onMounted(async () => {
      await getEmployerInfo()
      formFields.messageContent = getDefaultMessage()
      dialogRef.value?.open()
    })

    async function getEmployerInfo() {
      const employerResponse = await GetEmployerProfile()
      if (employerResponse.id) {
        employer.value = employerResponse
      }
    }

    const getDefaultMessage = (linkText: string = '') => {
      return `<p>Hi ${props.candidate.profileData.firstName} –</p>` +
        `<p>My name is ${opportunity.value.opportunityListing?.recruiterContact} from ${employer.value.name}. I reviewed your profile on the BARBRI Job Network and am very impressed with your credentials and would like to invite you to apply for this opportunity:</p>` +
        `<p>Title: ${opportunity.value.opportunityListing?.title}</p>` +
        `<p>Description: ${opportunity.value.opportunityListing?.description}</p>` +
        linkText +
        '<p>If you are interested and agree that you might be a fit, you can apply through the link on that page. In the meantime, if you have any questions, please do not hesitate to contact me directly.</p>' +
        '<p>Best,</p>' +
        `<p>${opportunity.value.opportunityListing?.recruiterContact}</p>` +
        `<p>${opportunity.value.opportunityListing?.recruiterTitle}</p>` +
        `<p>${employer.value.name}</p>`+
        `<p>${opportunity.value.opportunityListing?.recruiterEmail}</p>`
    }

    const linkText = computed(() => {
      return `<p><a target="_blank" rel="noopener noreferrer nofollow" href="${Configuration.JOBNETWORK_SITE_URL}/OpportunityPreview/${opportunity.value.id}">Link to ${opportunity.value.opportunityListing?.title}</a></p>`
    })

    watch(() => formFields.includeLink, (includeLink: Boolean) => {
      if (includeLink && formFields.messageContent === getDefaultMessage()) {
        formFields.messageContent = getDefaultMessage(linkText.value)
      } else if (includeLink && !formFields.messageContent.includes(linkText.value)) {
        formFields.messageContent += linkText.value
      } else if (!includeLink) {
        formFields.messageContent = formFields.messageContent.replace(linkText.value, '')
      }
    })

    watch(() => formFields.opportunityId, (newVal: string) => {
      if (newVal) {
        const findOpportunity = (props.employerOpportunities as JobOpportunity[]).find(opp => opp.id === newVal)
        if (findOpportunity?.opportunityListing) {
          opportunity.value = findOpportunity
          formFields.messageContent = formFields.includeLink ? getDefaultMessage(linkText.value) : getDefaultMessage()
        }
      }
    })

    function handleSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('send', { opportunityId: formFields.opportunityId, message: formFields.messageContent, title: opportunity.value.opportunityListing?.title })
      toggleDialog(false)
      isToastOpen.value = true
    }

    function toggleDialog(status: boolean) {
      status ? dialogRef.value?.open() : dialogRef.value?.close()
      emit('cancel')
    }

    const invitableOpportunities = computed(() => 
      props.employerOpportunities
        .filter((eo: JobOpportunity) => !eo.candidatesInvitedToApply.includes(props.candidate.id ?? ''))
        .filter((eo: JobOpportunity) => !!eo && !!eo.opportunityListing)
        .map((opp: JobOpportunity) => ({ label: opp.opportunityListing!.title, value: opp.id }))
    )

    return {
      dialogRef,
      locales,
      formFields,
      handleSubmit,
      toggleDialog,
      v$,
      isToastOpen,
      invitableOpportunities
    }
  }
})
