import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppPage = _resolveComponent("AppPage")!
  const _component_ToastQueue = _resolveComponent("ToastQueue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppPage, null, {
      header: _withCtx(() => [
        _createVNode(_component_nav_bar)
      ]),
      main: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_ToastQueue)
  ], 64))
}