import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb7894ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "experience-wrapper" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 col-sm-12" }
const _hoisted_4 = { class: "col-md-6 col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsSelect = _resolveComponent("BsSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsTextField, {
      id: `law-school-experience-title-${_ctx.idIndex}`,
      modelValue: _ctx.reactiveWorkExperience.positionTitle,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reactiveWorkExperience.positionTitle) = $event)),
      class: "mb-3",
      label: _ctx.locales.title_position,
      validation: _ctx.v$.form.positionTitle,
      maxlength: "100"
    }, null, 8, ["id", "modelValue", "label", "validation"]),
    _createVNode(_component_BsTextField, {
      id: `law-school-experience-employer-${_ctx.idIndex}`,
      modelValue: _ctx.reactiveWorkExperience.candidateEmployer,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reactiveWorkExperience.candidateEmployer) = $event)),
      class: "mb-3",
      label: _ctx.locales.employer,
      validation: _ctx.v$.form.candidateEmployer,
      maxlength: "100"
    }, null, 8, ["id", "modelValue", "label", "validation"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BsSelect, {
          modelValue: _ctx.reactiveWorkExperience.startYear,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reactiveWorkExperience.startYear) = $event)),
          validation: _ctx.v$.form.startYear,
          label: _ctx.locales.start_date,
          options: [
            {
              text: _ctx.locales.please_select,
              value: '',
              disabled: true
            },
            ..._ctx.GetValidYears()
          ],
          "option-label": "text",
          "option-value": "value",
          "option-disabled": "disabled",
          "default-selected-value": ""
        }, null, 8, ["modelValue", "validation", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BsSelect, {
          modelValue: _ctx.reactiveWorkExperience.endYear,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reactiveWorkExperience.endYear) = $event)),
          validation: _ctx.v$.endYearIsValid,
          label: _ctx.locales.end_date_optional,
          options: [
            {
              text: _ctx.locales.current_workplace,
              value: '',
            },
            ..._ctx.GetValidYears()
          ],
          "option-label": "text",
          "option-value": "value",
          "default-selected-value": ""
        }, null, 8, ["modelValue", "validation", "label", "options"])
      ])
    ])
  ]))
}