import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "d-flex justify-content-end" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["aria-label", "aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BubbleMenu = _resolveComponent("BubbleMenu")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!
  const _component_EditorContent = _resolveComponent("EditorContent")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["TextEditor", {
      'bs-invalid-field': _ctx.dirty && _ctx.invalid,
      'is-invalid-editor': _ctx.dirty && _ctx.invalid
    }]),
        "data-disabled": _ctx.disabled
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.id,
              class: "form-label bs-field-label"
            }, _toDisplayString(_ctx.label), 9, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.editor)
          ? (_openBlock(), _createBlock(_component_BubbleMenu, {
              key: 1,
              editor: _ctx.editor
            }, null, 8, ["editor"]))
          : _createCommentVNode("", true),
        (_ctx.editor)
          ? (_openBlock(), _createBlock(_component_FloatingMenu, {
              key: 2,
              editor: _ctx.editor
            }, null, 8, ["editor"]))
          : _createCommentVNode("", true),
        _createVNode(_component_EditorContent, {
          id: _ctx.id,
          editor: _ctx.editor,
          class: "editor__content",
          "aria-describedby": _ctx.invalidMessage && _ctx.id + '__invalid_message'
        }, null, 8, ["id", "editor", "aria-describedby"]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.dirty)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                id: _ctx.id + '__invalid_message',
                class: "invalid-label"
              }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.limitExist && _ctx.editable && !_ctx.disabled)
            ? (_openBlock(), _createElementBlock("label", {
                key: 1,
                class: "text-end m-1 character-counter",
                "aria-live": "polite",
                "aria-atomic": "true",
                "aria-label": `${ _ctx.count } characters left out of ${ _ctx.limit }`,
                "aria-describedby": _ctx.id
              }, " Characters: " + _toDisplayString(_ctx.count) + " / " + _toDisplayString(_ctx.limit), 9, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}