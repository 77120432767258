
import { onMounted, ref, Ref } from 'vue'
import { IsValidEmail } from '@/types/validation/genericValidation'
import { SendOtp, UpsertAgentProfile, VerifyOtp } from '@/api/AgentApi'
import { BuildAgentProfileFromToken, oktaAuth } from '@/okta'
import { TextValue } from '@/types'
import { GetEmployersByDomain } from '@/api/EmployerApi'
import { AgentProfile } from '@/types/agent'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsPhoneNumber from '@/components/BsPhoneNumberDropdown/BsPhoneNumber.vue'
import CodeValidation from '@/components/CodeValidation/CodeValidation.vue'
import useVuelidate, {ValidationArgs} from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { ICodeValidationChallengeEvent } from '@/components/CodeValidation/CodeValidation.interfaces'
import locales from './NewAgent/NewAgent.locales.en.json'

export default {
  components: {
    BsTextField,
    BsSelect,
    BsPhoneNumber,
    CodeValidation
  },
  setup() {
    const router = useRouter()
    const { showErrorToast, showSuccessToast, showInfoToast } = useToast()
    const agentProfile = ref({}) as Ref<AgentProfile>
    const generatingOtp = ref(false)
    const emailEntered = ref(false)
    const loadingEmployers = ref(false)
    const employersMatchingDomain = ref<Array<TextValue<string>>>([])
    const selectedEmployer = ref('')
    const employerSelected = ref(false)
    const validatingOtp = ref(false)
    const disableOptInput = ref(false)
    const manualRegistration = ref(true)
    const displayManualDialog = ref(false)

    const agentProfileRules = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email: helpers.withMessage(
          'Invalid email address',
          IsValidEmail
        )
      },
      phoneNumber: {
        required
      }
    } as ValidationArgs<any>

    const employerRules = {
      selectedEmployer: {
        required
      }
    } as ValidationArgs<any>

    const agentProfileValidity = useVuelidate(agentProfileRules, agentProfile, { $scope: 'associate-agent' })
    const employerValidity = useVuelidate(employerRules, { selectedEmployer })

    onMounted(async () => {
      agentProfile.value = await BuildAgentProfileFromToken()
      await handleEmailInputChange()
    })

    function extractDomainFromEmail(emailAddress: string) {
      const [, domain = ''] = emailAddress.split('@')
      return domain
    }

    async function handleEmailInputChange() {
      agentProfileValidity.value.emailAddress.$touch()
      const isValid = await agentProfileValidity.value.emailAddress.$validate()
      if (!isValid) {
        return
      }
      loadingEmployers.value = true
      manualRegistration.value = false
      try {
        const domain = extractDomainFromEmail(agentProfile.value.emailAddress)
        const employers = await GetEmployersByDomain(domain)
        employersMatchingDomain.value = employers.map((emp) => ({ text: emp.name, value: emp.id }))
        showInfoToast({
          title: '',
          message: employersMatchingDomain.value.length
            ? 'A list of available employers has been loaded'
            : 'There are no available employers that match your email domain'
        })
      } catch {
        showErrorToast({
          message: 'There was an error getting employers that match your email. Please try again'
        })
        agentProfile.value.emailAddress = ''
        return
      } finally {
        loadingEmployers.value = false
      }

      if (!employersMatchingDomain.value.length) {
        manualRegistration.value = true
      }
    }

    function handleCloseDialog() {
      displayManualDialog.value = false
    }

    function handleRegisterEmployer() {
      router.push({
        name: 'RegisterEmployer',
        params: {
          firstName: agentProfile.value.firstName,
          lastName: agentProfile.value.lastName,
          emailAddress: agentProfile.value.emailAddress,
          phoneNumber: agentProfile.value.phoneNumber
        }
      })
    }

    const createAssociation = async () => {
      if (manualRegistration.value) {
        displayManualDialog.value = true
        return
      }
      agentProfileValidity.value.$touch()
      employerValidity.value.$touch()
      const isValidAgentProfile = await agentProfileValidity.value.$validate()
      const isValidEmployer = await employerValidity.value.$validate()

      if (!isValidAgentProfile || !isValidEmployer) {
        showErrorToast({
          message: 'Please make sure all fields are filled in correctly'
        })
        return
      }

      try {
        generatingOtp.value = true

        await UpsertAgentProfile(agentProfile.value, selectedEmployer.value)
        await generateOtp()
      } catch {
        showErrorToast({
          message: 'There was an error creating your association. Please try again'
        })
      } finally {
        generatingOtp.value = false
      }
    }

    const generateOtp = async () => {
      try {
        generatingOtp.value = true
        await SendOtp(selectedEmployer.value)
        employerSelected.value = true
        showSuccessToast({
          message: 'A new code has been sent to ' + agentProfile.value.emailAddress
        })
      } catch {
        showErrorToast({
          message: 'There was an error generating your verification email. Please try again'
        })
      } finally {
        generatingOtp.value = false
      }
    }

    const verifyOtp = async (event: ICodeValidationChallengeEvent) => {
      validatingOtp.value = true
      disableOptInput.value = true

      try {
        const isOtpValid = await VerifyOtp(event.code, selectedEmployer.value)
        event.resolve(isOtpValid)
      } catch {
        showErrorToast({
          message: 'There was an error verifying the code. Please try again'
        })
        return
      } finally {
        validatingOtp.value = false
      }
    }

    async function handleValidOtp() {
      //The user could have been associated with an employer so get a fresh token
      const res = await oktaAuth.token.getWithoutPrompt()
      oktaAuth.tokenManager.setTokens(res.tokens)

      router.push('/EmployerJobOpportunitiesSummary')
    }

    async function handleInvalidOtp() {
      showErrorToast({
        message: 'Invalid code provided. Please try again, or generate a new code'
      })
      disableOptInput.value = false
    }

    return {
      agentProfile,
      generatingOtp,
      emailEntered,
      loadingEmployers,
      employersMatchingDomain,
      selectedEmployer,
      employerSelected,
      disableOptInput,
      validatingOtp,
      manualRegistration,
      displayManualDialog,
      agentProfileValidity,
      employerValidity,
      locales,
      createAssociation,
      generateOtp,
      verifyOtp,
      handleValidOtp,
      handleInvalidOtp,
      handleEmailInputChange,
      handleCloseDialog,
      handleRegisterEmployer
    }
  }
}
