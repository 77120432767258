import { DropdownOptionModel, MenuItemsModel } from './Header.interface'
import locales from './Header.locales.en.json'

export const employerDropdownOptions: DropdownOptionModel[] = [
  { label: locales.agent_settings, icon: 'bi-bag-plus', key: 'settings' },
  { label: locales.employer_view, icon: 'bi-building', key: 'employerProfile' },
  { label: locales.logout, icon: 'bi-box-arrow-in-right', key: 'logout' }
]

export const candidateDropdownOptions: DropdownOptionModel[] = [
  { label: locales.candidate_profile, icon: 'bi-newspaper', key: 'candidateProfile' },
  { label: locales.logout, icon: 'bi-box-arrow-in-right', key: 'logout' }
]

export const candidateMenuItems: MenuItemsModel[] = [
  { label: locales.home, path: '/' },
  { label: locales.profile, path: '/CandidateProfile' }
]

export const employerMenuItems: MenuItemsModel[] = [
  { label: locales.opportunities, path: '/EmployerJobOpportunitiesSummary', key: 'opportunities' },
  { label: locales.find_candidates, path: '/CandidateSearch', key: 'candidate' }
]

export const adminMenuItems: MenuItemsModel[] = [
  { label: locales.dashboard, path: '/Dashboard', key: 'dashboard' }
]

export const unauthenticatedMenuItems: MenuItemsModel[] = [
  { label: locales.home, path: '/' }
]
