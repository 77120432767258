

export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits:['update:modelValue'],
  setup(props: any, context: any) {
    const focusNext = async (event: any) => {
      if (event.srcElement.maxLength !== event.srcElement.value.length) {
        return
      }

      let nextControl  = event.srcElement.nextElementSibling
      if (nextControl?.type === event.srcElement.type) {
        nextControl.focus()
      }
    }

    const setValue = (event: any) => {
      const cleanedValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(.*)\./g, '')
      event.target.value = cleanedValue
      context.emit('update:modelValue', cleanedValue)
      focusNext(event)
    }

    const clearValue = (event: any) => {
      if(!isFinite(event.key)){
        return
      }

      event.target.value = ''
      context.emit('update:modelValue', '')
    }

    return {
      setValue,
      clearValue
    }
  }
}
