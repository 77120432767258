import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3976702a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "employer-profile-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_employer_profile_component = _resolveComponent("employer-profile-component")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    ref: "dialogRef",
    class: "employer-profile-dialog",
    "aria-label": "Employer Profile Candidate View",
    horizontal: "end",
    vertical: "start"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_employer_profile_component, {
          "employer-details": _ctx.employerDetails,
          "persona-view": true,
          "show-as-dialog": true,
          "logo-image-source": _ctx.logoImageSource,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModal(false)))
        }, null, 8, ["employer-details", "logo-image-source"])
      ])
    ]),
    _: 1
  }, 512))
}