
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { createAutoIncrementId } from '@/utils/VueTools'
import { injectEmployerJobPostBoardStore } from './EmployerJobPostBoard.utils'
import EmployerJobPostGrid from './EmployerJobPostGrid.vue'
import tabComponents from '@/components/CustomBootstrap/BsTab'
import JobOpportunitiesTableFilter from '@/components/JobOpportunitiesTableFilter/JobOpportunitiesTableFilter.vue'
import { IEmployerJobPostStatusViewModel, IEmployerJobPostViewModel } from './EmployerJobPost.interfaces'
import { RouteLocationRaw } from 'vue-router'
import locales from './EmployerJobPost.locales.en.json'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { OpportunityFilter } from '@/types/opportunity'
import { hasDefaultFlag, withDefaultFlag } from '@/types'

export const EmployerJobPostBoard = defineComponent({
  name: 'EmployerJobPostBoard',
  components: { ...tabComponents, EmployerJobPostGrid, JobOpportunitiesTableFilter },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('EmployerJobPostBoard')
    },
    postJobOpportunityRoute: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: () => '/'
    }
  },
  setup() {
    const store = injectEmployerJobPostBoardStore()
    const globalStore = useStore()
    const router = useRouter()
    const filterDialogIsOpened = ref(false)

    const createDefaultOpportunityFilter = () => withDefaultFlag<OpportunityFilter>({
      postedWithinHours: 0,
      opportunityTypes: [],
      requirementsToApply: [],
      remote: false,
      countryCode: '',
      locations: []
    })
    const filter = ref<OpportunityFilter>(createDefaultOpportunityFilter())

    onMounted(() => {
      store.actions.loadJobStatuses()
    })

    const handleJobStatusSelected = (status: IEmployerJobPostStatusViewModel) => {
      store.state.jobStatuses.forEach(jobStatus => jobStatus.isActive = (jobStatus.name == status.name))
      filter.value = status.filter ?? createDefaultOpportunityFilter()

      if (!status.jobOpportunities.length) {
        handleShowMore(status)
      }
    }

    const handleShowMore = (status: IEmployerJobPostStatusViewModel) => {
      status.currentPage += 1
      store.actions.loadJobPostsByStatus(status)
    }

    const createTabId = (id: string, index: number) => {
      return id + '__tab_' + index
    }

    const handleJobPostSelected = (jobPost: IEmployerJobPostViewModel) => {
      const jobStatus = store.state.jobStatuses.find(status => status.name === jobPost.status)
      globalStore.state.jobOpportunity = jobStatus?.jobOpportunities.find(jobOpportunity => jobOpportunity.id === jobPost.opportunityId)
      router.push({ path: '/Applicants', query: { opportunityId: jobPost.opportunityId } })
    }

    const handleEditJobPost = (jobPost: IEmployerJobPostViewModel) => {
      const jobStatus = store.state.jobStatuses.find(status => status.name === jobPost.status)
      globalStore.state.jobOpportunity = jobStatus?.jobOpportunities.find(jobOpportunity => jobOpportunity.id === jobPost.opportunityId)
      router.push({ path: '/PostJobOpportunity', query: { opportunityId: jobPost.opportunityId } })
    }

    watch(filter, (newVal) => {
      const status = store.state.jobStatuses.find(js => js.isActive)
      if (hasDefaultFlag(newVal)       // The reset case is handled by filterReset()
          || !status                   // If the status can't be found (should never happen)
          || status.filter === newVal) // If the filter is the same, the work has already been done
      {
        return
      }

      resetPostsForStatus(status, newVal)
    })

    const resetPostsForStatus = (status: IEmployerJobPostStatusViewModel, updatedFilter: undefined | OpportunityFilter) => {
      status.filter = updatedFilter
      status.jobOpportunities = []
      status.posts = []
      status.currentPage = 0
      handleShowMore(status)
    }

    const filterReset = () => {
      filterDialogIsOpened.value = false
      filter.value = createDefaultOpportunityFilter()
      const status = store.state.jobStatuses.find(js => js.isActive)
      if (!status?.filter) {
        return
      }
      resetPostsForStatus(status, undefined)
    }

    return {
      locales,
      store,
      filter,
      filterDialogIsOpened,
      createTabId,
      filterReset,
      handleJobStatusSelected,
      handleShowMore,
      handleJobPostSelected,
      handleEditJobPost
    }
  }
})

export default EmployerJobPostBoard
