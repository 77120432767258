
import { onMounted, watch, defineComponent, computed, ref } from 'vue'
import JobOpportunitiesTable from '@/components/JobOpportunitiesTable/JobOpportunitiesTable.vue'
import JobOpportunitiesTableFilter from '@/components/JobOpportunitiesTableFilter/JobOpportunitiesTableFilter.vue'
import PaginationBar from '@/components/PaginationBar.vue'
import CandidateOpportunityStatusSelector from '@/components/CandidateOpportunityStatusSelector/CandidateOpportunityStatusSelector.vue'
import { CandidateOpportunityStatus } from '@/types/candidate'
import locales from './JobOpportunitiesSummary.locales.en.json'
import bsTabComponents from '@/components/CustomBootstrap/BsTab'
import { injectJobOpportunitiesSummaryStore } from './JobOpportunitiesSummary.store'
import { useStore } from 'vuex'
import { GetUserId } from '@/okta'
import { redirectToSSU } from '@/signin'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { JobOpportunity, OpportunityListing } from '@/types/opportunity'
import { IJobOpportunityRowViewModel } from './JobOpportunitiesSummary.interface'
import ApplyOpportunityForm from '@/components/ApplyToOpportunity/ApplyOpportunityForm.vue'
import OpportunityDetails from '@/components/ApplyToOpportunity/OpportunityDetails.vue'
import store from '@/store'

export default defineComponent({
  components: {
    CandidateOpportunityStatusSelector,
    JobOpportunitiesTableFilter,
    JobOpportunitiesTable,
    PaginationBar,
    ApplyOpportunityForm,
    OpportunityDetails,
    ...bsTabComponents
  },
  setup() {
    const applyOpportunityForm = ref<InstanceType<typeof ApplyOpportunityForm>>()
    const opportunityDetails = ref<InstanceType<typeof OpportunityDetails>>()
    const globalStore = useStore()
    const { state, actions } = injectJobOpportunitiesSummaryStore()
    const userFirstName = computed<string>(() => globalStore.getters.userFirstName)
    const { showErrorToast, showInfoToast } = useToast()
    const opportunityToApplyTo = ref<JobOpportunity>({} as JobOpportunity)

    onMounted(async () => {
      const promises: Promise<unknown>[] = []
      promises.push(actions.loadOpportunities())
      if (state.authenticated) {
        promises.push(actions.loadOpportunityCounts())
        promises.push(actions.loadCandidateFileDescriptions())
      }
      await Promise.all(promises)
    })

    watch(() => state.currentPage, () => actions.loadOpportunities())

    watch(() => state.filter, () => actions.loadOpportunities())

    async function handleFilterApplied(): Promise<void> {
      await actions.loadFirstPage()
    }

    async function handleStatusSelected(status: CandidateOpportunityStatus) {
      state.selectedStatus = status
      actions.resetFilter()
      await actions.loadFirstPage()
    }

    function verifyOpportunityHasExpired(opportunityListing: OpportunityListing) {
    if (!opportunityListing.applicationDeadline) {
      return false
    }
    const currentDate = new Date()
    const applicationDeadline = new Date(opportunityListing.applicationDeadline)
    return applicationDeadline < currentDate
  }

  async function openOpportunityDetailsToApply(row: IJobOpportunityRowViewModel) {
    if(!await GetUserId()){
      showInfoToast({
        title: locales.message_redirecting_to_ssu_title,
        message: locales.message_redirecting_to_ssu
      })
      await redirectToSSU()
      return
    }

    const jobOpportunity = state.opportunities.find(opportunity => opportunity.id === row.opportunityId)
    if (!jobOpportunity || !jobOpportunity.opportunityListing) {
      showErrorToast({
        message: locales.message_unmanageable_opportunity
      })
      return
    }
    if (verifyOpportunityHasExpired(jobOpportunity.opportunityListing)) {
      showErrorToast({
        message: locales.message_expired_opportunity
      })
      return
    }

    store.state.applicationInsights?.trackPageView({name: `/OpportunityPreview/${jobOpportunity.id}`})
    opportunityToApplyTo.value = jobOpportunity
    opportunityDetails.value!.toggleModal(true)
  }

  async function openApplyDialog(){
    switch(opportunityToApplyTo.value.opportunityListing?.applyType) {
        case 'EmailReceive':
          // We're specifically not creating an Application in JN because this is externally managed
          window.open('mailto:' + opportunityToApplyTo.value.opportunityListing.applicationReceiveEmail, '_self')
          break
        case 'ExternalDirect':
          // We're specifically not creating an Application in JN because this is externally managed
          window.open(opportunityToApplyTo.value.opportunityListing.externalApplicationUrl, '_blank')
          break
        case 'InternalManage':
          await applyOpportunityForm.value!.setCandidateValues()
          applyOpportunityForm.value!.toggleModal(true)
          break
        default:
          console.error('Opportunity listing does not have a valid apply type!')
      }
  }

  function applyToOpportunity(){
    markOpportunityAsApplied()
  }

  function markOpportunityAsApplied(){
    const jobOpportunity = state.opportunities.find(opportunity => opportunity.id === opportunityToApplyTo.value.id)
    jobOpportunity!.hasAppliedTo = true
    state.opportunityCountsByStatus.applied += 1
  }

    return {
      applyOpportunityForm,
      opportunityDetails,
      state,
      actions,
      locales,
      userFirstName,
      handleStatusSelected,
      handleFilterApplied,
      openOpportunityDetailsToApply,
      opportunityToApplyTo,
      openApplyDialog,
      applyToOpportunity
    }
  }
})
