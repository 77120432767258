import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0501a8e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src", "data-loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "rounded-circle",
    alt: _ctx.alt,
    src: _ctx.profilePictureUrl,
    "data-loading": _ctx.isLoading,
    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.useDefaultImage && _ctx.useDefaultImage(...args)))
  }, null, 40, _hoisted_1))
}