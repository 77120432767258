import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3769e05b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card-content" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "card-content" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "checkbox-area mb-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "instruction-content" }
const _hoisted_9 = { class: "instruction-content" }
const _hoisted_10 = { class: "instruction-content" }
const _hoisted_11 = { class: "instruction-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsRadio = _resolveComponent("BsRadio")!
  const _component_DiversityDisclosureUSForm = _resolveComponent("DiversityDisclosureUSForm")!
  const _component_DiversityDisclosureUKForm = _resolveComponent("DiversityDisclosureUKForm")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", null, _toDisplayString(_ctx.locales.loading), 1)
        ])
      ]))
    : (_ctx.errorLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.locales.error_content), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BsRadio, {
              modelValue: _ctx.country,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.country) = $event)),
              class: "mb-3",
              label: "I reside in the United States",
              value: "US"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BsRadio, {
              modelValue: _ctx.country,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.country) = $event)),
              class: "mb-3",
              label: "I reside outside the United States",
              value: "UK"
            }, null, 8, ["modelValue"])
          ]),
          (!_ctx.viewOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.locales.disclosure_message_1), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.locales.disclosure_message_2), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.locales.disclosure_message_3), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.locales.please_select), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.country === 'US')
            ? (_openBlock(), _createBlock(_component_DiversityDisclosureUSForm, {
                key: 1,
                details: _ctx.diversityDisclosureRef,
                "view-only": _ctx.viewOnly,
                "show-buttons": _ctx.showButtons
              }, null, 8, ["details", "view-only", "show-buttons"]))
            : (_ctx.country === 'UK')
              ? (_openBlock(), _createBlock(_component_DiversityDisclosureUKForm, {
                  key: 2,
                  details: _ctx.diversityDisclosureRef,
                  "view-only": _ctx.viewOnly,
                  "show-buttons": _ctx.showButtons
                }, null, 8, ["details", "view-only", "show-buttons"]))
              : _createCommentVNode("", true)
        ]))
}