
import { defineComponent, ref, onMounted, watch } from 'vue'
import locales from './DiversityDisclosure.locales.en.json'
import {
  DiversityDisclosureUS,
  DiversityDisclosureUK,
  Candidate
} from '@/types/candidate'
import { GetCandidate } from '@/api/CandidateApi'
import DiversityDisclosureUSForm from './DiversityDisclosureUSForm.vue'
import DiversityDisclosureUKForm from './DiversityDisclosureUKForm.vue'
import BsRadio from '@/components/BsRadio/BsRadio.vue'

export default defineComponent({
  components: {
    DiversityDisclosureUSForm,
    DiversityDisclosureUKForm,
    BsRadio
  },
  props: {
    viewOnly:  {
      type: Boolean,
      required: false
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  emits: ['us-disclosure-updated', 'uk-disclosure-updated'],
  setup(_props, { emit }) {
    const candidate = ref({} as Candidate)
    const loading = ref<boolean>(true)
    const errorLoading = ref<boolean>(false)
    const diversityDisclosureRef = ref<DiversityDisclosureUS | DiversityDisclosureUK>()
    const country = ref('US')

    const getDisclosureData = async () => {
      try {
        candidate.value = await GetCandidate()
        switch (country.value) {
          case 'US':
            diversityDisclosureRef.value =
              candidate.value?.profileData?.candidateDiversityDisclosure
                ?.usDisclosure ?? ({} as DiversityDisclosureUS)
            break

          case 'UK':
            diversityDisclosureRef.value =
              candidate.value?.profileData?.candidateDiversityDisclosure
                ?.ukDisclosure ?? ({} as DiversityDisclosureUK)
            break

          default:
            return
        }
      } catch {
        errorLoading.value = true
      } finally {
        loading.value = false
      }
    }

    onMounted(getDisclosureData)

    watch(() => country.value, getDisclosureData)

    watch(() => diversityDisclosureRef.value, (newVal: DiversityDisclosureUS | DiversityDisclosureUK | undefined) => {
      if (country.value === 'US') {
        emit('us-disclosure-updated', newVal)
      } else if (country.value === 'UK') {
        emit('uk-disclosure-updated', newVal)
      }
    },
    { deep: true })

    return {
      locales,
      loading,
      errorLoading,
      diversityDisclosureRef,
      country
    }
  }
})
