import { AgentProfile } from '@/types/agent'
import { Models } from 'azure-maps-rest'

function IsValidAgentProfile(agentProfile: AgentProfile): boolean {
  return IsValidOfficeLocationList(agentProfile.officeLocations)
}

function IsValidOfficeLocationList(
  locations: Array<Models.SearchResultAddress>
): boolean {
  return !!locations && locations.length > 0
}

export { IsValidAgentProfile }
