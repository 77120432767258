import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eef7a47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "RegisterEmployer"
}
const _hoisted_2 = { class: "RegisterEmployer__title mb-5 animate__animated animate__fadeIn" }
const _hoisted_3 = { class: "card shadow p-5 mb-4 animate__animated animate__fadeInUp" }
const _hoisted_4 = { class: "RegisterEmployer__subtitle mb-4" }
const _hoisted_5 = { class: "row mb-4" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row mb-4" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "row mb-4" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  key: 1,
  class: "RegisterEmployer"
}
const _hoisted_20 = { class: "RegisterEmployer__title text-center" }
const _hoisted_21 = { class: "row justify-content-center" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "card shadow p-5" }
const _hoisted_24 = { class: "RegisterEmployer__subtitle mb-4 text-center" }
const _hoisted_25 = { class: "RegisterEmployerDialog__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsPhoneNumber = _resolveComponent("BsPhoneNumber")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.registrationRequestSent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString($setup.locales.welcome), 1),
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString($setup.locales.registerEmployer), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agent.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.agent.firstName) = $event)),
                  label: $setup.locales.firstName,
                  placeholder: $setup.locales.firstName,
                  "auto-trim": true,
                  validation: $setup.agentValidity.firstName
                }, null, 8, ["modelValue", "label", "placeholder", "validation"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agent.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.agent.lastName) = $event)),
                  label: $setup.locales.lastName,
                  placeholder: $setup.locales.lastName,
                  "auto-trim": true,
                  validation: $setup.agentValidity.lastName
                }, null, 8, ["modelValue", "label", "placeholder", "validation"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.agent.emailAddress,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.agent.emailAddress) = $event)),
                  label: $setup.locales.emailAddress,
                  placeholder: $setup.locales.emailAddress,
                  "auto-trim": true,
                  validation: $setup.agentValidity.emailAddress
                }, null, 8, ["modelValue", "label", "placeholder", "validation"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BsPhoneNumber, {
                  modelValue: $setup.agent.phoneNumber,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.agent.phoneNumber) = $event)),
                  label: $setup.locales.phoneNumber,
                  validation: $setup.agentValidity.phoneNumber
                }, null, 8, ["modelValue", "label", "validation"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.employer.name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.employer.name) = $event)),
                  label: $setup.locales.employerName,
                  placeholder: $setup.locales.employerName,
                  "auto-trim": true,
                  validation: $setup.employerValidity.name
                }, null, 8, ["modelValue", "label", "placeholder", "validation"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_BsTextField, {
                  modelValue: $setup.websiteUrl,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.websiteUrl) = $event)),
                  label: $setup.locales.employerWebsite,
                  placeholder: $setup.locales.employerWebsite,
                  "auto-trim": true,
                  validation: $setup.employerValidity.website
                }, null, 8, ["modelValue", "label", "placeholder", "validation"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_BsSelect, {
                  id: "register-employer-employer-type",
                  modelValue: $setup.employer.employerType,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.employer.employerType) = $event)),
                  class: "mb-3",
                  label: $setup.locales.employerType,
                  validation: $setup.employerValidity.employerType,
                  options: $setup.EmployerTypes,
                  "option-label": "text",
                  "option-value": "value"
                }, null, 8, ["modelValue", "label", "validation", "options"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_BsAutoComplete, {
                  id: "register-employer-office-locations",
                  modelValue: $setup.selectedCities,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.selectedCities) = $event)),
                  class: "mb-3",
                  suggestions: $setup.filteredCities,
                  label: $setup.locales.officeLocations,
                  multiple: true,
                  field: "text",
                  validation: $setup.employerValidity.officeLocations,
                  onComplete: $setup.handleLocationSearch
                }, null, 8, ["modelValue", "suggestions", "label", "validation", "onComplete"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_router_link, {
              to: "/SelectUserType",
              class: "btn btn-outline-primary me-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.locales.cancel), 1)
              ]),
              _: 1
            }),
            _createElementVNode("button", {
              class: "btn btn-primary",
              disabled: !$setup.areFieldsValid,
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($setup.sendRegistrationRequest && $setup.sendRegistrationRequest(...args)))
            }, _toDisplayString($setup.locales.submit), 9, _hoisted_18)
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.registrationRequestSent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("h1", _hoisted_20, _toDisplayString($setup.locales.successMessage), 1),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("section", _hoisted_23, [
                _createElementVNode("h4", _hoisted_24, _toDisplayString($setup.locales.thankYouMessage), 1),
                _createElementVNode("h4", _hoisted_25, _toDisplayString($setup.locales.meantimeQuestionsMessage), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}