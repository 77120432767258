
import { defineComponent, PropType } from 'vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import { PreviewDropdownModel } from '../CandidateProfile.interface'

export default defineComponent({
  components: {
    DropdownOptions
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    dropdownOptions: {
      type: Array as PropType<PreviewDropdownModel[]>,
      required: true
    },
    hideOptions:  {
      type: Boolean,
      required: false
    }
  },
  emits: ['option-change'],
  setup(_, { emit }) {
    function handleOptionChange(val: PreviewDropdownModel){
      emit('option-change', val)
    }

    return{
      handleOptionChange
    }
  }
})
