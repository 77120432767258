
import { defineComponent, PropType } from 'vue'
import RecruiterCard from './RecruiterCard.vue'
import DownloadAttachments from '@/components/DownloadAttachments/DownloadAttachements.vue'
import { SummaryDetailsModel, SummaryDetailAttachmentModel } from '@/types/application'
import { GetOpportunitySupportingFileDataByDomainObjectId } from '@/api/OpportunityApi'
import locales from '@/locales/en/locales.json'

export default defineComponent({
  components: {
    RecruiterCard,
    DownloadAttachments
  },
  props: {
    summaryDetails: {
      type: Object as PropType<SummaryDetailsModel>,
      required: true
    }
  },
  setup(props) {
    async function downloadAttachment(attachment: SummaryDetailAttachmentModel) {
      if (!props.summaryDetails.opportunityId || !attachment.domainObjectId) {
        return
      }
      const result = await GetOpportunitySupportingFileDataByDomainObjectId(props.summaryDetails.opportunityId, attachment.domainObjectId)
      if(!result) return

      const fileURL = window.URL.createObjectURL(result)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', attachment.fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    }

    return {
      locales,
      downloadAttachment
    }
  }
})
