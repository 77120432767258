import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "BsCheckbox__left" }
const _hoisted_3 = ["id", "value", "checked", "aria-describedby", "disabled", "role"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "BsCheckbox__right" }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["BsCheckbox form-check", {
      'bs-invalid-field': _ctx.dirty && _ctx.invalid,
      'form-switch': _ctx.isSwitch
    }])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "left")
    ]),
    _createElementVNode("input", {
      id: _ctx.id + '__input',
      class: _normalizeClass(["form-check-input", {
        'is-invalid': _ctx.dirty && _ctx.invalid
      }]),
      type: "checkbox",
      value: _ctx.modelValue,
      checked: _ctx.modelValue,
      "aria-describedby": _ctx.id + '__invalid_message',
      disabled: _ctx.disabled,
      role: _ctx.isSwitch ? 'switch' : '',
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 42, _hoisted_3),
    (_ctx.label && !_ctx.isSwitch)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-check-label bs-field-label",
          for: _ctx.id + '__input'
        }, _toDisplayString(_ctx.label), 9, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "right")
    ]),
    (_ctx.dirty)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: _ctx.id + '__invalid_message',
          class: "invalid-feedback"
        }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_6))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}