import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BsTextField, {
      id: $props.id,
      modelValue: $setup.dateString,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateString) = $event)),
      type: $props.disableTime ? 'date' : 'datetime-local',
      label: $props.label,
      disabled: $props.disabled,
      validation: $props.validation,
      onInput: _cache[1] || (_cache[1] = () => ($setup.dateInput = true))
    }, null, 8, ["id", "modelValue", "type", "label", "disabled", "validation"])
  ]))
}