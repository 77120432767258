import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "BsRadio form-check" }
const _hoisted_2 = ["id", "name", "disabled", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.id + '__input',
      class: "form-check-input",
      type: "radio",
      name: _ctx.name,
      disabled: _ctx.disabled,
      checked: _ctx.modelValue === _ctx.value,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 40, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-check-label",
          for: _ctx.id + '__input'
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}