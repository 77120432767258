
import { GetCandidateProfilePicture } from '@/api/CandidateApi'
import { GetEmployerLogo, GetEmployerLogoUrl } from '@/api/EmployerApi'
import { defineComponent, ref, watch, onMounted, onUnmounted } from 'vue'
import { getDefaultProfileUrl } from './ProfilePicture.utils'

export default defineComponent({
  props: {
    alt: {
      type: String,
      default: 'Profile picture'
    },
    employerId: {
      type: String,
      default: () => undefined
    },
    candidateId: {
      type: String,
      default: () => undefined
    },
    imageSource: {
      type: String,
      default: () => undefined
    },
    opportunityEmployerId: {
      type: String,
      default: () => undefined
    },
    revokeObjectUrlOnUnmounted: {
      type: Boolean,
      default: true
    }
  },
  emits: ['profile-pic-loaded'],
  setup(props, { emit }) {
    const profilePictureUrl = ref(props.imageSource ?? getDefaultProfileUrl())
    const isObjectUrl = ref(true)
    const isLoading = ref(true)

    onMounted(() => {
      if (props.imageSource) {
        isLoading.value = false
      } else {
        updateProfilePicture()
      }
    })

    onUnmounted(() => {
      if (props.revokeObjectUrlOnUnmounted && isObjectUrl.value) {
        window.URL.revokeObjectURL(profilePictureUrl.value)
      }
    })

    const updateProfilePicture = async () => {
      isLoading.value = true
      try {
        let fileData = undefined
        if (props.employerId) {
          fileData = await GetEmployerLogo(props.employerId)
        } else if (props.candidateId) {
          fileData = await GetCandidateProfilePicture(props.candidateId)
        }
        if (fileData) {
          profilePictureUrl.value = window.URL.createObjectURL(fileData)
          isObjectUrl.value = true
        } else if (props.opportunityEmployerId) {
          profilePictureUrl.value = GetEmployerLogoUrl(props.opportunityEmployerId)
          isObjectUrl.value = false
        } else {
          useDefaultImage()
          return
        }
      } catch {
        return
      } finally {
        isLoading.value = false
        emit('profile-pic-loaded', profilePictureUrl.value)
      }
    }

    watch(() => props.employerId, () => {
      updateProfilePicture()
      emit('profile-pic-loaded', profilePictureUrl.value)
    })

    watch(() => props.imageSource, (newVal: string | undefined) => {
      profilePictureUrl.value = newVal
      emit('profile-pic-loaded', profilePictureUrl.value)
    })

    const useDefaultImage = () => {
      profilePictureUrl.value = getDefaultProfileUrl()
      isObjectUrl.value = false
    }

    return {
      profilePictureUrl,
      useDefaultImage,
      isLoading
    }
  }
})
