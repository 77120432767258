
import { computed, defineComponent, ref, watch, onMounted } from 'vue'
import { GetEmployerId, GetUserGroups, oktaAuth } from '@/okta'
import { useStore } from 'vuex'
import { redirectToSSU } from '@/signin'
import HeaderDropdown from './HeaderDropdown.vue'
import { DropdownOptionModel, MenuItemsModel } from './Header.interface'
import googleHeadText from 'raw-loader!./GoogleAnalyticsHead.txt'
import googleBodyText from 'raw-loader!./GoogleAnalyticsBody.txt'
import {
  candidateDropdownOptions,
  candidateMenuItems,
  employerDropdownOptions,
  employerMenuItems,
  adminMenuItems,
  unauthenticatedMenuItems
} from './Header.util'
import locales from './Header.locales.en.json'
import { UserClaims } from '@okta/okta-auth-js'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { Configuration } from '@/config/config'

export default defineComponent({
  components: {
    HeaderDropdown
  },
  setup() {
    const store = useStore()
    const { showErrorToast } = useToast()
    const authenticated = ref(false)
    const userDetails = computed<UserClaims | null>(() => store.state.userDetails)
    const isCreatingSession = ref<boolean>(false)
    const dropdownOptions = ref<DropdownOptionModel[]>([])
    const routes = ref<MenuItemsModel[]>([])

    onMounted(async () => {
      await isAuthenticated()
    })

    watch(authenticated, async () => {
      await store.dispatch('loadUserDetails').catch(() => {
        showErrorToast({
          message: locales.cannot_get_userdetails,
          position: 'top-center'
        })
      })
    })

    const isAuthenticated = async () => {
      authenticated.value = await oktaAuth.isAuthenticated()

      if (!authenticated.value){
        routes.value = unauthenticatedMenuItems
        return
      }

      await setUserAccess()
      writeHotjar()
      writeGoogleAnalytics()
    }

    const signIn = async () => {
      if(isCreatingSession.value) return

      try {
      isCreatingSession.value = true
      await redirectToSSU()

      }
      finally {
        isCreatingSession.value = false
      }
    }

    const logout = async () => {
      store.state.jobOpportunity = undefined
      store.state.candidate = undefined
      store.state.agentProfile = undefined
      store.state.applicaiton = undefined
      store.state.employerProfile = undefined
      await oktaAuth.signOut()
    }

    const setUserAccess = async () => {
      let empId = await GetEmployerId()
      dropdownOptions.value = empId ? employerDropdownOptions : candidateDropdownOptions

      let returnRoutes = empId ? employerMenuItems : candidateMenuItems

      let userGroups = await GetUserGroups()
      if(userGroups.includes(Configuration.ADMIN_GROUP)) {
        returnRoutes = [...returnRoutes, ...adminMenuItems]
      }

      routes.value = returnRoutes
    }

    watch(authenticated, (newVal) => {
      if (newVal) {
        store.commit('setAuthenticated')
      } else {
        store.commit('resetAuthenticated')
      }
    })

    oktaAuth.authStateManager.subscribe(isAuthenticated)

    const handleUserLogin = () => {
      if (!authenticated.value) {
        signIn()
      } else {
        logout()
      }
    }

    const writeHotjar = async () => {
      let hotjarExists = !!document.getElementById('jn_hotjar_script')
      if (!hotjarExists){
        let script = document.createElement('script')
        script.setAttribute('id', 'jn_hotjar_script')
        script.async = true
        script.text = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2865402,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
        document.head.appendChild(script)
      }
    }

    const writeGoogleAnalytics = async () => {
      let googleAnalyticsHeadExists = !!document.getElementById('jn_googleAnalyticsHead_script')
      if (!googleAnalyticsHeadExists){
        let script = document.createElement('script')
        script.setAttribute('id', 'jn_googleAnalyticsHead_script')
        script.async = true
        script.text = googleHeadText

        document.head.appendChild(script)
      }
      let googleAnalyticsBodyExists = !!document.getElementById('jn_googleAnalyticsBody_div')
      if (!googleAnalyticsBodyExists){
        let div = document.createElement('div')
        div.setAttribute('id', 'jn_googleAnalyticsBody_div')
        div.innerHTML = googleBodyText
        //This element should at the top in the body tag
        document.body.prepend(div)
      }
    }

    return {
      locales,
      dropdownOptions,
      handleUserLogin,
      authenticated,
      routes,
      userDetails,
      isCreatingSession
    }
  }
})
