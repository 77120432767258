
import { defineComponent, reactive, ref, PropType, watch } from 'vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required, url, helpers } from '@vuelidate/validators'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'
import BsButton from '@/components/BsButton/BsButton.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import locales from './EmployerProfile.locales.en.json'
import {
  EmployerDetailsFormModel,
  EmployerDetailsModel
} from './EmployerProfile.interface'
import { EmployerProfile, EmployerTypes } from '@/types/employer'
import { debounce } from 'vue-debounce'
import { BuildLocationKey, GetUniqueCityCountryResults } from '@/api/AzMapsApi'
import { TextValue } from '@/types'
import { Models } from 'azure-maps-rest'
import { DeleteEmployerFile, PostEmployerFileUpload, PutEmployerProfile } from '@/api/EmployerApi'
import {
  IsValidEmployerType,
  IsValidWebsite,
  IsValidOfficeLocationList
} from '@/types/validation/employerValidation'
import { IsValidCompanyLinkedInUrl } from '@/types/validation/urlValidation'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import AttachmentUpload from '@/components/AttachmentUpload/AttachmentUpload.vue'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import EditConfirmationModal from './EditConfirmationModal.vue'
import { http, https } from '@/constants'
import { FileWithId } from '@/types/fileMetadata/fileMetadata'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import EmployerProfileWelcomeBanner from './EmployerProfileWelcomeBanner.vue'
import store from '@/store'

export default defineComponent({
  components: {
    Dialog,
    BsTextField,
    BsSelect,
    BsAutoComplete,
    BsButton,
    ScrollLayout,
    TextEditor,
    AttachmentUpload,
    EditConfirmationModal,
    ProfilePicture,
    EmployerProfileWelcomeBanner
  },
  props: {
    employerDetails: {
      type: Object as PropType<EmployerDetailsModel>,
      required: true
    },
    logoImageSource: {
      type: String,
      default: () => undefined
    },
    forceComplete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateProfile', 'logo-image-file-selected'],
  setup(props, { emit }) {
    type DialogInstance = InstanceType<typeof Dialog>
    const selectedCities = ref()
    const dialogRef = ref<DialogInstance>()
    const files = ref<File[]>([])
    let failedFiles: string[] = []
    const { showErrorToast, showSuccessToast } = useToast()
    const editConfirmationModal = ref<InstanceType<typeof EditConfirmationModal>>()
    const isSavingProfile = ref(false)
    const formFields = reactive<EmployerDetailsFormModel>({
      employerName: '',
      linkedin: '',
      website: '',
      locations: [],
      country: '',
      employerType: '',
      about: '',
      file: null,
      fileName: ''
    })
    const companyWebsite = ref('')
    const companyLinkedin = ref('')

    watch(companyWebsite, (newVal: string) => {
      if (isHttpUrl(newVal)) {
        formFields.website = newVal
      } else {
        formFields.website = http + newVal
      }
    })

    watch(companyLinkedin, (newVal: string) => {
      if (isHttpUrl(newVal)) {
        formFields.linkedin = newVal
      } else {
        formFields.linkedin = https + newVal
      }
    })

    function isHttpUrl(urlString: string) {
      return urlString.startsWith(http) || urlString.startsWith(https)
    }

    const rules: ValidationArgs<any> = {
      employerName: { required },
      locations: {
        required,
        validOfficeLocation: helpers.withMessage(
          'The value is not a valid office location from the list',
          IsValidOfficeLocationList
        )
      },
      employerType: {
        required,
        validEmployerType: helpers.withMessage(
          'The value is not a valid employer type from the list',
          IsValidEmployerType
        )
      },
      website: {
        required,
        url,
        validUrl: helpers.withMessage(
          'The value should have less than 256 characters',
          IsValidWebsite
        )
      },
      linkedin: {
        validLinkedin: helpers.withMessage(
          'The value should be a valid LinkedIn company URL',
          IsValidCompanyLinkedInUrl
        )
      }
    }

    const v$ = useVuelidate(rules, formFields)

    async function assignProfileValues() {
      const { employerDetails } = props
      if (!employerDetails) {
        return
      }
      formFields.employerName = employerDetails.companyName
      companyLinkedin.value = employerDetails.linkedinLink
      companyWebsite.value = employerDetails.website
      formFields.about = employerDetails.description
      formFields.employerType = employerDetails.employerType
      selectedCities.value = employerDetails.locations?.map((loc: Models.SearchResultAddress) => ({ text: BuildLocationKey(loc), value: loc }))
      files.value = employerDetails.attachments?.map(sfm => ({ name: sfm.fileName, webkitRelativePath: 'uploaded', fileMetadataId: sfm.domainObjectId } as FileWithId)) ?? []
    }

    function toggleModal(status: boolean) {
      if (!isSavingProfile.value) {
        if(status) {
          dialogRef.value?.open()
          v$.value.$touch()
         } else {
          dialogRef.value?.close()
         }
      }
    }

    const filteredCities = ref<TextValue<Models.SearchResultAddress>[]>([])

    const handleLocationSearch = debounce(async (event: any) => {
      const resultDict = await GetUniqueCityCountryResults(event.query)
      filteredCities.value = Object.keys(resultDict).map((key) => ({
        text: key,
        value: resultDict[key]
      }))
    }, 300)

    watch(selectedCities, (newVal: TextValue<Models.SearchResultAddress>[]) => {
      formFields.locations = newVal.map(tvp => tvp.value ?? {})
    })

    const handleFileUploadClicked = (uploads: FileWithId[]): void => {
      files.value = uploads
    }

    const handleRemoveFile = async (deletedFile: FileWithId) => {
      files.value = files.value.filter(file => file !== deletedFile)
      const supportingFileMetadata = props.employerDetails.attachments?.find(sfm => sfm.domainObjectId === deletedFile.fileMetadataId)
      if(supportingFileMetadata){
        await DeleteEmployerFile(deletedFile.fileMetadataId)
      }
    }

    const uploadFiles = async () => {
      for (let file of files.value.filter(
        (f: any) => f.webkitRelativePath != 'uploaded'
      )) {
        try {
          await PostEmployerFileUpload(file, FileDescription.EmployerImage)
        } catch (err) {
          failedFiles.push(file.name)
        }
      }
    }

    const mapFormToEmployer = () => {
      return {
        name: formFields.employerName,
        employerType: formFields.employerType,
        description: formFields.about,
        descriptionLength: formFields.about.length,
        linkedInLink: formFields.linkedin,
        website: formFields.website,
        officeLocations: formFields.locations
      } as EmployerProfile
    }

    async function handleConfirm() {
      v$.value.$touch()
      const isValidEmployer = await v$.value.$validate()
       if (isValidEmployer) {
         editConfirmationModal.value?.openModal()
      } else {
        showErrorToast({
          message: 'One or more of your entered fields is invalid. Please correct and resubmit.'
        })
      }
    }

    async function handleAgree() {
      isSavingProfile.value = true
      try {
        const employerProfile = mapFormToEmployer()
        await PutEmployerProfile(employerProfile)
        await uploadFiles()
        store.state.employerProfile = employerProfile
        emit('updateProfile')

        if (failedFiles.length === 0) {
          showSuccessToast({
            message: 'Successfully updated employer profile'
          })
          dialogRef.value?.close()
        } else {
          const errorMsg = failedFiles.join(', ')
          failedFiles = []
          showErrorToast({
            message: `Your profile was updated but we were unable to upload file(s): ${errorMsg}`
          })
        }
      } catch {
        showErrorToast({
          message: 'Cannot save profile'
        })
      } finally {
        isSavingProfile.value = false
      }
    }

    function handleLogoImageFileSelected(e: Event) {
      const element = e.target as HTMLInputElement
      if (element.files?.length) {
        emit('logo-image-file-selected', element.files[0])
      }
    }

    return {
      dialogRef,
      locales,
      files,
      formFields,
      EmployerTypes,
      companyWebsite,
      companyLinkedin,
      handleLocationSearch,
      filteredCities,
      selectedCities,
      editConfirmationModal,
      isSavingProfile,
      v$,
      toggleModal,
      handleAgree,
      handleConfirm,
      assignProfileValues,
      handleFileUploadClicked,
      handleRemoveFile,
      handleLogoImageFileSelected
    }
  }
})
