
import { ref, watch } from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'

export default {
  components: { BsTextField },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    step: {
      type: Number,
      default: 1
    },
    minSelection: {
      type: Number,
      required: true
    },
    maxSelection: {
      type: Number,
      required: true
    },
    minLabel: {
      type: String,
      default: 'Min'
    },
    maxLabel: {
      type: String,
      default: 'Max'
    },
    label: {
      type: String,
      default: null
    }
  },
  emits: ['update:minSelection', 'update:maxSelection'],
  //@ts-ignore no-implicit-any
  setup(props, context) {
    const range = ref([props.minSelection, props.maxSelection])

    watch(
      () => range.value[0],
      (newVal: number) => {
        context.emit('update:minSelection', newVal)
      }
    )

    watch(
      () => range.value[1],
      (newVal: number) => {
        context.emit('update:maxSelection', newVal)
      }
    )

    return {
      range
    }
  }
}
