import { BarbriAffiliationKey, Candidate, DreamJobData, LanguageKey, LawReviewJournalExperience } from '@/types/candidate'
import { IsValidDescription } from '@/types/validation/genericValidation'
import { CandidateTypes } from '@/types/opportunity'
import { IsValidUserLinkedInUrl } from '@/types/validation/urlValidation'
import { Models } from 'azure-maps-rest'
import { IsValidSchoolInformationArray } from './schoolInformationValidation'
import { EmployerTypeKey } from '../employer'

function IsValidCandidate(candidate: Candidate): boolean {
  return IsValidCandidateType(candidate.profileData.candidateType)
    && IsValidDescription(candidate.profileData.description, candidate.profileData.descriptionLength)
    && IsValidUserLinkedInUrl(candidate.profileData.linkedInLink)
    && AreValidLanguages(candidate.profileData.languages)
    && IsValidDreamJobData(candidate.profileData.dreamJobData)
    && IsValidBarbriAffiliation(candidate.profileData.barbriAffiliated, candidate.profileData.barbriAffiliations)
    && IsValidSchoolInformationArray(candidate.profileData.schoolInformation)
}

function AreValidLanguages(languages: Array<LanguageKey>) {
  return !!languages && languages.length > 0
}

function IsValidCandidateType(type?: string): boolean {
  return !!type && CandidateTypes.some((v) => v.value === type)
}

function IsValidDreamJobData(dreamJobData: DreamJobData) {
  return (
    !!dreamJobData &&
    AreValidPracticeAreas(dreamJobData.practiceAreas) &&
    AreValidJobTypes(dreamJobData.dreamJobTypes) &&
    AreValidPreferredLocations(dreamJobData.preferredLocations)
  )
}

function AreValidPracticeAreas(areas: Array<string>) {
  return !!areas && areas.length > 0
}

function AreValidJobTypes(jobTypes: Array<EmployerTypeKey>) {
  return !!jobTypes && jobTypes.length > 0
}

function AreValidPreferredLocations(
  locations: Array<Models.SearchResultAddress>
) {
  return !!locations && locations.length > 0
}

function IsValidBarbriAffiliation(
  barbriAffiliated: boolean,
  barbriAffiliations: Array<BarbriAffiliationKey>
) {
  return (
    (!barbriAffiliated &&
      (!barbriAffiliations || barbriAffiliations.length === 0)) ||
    (barbriAffiliated && !!barbriAffiliations && barbriAffiliations.length > 0)
  )
}

const RequiredFields = [
  {name: 'firstName', displayName: 'First Name' },
  {name: 'lastName', displayName: 'Last Name' },
  {name: 'emailAddress', displayName: 'Email' },
  {name: 'phoneNumber', displayName: 'Phone' },
  {name: 'candidateType', displayName: 'Candidate Type' },
  {name: 'languages', displayName: 'Languages' },
  {name: 'practiceAreas', displayName: 'Practice Areas'},
  {name: 'preferredLocations', displayName: 'Preferred Locations'},
  {name: 'dreamJobTypes', displayName: 'Job Types' },
  {name: 'schoolInformation', displayName: 'School Information' }
]

function GetIncompleteRequiredFields(candidate: Candidate): Array<string> {
  const incompleteFields = [] as Array<string>
  RequiredFields.forEach(field => {
    let index = Object.keys(candidate.profileData).indexOf(field.name)
    let value = Object.values(candidate.profileData)[index]
    if (index < 0) {
      index = Object.keys(candidate.profileData.dreamJobData).indexOf(field.name)
      value = Object.values(candidate.profileData.dreamJobData)[index]
    }
    if ((!value || (Array.isArray(value) && !value.length))
        || (field.name === 'schoolInformation' && !IsValidSchoolInformationArray(value))) {
      incompleteFields.push(field.displayName)
    }
  })

  return incompleteFields
}

function IsValidLawReviewJournalExperience(experience: LawReviewJournalExperience): boolean {
  return !!experience && !!experience.lawReviewJournal && !!experience.titlePosition
}

export {
  IsValidCandidate,
  IsValidDescription,
  AreValidLanguages,
  IsValidDreamJobData,
  AreValidPracticeAreas,
  AreValidJobTypes,
  AreValidPreferredLocations,
  IsValidCandidateType,
  RequiredFields,
  GetIncompleteRequiredFields,
  IsValidLawReviewJournalExperience
}
