import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcc32930"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-selected", "aria-controls", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tab)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        id: _ctx.tab.id + '__tab',
        ref: "button",
        role: "tab",
        class: _normalizeClass(["BsTab", {
      'BsTab--selected': _ctx.selected
    }]),
        "aria-selected": _ctx.selected,
        "aria-controls": _ctx.tab.id + '__tabpanel',
        tabindex: _ctx.focused ? 0 : -1,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleButtonClick && _ctx.handleButtonClick(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleButtonFocus && _ctx.handleButtonFocus(...args))),
        onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleButtonKeyDown && _ctx.handleButtonKeyDown(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", { selected: _ctx.selected }, undefined, true)
      ], 42, _hoisted_1))
    : _createCommentVNode("", true)
}