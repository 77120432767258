import { nextTick, ref, Ref } from 'vue'

export function useDropdownBehavior(toggle: Ref<HTMLElement | undefined>, container: Ref<HTMLElement | undefined>, dropdown: Ref<HTMLElement | undefined>) {
  const dropdownMaxWidth = ref<number>(0)
  const dropdownMaxHeight = ref<number>(0)
  const dropdownTopOffset = ref<number>(0)
  const dropdownLeftOffset = ref<number>(0)
  const dropdownVerticalDirection = ref<'top'|'bottom'>('top')

  async function updateDropdownPositionAndDimension() {
    const containerRect = container.value?.getBoundingClientRect()
    const toggleRect = toggle.value?.getBoundingClientRect()
    if (!containerRect || !toggleRect) {
      return
    }
    await nextTick()
    const dropdownRect = dropdown.value?.getBoundingClientRect()
    if (!dropdownRect) {
      return
    }
    const inputHeightAbove = toggleRect.top
    const inputHeightBelow = document.documentElement.clientHeight - dropdownRect.height
    dropdownMaxWidth.value = containerRect.width
    dropdownLeftOffset.value = containerRect.left

    if (inputHeightAbove > inputHeightBelow) {
      dropdownVerticalDirection.value = 'top'
      dropdownTopOffset.value = toggleRect.bottom - dropdownRect.height - toggleRect.height
      dropdownMaxHeight.value = toggleRect.top
    } else {
      dropdownVerticalDirection.value = 'top'
      dropdownTopOffset.value = toggleRect.bottom
      dropdownMaxHeight.value =
        document.documentElement.clientHeight -
        toggleRect.y -
        toggleRect.height
    }
  }

  return {
    dropdownMaxWidth,
    dropdownMaxHeight,
    dropdownTopOffset,
    dropdownLeftOffset,
    dropdownVerticalDirection,
    updateDropdownPositionAndDimension
  }
}
