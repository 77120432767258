
import { defineComponent, ref, computed, nextTick } from 'vue'
import { ICodeValidationChallengeEvent } from './CodeValidation.interfaces'
import locales from './CodeValidation.locales.en.json'

const CODE_LENGTH = 6
const numberValidator = /^\d*$/
const ALLOWED_KEYS = ['Tab', 'ArrowUp', 'ArrowDown']

function createInitialInputValues() {
  return [...Array(CODE_LENGTH)].map(() => '')
}

function sanitizeEventKey(key: string): string | undefined {
  return key === 'Unidentified' ? undefined : key
}

export const CodeVerification = defineComponent({
  name: 'CodeVerification',
  props: {
    email: {
      type: String,
      required: true
    },
    resetInterval: {
      type: Number,
      default: 1000 // in ms
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['validation-challenge', 'validated', 'invalidated', 'resend'],
  setup(props, { emit }) {
    const valueParts = ref<string[]>(createInitialInputValues())
    const codeInputWrapper = ref<HTMLDivElement>()
    const isValidationComplete = ref<boolean>(false)
    const isValidatingCode = ref<boolean>(false)
    const isCodeValid = ref<boolean>(false)
    const code = computed<string>(() => valueParts.value.join(''))

    function focusInput(index: number) {
      if (codeInputWrapper.value) {
        const input = codeInputWrapper.value?.children?.[index]
        if (input instanceof HTMLInputElement) {
          input.focus()
        }
      }
    }

    function isPasteKeyCombination(event: KeyboardEvent) {
      return event.ctrlKey && event.key === 'v'
    }

    function isNumber(key: string) {
      return numberValidator.test(key)
    }

    function getValueByIndex(index: number) {
      return valueParts.value[index]
    }

    function setValueByIndex(index: number, value: string) {
      valueParts.value[index] = value
    }

    function isNavigationKey(event: KeyboardEvent) {
      return ALLOWED_KEYS.includes(event.key)
    }

    function handleKeyDown(event: KeyboardEvent, index: number) {
      const sanitizedKey = sanitizeEventKey(event.key)

      if (!sanitizedKey) {
        return
      }
      if (sanitizedKey === 'Backspace' && getValueByIndex(index)) {
        setValueByIndex(index, '')
        return
      }
      if (sanitizedKey === 'Backspace') {
        focusInput(index - 1)
      } else if (!event.shiftKey && (sanitizedKey === 'ArrowRight' || sanitizedKey === 'Right')) {
        focusInput(index + 1)
      } else if (!event.shiftKey && (sanitizedKey === 'ArrowLeft' || sanitizedKey === 'Left')) {
        focusInput(index - 1)
      }

      const shouldUpdateInput = isNumber(sanitizedKey) || isPasteKeyCombination(event)
      const isSingleCharacterKey = sanitizedKey.length === 1

      if (shouldUpdateInput && isSingleCharacterKey && getValueByIndex(index)) {
        setValueByIndex(index, sanitizedKey)
        focusInput(index + 1)
        emitInput()
      }
      if (shouldUpdateInput) {
        return
      }
      if (!isNavigationKey(event)) {
        event.preventDefault()
      }
    }

    function handleInput(index: number) {
      const value = valueParts.value[index]

      if (value) {
        if (value.length > 1) {
          valueParts.value[index] = value[value.length - 1]
        }
        focusInput(index + 1)
      }

      emitInput()
    }

    function onPaste(event: ClipboardEvent) {
      const clipboardData = event.clipboardData || (window as any).clipboardData
      if (!clipboardData) {
        return
      }
      // IE fix
      event.preventDefault()
      const _code = clipboardData.getData('Text') || clipboardData.getData('text/plain')
      fillCode(_code)
    }

    function fillCode(_code: string) {
      _code = _code.trim()
      _code = _code.slice(0, CODE_LENGTH)
      const parts = _code.split('')
      parts.length = CODE_LENGTH
      valueParts.value = [...parts]

      const last = _code.length - 1

      valueParts.value[last] =
        valueParts.value[last] && valueParts.value[last].slice(0, 1)
      emitInput()
      focusInput(last)
    }

    function emitInput() {
      if (valueParts.value.every((value: string) => value)) {
        validateCode()
      }
    }

    function delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }

    function dispatchCodeValidationChallengeEvent() {
      return new Promise<boolean>((resolve) => {
        const event: ICodeValidationChallengeEvent = {
          code: code.value,
          resolve
        }
        emit('validation-challenge', event)
      })
    }

    async function validateCode() {
      isValidationComplete.value = false
      isValidatingCode.value = true
      isCodeValid.value = await dispatchCodeValidationChallengeEvent()
      isValidationComplete.value = true
      await delay(props.resetInterval)
      if (isCodeValid.value) {
        emit('validated')
      } else {
        emit('invalidated')
      }
      valueParts.value = createInitialInputValues()
      isValidationComplete.value = false
      isValidatingCode.value = false
      await nextTick()
      focusInput(0)
    }

    function handleResendButtonClick() {
      emit('resend', props.email)
    }

    return {
      locales,
      valueParts,
      codeInputWrapper,
      handleKeyDown,
      handleInput,
      onPaste,
      handleResendButtonClick,
      isValidationComplete,
      isValidatingCode,
      isCodeValid
    }
  }
})

export default CodeVerification
