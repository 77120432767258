export interface Data {
  [key: string]: unknown
}

export interface TextValue<Type> {
  text: string
  value: Type
}

/** @deprecated */
export interface LabelValue<Type = string> {
  label: string
  value: Type
}

export enum RepeatTypes {
  AutoTrimTextInput,
  SchoolInformationComponent,
  WorkExperience,
  CityStateCountry,
  LawReviewJournalExperience
}

export interface DateRange {
  start: Date
  end: Date
}

export const DefaultFlag = Symbol('DefaultFlag')

// A new utility type to append DefaultFlag
// DefaultFlag value is forced to be true, having the key is most important anyways
export type WithDefaultFlag<E extends object> = E & { [DefaultFlag]: true }

// Evaluates if object has default flag
export function hasDefaultFlag<E extends object>(obj: E): obj is WithDefaultFlag<E> {
  // Some linting tool could complain about directly using hasOwnProperty but recommending using in this way
  return Object.prototype.hasOwnProperty.call(obj, DefaultFlag)
}

// copies the object with the default flag
export function withDefaultFlag<E extends object>(obj: E): WithDefaultFlag<E> {
  return { ...obj, [DefaultFlag]: true }
}