interface IConfig {
  [key: string]: string,
  JOBNETWORK_SITE_URL: string,
  API_URL_BASE: string,
  ACCESS_CODE: string,
  OKTA_DOMAIN: string,
  CLIENT_ID: string,
  SEARCH_BASE: string,
  SSU_DOMAIN: string,
  APPI_CONNECTION_STRING: string,
  ADMIN_GROUP: string,
  EMPLOYER_VERIFICATION_GROUP: string
}

interface JsonFile {
  [key: string]: string
}

import axios from 'axios'
let Configuration: IConfig = {} as IConfig

const InitConfiguration = async (): Promise<void> => {
  const response = await axios.get('/config.values.json')
  const configValues: JsonFile = response.status === 404 ? {} : response.data
  const config = {} as IConfig
  for (const value in configValues) {
    config[value] = parse(configValues[value], process.env[`VUE_APP_${value}`])
  }
  Configuration = config
}

function parse(value: any, fallback: any) {
  if (typeof value === 'undefined' || value === '') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

export { InitConfiguration, Configuration }
