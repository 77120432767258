import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59c99700"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EmployerProfileDisplayView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_employer_profile_component = _resolveComponent("employer-profile-component")!
  const _component_employer_profile_edit_component = _resolveComponent("employer-profile-edit-component")!
  const _component_employer_profile_candidate_view = _resolveComponent("employer-profile-candidate-view")!
  const _component_profile_picture_upload = _resolveComponent("profile-picture-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_employer_profile_component, {
      "employer-details": $setup.employerDetails,
      "persona-view": $setup.viewingAs,
      "is-loading": $setup.isLoadingProfile,
      "logo-image-source": $setup.logoImageSource,
      onViewAs: _cache[0] || (_cache[0] = ($event: any) => ($setup.viewAsCandidate())),
      onEditProfile: _cache[1] || (_cache[1] = ($event: any) => ($setup.editEmployerProfile())),
      onUpdatedProfilepic: $setup.handleUploadProfilePic,
      onLogoImageSourceUpdated: $setup.updateLogoImageSource
    }, null, 8, ["employer-details", "persona-view", "is-loading", "logo-image-source", "onUpdatedProfilepic", "onLogoImageSourceUpdated"]),
    _createVNode(_component_employer_profile_edit_component, {
      ref: "editProfileDialog",
      "force-complete": $setup.forceComplete,
      "employer-details": $setup.employerDetails,
      "logo-image-source": $setup.logoImageSource,
      onUpdateProfile: _cache[2] || (_cache[2] = ($event: any) => ($setup.updateProfile())),
      onLogoImageFileSelected: $setup.handleUploadProfilePic
    }, null, 8, ["force-complete", "employer-details", "logo-image-source", "onLogoImageFileSelected"]),
    _createVNode(_component_employer_profile_candidate_view, {
      ref: "candidateViewDialog",
      "employer-details": $setup.employerDetails,
      "logo-image-source": $setup.logoImageSource
    }, null, 8, ["employer-details", "logo-image-source"]),
    _createVNode(_component_profile_picture_upload, {
      ref: "profilePictureUpload",
      onCroppedPictureAsBlob: $setup.handleCroppedPicture
    }, null, 8, ["onCroppedPictureAsBlob"])
  ]))
}