
import { defineComponent, PropType, computed, unref, ref, watch, DefineComponent, inject } from 'vue'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementId } from '@/utils/VueTools'
import Dropdown, { DropdownProps } from 'primevue/dropdown'
import { AppendToSelectorKey } from '@/types/ui'
import { useDropdownOffsetPatch } from '@/utils/PrimeVuePatch'

const SHOW_FILTER_LIMIT = 10

const BsSelect = defineComponent({
  name: 'BsSelect',
  components: { Dropdown },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsSelect')
    },
    modelValue: {
      type: [String, Boolean, Number, Object],
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    disabled: Boolean,
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    },
    prepend: {
      type: String,
      default: () => ''
    },
    append: {
      type: String,
      default: () => ''
    },
    options: {
      type: Array as PropType<DropdownProps['options']>,
      default: () => []
    },
    optionLabel: {
      type: [Function, String] as PropType<DropdownProps['optionLabel']>,
      default: () => undefined
    },
    optionValue: {
      type: [Function, String] as PropType<DropdownProps['optionValue']>,
      default: () => undefined
    },
    optionDisabled: {
      type: [Function, String] as PropType<DropdownProps['optionDisabled']>,
      default: () => undefined
    },
    defaultSelectedValue: {
      type: [String, Number, Boolean, Object] as PropType<any>,
      default: () => undefined
    }
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const appendToSelector = inject(AppendToSelectorKey, ref<string>('body'))
    const dropdown = ref<InstanceType<DefineComponent & typeof Dropdown>>()
    const selectId = computed(() => props.id + '__select')
    const invalidMessageId = computed(() => props.id + '__invalid_message')
    const selectedValue = ref<string | boolean | number | object>(props.modelValue)

    const showFilter = computed<boolean>(() => {
      if (props.options) {
        return props.options.length >= SHOW_FILTER_LIMIT
      }
      return false
    })

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    watch(() => props.modelValue, (newValue) => {
      if(selectedValue.value !== newValue){
        selectedValue.value = newValue
      }
    })

    useDropdownOffsetPatch(dropdown, appendToSelector, '.p-dropdown-panel')

    watch(selectedValue, newValue => {
      if(props.modelValue !== newValue){
        emit('update:model-value', newValue)
      }
    })

    watch(() => props.options, newValue => {
      handleOptionDefaultValue(newValue)
    })

    function handleDropdownShow() {
      // "filterInput" is part of Dropdown.vue at PrimeVue v3.12.2
      // https://github.com/primefaces/primevue/blob/3.12.2/src/components/dropdown/Dropdown.vue#L24
      const filterInput = dropdown.value?.$refs['filterInput']
      if (!(filterInput instanceof HTMLInputElement)) {
        if (showFilter.value) {
          console.error('[BsSelect] Missing "filterInput"')
        }
        return
      }
      filterInput.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
          cleanDropdownFilterInput(filterInput)
        }
      })
    }

    function handleDropdownBeforeHide() {
      // "filterInput" is part of Dropdown.vue at PrimeVue v3.12.2
      // https://github.com/primefaces/primevue/blob/3.12.2/src/components/dropdown/Dropdown.vue#L24
      const filterInput = dropdown.value?.$refs['filterInput']
      if (!(filterInput instanceof HTMLInputElement)) {
        if (showFilter.value) {
          console.error('[BsSelect] Missing "filterInput"')
        }
        return
      }
      cleanDropdownFilterInput(filterInput)
    }

    function cleanDropdownFilterInput(filterInput: HTMLInputElement) {
      filterInput.value = ''
      const event = new Event('input')
      filterInput.dispatchEvent(event)
    }

    function handleOptionDefaultValue(options: DropdownProps['options']) {
      if (!options || props.modelValue !== undefined || !props.defaultSelectedValue === undefined) {
        return
      }
      emit('update:model-value', props.defaultSelectedValue)
    }

    return {
      dropdown,
      selectId,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      showRequiredAttr,
      selectedValue,
      showFilter,
      appendToSelector,
      handleDropdownShow,
      handleDropdownBeforeHide
    }
  }
})

export default BsSelect
