import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6 mt-4" }
const _hoisted_5 = { class: "col-6 mt-4" }
const _hoisted_6 = { class: "col-6 mt-4" }
const _hoisted_7 = { class: "col-6 mt-4" }
const _hoisted_8 = { class: "col-6 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_field_required_message = _resolveComponent("field-required-message")!
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsSelect, {
        modelValue: $setup.refCountry,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.refCountry) = $event)),
        disabled: $props.disabled,
        label: "Country *",
        options: $setup.CountryAddressDetails,
        "option-label": "isoCode",
        "option-value": "isoCode"
      }, null, 8, ["modelValue", "disabled", "options"])
    ]),
    _createVNode(_component_field_required_message, {
      show: !$setup.refCountry && $setup.isFormSubmitted
    }, null, 8, ["show"]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BsTextField, {
        "model-value": $setup.refAddressFieldValues.addressLine1,
        "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => (($setup.refAddressFieldValues.addressLine1) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[2] || (_cache[2] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": true,
        label: $setup.addressFieldNames.addressLine1 + '*',
        disabled: $props.disabled
      }, null, 8, ["model-value", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.addressLine1]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BsTextField, {
        modelValue: $setup.refAddressFieldValues.addressLine2,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.refAddressFieldValues.addressLine2) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[4] || (_cache[4] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": false,
        label: $setup.addressFieldNames.addressLine2,
        disabled: $props.disabled
      }, null, 8, ["modelValue", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.addressLine2]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BsTextField, {
        modelValue: $setup.refAddressFieldValues.locality,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.refAddressFieldValues.locality) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[6] || (_cache[6] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": true,
        label: $setup.addressFieldNames.locality + '*',
        disabled: $props.disabled
      }, null, 8, ["modelValue", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.locality]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BsTextField, {
        modelValue: $setup.refAddressFieldValues.dependentLocality,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.refAddressFieldValues.dependentLocality) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[8] || (_cache[8] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": true,
        label: $setup.addressFieldNames.dependentLocality + '*',
        disabled: $props.disabled
      }, null, 8, ["modelValue", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.dependentLocality]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BsTextField, {
        modelValue: $setup.refAddressFieldValues.administrativeArea,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.refAddressFieldValues.administrativeArea) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[10] || (_cache[10] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": true,
        label: $setup.addressFieldNames.administrativeArea + '*',
        disabled: $props.disabled
      }, null, 8, ["modelValue", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.administrativeArea]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      _createVNode(_component_BsTextField, {
        modelValue: $setup.refAddressFieldValues.postalCode,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.refAddressFieldValues.postalCode) = $event)),
        "form-submitted": $setup.isFormSubmitted,
        "onUpdate:form-submitted": _cache[12] || (_cache[12] = ($event: any) => (($setup.isFormSubmitted) = $event)),
        "required-field": true,
        label: $setup.addressFieldNames.postalCode + '*',
        disabled: $props.disabled
      }, null, 8, ["modelValue", "form-submitted", "label", "disabled"])
    ], 512), [
      [_vShow, !$setup.textFieldsHidden && $setup.addressFieldNames.postalCode]
    ])
  ]))
}