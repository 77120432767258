
import { FileWithId } from '@/types/fileMetadata/fileMetadata'
import { defineComponent, ref, watch, computed, PropType, onMounted } from 'vue'
import locales from './AttachmentUpload.locales.en.json'

export default defineComponent({
  props: {
    disabled: Boolean,
    limit: {
      type: Number,
      default: () => undefined
    },
    files: {
      type: Array as PropType<FileWithId[]>,
      default: () => []
    }
  },
  emits: ['on-upload', 'on-remove'],
  setup(props, { emit }) {
    const fileItems = ref<Array<FileWithId>>([])
    const allowUpload = computed(() => {
      if (typeof props.limit !== 'number') {
        return true
      }
      return fileItems.value.length < props.limit
    })

    onMounted(() => {
      fileItems.value = props.files
    })

    watch(() => props.files, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fileItems.value = newVal
      }
    })

    function emitFile() {
      emit('on-upload', fileItems.value)
    }

    function insertFiles(files: FileWithId[]) {
      if (!allowUpload.value) {
        return
      }
      if (typeof props.limit === 'number') {
        fileItems.value = [
          ...fileItems.value,
          ...Array.from(files).splice(0, props.limit - fileItems.value.length)
        ]
      } else {
        fileItems.value = [...fileItems.value, ...files]
      }

      emitFile()
    }

    function uploadFile(e: { target: { files: FileWithId[] } }) {
      insertFiles(e.target.files)
    }

    function dragFile(e: { dataTransfer: { files: FileWithId[] } }) {
      insertFiles(e.dataTransfer.files)
    }

    function handleRemove(i: number) {
      emit('on-remove', fileItems.value[i])
      fileItems.value = fileItems.value.filter((_, index) => index !== i)
    }

    return {
      fileItems,
      allowUpload,
      locales,
      uploadFile,
      dragFile,
      handleRemove
    }
  }
})
