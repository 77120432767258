import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce4f963e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "certifications-segment" }
const _hoisted_2 = { class: "row-item" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingWrapper = _resolveComponent("HeadingWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingWrapper, {
      "hide-options": _ctx.viewOnly,
      heading: _ctx.locales.certifications,
      "dropdown-options": _ctx.otherSubMenuOptions,
      onOptionChange: _ctx.handleOptionChange
    }, null, 8, ["hide-options", "heading", "dropdown-options", "onOptionChange"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details.certifications, (item, i) => {
        return (_openBlock(), _createElementBlock("label", {
          id: `view-certifications-${i}`,
          key: i,
          class: "label-badge"
        }, _toDisplayString(item || _ctx.locales.none), 9, _hoisted_3))
      }), 128))
    ])
  ]))
}