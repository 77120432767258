
import { defineComponent } from 'vue'
import locales from './SelectUserType.locales.en.json'

const userTypes = [
  {title: locales.im_jobseeker, content: locales.jobseeker_content, userType: 'candidate'},
  {title: locales.im_employer, content: locales.employer_content, userType: 'agent'}
]
export default defineComponent({

  emits:['submit'],
  setup(_, { emit }) {

    function handleClick(role: string){
        emit('submit', role)
    }
    return{
      locales,
      userTypes,
      handleClick
    }
  }
})
