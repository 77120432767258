import { SchoolInformation } from '@/types/candidate'
import { SchoolNameSet } from '../candidate/schoolNames'
import { IsValidDate, FalsyOrGreaterThan100Chars } from './genericValidation'

function IsValidSchoolInformationArray(
  schoolInfo?: Array<SchoolInformation>
): boolean {
  if (!schoolInfo || schoolInfo.length < 1 || schoolInfo.length > 5) {
    return false
  }
  return schoolInfo.every((si) => IsValidSchoolInformation(si))
}

function ContainsValidSchoolInformation(
  schoolInfo?: Array<SchoolInformation>
): boolean {
  if (!schoolInfo || schoolInfo.length < 1) {
    return false
  }
  return schoolInfo.some((si) => IsValidSchoolInformation(si))
}

function IsValidSchoolInformation(
  schoolInfo: SchoolInformation | undefined
): boolean {
  if (!schoolInfo) {
    return false
  }

  if (schoolInfo.isManualEntry) {
    if (FalsyOrGreaterThan100Chars(schoolInfo.schoolName)) {
      return false
    } else if (FalsyOrGreaterThan100Chars(schoolInfo.country)) {
      return false
    }
  } else {
    if (!SchoolNameSet.has(schoolInfo.schoolName)) {
      return false
    }
  }

  if (FalsyOrGreaterThan100Chars(schoolInfo.degree)) {
    return false
  }

  if (!IsValidDate(schoolInfo.startDate)) {
    return false
  }

  if(!IsValidDate(schoolInfo.endDate)){
    return false
  }

  if (EndDateInvalidGivenStartDate(schoolInfo.startDate, schoolInfo.endDate)) {
    return false
  }

  return true
}

function EndDateInvalidGivenStartDate(startDate: Date, endDate: Date) {
  return endDate && startDate.getTime() >= endDate.getTime()
}

function IsValidCumulativeGpa(gpa: number | undefined): boolean {
  if (gpa === undefined || gpa === null) {
    return true
  }

  if ((!gpa && gpa !== 0) || isNaN(gpa) || gpa > 4.3 || gpa < 0) {
    return false
  }

  const decimals = gpa.toString().split('.')[1] ?? 0

  return decimals.toString().length <= 2
}

function IsValidClassRank(rank: number) {
  return !!rank && rank >= -1 && rank <= 100
}

export {
  IsValidSchoolInformationArray,
  ContainsValidSchoolInformation,
  IsValidSchoolInformation,
  EndDateInvalidGivenStartDate,
  IsValidCumulativeGpa,
  IsValidClassRank
}
