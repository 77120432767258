
import { Data, TextValue } from '@/types'
import { UpsertJobOpportunity, PostOpportunitySupportingFile, GetOpportunityById, DeleteOpportunitySupportingFile } from '@/api/OpportunityApi'
import { computed, onMounted, Ref, ref, watch, defineComponent } from 'vue'
import { IsValidApplicationEmail, IsValidExternalURL, IsValidFutureDate, IsValidJobOpportunity, IsValidSalaryInfo } from '@/types/validation/opportunityValidation'
import { IsValidYouTubeUrl } from '@/types/validation/urlValidation'
import {
  FileDescription,
  FileDescriptionTextValues
} from '@/types/fileMetadata/fileDescription'
import {
  JobOpportunity,
  ApplyTypes,
  AudienceTypes,
  AudienceKey,
  SalaryTypes,
  Currencies,
  RequirementsToApply,
  OpportunityTypes,
  isUSOpportunityTypeKey,
  isNonUSOpportunityTypeKey,
  OpportunityListing,
  SalaryInclusionOptions,
  RequirementToApplyKey
} from '@/types/opportunity'
import { FileMetadata, FileWithId } from '@/types/fileMetadata/fileMetadata'
import router from '@/router'
import { Models } from 'azure-maps-rest'
import { GetEmployerProfile } from '@/api/EmployerApi'
import { BuildLocationKey } from '@/api/AzMapsApi'
import locales from '@/locales/en/locales.json'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import ActionsButton from '@/components/CreateOpportunity/ActionsButton.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import AttachmentUpload from '@/components/AttachmentUpload/AttachmentUpload.vue'
import InternationalAddressInput from '@/components/InternationalAddressInput.vue'
import PreviewOpportunity from '@/components/CreateOpportunity/PreviewOpportunity.vue'
import DateTimeInput from '@/components/DateTimeInput.vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { email, helpers, required, url } from '@vuelidate/validators'
import { PracticeAreaOptions } from '@/types/candidate'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    BsTextField,
    BsSelect,
    BsMultiSelect,
    BsCheckbox,
    ActionsButton,
    TextEditor,
    AttachmentUpload,
    InternationalAddressInput,
    DateTimeInput,
    PreviewOpportunity
  },
  setup(): Data {
    const { showSuccessToast, showErrorToast } = useToast()
    let jobOpportunity = {} as JobOpportunity
    const opportunityListing = ref({}) as Ref<OpportunityListing>
    const disableFields = ref(false)
    const formSubmitted = ref(false)
    const files = ref([]) as Ref<FileWithId[]>
    const opportunityTypes = ref(OpportunityTypes)
    const statusMessage = ref('')
    const uploaded = 'uploaded'
    const employerOfficeLocations = ref([]) as Ref<TextValue<Models.SearchResultAddress | undefined>[]>
    const showPreview = ref(false)
    const youTubeUrl = ref('')
    const validYouTubeUrl = ref(true)
    const employerName = ref('')
    const requirementsToApply = ref<(RequirementToApplyKey | FileDescription)[]>([])
    const requirementsToApplyOptions = ref<TextValue<RequirementToApplyKey | FileDescription>[]>([])
    const dialog = ref<InstanceType<typeof PreviewOpportunity>>()
    const container = ref<HTMLElement>()
    const route = ref(useRoute())
    const optionalOpportunityId = route.value.query.opportunityId as string

    const resetForm = () => {
      jobOpportunity = {} as JobOpportunity
      opportunityListing.value = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        applyType: 'InternalManage',
        audience: 'USLawStudents',
        includeSalaryInfo: true,
        salaryType: 'PerAnnum',
        currency: 'USD',
        salary: 0,
        remote: false,
        officeLocation: employerOfficeLocations.value[0].value
      } as OpportunityListing
      if (!opportunityListing.value.officeLocation) {
        opportunityListing.value.address = { country: 'US' }
      }
      formSubmitted.value = false
      files.value = []
      youTubeUrl.value = ''
      requirementsToApply.value = []
      v$.value.$reset()
    }


    watch(requirementsToApply, (newVal: Array<RequirementToApplyKey | FileDescription>) => {
      // The requirementsToAppy list may contain file requirements along with other requirements,
      // so we need to make sure to assign the opportunityListing requirement fields to the appropriate values
      opportunityListing.value.requirementsToApply =
        newVal
        .filter(req => RequirementsToApply.find(tvp => tvp.value === req))
        .map(req => req as RequirementToApplyKey)
      opportunityListing.value.fileRequirementsToApply =
        newVal
        .filter(req => FileDescriptionTextValues.find(tvp => tvp.value === req))
        .map(req => req as FileDescription)
      if (!opportunityListing.value.fileRequirementsToApply.length) {
        opportunityListing.value.fileRequirementsToApply = [ FileDescription.None ]
      }
    })

    const submitJobOpportunity = async () => {
      disableFields.value = true
      formSubmitted.value = true

      v$.value.$touch() //Vuelidate requires a touch call to display error messages
      const isValidJobOpportunity = await v$.value.$validate()

      jobOpportunity.opportunityListing = opportunityListing.value
      jobOpportunity.status = 'Published'
      if (isValidJobOpportunity && IsValidJobOpportunity(jobOpportunity)) {
        statusMessage.value = 'Status: Posting Opportunity'
        let opportunityId: string
        try {
          opportunityId = await UpsertJobOpportunity(jobOpportunity)
        } catch {
          disableFields.value = false
          handleOpportunityApiError('Please fill in all required fields')
          scrollToFirstInvalidField()
          return
        }

        uploadFiles(opportunityId)

        resetForm()
        window.scroll({
          top: 0,
          behavior: 'smooth'
        })
        handleOpportunityApiSuccess(
          'Thank you for submitting. Your job has been published.'
        )
      } else {
        handleOpportunityApiError('Please fill in all required fields')
        scrollToFirstInvalidField()
      }
      showPreview.value = false
      statusMessage.value = ''
    }

    const scrollToFirstInvalidField = () => {
      container.value?.querySelector('.is-invalid')?.scrollIntoView()
    }

    const previewJobOpportunity = () => {
      disableFields.value = true
      formSubmitted.value = true

      jobOpportunity.opportunityListing = opportunityListing.value
      jobOpportunity.status = 'Published'
      if (IsValidJobOpportunity(jobOpportunity)) {
        showPreview.value = true
      } else {
        handleOpportunityApiError('Please fill in all required fields')
      }
    }

    const unlockPage = () => {
      disableFields.value = false
    }

    const saveJobOpportunityDraft = async () => {
      disableFields.value = true

      statusMessage.value = 'Status: Saving Opportunity Draft'
      let opportunityId: string
      try{
        jobOpportunity.opportunityListing = opportunityListing.value
        jobOpportunity.status = 'Draft'
        opportunityId = await UpsertJobOpportunity(jobOpportunity)
        jobOpportunity.id ??= opportunityId
      } catch {
        handleOpportunityApiError(
          'There was an error saving your draft.  Please try again in a few minutes'
        )
        return
      }

      uploadFiles(opportunityId)
      handleOpportunityApiSuccess('Your draft has been saved.')
      statusMessage.value = ''
    }

    watch(
      () => opportunityListing.value.audience,
      (newVal: AudienceKey): void => {
        if (!newVal) {
          return
        }
        let audience = newVal.toString()

        if (audience.includes('NonUS')) {
          opportunityTypes.value = OpportunityTypes.filter((ot) =>
            isNonUSOpportunityTypeKey(ot.value)
          )
        } else if (audience.includes('US')) {
          opportunityTypes.value = OpportunityTypes.filter((ot) =>
            isUSOpportunityTypeKey(ot.value)
          )
        } else {
          opportunityTypes.value = OpportunityTypes
        }
      },
      { deep: true }
    )

    const loadEmployerProfile = async () => {
      const employerProfile = await GetEmployerProfile()
      employerOfficeLocations.value = employerProfile.officeLocations.map(
        (loc) => ({ text: BuildLocationKey(loc), value: loc })
      )
      employerName.value = employerProfile.name
    }

    watch(
      () => opportunityListing.value.officeLocation,
      (newVal) => {
        if (newVal) {
          opportunityListing.value.address = undefined
        } else {
          opportunityListing.value.address = opportunityListing.value.address ?? { country: 'US' }
        }
      }
    )

    const prePopulateFields = async () => {
      opportunityListing.value = jobOpportunity.opportunityListing ?? opportunityListing.value
      opportunityListing.value.timezone ??= Intl.DateTimeFormat().resolvedOptions().timeZone
      opportunityListing.value.applyType ??= 'InternalManage'
      opportunityListing.value.audience ??= 'USLawStudents'
      opportunityListing.value.includeSalaryInfo ??= true
      opportunityListing.value.salaryType ??= 'PerAnnum'
      opportunityListing.value.currency ??= 'USD'
      opportunityListing.value.salary ??= 0
      opportunityListing.value.remote ??= false
      opportunityListing.value.officeLocation ??= employerOfficeLocations.value[0].value

      files.value = jobOpportunity.supportingFileMetadata?.map(sfm => ({ name: sfm.fileName, webkitRelativePath: uploaded, fileMetadataId: sfm.domainObjectId } as FileWithId)) ?? []
      youTubeUrl.value = opportunityListing.value.youTubeVideoId ? `https://www.youtube.com/watch?v=${opportunityListing.value.youTubeVideoId}` : youTubeUrl.value
      requirementsToApply.value = requirementsToApplyOptions?.value.filter(req => req.value != FileDescription.None && (opportunityListing.value.fileRequirementsToApply?.includes(req.value as FileDescription) ?? false)).map(val=> val.value)
      requirementsToApply.value = requirementsToApply?.value.concat(requirementsToApplyOptions.value.filter(req => opportunityListing.value.requirementsToApply?.includes(req.value as RequirementToApplyKey) ?? false).map(val=> val.value))
    }

    onMounted(async () => {
      await loadEmployerProfile()
      requirementsToApplyOptions.value = FileDescriptionTextValues
      requirementsToApplyOptions.value = requirementsToApplyOptions.value.concat(RequirementsToApply)

      if(optionalOpportunityId) {
        jobOpportunity = await GetOpportunityById(optionalOpportunityId)
        prePopulateFields()
      } else {
        resetForm()
      }
    })

    watch(
      () => opportunityListing.value.remote,
      (isRemote) => {
        if (isRemote) {
          if (opportunityListing.value.address) {
            opportunityListing.value.address.addressLine1 = ''
            opportunityListing.value.address.addressLine2 = ''
            opportunityListing.value.address.locality = ''
            opportunityListing.value.address.dependentLocality = ''
            opportunityListing.value.address.administrativeArea = ''
            opportunityListing.value.address.postalCode = ''
          }
          opportunityListing.value.officeLocation = undefined
        } else if (opportunityListing.value.address) {
          opportunityListing.value.officeLocation = undefined
        } else {
          opportunityListing.value.officeLocation = opportunityListing.value.officeLocation ?? employerOfficeLocations.value[0].value
        }
      }
    )

    const handleFileUploadClicked = (uploads: FileWithId[]): void => {
      files.value = uploads
    }

    const handleRemoveFile = async (deletedFile: FileWithId) => {
      files.value = files.value.filter(file => file !== deletedFile)
      const supportingFileMetadata = jobOpportunity.supportingFileMetadata?.find(sfm => sfm.domainObjectId === deletedFile.fileMetadataId)
      if(supportingFileMetadata){
        await DeleteOpportunitySupportingFile(deletedFile.fileMetadataId, jobOpportunity.id)
      }
    }

    const selectedFilesMetadata = computed<FileMetadata[]>((): FileMetadata[] => {
      return files.value.map(f => { return { fileName: f.name, domainObjectId: f.name } as FileMetadata })
    })

    const uploadFiles = async (opportunityId: string) => {
      for (let file of files.value.filter(
        (f: any) => f.webkitRelativePath != uploaded
      )) {
        try {
          statusMessage.value = `Status: Uploading ${file.name}`
          await PostOpportunitySupportingFile(file, opportunityId)
        } catch (err) {
          showErrorToast({
            message: `The opportunity was created but we were unable to upload file: ${file.name}`
          })
        }
      }
    }

    const handleOpportunityApiError = (message: string) => {
      disableFields.value = false
      showErrorToast({ message })
      // setting array values to [] instead of null, as null is treated as a valid value so that the element isn't immediately marked as invalid
      opportunityListing.value.requirementsToApply =
        opportunityListing.value.requirementsToApply ?? []
      opportunityListing.value.fileRequirementsToApply =
        opportunityListing.value.fileRequirementsToApply ?? []
    }

    watch(() => opportunityListing.value.includeSalaryInfo, () => {
      //Check if other salary values are null before setting default values in case this page was loaded for an Edit
      if(opportunityListing.value.includeSalaryInfo &&
         !opportunityListing.value.salaryType &&
         !opportunityListing.value.currency &&
         !opportunityListing.value.salary)
      {
        opportunityListing.value.salaryType = 'PerAnnum'
        opportunityListing.value.currency = 'USD'
        opportunityListing.value.salary = 0
      }
      else if(!opportunityListing.value.includeSalaryInfo)
      {
        opportunityListing.value.salaryType = undefined
        opportunityListing.value.currency = undefined
        opportunityListing.value.salary = undefined
      }
    })

    const handleOpportunityApiSuccess = (message: string) => {
      disableFields.value = false
      showSuccessToast({ message })
    }

    watch(youTubeUrl, (newUrl: string) => {
      validYouTubeUrl.value = IsValidYouTubeUrl(newUrl)

      const match = newUrl.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/)

      if(match && match[7].length == 11) {
        opportunityListing.value.youTubeVideoId = match[7]
      } else {
        opportunityListing.value.youTubeVideoId = undefined
      }
    })

    const validationRules = {
      title: { required },
      description: { required },
      opportunityType: { required },
      applyEmail: { required, email },
      recruiterEmail: { required, email },
      recruiterContact: { required },
      recruiterTitle: { required },
      startDate: {
        required,
        futureDate: helpers.withMessage(
          'The Start Date must be in the future',
          IsValidFutureDate)
      },
      applicationDeadline: {
        required,
        futureDate: helpers.withMessage(
          'The Application Deadline must be in the future',
          IsValidFutureDate)
      },
      applicationReceiveEmail: {
        email,
        required: helpers.withMessage(
          `The ${locales.label_external_email} is required`,
          helpers.withParams(
            {},
            (val: string) => IsValidApplicationEmail(opportunityListing.value.applyType == 'EmailReceive', val)
          )
        )
      },
      externalApplicationUrl: {
        url,
        required: helpers.withMessage(
          `The ${locales.label_external_tms} is required`,
          helpers.withParams(
          {},
          (val: string) => IsValidExternalURL(opportunityListing.value.applyType == 'ExternalDirect', val)
          )
        )
      },
      salary: {
        salary: helpers.withParams(
          {},
          () => IsValidSalaryInfo(opportunityListing.value.includeSalaryInfo,
                                  opportunityListing.value.salaryType,
                                  opportunityListing.value.currency,
                                  opportunityListing.value.salary)
        )
      }
    } as ValidationArgs<any>

    const v$ = useVuelidate(validationRules as any, opportunityListing)

    function openPreviewOpportunityDialog() {
      dialog.value?.open()
    }

    const cancel = () => {
      router.go(-1)
    }

    return {
      opportunityListing,
      ApplyTypes,
      AudienceTypes,
      FileDescriptionTextValues,
      opportunityTypes,
      SalaryInclusionOptions,
      SalaryTypes,
      Currencies,
      statusMessage,
      submitJobOpportunity,
      previewJobOpportunity,
      requirementsToApply,
      requirementsToApplyOptions,
      formSubmitted,
      files,
      selectedFilesMetadata,
      handleFileUploadClicked,
      disableFields,
      saveJobOpportunityDraft,
      employerOfficeLocations,
      showPreview,
      unlockPage,
      youTubeUrl,
      validYouTubeUrl,
      employerName,
      locales,
      PracticeAreaOptions,
      v$,
      dialog,
      openPreviewOpportunityDialog,
      cancel,
      container,
      prePopulateFields,
      handleRemoveFile
    }
  }
})
