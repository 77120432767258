import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6172b9eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ApplicantsComponent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_opportunity_summary_card = _resolveComponent("opportunity-summary-card")!
  const _component_applicant_list = _resolveComponent("applicant-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_opportunity_summary_card, {
      "summary-details": $setup.summaryDetails,
      onEditOpportunity: $setup.editOpportunity
    }, null, 8, ["summary-details", "onEditOpportunity"]),
    _createVNode(_component_applicant_list, {
      "applicant-lists": $setup.rowDataApplicants,
      onApplicantShortlisted: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateShortlistStatus($event)))
    }, null, 8, ["applicant-lists"])
  ]))
}