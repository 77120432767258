
import { defineComponent, PropType, ref, computed, unref, inject, DefineComponent } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { AppendToSelectorKey } from '@/types/ui'
import { useDropdownOffsetPatch } from '@/utils/PrimeVuePatch'

const BsAutoComplete = defineComponent({
  name: 'BsAutoComplete',
  components: {
    AutoComplete
  },
  props: {
    id: createAutoIncrementIdProp('BsAutoComplete'),
    modelValue: {
      type: [Array, String] as PropType<Record<string, unknown>[] | string>,
      default: ()=> [] || ''
    },
    label: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    },
    placeholder: {
      type: String,
      default: () => 'Begin typing to search'
    },
    suggestions: {
      type: Array as PropType<Record<string, unknown>[]>,
      default: () => []
    },
    forceSelection: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    field: {
      type: String,
      default: () => null
    },
    completeOnFocus: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:modelValue', 'complete', 'item-select', 'item-unselect'],
  setup(props, { emit }) {
    const appendToSelector = inject(AppendToSelectorKey, ref<string>('body'))
    const autocomplete = ref<InstanceType<DefineComponent & typeof AutoComplete>>()
    const selectId = computed(() => props.id + '__autocomplete')
    const invalidMessageId = computed(() => props.id + '__invalid_message')
    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    useDropdownOffsetPatch(autocomplete, appendToSelector, '.p-autocomplete-panel')

    function handleUserSearch(event: any) {
      event.query = (event.query || '').toLowerCase()
      emit('complete', event)
    }

    const computeModelValue = computed({
      get: () => props.modelValue,
      set: (newValue) => {
        emit('update:modelValue', newValue)
      }
    })

    return {
      autocomplete,
      selectId,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      showRequiredAttr,
      appendToSelector,
      handleUserSearch,
      computeModelValue
    }
  }
})

export default BsAutoComplete
