
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch } from 'vue'
import locales from './CandidateSearch.locales.en.json'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import BsTextValueAutoComplete from '@/components/BsAutoComplete/BsTextValueAutoComplete.vue'
import AccordionExpander from '@/components/Accordion/AccordionExpander.vue'
import AccordionMenu from '@/components/Accordion/AccordionMenu.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import RangeSelector from '@/components/RangeSelector.vue'
import LocationFilter from '@/components/LocationFilter.vue'
import SaveFilter from '@/components/Candidates/SaveFilter.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

import { TextValue } from '@/types'
import {
  DiversityDisclosures,
  PracticeAreas,
  StudentOrganizationKey, StudentOrganizations
} from '@/types/candidate'
import { SchoolNameArray } from '@/types/candidate/schoolNames'
import { CandidateFilter } from '@/types/candidateFilter'
import { Models } from 'azure-maps-rest'
import {ICandidateSearchStore} from '@/views/CandidateSearchView/CandidateSearch.interfaces'
import { EmployerTypeKey, EmployerTypes } from '@/types/employer'
import { AudienceKey, CandidateTypes } from '@/types/opportunity'

interface CandidateFilterOptions {
  dreamJobTypeOptions: TextValue<EmployerTypeKey>[]
  practiceAreasOptions: TextValue<string>[]
  candidateTypeOptions: TextValue<AudienceKey>[]
  studentOrganizationOptions: TextValue<StudentOrganizationKey>[]
  genderOptions: typeof DiversityDisclosures.US.gender
  heritageOptions: typeof DiversityDisclosures.US.heritage
  otherDiversityCriteriaOptions: typeof DiversityDisclosures.US.other
  schoolNameOptions: TextValue<string>[]
}

interface CandidateFilterView {
  filterDescription: string
  maxGraduationYear: number
}

interface CandidateFilterViewModel {
  dreamJobTypes: TextValue<EmployerTypeKey>[]
  practiceAreas: TextValue<string>[]
  candidateTypes: TextValue<string>[]
  studentOrganizations: TextValue<StudentOrganizationKey>[]
  schoolNames: TextValue<string>[]
  preferredLocations: Models.SearchResultAddress[]
  genders: TextValue<string>[]
  heritages: TextValue<string>[]
  otherDiversityCriteria: TextValue<string>[]
  filterByGraduationYear?: boolean
  graduationYearMin?: number
  graduationYearMax?: number
}

export default defineComponent({
  components: {
    BsCheckbox,
    BsTextValueAutoComplete,
    AccordionExpander,
    AccordionMenu,
    AccordionItem,
    RangeSelector,
    LocationFilter,
    SaveFilter
  },
  props: {
    showDiversityFields: {
      type: Boolean,
      default: true
    },
    store: {
      type: Object as PropType<ICandidateSearchStore>,
      required: true
    },
    visible: Boolean
  },
  emits: ['update:visible', 'update:modelValue', 'filter-saved', 'filter-applied'],
  setup(props, { emit }) {
    const saveFilterDialog = ref<InstanceType<typeof SaveFilter>>()
    const options = reactive<CandidateFilterOptions>({
      dreamJobTypeOptions: EmployerTypes,
      practiceAreasOptions: PracticeAreas.map(value => ({ text: value, value })),
      candidateTypeOptions: CandidateTypes,
      studentOrganizationOptions: StudentOrganizations.filter(org => org.value !== 'Other'),
      genderOptions: DiversityDisclosures.US.gender,
      heritageOptions: DiversityDisclosures.US.heritage,
      otherDiversityCriteriaOptions: DiversityDisclosures.US.other,
      schoolNameOptions: SchoolNameArray.map(value => ({ text: value, value }))
    })
    const form = reactive<CandidateFilterViewModel>({
      // Find Candidates
      preferredLocations: [],
      dreamJobTypes: [],
      practiceAreas: [],
      candidateTypes: [],

      // Education
      studentOrganizations: [],
      schoolNames: [],
      filterByGraduationYear: false,
      graduationYearMin: undefined,
      graduationYearMax: undefined,

      // Diversity
      genders: [],
      heritages: [],
      otherDiversityCriteria: []
    })
    const view = reactive<CandidateFilterView>({
      maxGraduationYear: new Date().getFullYear() + 3,
      filterDescription: ''
    })

    const saveDisabled = computed<boolean>(() => {
      return !form.preferredLocations?.length
        && !form.dreamJobTypes?.length
        && !form.practiceAreas?.length
        && !form.candidateTypes?.length
        && !form.studentOrganizations?.length
        && !form.schoolNames?.length
        && !form.filterByGraduationYear
        && !form.genders?.length
        && !form.heritages?.length
        && !form.otherDiversityCriteria?.length
    })

    const { showErrorToast } = useToast()

    watch(() => form.filterByGraduationYear, (newVal) => {
      if (!newVal) {
        form.graduationYearMin = 1950
        form.graduationYearMax = view.maxGraduationYear
      }
    })

    onMounted(() => {
      // Object.assign(form, createCandidateFilterViewModel(props.store.state.candidateFilter))
      setFormFromFilter()
    })

    function countFilters(candidates: any): number {
      const parsedFilters: CandidateFilterViewModel = JSON.parse(JSON.stringify(candidates))
      const counter: number = parsedFilters.candidateTypes.length
      + parsedFilters.dreamJobTypes.length
      + parsedFilters.practiceAreas.length
      + parsedFilters.preferredLocations.length
      + parsedFilters.schoolNames.length
      + parsedFilters.studentOrganizations.length
      + (parsedFilters.filterByGraduationYear ? 1 : 0)
      return counter
    }

    function showFilterError() {
      return showErrorToast({
          message: "You can't select more than 5 filters.",
          position: 'top-center'
        })
    }

    function handleFilterApplied(e: any) {
      const state = props.store.state
      state.candidateFilter = createCandidateFilter(form)
      const counter: number = countFilters(state.candidateFilter)
      if(counter <= 5) {
        e.preventDefault()
        emit('update:visible', false)
        props.store.actions.loadFirstPage()
      } else {
        e.preventDefault()
        showFilterError()
      }
    }

    function openSaveDialog() {
      const state = props.store.state
      state.candidateFilter = createCandidateFilter(form)
      const counter: number = countFilters(state.candidateFilter)
      if(counter <= 5) {
        emit('update:visible', false)
        saveFilterDialog.value?.open()
      } else {
        showFilterError()
      }

    }

    function reset() {
      Object.assign(form, {
        preferredLocations: [],
        dreamJobTypes: [],
        practiceAreas: [],
        candidateTypes: [],
        dualDegrees: [],
        schoolNames: [],
        filterByGraduationYear: false,
        graduationYearMin: undefined,
        graduationYearMax: undefined,
        filterByGpa: false,
        gpaMin: undefined,
        gpaMax: undefined,
        filterByClassRank: false,
        classRankPercentile: undefined,
        genders: [],
        heritages: [],
        otherDiversityCriteria: [],
        studentOrganizations: []
      })
    }

    function setFormFromFilter() {
      const state = props.store.state
      if (!state.candidateFilter) {
        state.candidateFilter = {} as CandidateFilter
      }
      const candidateFilter = state.candidateFilter as CandidateFilter
      form.preferredLocations = candidateFilter.preferredLocations ?? []
      form.dreamJobTypes = getMatchingOptions(candidateFilter.dreamJobTypes, options.dreamJobTypeOptions)
      form.practiceAreas = getMatchingOptions(candidateFilter.practiceAreas, options.practiceAreasOptions)
      form.candidateTypes = getMatchingOptions(candidateFilter.candidateTypes, options.candidateTypeOptions)
      form.studentOrganizations = getMatchingOptions(candidateFilter.studentOrganizations, options.studentOrganizationOptions)
      form.schoolNames = getMatchingOptions(candidateFilter.schoolNames, options.schoolNameOptions)
      form.filterByGraduationYear = candidateFilter.filterByGraduationYear ?? false
      form.graduationYearMin = candidateFilter.graduationYearMin ?? 1950
      form.graduationYearMax = candidateFilter.graduationYearMax ?? new Date().getFullYear() + 3
      form.genders = getMatchingOptions(candidateFilter.genders, options.genderOptions)
      form.heritages = getMatchingOptions(candidateFilter.heritages, options.heritageOptions)
      form.otherDiversityCriteria = getMatchingOptions(candidateFilter.otherDiversityCriteria, options.otherDiversityCriteriaOptions)
    }

    function createCandidateFilter(candidateForm: CandidateFilterViewModel): CandidateFilter {
      return {
        preferredLocations: candidateForm.preferredLocations,
        dreamJobTypes: getValues(candidateForm.dreamJobTypes),
        practiceAreas: getValues(candidateForm.practiceAreas),
        candidateTypes: getValues(candidateForm.candidateTypes),
        studentOrganizations: getValues(candidateForm.studentOrganizations),
        schoolNames: getValues(candidateForm.schoolNames),
        filterByGraduationYear: candidateForm.filterByGraduationYear,
        graduationYearMin: candidateForm.graduationYearMin,
        graduationYearMax: candidateForm.graduationYearMax,
        genders: getValues(candidateForm.genders),
        heritages: getValues(candidateForm.heritages),
        otherDiversityCriteria: getValues(candidateForm.otherDiversityCriteria)
      }
    }

    function getValues<Type>(textValues: TextValue<Type>[]): Type[] {
      return textValues?.map(option => option.value) ?? []
    }

    function getMatchingOptions<Type>(values: Type[], options: TextValue<Type>[]): TextValue<Type>[] {
      return options?.filter(option => values?.includes(option.value)) ?? []
    }

    return {
      form,
      options,
      view,
      locales,
      saveFilterDialog,
      saveDisabled,
      handleFilterApplied,
      reset,
      openSaveDialog,
      setFormFromFilter
    }
  }
})
