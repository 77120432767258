
import { defineComponent, ref, computed, onMounted } from 'vue'
import UploadFileCard from './UploadFileCard.vue'
import locales from '../CandidateProfile.locales.en.json'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import {
  PostCandidateFileUpload,
  GetCandidate,
  DeleteCandidateFile
} from '@/api/CandidateApi'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { useDownload } from '@/utils/download'

export default defineComponent({
  components: {
    UploadFileCard
  },
  props: {
    label: {
      type: String,
      default: () => undefined
    },
    addLabel: {
      type: String,
      default: () => undefined
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      ...createAutoIncrementIdProp('FileInput'),
      required: true
    }
  },
  setup() {
    const { downloadFromCandidateFileMetadata: handleDownload } = useDownload()
    const allFiles = ref([] as FileMetadata[])
    const { showSuccessToast } = useToast()

    const writingSamples = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(
        (f) => f.fileDescription === FileDescription.CandidateWritingSample
      )
    })

    const resumes = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(
        (f) => f.fileDescription === FileDescription.CandidateResume
      )
    })

    const transcripts = computed<FileMetadata[]>((): FileMetadata[] => {
      return allFiles.value.filter(
        (f) => f.fileDescription === FileDescription.CandidateTranscript
      )
    })

    onMounted(async () => {
      allFiles.value = (await GetCandidate()).candidateFileMetadata
    })

    const handleUpload = async (file: File, fileDescription: FileDescription): Promise<void> => {
      const fileId = await PostCandidateFileUpload(file, fileDescription)

      if (fileDescription === FileDescription.CandidateProfilePicture) {
        showSuccessToast({ message: locales.profile_picture_success_message })
      }

      const newFileMetadata: FileMetadata = {
        domainObjectId: fileId,
        fileName: file.name,
        fileUploadTimestamp: new Date(),
        fileType: file.type,
        fileDescription: fileDescription
      }

      allFiles.value.push(newFileMetadata)
    }

    const handleDelete = async (fileMetadataId: string): Promise<void> =>{
      await DeleteCandidateFile(fileMetadataId)
      allFiles.value.splice(allFiles.value.findIndex(file => file.domainObjectId == fileMetadataId), 1)
    }


    return {
      locales,
      writingSamples,
      resumes,
      transcripts,
      FileDescription,
      handleUpload,
      handleDownload,
      handleDelete
    }
  }
})
