import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-507c1b6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-expanded", "aria-controls"]
const _hoisted_2 = { class: "accordion-item-content" }
const _hoisted_3 = { class: "trigger-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    role: "button",
    tabindex: "0",
    "aria-expanded": _ctx.isExpanded,
    "aria-controls": 'expander-' + _ctx.id,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccordionState && _ctx.toggleAccordionState(...args))),
    onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.toggleAccordionState && _ctx.toggleAccordionState(...args)), ["enter"]))
  }, [
    _renderSlot(_ctx.$slots, "default", { isExpanded: _ctx.isExpanded }, () => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", {
          class: _normalizeClass(["bi bi-chevron-right text-primary fs-4", {
            'icon-expanded': _ctx.isExpanded,
            'icon-content': true
          }])
        }, null, 2)
      ])
    ], true)
  ], 40, _hoisted_1))
}