
import { defineComponent, PropType } from 'vue'
import BsTabComponents from '@/components/CustomBootstrap/BsTab'
import { OpportunitiesDetailsOptions } from './CandidateOpportunityStatusSelector.interfaces'
import locales from './CandidateOpportunityStatusSelector.locales.en.json'

export default defineComponent({
  components: { ...BsTabComponents },
  props: {
    selected: {
      type: String,
      required: true
    },
    cardOptions: {
      type: Array as PropType<OpportunitiesDetailsOptions[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['handle-select'],
  setup(props, { emit }) {
    function handleSelected(status: string) {
      if (props.isLoading) {
        return
      }
      emit('handle-select', status)
    }

    function numberWithCommas(x: number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return {
      locales,
      handleSelected,
      numberWithCommas
    }
  }
})
