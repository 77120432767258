
import { defineComponent, PropType } from 'vue'
import locales from '../CandidateProfile.locales.en.json'
import { otherSubMenuOptions } from '../CandidateProfile.util'
import { PreviewDropdownModel, ProfileCategories } from '../CandidateProfile.interface'
import { CandidateProfile } from '@/types/candidate'
import { IsValidWorkExperience } from '@/types/validation/workExperienceValidation'

export default defineComponent({
  props: {
    details: {
      type: Object as PropType<CandidateProfile>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    }
  },
  emits: ['dropdown-change'],
  setup(_, { emit }) {
    function handleOptionChange(val: PreviewDropdownModel) {
      emit('dropdown-change', val, ProfileCategories.Experience)
    }

    function formatEmploymentEnd(endYear: number) {
      if(!endYear) {
        return locales.present
      }

      return endYear
    }

    return {
      locales,
      handleOptionChange,
      otherSubMenuOptions,
      formatEmploymentEnd,
      IsValidWorkExperience
    }
  }
})
