
import { defineComponent, ref, computed, onMounted, nextTick } from 'vue'
import CandidateInfoCard from './CandidateInfoCard.vue'
import EducationCard from './EducationCard.vue'
import ExperienceCard from './ExperienceCard.vue'
import AwardsCard from './AwardsCard.vue'
import CertificationsCard from './CertificationsCard.vue'
import EditCandidateProfile from '../EditCandidateProfile/EditCandidateProfile.vue'
import { PreviewDropdownModel } from '../CandidateProfile.interface'
import {
  Candidate, CandidateDiversityDisclosure,
  CandidateProfile, DiversityDisclosureUS, DreamJobData,
  SchoolInformation
} from '@/types/candidate'
import { GetCandidate, PutCandidateProfile, GetCandidateById } from '@/api/CandidateApi'
import { IsValidCandidate } from '@/types/validation/candidateValidation'
import locales from '../CandidateProfile.locales.en.json'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { useRoute } from 'vue-router'
import { JobOpportunity } from '@/types/opportunity'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { logErrorMessage } from '@/logging'
import { SendInviteToApply } from '@/api/InvitationApi'
import { GetOpportunitiesByEmployer } from '@/api/OpportunityApi'
import InviteToApply from '@/components/InviteToApply/InviteToApply.vue'

export default defineComponent({
  components: {
    EducationCard,
    ExperienceCard,
    AwardsCard,
    CertificationsCard,
    CandidateInfoCard,
    EditCandidateProfile,
    InviteToApply
  },
  setup() {
    const candidate = ref({} as Candidate)
    const editableCandidate = ref({} as Candidate)
    const isLoading = ref<boolean>(true)
    const isReady = ref<boolean>(false)
    const openedMenuItem = ref<number | null>(0)
    const route = useRoute()
    const optionalCandidateId = route.params.id as string
    const viewOnly = ref<boolean>(false)
    const forceComplete = computed(() => !IsValidCandidate(candidate.value))
    const editDialogVisible = ref(false)
    const infoCardProfilePictureSource = ref('')
    const editViewProfilePictureSource = ref('')
    const showInviteToApplyModal = ref(false)
    const employerOpportunities = ref([] as JobOpportunity[])
    const { showSuccessToast, showErrorToast } = useToast()

    onMounted(() => {
      loadCandidateDetails()
    })

    const loadCandidateDetails = async (): Promise<void> => {
      try {
        if(optionalCandidateId) {
          candidate.value = await GetCandidateById(optionalCandidateId)
          viewOnly.value = true
          return
        }

        const Candidates : Candidate = await GetCandidate()
        candidate.value = Candidates
        editableCandidate.value = Candidates && cloneCandidate(Candidates)
        isReady.value = true
        await nextTick()
        if (forceComplete.value) {
          editDialogVisible.value = true
        }
      }
      finally {
        isLoading.value = false
      }
    }

    async function handleEditProfile(val: PreviewDropdownModel, type: number){
      if (val.value === 'edit'){
        openedMenuItem.value = type
        editableCandidate.value = candidate.value && cloneCandidate(candidate.value)
        editDialogVisible.value = true
      }
      else if (val.value === 'hide-profile') {
        //update the profile status
        let updateValue = {
          ...candidate.value,
          profileData: {
            ...candidate.value.profileData,
            hideProfile: !candidate.value.profileData.hideProfile
          }
        }
        let result = await PutCandidateProfile(updateValue)
        if (result) {
          candidate.value = updateValue
          editableCandidate.value = updateValue && cloneCandidate(updateValue)
        }
      }
    }

    const isValidProfile = computed(() => {
      if (viewOnly.value) {
        return true
      }
      if (candidate.value) {
        return IsValidCandidate(candidate.value)
      }
      return false
    })

    function onProfileUpdate(value: Candidate){
      candidate.value = value
      editableCandidate.value = value && cloneCandidate(value)
    }

    function cloneObject(value: any) {
      return { ...value }
    }

    function cloneDiversityDisclosureUS(value: DiversityDisclosureUS): DiversityDisclosureUS {
      return {
        ...value,
        other: value.other && Array.from(value.other)
      }
    }

    function cloneSchoolInformation(value: SchoolInformation): SchoolInformation {
      return {
        ...value,
        startDate: value.startDate && new Date(value.startDate),
        endDate: value.endDate && new Date(value.endDate)
      }
    }

    function cloneDreamJobData(value: DreamJobData): DreamJobData {
      return {
        ...value,
        dreamJobTypes: value.dreamJobTypes && Array.from(value.dreamJobTypes),
        practiceAreas: value.practiceAreas && Array.from(value.practiceAreas),
        preferredLocations: value.preferredLocations && value.preferredLocations.map(cloneObject)
      }
    }

    function cloneCandidateDiversityDisclosure(value: CandidateDiversityDisclosure): CandidateDiversityDisclosure {
      return {
        ...value,
        ukDisclosure: value.ukDisclosure && cloneObject(value.ukDisclosure),
        usDisclosure: value.usDisclosure && cloneDiversityDisclosureUS(value.usDisclosure)
      }
    }

    function cloneCandidateProfile(value: CandidateProfile): CandidateProfile {
      return {
        ...value,
        languages: value.languages && Array.from(value.languages),
        certifications: value.certifications && Array.from(value.certifications),
        schoolInformation: value.schoolInformation && value.schoolInformation.map(cloneSchoolInformation),
        workExperience: value.workExperience && Array.from(value.workExperience),
        honorsAwards: value.honorsAwards && Array.from(value.honorsAwards),
        studentOrganizations: value.studentOrganizations && Array.from(value.studentOrganizations),
        otherStudentOrganization: value.otherStudentOrganization,
        barbriAffiliations: value.barbriAffiliations && Array.from(value.barbriAffiliations),
        dreamJobData: value.dreamJobData && cloneDreamJobData(value.dreamJobData),
        candidateDiversityDisclosure: value.candidateDiversityDisclosure && cloneCandidateDiversityDisclosure(value.candidateDiversityDisclosure)
      }
    }

    function cloneFileMetadata(value: FileMetadata): FileMetadata {
      return {
        ...value,
        fileUploadTimestamp: value.fileUploadTimestamp && new Date(value.fileUploadTimestamp)
      }
    }

    function cloneCandidate(value: Candidate): Candidate {
      return {
        id: value.id,
        profileData: value.profileData && cloneCandidateProfile(value.profileData),
        candidateFileMetadata: value.candidateFileMetadata && value.candidateFileMetadata.map(cloneFileMetadata)
      }
    }

    function updateInfoCardProfilePicture(imageSource: string) {
      infoCardProfilePictureSource.value = imageSource
    }

    function updateEditViewProfilePicture(imageSource: string) {
      editViewProfilePictureSource.value = imageSource
    }

    async function openInviteToApplyModal() {
      if (!employerOpportunities.value.length) {
        employerOpportunities.value = await GetOpportunitiesByEmployer('Published')
      }
      showInviteToApplyModal.value = true
    }

    const sendInvitation = async (data: any) => {
      if (!candidate.value.id) {
        logErrorMessage('Unable to send invite to candidate')
        showErrorToast({ message: locales.message_invite_candidate_error })
        return
      }
      if (!await SendInviteToApply(candidate.value.id, data.opportunityId, data.message)) {
        showErrorToast({ message: locales.message_invite_candidate_error })
      } else {
        showSuccessToast({ message: locales.message_invite_candidate_success })
      }
      showInviteToApplyModal.value = false
    }

    return {
      candidate,
      editableCandidate,
      isLoading,
      isReady,
      viewOnly,
      handleEditProfile,
      locales,
      isValidProfile,
      openedMenuItem,
      onProfileUpdate,
      editDialogVisible,
      forceComplete,
      infoCardProfilePictureSource,
      editViewProfilePictureSource,
      updateInfoCardProfilePicture,
      updateEditViewProfilePicture,
      showInviteToApplyModal,
      employerOpportunities,
      openInviteToApplyModal,
      sendInvitation
    }
  }
})
