import BsTab from './BsTab.vue'
import BsTabList from './BsTabList.vue'
import BsTabPanel from './BsTabPanel.vue'
import BsTabContainer from './BsTabContainer.vue'

const components = {
  BsTab,
  BsTabList,
  BsTabPanel,
  BsTabContainer
}

export default components
