import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f0bcfb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "dropdownRef",
  class: "username-wrapper"
}
const _hoisted_2 = ["id", "aria-controls", "aria-expanded"]
const _hoisted_3 = {
  key: 0,
  class: "visually-hidden"
}
const _hoisted_4 = { class: "header-dropdown-segment" }
const _hoisted_5 = {
  key: 0,
  class: "username-segment"
}
const _hoisted_6 = { class: "name-content" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = ["id", "aria-labelledby"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "privacy-and-terms" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserNameIcon = _resolveComponent("UserNameIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AgentProfile = _resolveComponent("AgentProfile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("button", {
              id: _ctx.menuButtonId,
              class: _normalizeClass(["btn p-0 c-pointer", {
          'c-loading': _ctx.isCreatingSession
        }]),
              type: "button",
              "aria-controls": _ctx.menuId,
              "aria-expanded": _ctx.isOpen,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpen && _ctx.handleOpen(...args)))
            }, [
              _createVNode(_component_UserNameIcon, { "name-details": _ctx.nameDetails }, null, 8, ["name-details"]),
              (_ctx.nameDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.nameDetails.name + ' ' + _ctx.locales.profile), 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_2),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              (_ctx.nameDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_UserNameIcon, { "name-details": _ctx.nameDetails }, null, 8, ["name-details"]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.nameDetails.name), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("ul", {
                id: _ctx.menuId,
                "aria-labelledby": _ctx.menuButtonId
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
                  return (_openBlock(), _createElementBlock("li", { key: i }, [
                    _createElementVNode("button", {
                      class: "btn",
                      type: "button",
                      onClick: ($event: any) => (_ctx.handleOptionClick($event, option))
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass({
                  'bi': true,
                  [option.icon]: true
                })
                      }, null, 2),
                      _createElementVNode("span", null, _toDisplayString(option.label), 1)
                    ], 8, _hoisted_9)
                  ]))
                }), 128))
              ], 8, _hoisted_8),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", null, [
                  _createVNode(_component_router_link, {
                    to: "/PrivacyPolicy",
                    target: "_blank",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locales.privacy), 1)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.locales.and) + " ", 1),
                  _createVNode(_component_router_link, {
                    to: "/TermsOfService",
                    target: "_blank",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locales.terms), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ], 512), [
              [_vShow, _ctx.isOpen]
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(["login-btn", {
        'c-loading': _ctx.isCreatingSession
      }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleUserLogin && _ctx.handleUserLogin(...args)))
          }, _toDisplayString(_ctx.locales.login_register), 3))
    ], 512),
    _createVNode(_component_AgentProfile, {
      isOpen: _ctx.isAgentProfileOpen,
      "onUpdate:isOpen": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAgentProfileOpen) = $event)),
      role: "none"
    }, null, 8, ["isOpen"])
  ], 64))
}