
import { defineComponent, normalizeClass, PropType, ref, computed, provide } from 'vue'
import StackedDialogCounter from '@/utils/StackedDialogCounter'
import FocusTrapWrapper from '@/components/Shared/FocusTrapWrapper.vue'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { AppendToSelectorKey } from '@/types/ui'

export interface DialogData {
  opened: boolean
  stackedDialogCount: number
  stackedDialogCountPosition: number
}

const stackedDialogCounter = new StackedDialogCounter()

const DialogWrapper = defineComponent({
  name: 'DialogWrapper',
  components: {
    FocusTrapWrapper
  },
  props: {
    id: createAutoIncrementIdProp('DialogWrapper'),
    class: [Object, Array, String] as PropType<unknown>,
    ariaLabel: String as PropType<string>,
    ariaLabelledBy: String as PropType<string>,
    ariaDescribedBy: String as PropType<string>,
    transitionMethod: {
        type: String,
        default: 'fade'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['backdrop', 'esc', 'opened', 'closed', 'update:visible'],
  setup(props) {
    const wrapper = ref<InstanceType<typeof FocusTrapWrapper>>()
    const appendToSelector = computed(() => {
      return (props.id && wrapper.value) ? '#' + props.id : 'body'
    })
    provide(AppendToSelectorKey, appendToSelector)
    return {
      wrapper
    }
  },
  data(): DialogData {
    return {
      opened: false,
      stackedDialogCount: 0,
      stackedDialogCountPosition: 0
    }
  },
  computed: {
    isFirstDialogOnStack() {
      return this.stackedDialogCountPosition === 1
    },
    isLastDialogOnStack() {
      return this.stackedDialogCountPosition === this.stackedDialogCount
    },
    ariaModal() {
      return this.opened && this.isFirstDialogOnStack
    },
    incomingClasses(): string {
      return normalizeClass(this.class) ?? ''
    },
    parentScopedId(): string | null {
      if (this.incomingClasses) {
        return this.$parent?.$options?.__scopeId ?? null
      }
      return null
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.open()
      } else {
        this.close()
      }
    }
  },
  mounted() {
    stackedDialogCounter.emitter.addEventListener(
      'update-dialog-count',
      this.updateDialogStackPosition
    )
  },
  unmounted() {
    stackedDialogCounter.emitter.removeEventListener(
      'update-dialog-count',
      this.updateDialogStackPosition
    )
    this.removeWindowEscapeClose()
    if (this.stackedDialogCountPosition !== 0) {
      stackedDialogCounter.decrement()
    }
  },
  methods: {
    open() {
      if (this.opened) {
        return
      }
      this.opened = true
      this.addWindowEscapeClose()
      stackedDialogCounter.increment()
      this.stackedDialogCountPosition = stackedDialogCounter.count
      this.$.emit('opened', this)
      this.preventBodyTab()
      this.$emit('update:visible', true)
      return this.$nextTick()
    },
    close() {
      if (!this.opened) {
        return
      }
      this.opened = false
      this.removeWindowEscapeClose()
      stackedDialogCounter.decrement()
      this.stackedDialogCountPosition = 0
      this.$emit('closed', this)
      this.restoreBodyTab()
      this.$emit('update:visible', false)
      return this.$nextTick()
    },
    addWindowEscapeClose() {
      window.addEventListener('keydown', this.handleWindowEscapeKeyDown)
    },
    removeWindowEscapeClose() {
      window.removeEventListener('keydown', this.handleWindowEscapeKeyDown)
    },
    handleWindowEscapeKeyDown(event: KeyboardEvent) {
      if (this.opened && event.key === 'Escape') {
        this.$emit('esc', this)
      }
    },
    handleBackdropClick(event: MouseEvent) {
      if (event.currentTarget !== this.$refs.backdrop) {
        return
      }
      this.$emit('backdrop', this)
    },
    updateDialogStackPosition(event: CustomEvent<number>) {
      this.stackedDialogCount = event.detail
    },
    preventBodyTab() {
      document
        .querySelectorAll<HTMLElement>('body > *:not(.Dialog)')
        .forEach((el) => {
          if (el.offsetParent) {
            // offsetParent of hidden elements is null
            el.setAttribute('tabindex', '-1')
          }
        })
    },
    restoreBodyTab() {
      document
        .querySelectorAll<HTMLElement>('body > *:not(.Dialog)')
        .forEach((el) => {
          el.removeAttribute('tabindex')
        })
    }
  }
})

export default DialogWrapper
export type DialogInstance = InstanceType<typeof DialogWrapper>
