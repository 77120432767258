
import { defineComponent, ref, onUnmounted } from 'vue'
import { FocusTrap } from 'focus-trap-vue'

// The purpose of this wrapper is to prevent the following error from focus-trap:
// "Your focus-trap must have at least one container with at least one tabbable node in it at all times"
const FocusTrapWrapper = defineComponent({
  name: 'FocusTrapWrapper',
  components: {
    FocusTrap
  },
  setup() {
    const target = ref<HTMLDivElement>()
    const preventHandleAnimationEnd = ref<boolean>(false)

    onUnmounted(() => {
      preventHandleAnimationEnd.value = true
    })

    function handleAnimationEnd(event: AnimationEvent) {
      if (preventHandleAnimationEnd.value) {
        return
      }
    }

    return {
      target,
      handleAnimationEnd
    }
  }
})

export default FocusTrapWrapper
export type FocusTrapWrapperInstance = InstanceType<typeof FocusTrapWrapper>
