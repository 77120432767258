
import { ref, onMounted } from 'vue'
import { GetPublishedOpportunity } from '@/api/OpportunityApi'
import { JobOpportunity, OpportunityListing } from '@/types/opportunity'
import OpportunitySummaryCard from '@/views/Applicants/OpportunitySummary/OpportunitySummaryCard.vue'
import store from '@/store'
import { useRoute } from 'vue-router'
import { SummaryDetailsModel, mapOpportunityToSummaryDetails, Application } from '@/types/application'
import { redirectToSSU } from '@/signin'
import { GetUserId } from '@/okta'
import locales from '@/views/JobOportunitiesSummary/JobOpportunitiesSummary.locales.en.json'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import ApplyOpportunityForm from '@/components/ApplyToOpportunity/ApplyOpportunityForm.vue'
import { GetApplicationsForCandidate } from '@/api/ApplicationApi'

export default {
  components: {
    OpportunitySummaryCard,
    ApplyOpportunityForm
  },
  setup() {
    const isLoggedIn = ref(false)
    const hasApplied = ref(false)
    const loading = ref(true)
    const errorLoading = ref(false)
    const route = useRoute()
    const opportunityId = route.params.id as string
    const summaryDetails = ref<SummaryDetailsModel>({ recruiter: {}} as SummaryDetailsModel)
    const opportunityPreview = ref<JobOpportunity>({} as JobOpportunity)
    const { showErrorToast } = useToast()
    const applyOpportunityForm = ref<InstanceType<typeof ApplyOpportunityForm>>()

    onMounted(async () => {
      isLoggedIn.value = !!await GetUserId()
      await loadOpportunityPreview()
    })

    const loadOpportunityPreview = async () => {
      try{
        const potentialOpportunity = store.state.jobOpportunity

        if(opportunityId !== potentialOpportunity?.id) {
          try{
            opportunityPreview.value = await GetPublishedOpportunity(opportunityId)
          } catch {
            errorLoading.value = true
          }
        } else {
          opportunityPreview.value  = potentialOpportunity
        }

        summaryDetails.value = await mapOpportunityToSummaryDetails(opportunityPreview.value)
        await findUserApplication()
      } catch {
        errorLoading.value = true
      } finally {
        store.state.jobOpportunity = undefined
        loading.value = false
      }
    }

    async function findUserApplication() {
      if(!isLoggedIn.value){
        return
      }

      const applications = await GetApplicationsForCandidate()
      hasApplied.value = !!applications.find((app: Application) => app.opportunityId === opportunityPreview.value.id)
    }

    async function openOpportunityDetailsToApply() {
      if(!isLoggedIn.value){
        await redirectToSSU(true)
        return
      }

      if (!opportunityPreview.value || !opportunityPreview.value.opportunityListing) {
        showErrorToast({
          message: locales.message_unmanageable_opportunity
        })
        return
      }

      if (verifyOpportunityHasExpired(opportunityPreview.value.opportunityListing)) {
        showErrorToast({
          message: locales.message_expired_opportunity
        })
        return
      }

      store.state.applicationInsights?.trackPageView({name: `/OpportunityPreview/${opportunityPreview.value.id}`})
      openApplyDialog()
    }

  function verifyOpportunityHasExpired(opportunityListing: OpportunityListing) {
    if (!opportunityListing.applicationDeadline) {
      return false
    }
    const currentDate = new Date()
    const applicationDeadline = new Date(opportunityListing.applicationDeadline)
    return applicationDeadline < currentDate
  }

  async function openApplyDialog(){
    switch(opportunityPreview.value.opportunityListing?.applyType) {
        case 'EmailReceive':
          // We're specifically not creating an Application in JN because this is externally managed
          window.open('mailto:' + opportunityPreview.value.opportunityListing.applicationReceiveEmail, '_self')
          break
        case 'ExternalDirect':
          // We're specifically not creating an Application in JN because this is externally managed
          window.open(opportunityPreview.value.opportunityListing.externalApplicationUrl, '_blank')
          break
        case 'InternalManage':
          await applyOpportunityForm.value!.setCandidateValues()
          applyOpportunityForm.value!.toggleModal(true)
          break
        default:
          console.error('Opportunity listing does not have a valid apply type!')
      }
  }

  function applyToOpportunity(){
    hasApplied.value = true
    applyOpportunityForm.value!.toggleModal(false)
  }

    return {
      isLoggedIn,
      loading,
      errorLoading,
      summaryDetails,
      openOpportunityDetailsToApply,
      opportunityPreview,
      locales,
      verifyOpportunityHasExpired,
      openApplyDialog,
      applyOpportunityForm,
      applyToOpportunity,
      hasApplied
    }
  }
}
