
import { computed, defineComponent, PropType, ref } from 'vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import DialogWrapper, { DialogInstance } from '@/components/Shared/DialogWrapper.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import DownloadAttachments from '@/components/DownloadAttachments/DownloadAttachements.vue'
import ProfilePicPlaceholder from '~/icons/ProfilePicPlaceholder.svg'
import locales from '@/locales/en/locales.json'
import tableLocals from '@/components/JobOpportunitiesTable/JobOpportunitiesTable.locales.en.json'
import { JobOpportunity, RequirementsToApply } from '@/types/opportunity'
import { formatOpportunityType, formatOpportunityLocation, formatOpportunityCompensation } from '@/utils/opportunity'
import { FileDescriptionTextValues } from '@/types/fileMetadata/fileDescription'
import YoutubeEmbed from '@/components/YoutubeEmbed.vue'

const OpportunityDetails = defineComponent({
  name: 'OpportunityDetails',
  components: {
    DialogWrapper,
    ProfilePicPlaceholder,
    ScrollLayout,
    TextEditor,
    DownloadAttachments,
    YoutubeEmbed
  },
  props: {
    id: {
      type: String,
      required: true
    },
    opportunity: {
      type: Object as PropType<JobOpportunity>,
      required: true
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    applied: {
      type: Boolean,
      default: false
    }
  },
  emits: ['apply-clicked'],
  setup(props, { emit } ) {
    const dialog = ref<DialogInstance>()

    function handleClick() {
      emit('apply-clicked')
    }

    function toggleModal(status: boolean) {
      status ? dialog.value?.open() : dialog.value?.close()
    }

    const compensation = computed(() => {
      const formattedSalary = formatOpportunityCompensation(props.opportunity.opportunityListing)
      return !formattedSalary ? tableLocals.text_salary_not_available : formattedSalary
    })

    const formatDate = (dateAsString: Date | undefined, includeTime = false): string => {
      if (dateAsString) {
        if (includeTime) {
          return dateAsString.toLocaleString(undefined, {
            day: 'numeric',
            year: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: '2-digit'
          })
        } else {
          return dateAsString.toLocaleString(undefined, {
            day: 'numeric',
            year: 'numeric',
            month: 'long'
          })
        }
      }

      return ''
    }

    const requirements = computed(() => {
      const requirementsToApply = RequirementsToApply
            .filter(rta => props.opportunity.opportunityListing?.requirementsToApply?.includes(rta.value))
            .map(rta => rta.text)

      const fileRequirementsToApply = FileDescriptionTextValues
        .filter(fdtv => props.opportunity.opportunityListing?.fileRequirementsToApply?.includes(fdtv.value))
        .map(fdtv => fdtv.text)

      return [...requirementsToApply, ...fileRequirementsToApply]
    })

    const practiceAreas = computed(() =>
      props.opportunity.opportunityListing?.practiceAreas
    )

    const applyButtonText = computed(() =>
      (props.opportunity.opportunityListing?.applyType ?? 'InternalManage') === 'InternalManage' ?
      locales.btn_apply :
      locales.btn_external_apply
    )

    return {
      locales,
      handleClick,
      dialog,
      toggleModal,
      formatDate,
      formatOpportunityLocation,
      formatOpportunityType,
      compensation,
      requirements,
      applyButtonText,
      practiceAreas
    }
  }
})

export default OpportunityDetails
export type OpportunityDetailsInstance = InstanceType<typeof OpportunityDetails>
