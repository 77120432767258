import { logDebugMessage } from '@/logging'
import { validate } from 'validate.js'

function IsValidUrl(website: string): boolean {
  return validate({website: website}, {website: {url: {schemes: ['http','https'], presence: true,  url: true}}}) === undefined
}

function IsLinkedInUrl(link: string): URL | undefined {
  let linkUrl: URL

  if(link.length > 256){
    return undefined
  }

  try{
    linkUrl = new URL(link)
  } catch {
    return undefined
  }

  if(!linkUrl.protocol.match(/https?:/i)){
    return undefined
  }

  if(linkUrl.port){
    return undefined
  }

  if(!linkUrl.hostname.match(/(?:^|\.)linkedin\.com/i)){
    logDebugMessage(linkUrl.hostname)
    return undefined
  }

  return linkUrl
}

function IsValidUserLinkedInUrl(link?: string): boolean {
  if(!link){
    return true
  }

  const linkUrl = IsLinkedInUrl(link)

  if(!linkUrl?.pathname.match(/\/(?:in|pub|profile)\//i)){
    return false
  }

  return true
}

function IsValidCompanyLinkedInUrl(link?: string): boolean {
  if(!link){
    return true
  }

  const linkUrl = IsLinkedInUrl(link)

  if(!linkUrl?.pathname.match(/\/(?:co|company)\//i)){
    return false
  }

  return true
}

function IsValidYouTubeUrl(link?: string): boolean {
  if(!link){
    return true
  }

  const match = link?.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/)

  if(!match || match[7].length != 11) {
    return false
  }

  return true
}

export { IsValidUrl, IsValidUserLinkedInUrl, IsValidCompanyLinkedInUrl, IsValidYouTubeUrl }
