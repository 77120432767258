import { Ref, DefineComponent, watch } from 'vue'
import { DomHandler } from 'primevue/utils'

export const useDropdownOffsetPatch = <S extends object>(component: Ref<InstanceType<DefineComponent & S> | undefined>, appendTo: Ref<string>, panelClass: string) => {
  
  watch(component, (newAutoComplete?: Record<string, unknown>) => {
    if (!newAutoComplete) {
      return
    }
    const { alignOverlay } = newAutoComplete
    if (typeof alignOverlay !== 'function') {
      console.error('Missing "alignOverlay" method, please check PrimeVue source code')
      return
    }
    newAutoComplete.alignOverlay = function(...args: unknown[]) {
      const result = alignOverlay.apply(this, args)
      fixPanelAlignment()
      return result
    }
  })

  function fixPanelAlignment() {
    // The dropdown panel offset is incorrect when this three conditions happens simultaneously:
    // 1. The dropdown is inside a dialog
    // 2. The append-to is distinct than 'body' or 'self'
    // 3. The document vertical scroll is distinct than zero
    // The dropdown panel is being corrected here by subtracting the current vertical scroll.
    if (appendTo.value !== 'body' && appendTo.value !== 'self') {
      const dropdownPanel = document.querySelector<HTMLDivElement>(panelClass)
      if (dropdownPanel) {
        const top = parseInt(dropdownPanel.style.top)
        const offset = DomHandler.getWindowScrollTop()
        dropdownPanel.style.top = `${top - offset}px`
      }
    }
  }
}
