
import { onMounted } from 'vue'
import BsSpinner from '@/components/CustomBootstrap/BsSpinner/BsSpinner.vue'
import { redirectToSSU } from '@/signin'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BsSpinner
  },
  setup() {

    onMounted(async () => {
       if(store.state.authenticated) {
        router.push('/')
      } else {
        await redirectToSSU()
      }
    })

    return {}
  }
}
