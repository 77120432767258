import { reactive } from 'vue'
import type {
  IEmployerJobPostStatusViewModel,
  IEmployerJobPostBoardStore,
  IEmployerJobPostBoardViewModel,
  IEmployerJobPostBoardActions,
  IEmployerJobPostViewModel,
  EmployerJobPostStatusName
} from './EmployerJobPost.interfaces'
import { GetOpportunitiesByEmployer } from '@/api/OpportunityApi'
import { JobOpportunity } from '@/types/opportunity'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { formatOpportunityLocation, formatOpportunityType } from '@/utils/opportunity'
import { formatDateToDays } from '@/utils/date'
import locales from './EmployerJobPost.locales.en.json'

export function useEmployerJobPostBoardStore(): IEmployerJobPostBoardStore {
  const { showErrorToast } = useToast()

  const state: IEmployerJobPostBoardViewModel = reactive({
    jobStatuses: [],
    isLoading: false,
    isReady: false
  })

  const actions: IEmployerJobPostBoardActions = {
    loadJobStatuses,
    loadJobPostsByStatus
  }

  function createEmployerJobPost(jobOpportunity: JobOpportunity): IEmployerJobPostViewModel {
    return reactive({
      opportunityId: jobOpportunity.id,
      status: jobOpportunity.status,
      title: jobOpportunity.opportunityListing?.title ?? '',
      opportunityType: formatOpportunityType(jobOpportunity.opportunityListing?.opportunityType),
      location: formatOpportunityLocation(jobOpportunity.opportunityListing),
      daysAgo: formatDateToDays(new Date()) - formatDateToDays(jobOpportunity.opportunityListing?.postedDate),
      applicants: jobOpportunity.numberOfApplications,
      notReviewed: jobOpportunity.numberOfApplicationsNotReviewed,
      isLoading: false,
      isReady: true
    })
  }

  function createDefaultEmployerJobStatus(status: EmployerJobPostStatusName, displayName: string): IEmployerJobPostStatusViewModel {
    return reactive({
      name: status,
      displayName,
      posts: [],
      isLoading: false,
      isReady: false,
      isActive: false,
      jobOpportunities: [],
      currentPage: 0,
      hasAnotherPage: false,
      filter: undefined
    })
  }

  async function loadJobStatuses() {
    state.jobStatuses = [
      createDefaultEmployerJobStatus('Published', 'Published'),
      createDefaultEmployerJobStatus('Draft', 'Draft'),
      createDefaultEmployerJobStatus('Expired', 'Archived')
    ]
    state.isReady = true
    state.isLoading = false
  }

  async function loadJobPostsByStatus(jobStatus: IEmployerJobPostStatusViewModel) {
    const pageSize = 10
    try {
      jobStatus.isLoading = true
      const jobOpportunities = await GetOpportunitiesByEmployer(jobStatus.name, jobStatus.currentPage, pageSize, jobStatus.filter)
      jobStatus.jobOpportunities = [...jobStatus.jobOpportunities, ...jobOpportunities]
      jobStatus.posts = [...jobStatus.posts, ...jobOpportunities.map(createEmployerJobPost)]
      jobStatus.isReady = true
      jobStatus.hasAnotherPage = jobOpportunities.length == pageSize
    } catch {
      showErrorToast({
        message: locales.error_retrieve_opportunities
      })
    } finally {
      jobStatus.isLoading = false
    }
  }

  return { state, actions }
}

export default useEmployerJobPostBoardStore
