
import { defineComponent, PropType } from 'vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import { OpportunityListing } from '@/types/opportunity'
import { DropdownOptionModel } from '@/components/DropdownOptions/DropdownOptions.interfaces'

type CreateOpportunityActionKey = 'preview' | 'saveDraft' | 'publish'

export const dropdownOptions: DropdownOptionModel<CreateOpportunityActionKey>[] = [
  {
    label: 'Preview',
    key: 'preview',
    icon: 'binoculars'
  },
  {
    label: 'Save as Draft',
    key: 'saveDraft',
    icon: 'journal-bookmark-fill'
  },
  {
    label: 'Publish',
    key: 'publish',
    icon: 'box-arrow-right'
  }
]

export default defineComponent({
  name: 'ActionsButton',
  components: {
    DropdownOptions
  },
  props: {
    formData: {
      type: Object as PropType<OpportunityListing>,
      required: true
    },
    employerName : {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['publish', 'saveDraft', 'preview'],
  setup(_, { emit }) {

    function handleOptionChange(option: DropdownOptionModel<CreateOpportunityActionKey>) {
      emit(option.key)
    }

    return {
      dropdownOptions,
      handleOptionChange
    }
  }
})
