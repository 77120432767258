
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { PropType } from 'vue'
import { GetOpportunitySupportingFileDataByDomainObjectId } from '@/api/OpportunityApi'
import locales from './DownloadAttachments.locales.en.json'

export default {
  props: {
    opportunityId: {
      type: String,
      default: undefined
    },
    attachments: {
      type: Object as PropType<FileMetadata[]>,
      default: undefined
    }
  },
  //@ts-ignore no-implicit-any
  setup(props) {
    async function downloadAttachment(attachment: FileMetadata) {
      if (!props.opportunityId || !attachment.domainObjectId) {
        return
      }
      const result = await GetOpportunitySupportingFileDataByDomainObjectId(props.opportunityId, attachment.domainObjectId)
      if(!result) return

      const fileURL = window.URL.createObjectURL(result)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', attachment.fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    }

    return {
      locales,
      downloadAttachment
    }
  }
}
