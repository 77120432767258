import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-483e6962"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-employer-profile-content" }
const _hoisted_2 = { class: "header-content" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 col-sm-12" }
const _hoisted_5 = {
  id: "employer-profile-heading",
  class: "heading"
}
const _hoisted_6 = { class: "col-md-6 col-sm-12 text-right p-0" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "form-content" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  class: "BsTextField__label form-label d-flex",
  for: "file-input"
}
const _hoisted_13 = { id: "fileInput" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmployerProfileWelcomeBanner = _resolveComponent("EmployerProfileWelcomeBanner")!
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_BsButton = _resolveComponent("BsButton")!
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_attachment_upload = _resolveComponent("attachment-upload")!
  const _component_ScrollLayout = _resolveComponent("ScrollLayout")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_EditConfirmationModal = _resolveComponent("EditConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      ref: "dialogRef",
      class: "employer-profile-form",
      "aria-labelled-by": "employer-profile-heading",
      horizontal: "end",
      vertical: "start"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("form", {
            novalidate: "",
            class: "form-main",
            onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)), ["prevent"]))
          }, [
            _createVNode(_component_ScrollLayout, null, {
              "header-left": _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.locales.employer_profile), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, [
                        (!_ctx.forceComplete)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              id: "edit-employer-profile-cancel",
                              class: "btn btn-text-primary cancel-btn",
                              type: "button",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModal(false)))
                            }, _toDisplayString(_ctx.locales.cancel), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          id: "edit-employer-profile-save",
                          class: "btn btn-primary",
                          type: "submit",
                          disabled: _ctx.isSavingProfile
                        }, _toDisplayString(_ctx.locales.save), 9, _hoisted_7)
                      ])
                    ])
                  ])
                ])
              ]),
              body: _withCtx(() => [
                (_ctx.forceComplete)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_EmployerProfileWelcomeBanner)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_ProfilePicture, {
                      id: "edit-employer-profile-profile-picture",
                      role: "button",
                      "employer-id": _ctx.employerDetails.employer.id,
                      "image-source": _ctx.logoImageSource,
                      "revoke-object-url-on-unmounted": false,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.logoImageInput.click()))
                    }, null, 8, ["employer-id", "image-source"]),
                    _createVNode(_component_BsButton, {
                      id: "edit-employer-profile-edit-profile-picture",
                      mode: "link",
                      label: _ctx.locales.add_logo,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.logoImageInput.click()))
                    }, null, 8, ["label"])
                  ]),
                  _createElementVNode("input", {
                    ref: "logoImageInput",
                    class: "d-none",
                    type: "file",
                    accept: "image/*",
                    onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleLogoImageFileSelected && _ctx.handleLogoImageFileSelected(...args)))
                  }, null, 544),
                  _createVNode(_component_BsTextField, {
                    id: "edit-employer-profile-employer-name",
                    modelValue: _ctx.formFields.employerName,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formFields.employerName) = $event)),
                    class: "mb-3",
                    label: _ctx.locales.firm_employer,
                    placeholder: _ctx.locales.firm_employer,
                    validation: _ctx.v$.employerName,
                    disabled: _ctx.isSavingProfile
                  }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled"]),
                  _createVNode(_component_BsTextField, {
                    id: "edit-employer-profile-linked-in-url",
                    modelValue: _ctx.companyLinkedin,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.companyLinkedin) = $event)),
                    class: "mb-3",
                    label: _ctx.locales.employer_linkedin,
                    placeholder: _ctx.locales.employer_linkedin,
                    validation: _ctx.v$.linkedin,
                    disabled: _ctx.isSavingProfile
                  }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled"]),
                  _createVNode(_component_BsTextField, {
                    id: "edit-employer-profile-website-url",
                    modelValue: _ctx.companyWebsite,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyWebsite) = $event)),
                    class: "mb-3",
                    label: _ctx.locales.employer_website,
                    placeholder: _ctx.locales.employer_website,
                    validation: _ctx.v$.website,
                    disabled: _ctx.isSavingProfile
                  }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled"]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_BsAutoComplete, {
                      id: "edit-employer-profile-locations",
                      modelValue: _ctx.selectedCities,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedCities) = $event)),
                      class: "mb-3",
                      suggestions: _ctx.filteredCities,
                      label: _ctx.locales.office_locations,
                      multiple: true,
                      field: "text",
                      validation: _ctx.v$.locations,
                      disabled: _ctx.isSavingProfile,
                      onComplete: _ctx.handleLocationSearch
                    }, null, 8, ["modelValue", "suggestions", "label", "validation", "disabled", "onComplete"])
                  ]),
                  _createVNode(_component_BsSelect, {
                    id: "edit-employer-profile-employer-type",
                    modelValue: _ctx.formFields.employerType,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formFields.employerType) = $event)),
                    class: "mb-3",
                    label: _ctx.locales.employer_type,
                    validation: _ctx.v$.employerType,
                    disabled: _ctx.isSavingProfile,
                    options: [
                  {
                    text: _ctx.locales.employer_type,
                    value: '',
                    disabled: true
                  },
                  ..._ctx.EmployerTypes
                ],
                    "option-label": "text",
                    "option-value": "value",
                    "option-disabled": "disabled",
                    "default-selected-value": ""
                  }, null, 8, ["modelValue", "label", "validation", "disabled", "options"]),
                  _createVNode(_component_TextEditor, {
                    id: "edit-employer-profile-about-us",
                    modelValue: _ctx.formFields.about,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formFields.about) = $event)),
                    class: "mb-3",
                    label: _ctx.locales.about_employer,
                    disabled: _ctx.isSavingProfile
                  }, null, 8, ["modelValue", "label", "disabled"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.locales.attach_file), 1),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_attachment_upload, {
                        disabled: _ctx.isSavingProfile,
                        files: _ctx.files,
                        limit: 5,
                        onOnUpload: _ctx.handleFileUploadClicked,
                        onOnRemove: _ctx.handleRemoveFile
                      }, null, 8, ["disabled", "files", "onOnUpload", "onOnRemove"])
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ], 32)
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_EditConfirmationModal, {
      ref: "editConfirmationModal",
      onAgree: _ctx.handleAgree
    }, null, 8, ["onAgree"])
  ], 64))
}