import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsPhoneNumberDropdown = _resolveComponent("BsPhoneNumberDropdown")!

  return (_openBlock(), _createBlock(_component_BsPhoneNumberDropdown, {
    modelValue: _ctx.phoneNumber,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneNumber) = $event)),
    placeholder: _ctx.placeholder,
    validation: _ctx.v$.phoneNumber
  }, null, 8, ["modelValue", "placeholder", "validation"]))
}