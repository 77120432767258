import { validate } from 'validate.js'

function IsValidString(s : string) : boolean {
  return !!s
}

function IsValidDate(date: Date | null | undefined): date is Date {
  return !!date && !isNaN(date.getTime())
}

function IsValidDescription(description?: string, length?: number): boolean {
  if (!description) {
    return true
  }

  if (length && length <= 2000) {
    return true
  } else {
    const lengthFinder = document.createElement('div')
    lengthFinder.innerHTML = description
    if (lengthFinder.innerText.length <= 2000) {
      return true
    }
  }

  return false
}

function IsValidEmail(email: string) {
  return (validate({ email: email }, { email: { presence: true, email: true } }) === undefined)
}

function FalsyOrGreaterThan100Chars(val: string | undefined): boolean {
  return !val || val.length > 100
}

export {
  IsValidString,
  IsValidDate,
  IsValidDescription,
  IsValidEmail,
  FalsyOrGreaterThan100Chars
}
