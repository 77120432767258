
import { onMounted, Ref, ref } from 'vue'
import router from '@/router'
import ValidateOtp from '@/components/ValidateOtp.vue'
import { GetAgentProfile, SendOtp, VerifyOtp } from '@/api/AgentApi'
import { GetEmployerId } from '@/okta'
import { AgentProfile } from '@/types/agent'
import store from '@/store'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

export default {
  components: {
    ValidateOtp
  },
  setup() {
    const agentProfile = ref({}) as Ref<AgentProfile>
    const generatingOtp = ref(false)
    const validatingOtp = ref(false)
    const employerId = ref('')

    const { showSuccessToast, showErrorToast } = useToast()

    onMounted(async () => {
      var potentialAgent = store.state.agentProfile ?? await GetAgentProfile()
      if(potentialAgent) {
        agentProfile.value = potentialAgent
      } else {
        router.push('/SelectUserType')
      }

      employerId.value = await GetEmployerId()
      await generateOtp()
    })

    const generateOtp = async () => {
      generatingOtp.value = true

      try {
        await SendOtp(employerId.value)
        showSuccessToast({
          message: 'A new code has been sent to ' + agentProfile.value.emailAddress,
          position: 'top-center'
        })
      } catch {
        showErrorToast({
          message: 'There was an error generating your verification email. Please try again',
          position: 'top-center'
        })
      } finally {
        generatingOtp.value = false
      }
    }

    const verifyOtp = async (otpToValidate: string) => {
      validatingOtp.value = true

      let isOtpValid
      try {
        isOtpValid = await VerifyOtp(otpToValidate, employerId.value)
      } catch {
        showErrorToast({
          message: 'There was an error verifiying the code. Please try again',
          position: 'top-center'
        })
        return
      } finally {
        validatingOtp.value = false
      }

      if (isOtpValid) {
        store.state.agentProfile = undefined
        router.push('/EmployerJobOpportunitiesSummary')
      } else {
        showErrorToast({
          message: 'Invalid code provided. Please try again, or generate a new code',
          position: 'top-center'
        })
      }
    }

    return {
      agentProfile,
      generatingOtp,
      validatingOtp,
      generateOtp,
      verifyOtp
    }
  }
}
