
export default {
  props: {
    count: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean
    }
  }
}
