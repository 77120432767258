import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0268c970"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-content w-100" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 col-sm-12" }
const _hoisted_4 = {
  id: "filter-jobs-heading",
  class: "heading"
}
const _hoisted_5 = { class: "col-md-6 col-sm-12 text-end p-0" }
const _hoisted_6 = {
  class: "btn btn-primary",
  type: "submit",
  form: "opportunities-filter-form"
}
const _hoisted_7 = { class: "form-content" }
const _hoisted_8 = { class: "filter-opportunity-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")!
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")!
  const _component_LocationFilter = _resolveComponent("LocationFilter")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    class: "filter-opportunity-content",
    modal: true,
    style: { width: '50vw' },
    draggable: false,
    "dismissable-mask": true,
    breakpoints: { '640px': '100vw' },
    visible: _ctx.visible,
    position: "topright",
    "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:visible', $event)))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.locales.title_filter), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-outline-primary me-3",
                type: "button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
              }, _toDisplayString(_ctx.locales.button_reset), 1),
              _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.locales.button_search), 1)
            ])
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        id: "opportunities-filter-form",
        ref: "form",
        novalidate: "",
        onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleFilterApplied && _ctx.handleFilterApplied(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.locales.section_job_search), 1),
          _createVNode(_component_BsSelect, {
            modelValue: _ctx.postedWithinHours,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postedWithinHours) = $event)),
            class: "mb-3",
            label: _ctx.locales.label_date_posted,
            placeholder: _ctx.locales.placeholder_date_posted,
            options: _ctx.datePostedFilterOptions,
            "option-label": "text",
            "option-value": "value"
          }, null, 8, ["modelValue", "label", "placeholder", "options"]),
          _createVNode(_component_BsMultiSelect, {
            modelValue: _ctx.opportunityTypes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.opportunityTypes) = $event)),
            class: "mb-3",
            label: _ctx.locales.label_opportunity_type,
            options: _ctx.OpportunityTypes,
            "option-label": "text"
          }, null, 8, ["modelValue", "label", "options"]),
          _createVNode(_component_BsMultiSelect, {
            modelValue: _ctx.requirementsToApply,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.requirementsToApply) = $event)),
            class: "mb-3",
            options: _ctx.requirementsToApplyOptions,
            label: _ctx.locales.label_requirements,
            "option-label": "text"
          }, null, 8, ["modelValue", "options", "label"]),
          _createVNode(_component_BsCheckbox, {
            modelValue: _ctx.isRemote,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isRemote) = $event)),
            class: "mb-3",
            label: "Remote"
          }, null, 8, ["modelValue"]),
          (_ctx.isRemote)
            ? (_openBlock(), _createBlock(_component_BsSelect, {
                key: 0,
                modelValue: _ctx.selectedCountryCode,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedCountryCode) = $event)),
                label: _ctx.locales.label_country,
                placeholder: _ctx.locales.placeholder_country,
                options: _ctx.CountryAddressDetails,
                "option-label": "name",
                "option-value": "isoCode"
              }, null, 8, ["modelValue", "label", "placeholder", "options"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.authenticated)
                  ? (_openBlock(), _createBlock(_component_LocationFilter, {
                      key: 0,
                      modelValue: _ctx.selectedLocations,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedLocations) = $event)),
                      label: "Locations"
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ], 64))
        ])
      ], 544)
    ]),
    _: 1
  }, 8, ["visible"]))
}