
import { defineComponent, ref, DefineComponent } from 'vue'
import VueCropper from 'vue-cropperjs'
import type Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import DialogWrapper from '@/components/Shared/DialogWrapper.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import locales from './ProfilePictureUpload.locales.en.json'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { logErrorMessage } from '@/logging'

export default defineComponent({
  components: { VueCropper, DialogWrapper, ScrollLayout },
  props: {
    isCandidate : Boolean
  },
  emits: ['cropped-picture-as-blob'],
  setup(props, { emit }) {
    const { showErrorToast } = useToast()
    const dialog = ref<InstanceType<typeof DialogWrapper>>()
    const cropper = ref<InstanceType<DefineComponent> & Cropper>()
    const pictureSrc = ref<string>(require('~/img/Default-ProfilePic.png'))
    const croppedPictureSrc = ref<string>('')

    async function openWithFile(file: File) {
      if (file.type.indexOf('image/') === -1) {
        showErrorToast({
          message: locales.select_picture_error_message
        })
        return
      }
      try {
        const result = await convertFileToDataURL(file)
        dialog.value?.open()
        pictureSrc.value = result as string
        cropper.value?.replace(result as string)
      } catch (error: any) {
        logErrorMessage(error)
        showErrorToast({
          message: locales.cannot_process_image_error_message
        })
      }
    }

    async function convertFileToDataURL(file: File) {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            if (!event?.target?.result) {
              reject(new Error('Invalid result'))
              return
            }
            resolve(event.target.result)
          }
          reader.readAsDataURL(file)
        } else {
          reject(new Error('Sorry, FileReader API not supported'))
        }
      })
    }

    function handleCropPicture() {
      croppedPictureSrc.value = cropper.value?.getCroppedCanvas().toDataURL() ?? ''
    }

    async function handleSubmitPicture() {
      if (!croppedPictureSrc.value) {
        showErrorToast({
          message: locales.no_picture_error_message
        })
        return
      }
      cropper.value?.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          emit('cropped-picture-as-blob', blob)
          handleCancel()
        }
      }, 'image/jpeg', 0.8)
    }

    function handleReset() {
      croppedPictureSrc.value = ''
    }

    function handleCancel() {
      croppedPictureSrc.value = ''
      dialog.value?.close()
    }

    return {
      locales,
      dialog,
      cropper,
      pictureSrc,
      croppedPictureSrc,
      openWithFile,
      handleCropPicture,
      handleSubmitPicture,
      handleReset,
      handleCancel
    }
  }
})
