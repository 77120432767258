
import { defineComponent, computed, PropType } from 'vue'
import locales from '../CandidateProfile.locales.en.json'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { GetUserId } from '@/okta'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: undefined
    },
    addLabel: {
      type: String,
      default: undefined
    },
    id: {
      ...createAutoIncrementIdProp('FileInput'),
      type: String,
      required: true
    },
    fileItems: {
      type: Object as PropType<Array<FileMetadata>>,
      default: [] as PropType<Array<FileMetadata>>
    },
    maxFiles: {
      type: Number,
      default: 0
    },
    accept: {
      type: String,
      default: null
    },
    category: {
      type: String,
      default: undefined
    },
    noFilesLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'file-upload-clicked',
    'file-download-clicked',
    'file-delete-clicked'
  ],
  setup(props, { emit }) {
    const handleFileChange = (evnt: Event): void => {
      if (hideUpload.value) {
        return
      }

      const files = (evnt.target as HTMLInputElement).files

      if (!files || !files.length) {
        return
      }
      emit('file-upload-clicked', files[0], props.category)
    }

    const handleDownloadClicked = async (existingFile: FileMetadata): Promise<void> => {
      if (!existingFile) {
        return
      }
      const candidateId = await GetUserId()
      emit('file-download-clicked', candidateId, existingFile)
    }

    const hideUpload = computed<boolean>((): boolean => {
      return props.maxFiles > 0 && props.fileItems.length >= props.maxFiles
    })

    const sortedFiles = computed<Array<FileMetadata>>(
      (): Array<FileMetadata> => {
        if (!props.fileItems) {
          return []
        }
        const sortedItems = [...props.fileItems]
        sortedItems.sort((a: FileMetadata, b: FileMetadata) =>
          b.fileUploadTimestamp
            ?.toString()
            .localeCompare(a.fileUploadTimestamp?.toString())
        )
        return sortedItems
      }
    )

    function addFile(id: string) {
      if (hideUpload.value) {
        return
      }
      let getInput = document.getElementById(id)
      if (getInput) {
        getInput.click()
      }
    }

    const computeSelectedCount = computed(() => {
      return sortedFiles.value.length + ' ' + locales.file_selected
    })

    function handleDelete(existingFile: FileMetadata) {
      emit('file-delete-clicked', existingFile)
    }

    return {
      locales,
      addFile,
      hideUpload,
      sortedFiles,
      handleFileChange,
      handleDownloadClicked,
      computeSelectedCount,
      FileDescription,
      handleDelete
    }
  }
})
