
import { defineComponent } from 'vue'
import { FloatingMenu } from '@tiptap/vue-3'

enum ActionItems {
  h1 = 'h1',
  h2 = 'h2',
  ul = 'ul'
}

type ActionItem = ActionItems.h1 | ActionItems.h2 | ActionItems.ul

export default defineComponent({
  components: {
    FloatingMenu
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const commands = {
      [ActionItems.h1]: () =>
        props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
      [ActionItems.h2]: () =>
        props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
      [ActionItems.ul]: () =>
        props.editor.chain().focus().toggleBulletList().run()
    }

    function handleActionClick(val: ActionItem) {
      commands[val]()
    }

    return {
      handleActionClick
    }
  }
})
