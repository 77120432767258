import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", [_ctx.modeClassMapping[_ctx.mode], _ctx.sizeClassMapping[_ctx.size]]]),
    type: _ctx.type
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["bi", _ctx.icon])
        }, null, 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}