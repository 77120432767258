
import { defineComponent } from 'vue'
import DefaultProfilePic from '../../../../public/icons/Applicants/Default-ProfilePic.svg'
import locales from '@/locales/en/locales.json'

export default defineComponent({
  components: {
    DefaultProfilePic
  },
  props: {
    name: {
      type: String
    },
    designation: {
      type: String
    },
    email: {
      type: String
    }
  },
  setup() {
    return {
      locales
    }
  }
})
