import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    maxlength: "1",
    size: "1",
    disabled: $props.disabled,
    onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.clearValue && $setup.clearValue(...args))),
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.setValue && $setup.setValue(...args)))
  }, null, 40, _hoisted_1))
}