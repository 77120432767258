import { TextValue } from '@/types'
import { Models } from 'azure-maps-rest'

interface EmployerProfile {
  id: string,
  name: string,
  employerType?: EmployerTypeKey
  description: string
  descriptionLength: number
  linkedInLink?: string
  website: string
  officeLocations: Array<Models.SearchResultAddress>
  status?: string
}

type EmployerTypeKey =
  | 'Academia'
  | 'DivEqIncPersonnel'
  | 'GovStateFedAgency'
  | 'InHouseBankingFinancial'
  | 'InHouseCorporation'
  | 'InHouseTech'
  | 'InHouseVentureCapital'
  | 'JudicialClerk'
  | 'LegalRecruiterPersonnel'
  | 'Nonprofit'
  | 'PrivateBig'
  | 'PrivateMid'
  | 'PrivateSmall'
  | 'PublicInterestLegalOrg'

const EmployerTypes: Array<TextValue<EmployerTypeKey>> = [
  { text: 'Academia', value: 'Academia' },
  { text: 'Diversity, Equity & Inclusion Personnel', value: 'DivEqIncPersonnel' },
  { text: 'Government - State/Federal Agency', value: 'GovStateFedAgency' },
  { text: 'In House Legal Department - Banking / Financial Services', value: 'InHouseBankingFinancial' },
  { text: 'In House Legal Department - Corporation', value: 'InHouseCorporation' },
  { text: 'In House Legal Department - Technology Company', value: 'InHouseTech' },
  { text: 'In House Legal Department - Venture Capital', value: 'InHouseVentureCapital' },
  { text: 'Judicial Clerk', value: 'JudicialClerk' },
  { text: 'Legal Recruiter Personnel', value: 'LegalRecruiterPersonnel' },
  { text: 'Nonprofit Organization', value: 'Nonprofit' },
  { text: 'Private Practice - BigLaw (350+ lawyers)', value: 'PrivateBig' },
  { text: 'Private Practice - Mid-sized (16-350 lawyers)', value: 'PrivateMid' },
  { text: 'Private Practice - Small-sized (2-15 lawyers)', value: 'PrivateSmall' },
  { text: 'Public Interest Legal Organization', value: 'PublicInterestLegalOrg' }
]

export { EmployerProfile, EmployerTypeKey, EmployerTypes }
