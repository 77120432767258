const filterTypes = ['string', 'number']

export const filterTableValues = (
  arr: any,
  searchFields: string[],
  searchText: string
) => {
  return arr.filter((val: any) => {
    return searchFields.some(
      (key: string) =>
        val[key] &&
        filterTypes.includes(typeof val[key]) &&
        val[key].toString().toLowerCase().includes(searchText.toLowerCase())
    )
  })
}

type SelectedItems = number | string

export const handleTableSelections = () => {
  const handleSelectAll = (state: boolean, data: any, key: SelectedItems) => {
    const selectedItems: SelectedItems[] = []

    if (state) {
      data.forEach((val: any) => {
        selectedItems.push(val[key])
      })
    }
    return selectedItems
  }

  const handleRowSelection = (
    selected: SelectedItems[],
    data: any,
    id: SelectedItems
  ) => {
    if (selected.includes(id)) {
      const filtered = selected.filter((val: SelectedItems) => val !== id)
      selected = filtered
    } else {
      selected.push(id)
    }

    return {
      isAllSelected: selected.length === data.length,
      selected: selected
    }
  }

  return {
    handleSelectAll,
    handleRowSelection
  }
}
