import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b28e3e0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "awards-segment" }
const _hoisted_2 = { class: "bordered-section" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "left-segment font-light-heading text-start col-6 text-uppercase" }
const _hoisted_5 = { class: "right-segment font-light-heading text-end col-6 text-uppercase" }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "left-segment col-6" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "right-segment text-end col-6" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "bordered-section" }
const _hoisted_12 = { class: "font-light-heading text-start pb-1 text-uppercase" }
const _hoisted_13 = {
  key: 0,
  class: "row"
}
const _hoisted_14 = ["id"]
const _hoisted_15 = { class: "font-light-heading pb-1 text-uppercase" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["id"]
const _hoisted_18 = {
  key: 1,
  id: "view-awards-student-organizations-0",
  class: "label-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingWrapper = _resolveComponent("HeadingWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingWrapper, {
      "hide-options": _ctx.viewOnly,
      heading: _ctx.locales.awards_honors,
      "dropdown-options": _ctx.otherSubMenuOptions,
      onOptionChange: _ctx.handleOptionChange
    }, null, 8, ["hide-options", "heading", "dropdown-options", "onOptionChange"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.locales.law_review_journal_experience), 1),
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.locales.title), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.details.lawReviewJournalExperiences || []), (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "row-item"
        }, [
          item
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h6", {
                    id: `view-law-review-journal-${i}`,
                    class: "font-bold-heading text-start ellipsis"
                  }, _toDisplayString(item.lawReviewJournal), 9, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h6", {
                    id: `view-title-position-${i}`,
                    class: "text-end ellipsis"
                  }, _toDisplayString(item.titlePosition), 9, _hoisted_10)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.locales.honors_awards), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.details.honorsAwards || []), (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "row-item"
        }, [
          item
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("h6", {
                  id: `view-awards-honors-${i}`,
                  class: "font-bold-heading text-start ellipsis"
                }, _toDisplayString(item), 9, _hoisted_14)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("h5", _hoisted_15, _toDisplayString(_ctx.locales.student_organizations), 1),
      (_ctx.getStudentOrganizations.length)
        ? (_openBlock(), _createElementBlock("label", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStudentOrganizations, (item, i) => {
              return (_openBlock(), _createElementBlock("label", {
                id: `view-awards-student-organizations-${i}`,
                key: i,
                class: "label-badge"
              }, _toDisplayString(item), 9, _hoisted_17))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("label", _hoisted_18, _toDisplayString(_ctx.locales.none), 1))
    ])
  ]))
}