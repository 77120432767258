import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-334f3694"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "table-container p-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CounterCard = _resolveComponent("CounterCard")!
  const _component_BsTab = _resolveComponent("BsTab")!
  const _component_BsTabList = _resolveComponent("BsTabList")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_BsTabPanel = _resolveComponent("BsTabPanel")!
  const _component_BsTabContainer = _resolveComponent("BsTabContainer")!
  const _component_EmployerVerificationForm = _resolveComponent("EmployerVerificationForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BsTabContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BsTabList, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_BsTab, {
                id: "pending",
                class: "card"
              }, {
                default: _withCtx(({ selected }) => [
                  _createVNode(_component_CounterCard, {
                    count: _ctx.isLoading ? '-' : _ctx.pendingEmployers.length,
                    text: _ctx.locales.pending_employers,
                    selected: selected
                  }, null, 8, ["count", "text", "selected"])
                ]),
                _: 1
              }),
              _createVNode(_component_BsTab, {
                id: "show-all",
                class: "card"
              }, {
                default: _withCtx(({ selected }) => [
                  _createVNode(_component_CounterCard, {
                    count: _ctx.isLoading ? '-' : _ctx.allEmployers.length,
                    text: _ctx.locales.show_all_employers,
                    selected: selected
                  }, null, 8, ["count", "text", "selected"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BsTabPanel, { for: "pending" }, {
            default: _withCtx(() => [
              _createVNode(_component_TableView, {
                columns: _ctx.columns,
                data: _ctx.pendingEmployers,
                "is-loading": _ctx.isLoading,
                onRowClicked: _ctx.verifyEmployer
              }, null, 8, ["columns", "data", "is-loading", "onRowClicked"])
            ]),
            _: 1
          }),
          _createVNode(_component_BsTabPanel, { for: "show-all" }, {
            default: _withCtx(() => [
              _createVNode(_component_TableView, {
                columns: _ctx.columns,
                data: _ctx.allEmployers,
                "is-loading": _ctx.isLoading
              }, null, 8, ["columns", "data", "is-loading"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_EmployerVerificationForm, {
      ref: "verifyEmployerDialog",
      "employer-info": _ctx.selectedEmployer,
      onUpdatedStatus: _ctx.getEmployers
    }, null, 8, ["employer-info", "onUpdatedStatus"])
  ], 64))
}