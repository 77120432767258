
import { Data } from '@/types'
import { computed, onMounted, PropType, ref, watch } from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/VueTools'

export default {
  components: { BsTextField },
  props: {
    id: createAutoIncrementIdProp('DateTimeInput'),
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Date,
      default: null
    },
    disableTime: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requiredField: {
      type: Boolean,
      default: false
    },
    formSubmitted: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    }
  },
  emits: ['update:modelValue'],
  //@ts-ignore no-implicit-any
  setup(props, context: any): Data {
    const dateInput = ref(false)
    const isFormSubmitted = ref(false)

    const dateString = ref('')

    let lastEmittedValue: Date | undefined

    onMounted(() => {
      lastEmittedValue = props.modelValue
      setStringsFromDate(props.modelValue)
    })

    const dateTime = computed((): Date | undefined => {
      if (!dateString.value) {
        return undefined
      }
      if(props.disableTime){
        return new Date(`${dateString.value}T00:00`)
      }

      return new Date(dateString.value)
    })

    watch(dateTime, (newValue: Date | undefined): void => {
      if (newValue === lastEmittedValue) {
        return
      }

      context.emit('update:modelValue', newValue)
      lastEmittedValue = newValue
    })

    watch(
      () => props.modelValue,
      (newValue: Date | undefined): void => {
        if (newValue === lastEmittedValue) {
          return
        }

        setStringsFromDate(newValue)
      }
    )

    watch(
      () => props.formSubmitted,
      (newValue: boolean): void => {
        isFormSubmitted.value = newValue
      }
    )

    const setStringsFromDate = (date: Date | undefined) => {
      if (date && !isNaN(date.getTime())) {
        dateInput.value = true
        let year = date.getFullYear().toString().padStart(4, '0')
        let month = (date.getMonth() + 1).toString().padStart(2, '0')
        let day = date.getDate().toString().padStart(2, '0')
        dateString.value = `${year}-${month}-${day}`
        if (!props.disableTime) {
          let hours = date.getHours().toString().padStart(2, '0')
          let mins = date.getMinutes().toString().padStart(2, '0')
          let timeString = `${hours}:${mins}`
          dateString.value = `${dateString.value}T${timeString}`
        }
      } else {
        resetValue()
      }
    }

    const resetValue = (): void => {
      dateString.value = ''
      dateInput.value = false
      lastEmittedValue = undefined
    }

    return {
      dateString,
      dateTime,
      dateInput,
      resetValue,
      isFormSubmitted
    }
  }
}
