import { TextValue } from '@/types'
import { Models } from 'azure-maps-rest'
import { EmployerTypeKey } from '../employer'
import { FileMetadata } from '../fileMetadata/fileMetadata'
import { AudienceKey } from '../opportunity'

interface Candidate {
  id: string | undefined,
  profileData: CandidateProfile,
  candidateFileMetadata: FileMetadata[],
  opportunityInvitationNames?: string[]
}

// Any fields here that are required in the canididate's profile must be kept in sync with RequiredFields in candidateValidation.ts
interface CandidateProfile {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  candidateType?: AudienceKey
  description?: string
  descriptionLength?: number
  linkedInLink?: string
  languages: Array<LanguageKey>
  certifications: Array<string>
  schoolInformation: Array<SchoolInformation>
  workExperience: Array<CandidateWorkExperience>
  lawReviewJournalExperiences: Array<LawReviewJournalExperience>,
  honorsAwards: Array<string>,
  studentOrganizations: Array<StudentOrganizationKey>,
  otherStudentOrganization?: string,
  dreamJobData: DreamJobData,
  hideProfile: boolean,
  barbriAffiliated: boolean,
  barbriAffiliations: Array<BarbriAffiliationKey>,
  candidateDiversityDisclosure: CandidateDiversityDisclosure,
  isCandidateFeedEnabled: boolean
}

interface LawReviewJournalExperience {
  lawReviewJournal: string,
  titlePosition: string
}

interface CandidateBirthday {
  month?: string
  day?: string
  year?: string
}

interface SchoolInformation {
  schoolName: string
  degree: string
  startDate: Date
  endDate: Date
  isManualEntry: boolean
  country?: string
  programType: ProgramType
}

interface DreamJobData {
  practiceAreas: Array<string>
  preferredLocations: Array<Models.SearchResultAddress>
  dreamJobTypes: Array<EmployerTypeKey>
}

interface CandidateWorkExperience {
  positionTitle: string
  candidateEmployer: string
  startYear: number | undefined
  endYear: number | undefined
}

type MonthKey =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
const ValidMonths: Array<TextValue<MonthKey>> = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' },
  { text: '11', value: '11' },
  { text: '12', value: '12' }
]

const ValidDaysMapping: { [key: string]: number } = {
  '1': 31,
  '2': 29,
  '3': 31,
  '4': 30,
  '5': 31,
  '6': 30,
  '7': 31,
  '8': 31,
  '9': 30,
  '10': 31,
  '11': 30,
  '12': 31
}

interface CandidateDiversityDisclosure {
  usDisclosure?: DiversityDisclosureUS,
  ukDisclosure?: DiversityDisclosureUK
}

interface DiversityDisclosureUK {
  ethnicity: string
  sex: string
  sexualOrientation: string
  socioEconomic: string
  age: string
  disabilityStatus: string
}

interface DiversityDisclosureUS {
  pronouns: string
  gender: string
  heritage: string
  other: Array<string>
}

const DiversityDisclosures = {
  US: {
    gender: [
      { text: 'I do not wish to disclose', value: 'I do not wish to disclose' },
      { text: 'Male', value: 'Male' },
      { text: 'Female', value: 'Female' },
      { text: 'Trans Male / Trans Man', value: 'Trans Male / Trans Man' },
      {
        text: 'Trans Female / Trans Woman',
        value: 'Trans Female / Trans Woman'
      },
      {
        text: 'Genderqueer  / Gender NonConforming',
        value: 'Genderqueer  / Gender NonConforming'
      },
      { text: 'Different Identity', value: 'Different Identity' }
    ],
    heritage: [
      { text: 'I do not wish to disclose', value: 'I do not wish to disclose' },
      {
        text: 'Non-Hispanic White or Euro-American',
        value: 'Non-Hispanic White or Euro-American'
      },
      {
        text: 'Black, Afro-Caribbean, or African American',
        value: 'Black, Afro-Caribbean, or African American'
      },
      {
        text: 'Latino or Hispanic American',
        value: 'Latino or Hispanic American'
      },
      {
        text: 'East Asian or Asian American',
        value: 'East Asian or Asian American'
      },
      {
        text: 'South Asian or Indian American',
        value: 'South Asian or Indian American'
      },
      {
        text: 'Middle Eastern or Arab American',
        value: 'Middle Eastern or Arab American'
      },
      {
        text: 'Native American or Alaskan Native',
        value: 'Native American or Alaskan Native'
      },
      { text: 'Two or more races', value: 'Two or more races' },
      { text: 'Other', value: 'Other' }
    ],
    other: [
      { text: 'I identify as openly Gay, Lesbian, Bisexual, Questioning or unsure.', value: 'I identify as openly Gay, Lesbian, Bisexual, Questioning or unsure.' },
      { text: 'First Generation - I am the first person in my immediate family to attend undergraduate college/university (excluding siblings).', value:
        'First Generation - I am the first person in my immediate family to attend undergraduate college/university (excluding siblings).' },
      { text: 'First Generation Professional - I am the first person in my immediate family to attend graduate/professional school (excluding siblings).',
        value: 'First Generation Professional - I am the first person in my immediate family to attend graduate/professional school (excluding siblings).' },
      { text: 'I have an undergraduate or graduate degree in engineering, medicine, pharmacy or in the field of natural, physical or computing sciences.',
        value: 'I have an undergraduate or graduate degree in engineering, medicine, pharmacy or in the field of natural, physical or computing sciences.' },
      { text: 'I attended a historically black college or university (HBCU)', value: 'I attended a historically black college or university (HBCU)' }
    ],
    pronouns: [
      {text: 'I do not wish to disclose', value: 'I do not wish to disclose' },
      {text: 'He/him/his', value: 'He/him/his'},
      {text: 'She/her/hers', value: 'She/her/hers'},
      {text: 'They/them/theirs', value: 'They/them/theirs'},
      {text: 'Ze/zir/zirs', value: 'Ze/zir/zirs'}
    ]
  },

  UK: {
    ethnicity: [
      { text: 'Arab', value: 'Arab' },
      { text: 'Asian', value: 'Asian' },
      {
        text: 'Asian or Asian British - Chinese',
        value: 'Asian or Asian British - Chinese'
      },
      {
        text: 'Asian or Asian British - Indian',
        value: 'Asian or Asian British - Indian'
      },
      {
        text: 'Asian or Asian British - Pakistani',
        value: 'Asian or Asian British - Pakistani'
      },
      {
        text: 'Asian or Asian British - Any other background',
        value: 'Asian or Asian British - Any other background'
      },
      { text: 'Black', value: 'Black' },
      {
        text: 'Black, African, Carribean or Black British - African',
        value: 'Black, African, Carribean or Black British - African'
      },
      {
        text: 'Black, African, Carribean or Black British - Any other background',
        value:
          'Black, African, Carribean or Black British - Any other background'
      },
      {
        text: 'Mixed/Multiple Ethnic Group - White & Carribean',
        value: 'Mixed/Multiple Ethnic Group - White & Carribean'
      },
      {
        text: 'Mixed/Multiple Ethnic Group - White and Asian',
        value: 'Mixed/Multiple Ethnic Group - White and Asian'
      },
      {
        text: 'Mixed/Multiple Ethnic Group - White and Black African',
        value: 'Mixed/Multiple Ethnic Group - White and Black African'
      },
      {
        text: 'Mixed/Multiple Ethnic Group - Any other background',
        value: ' - Any other background'
      },
      {
        text: 'White - English, Welsh, Scottish, Northern Irish or British',
        value: 'White - English, Welsh, Scottish, Northern Irish or British'
      },
      {
        text: 'White - Gypsy or Traveller',
        value: 'White - Gypsy or Traveller'
      },
      { text: 'White - Irish', value: 'White - Irish' },
      {
        text: 'White - Any other background',
        value: 'White - Any other background'
      },
      {
        text: 'Other Ethnic Group (please specify below)',
        value: 'Other Ethnic Group (please specify below)'
      }
    ],
    sex: [
      { text: 'Female', value: 'Female' },
      { text: 'Male', value: 'Male' },
      { text: 'Non Binary', value: 'Non Binary' },
      { text: 'Transgender', value: 'Transgender' },
      { text: 'Prefer not to say', value: 'Prefer not to say' }
    ],
    sexualOrientation: [
      { text: 'Bi-Sexual', value: 'Bi-Sexual' },
      { text: 'Gay woman/lesbian', value: 'Gay woman/lesbian' },
      { text: 'Gay man', value: 'Gay man' },
      { text: 'Heterosexual', value: 'Heterosexual' },
      { text: 'Other', value: 'Other' },
      { text: 'Prefer not to say', value: 'Prefer not to say' }
    ],
    socioEconomic: [
      {
        text: 'First Generation - I am the first person in my immediate family to attend University (excluding siblings).',
        value:
          'First Generation - I am the first person in my immediate family to attend University (excluding siblings).'
      },
      {
        text: 'I have an undergraduate degree in a non-law subject.',
        value: 'I have an undergraduate degree in a non-law subject.'
      }
    ],
    age: [
      { text: 'Under 16', value: 'Under 16' },
      { text: '16-18', value: '16-18' },
      { text: '19-25', value: '19-25' },
      { text: '26-30', value: '26-30' },
      { text: '31-40', value: '31-40' },
      { text: '41-50', value: '41-50' },
      { text: '51-60', value: '51-60' },
      { text: '61 or over', value: '61 or over' }
    ],
    disabilityStatus: [
      { text: 'Yes', value: 'Yes' },
      { text: 'No', value: 'No' },
      { text: 'Prefer not to say', value: 'Prefer not to say' }
    ]
  }
}

type LanguageKey =
  | 'Other'
  | 'ASL'
  | 'Arabic'
  | 'Cantonese'
  | 'Chinese'
  | 'Czech'
  | 'English'
  | 'Filipino'
  | 'Finnish'
  | 'French'
  | 'German'
  | 'Hebrew'
  | 'Indonesian'
  | 'Italian'
  | 'Japanese'
  | 'Korean'
  | 'Lithuanian'
  | 'Mandarin'
  | 'Russian'
  | 'Spanish'
  | 'Swedish'
  | 'Thai'
  | 'Vietnamese'
const Languages: Array<TextValue<LanguageKey>> = [
  { text: '*OTHER / NOT LISTED (Please See Resume)', value: 'Other' },
  { text: 'American Sign Language  ', value: 'ASL' },
  { text: 'Arabic', value: 'Arabic' },
  { text: 'Cantonese', value: 'Cantonese' },
  { text: 'Chinese', value: 'Chinese' },
  { text: 'Czech', value: 'Czech' },
  { text: 'English', value: 'English' },
  { text: 'Filipino', value: 'Filipino' },
  { text: 'Finnish', value: 'Finnish' },
  { text: 'French', value: 'French' },
  { text: 'German', value: 'German' },
  { text: 'Hebrew', value: 'Hebrew' },
  { text: 'Indonesian', value: 'Indonesian' },
  { text: 'Italian', value: 'Italian' },
  { text: 'Japanese', value: 'Japanese' },
  { text: 'Korean', value: 'Korean' },
  { text: 'Lithuanian', value: 'Lithuanian' },
  { text: 'Mandarin', value: 'Mandarin' },
  { text: 'Russian', value: 'Russian' },
  { text: 'Spanish', value: 'Spanish' },
  { text: 'Swedish', value: 'Swedish' },
  { text: 'Thai', value: 'Thai' },
  { text: 'Vietnamese', value: 'Vietnamese' }
]
type StudentOrganizationKey =
  | 'Other'
  | 'AdjudicationSociety'
  | 'AgriculturalLawAssocationALA'
  | 'AmericanBarAssociationLawStudentDivision'
  | 'AmericanCivilLibertiesUnion'
  | 'AnimalLawSociety'
  | 'AnimalLegalDefenseFund'
  | 'ArtLawSociety'
  | 'AsianandPacificAmericanLawStudentsAssociation'
  | 'AssociationofContentiousTrustandProbateSpecialistsACTAPS'
  | 'AssociationofLawCostsDraftsmen'
  | 'AssociationofLawyersforChildren'
  | 'AssociationofPensionsLawyers'
  | 'AssociationofPersonalInjuryLawyersAPIL'
  | 'AvMAActionagainstMedicalAccidents'
  | 'BarAssociationforLocalGovernmentandthePublicService'
  | 'BarristersBenevolentAssociation'
  | 'BlackLawStudentsAssociation'
  | 'BlackSolicitorsNetwork'
  | 'BritishIrishLegalEducationTechnologyAssociationBILETA'
  | 'BritishandIrishAssociationofLawLibrariansBIALL'
  | 'BritishInsuranceLawAssociationBILA'
  | 'BusinessLawAssociation'
  | 'ChinaLawAssociation'
  | 'ChristianLawStudentsAssociation'
  | 'CorporateandBusinessLawSociety'
  | 'DisabilityLawSociety'
  | 'EasternEuropeanLawStudentAssociation'
  | 'EducationandLawSociety'
  | 'EntertainmentArtsandSportsLawSociety'
  | 'EnvironmentalLawSociety'
  | 'FederalistSociety'
  | 'HealthLawSociety'
  | 'InnocenceProject'
  | 'IntellectualPropertyStudentAssociation'
  | 'InternationalHumanRightsLawAssociation'
  | 'InternationalLawStudentsAssocation'
  | 'JReubenClarkLawSociety'
  | 'JessupInternationalMootCourtTeam'
  | 'JewishLawStudentsAssociation'
  | 'KoreanLawStudentsAssociation'
  | 'LaRazaLawStudentAssociation'
  | 'LambdaLaw'
  | 'LatinAmericanLawStudentsAssociation'
  | 'LatinoALawStudentsAssociation'
  | 'LawScienceStudentAssociation'
  | 'MexicanAmericanLawStudentsAssociation'
  | 'MiddleEasternLawStudentsAssociation'
  | 'MockTrialAssociation'
  | 'MootCourt'
  | 'MuslimLawStudentsAssociation'
  | 'NationalLawyersGuild'
  | 'NativeAmericanLawStudentsAssociation'
  | 'OUTLaw'
  | 'PhiAlphaDelta'
  | 'PhiDeltaPhi'
  | 'SouthAsianLawStudentsAssociation'
  | 'SportsEntertainmentLawStudentsAssociation'
  | 'StThomasMoreLawStudentSociety'
  | 'StreetLaw'
  | 'StudentBarAssociationSBAStudentSenateStudentCouncil'
  | 'TaxandEstatePlanningLawAssociation'
  | 'TheAssociationofChildAbuseLawyersACAL'
  | 'TheAssociationofMuslimLawyersAML'
  | 'TheAssociationofWomenBarristers'
  | 'TheAssociationofWomenSolicitors'
  | 'TheBlackLawyersDirectoryBLD'
  | 'VeteransLawSociety'
  | 'WomenLawStudentsAssociation'
const StudentOrganizations: Array<TextValue<StudentOrganizationKey>> = [
  { text: 'Not Listed/Other', value: 'Other'},
  { text: 'Adjudication Society', value: 'AdjudicationSociety' },
  { text: 'Agricultural Law Assocation (ALA)', value: 'AgriculturalLawAssocationALA' },
  { text: 'American Bar Association Law Student Division', value: 'AmericanBarAssociationLawStudentDivision' },
  { text: 'American Civil Liberties Union', value: 'AmericanCivilLibertiesUnion' },
  { text: 'Animal Law Society', value: 'AnimalLawSociety' },
  { text: 'Animal Legal Defense Fund', value: 'AnimalLegalDefenseFund' },
  { text: 'Art Law Society', value: 'ArtLawSociety' },
  { text: 'Asian and Pacific American Law Students Association', value: 'AsianandPacificAmericanLawStudentsAssociation' },
  { text: 'Association of Contentious Trust and Probate Specialists (ACTAPS)', value: 'AssociationofContentiousTrustandProbateSpecialistsACTAPS' },
  { text: 'Association of Law Costs Draftsmen', value: 'AssociationofLawCostsDraftsmen' },
  { text: 'Association of Lawyers for Children', value: 'AssociationofLawyersforChildren' },
  { text: 'Association of Pensions Lawyers', value: 'AssociationofPensionsLawyers' },
  { text: 'Association of Personal Injury Lawyers (APIL)', value: 'AssociationofPersonalInjuryLawyersAPIL' },
  { text: 'AvMA (Action against Medical Accidents)', value: 'AvMAActionagainstMedicalAccidents' },
  { text: 'Bar Association for Local Government and the Public Service', value: 'BarAssociationforLocalGovernmentandthePublicService' },
  { text: "Barristers' Benevolent Association", value: 'BarristersBenevolentAssociation' },
  { text: 'Black Law Students Association', value: 'BlackLawStudentsAssociation' },
  { text: 'Black Solicitors Network', value: 'BlackSolicitorsNetwork' },
  { text: 'British & Irish Legal Education Technology Association (BILETA)', value: 'BritishIrishLegalEducationTechnologyAssociationBILETA' },
  { text: 'British and Irish Association of Law Librarians (BIALL)', value: 'BritishandIrishAssociationofLawLibrariansBIALL' },
  { text: 'British Insurance Law Association (BILA)', value: 'BritishInsuranceLawAssociationBILA' },
  { text: 'Business Law Association', value: 'BusinessLawAssociation' },
  { text: 'China Law Association', value: 'ChinaLawAssociation' },
  { text: 'Christian Law Students Association', value: 'ChristianLawStudentsAssociation' },
  { text: 'Corporate and Business Law Society', value: 'CorporateandBusinessLawSociety' },
  { text: 'Disability Law Society', value: 'DisabilityLawSociety' },
  { text: 'Eastern European Law Student Association', value: 'EasternEuropeanLawStudentAssociation' },
  { text: 'Education and Law Society', value: 'EducationandLawSociety' },
  { text: 'Entertainment, Arts, and Sports Law Society', value: 'EntertainmentArtsandSportsLawSociety' },
  { text: 'Environmental Law Society', value: 'EnvironmentalLawSociety' },
  { text: 'Federalist Society', value: 'FederalistSociety' },
  { text: 'Health Law Society', value: 'HealthLawSociety' },
  { text: 'Innocence Project', value: 'InnocenceProject' },
  { text: 'Intellectual Property Student Association', value: 'IntellectualPropertyStudentAssociation' },
  { text: 'International Human Rights Law Association', value: 'InternationalHumanRightsLawAssociation' },
  { text: 'International Law Students Assocation', value: 'InternationalLawStudentsAssocation' },
  { text: 'J. Reuben Clark Law Society', value: 'JReubenClarkLawSociety' },
  { text: 'Jessup International Moot Court Team ', value: 'JessupInternationalMootCourtTeam' },
  { text: 'Jewish Law Students Association', value: 'JewishLawStudentsAssociation' },
  { text: 'Korean Law Students Association', value: 'KoreanLawStudentsAssociation' },
  { text: 'La Raza Law Student Association', value: 'LaRazaLawStudentAssociation' },
  { text: 'Lambda Law ', value: 'LambdaLaw' },
  { text: 'Latin American Law Students Association', value: 'LatinAmericanLawStudentsAssociation' },
  { text: 'Latino/A Law Students Association', value: 'LatinoALawStudentsAssociation' },
  { text: 'Law & Science Student Association', value: 'LawScienceStudentAssociation' },
  { text: 'Mexican American Law Students Association', value: 'MexicanAmericanLawStudentsAssociation' },
  { text: 'Middle Eastern Law Students Association', value: 'MiddleEasternLawStudentsAssociation' },
  { text: 'Mock Trial Association', value: 'MockTrialAssociation' },
  { text: 'Moot Court', value: 'MootCourt' },
  { text: 'Muslim Law Students Association', value: 'MuslimLawStudentsAssociation' },
  { text: 'National Lawyers Guild', value: 'NationalLawyersGuild' },
  { text: 'Native American Law Students Association', value: 'NativeAmericanLawStudentsAssociation' },
  { text: 'OUTLaw', value: 'OUTLaw' },
  { text: 'Phi Alpha Delta', value: 'PhiAlphaDelta' },
  { text: 'Phi Delta Phi', value: 'PhiDeltaPhi' },
  { text: 'South Asian Law Students Association', value: 'SouthAsianLawStudentsAssociation' },
  { text: 'Sports & Entertainment Law Students Association', value: 'SportsEntertainmentLawStudentsAssociation' },
  { text: 'St. Thomas More Law Student Society', value: 'StThomasMoreLawStudentSociety' },
  { text: 'Street Law', value: 'StreetLaw' },
  { text: 'Student Bar Association (SBA) / Student Senate / Student Council', value: 'StudentBarAssociationSBAStudentSenateStudentCouncil' },
  { text: 'Tax and Estate Planning Law Association', value: 'TaxandEstatePlanningLawAssociation' },
  { text: 'The Association of Child Abuse Lawyers (ACAL)', value: 'TheAssociationofChildAbuseLawyersACAL' },
  { text: 'The Association of Muslim Lawyers (AML)', value: 'TheAssociationofMuslimLawyersAML' },
  { text: 'The Association of Women Barristers', value: 'TheAssociationofWomenBarristers' },
  { text: 'The Association of Women Solicitors', value: 'TheAssociationofWomenSolicitors' },
  { text: 'The Black Lawyers Directory (BLD)', value: 'TheBlackLawyersDirectoryBLD' },
  { text: 'Veterans Law Society', value: 'VeteransLawSociety' },
  { text: 'Women Law Students Association', value: 'WomenLawStudentsAssociation' }
]

const PracticeAreas = [
  'Administrative Law and Regulatory Practice',
  'Admiralty & Maritime Law',
  'Agricultural Law',
  'Alternative Dispute Resolution',
  'Antitrust & Trade Regulation',
  'Appellate Practice',
  'Aviation & Aerospace',
  'Banking Law',
  'Bankruptcy',
  'Business Law',
  'Civil Rights and Social Justice',
  'Class Actions',
  'Commercial Law',
  'Communications Law',
  'Constitutional Law',
  'Construction Law',
  'Contracts',
  'Corporate Law (General Transactional Practice)',
  'Criminal Law',
  'Cybersecurity',
  'Debtor & Creditor',
  'Education Law',
  'Elder Law',
  'Election, Campaign & Political',
  'Eminent Domain',
  'Employee Benefits',
  'Energy',
  'Entertainment Law',
  'Environmental Law',
  'ERISA',
  'Family Law',
  'Finance',
  'Food Law',
  'Government',
  'Government Contracts',
  'Health Care',
  'Health Law',
  'Housing Discrimination Law',
  'Human Rights Law',
  'Immigration',
  'Indians & Native Populations',
  'Insurance',
  'Intellectual Property',
  'International Law',
  'International Trade',
  'Internet Law',
  'Investment Law',
  'Labor & Employment',
  'Legal Malpractice',
  'Litigation (General Litigtaion Practice)',
  'Media Law',
  'Medical Malpractice',
  'Mergers & Acquisitions',
  'Military Law',
  'Natural Resources',
  'Occupational Safety & Health',
  'Oil & Gas Law',
  'Personal Injury',
  'Privacy Law',
  'Products Liability',
  'Professional Liability',
  'Public Contract Law',
  'Real Estate',
  'Sports Law',
  'Securities Law',
  'State and Local Government Law',
  'Taxation',
  'Technology & Science',
  'Toxic Torts',
  'Transportation',
  'Trusts & Estates',
  'Wills & Probate',
  'White Collar Crime',
  'Workers Compensation',
  'Zoning, Planning & Land Use'
]

const PracticeAreaOptions = PracticeAreas.map(pa => { return { text: pa, value: pa }})

enum ProgramType {
  Law = 'Law',
  Graduate = 'Graduate',
  Undergraduate = 'Undergraduate'
}

type BarbriAffiliationKey =
  | 'LawPreview'
  | 'OneLMastery'
  | 'MPRE'
  | 'BarReview'
  | 'SQE'
const BarbriAffiliations: Array<TextValue<BarbriAffiliationKey>> = [
  { text: 'Law Preview', value: 'LawPreview' },
  { text: '1L Mastery', value: 'OneLMastery' },
  { text: 'MPRE', value: 'MPRE' },
  { text: 'Bar Review', value: 'BarReview' },
  { text: 'SQE', value: 'SQE' }
]

type DualDegreeKey =
  | 'Other'
  | 'LLM_AsianLegal'
  | 'LLM_BankingFinancial'
  | 'LLM_EuroLaw'
  | 'LLM_ComparativeLaw'
  | 'LLM_EuroBizLaw'
  | 'LLM_IntLaw'
  | 'LLM_Entrepreneur'
  | 'LLM_Taxation'
  | 'MA_Economics'
  | 'MA_Education'
  | 'MA_English'
  | 'MA_French'
  | 'MA_GlobalBizLaw'
  | 'MA_History'
  | 'MA_IntRelations'
  | 'MA_LatinAmerCarribbean'
  | 'MA_Chinese'
  | 'MA_EastEuroEurasia'
  | 'MA_Japanese'
  | 'MA_NorthAfricanMidEast'
  | 'MA_Preservation'
  | 'MA_MgmtSciEng'
  | 'MA_Philosophy'
  | 'MA_Politics'
  | 'MA_Women'
  | 'MA_WorldPolitics'
  | 'MBA_HealthSector'
  | 'MBA_BizAdmin'
  | 'MBA_Management'
  | 'MD_DrMedicine'
  | 'MDiv_Divinity'
  | 'MILR_IndustryLabor'
  | 'MPA_PublicAdminIntDev'
  | 'MPA_PublicAdmin'
  | 'MPA_PublicAffairs'
  | 'MPH_PublicHealth'
  | 'MPP_PublicPolicy'
  | 'MRP_RegionalPlanning'
  | 'MS_BioEng'
  | 'MS_CompSci'
  | 'MS_ElectricalEng'
  | 'MS_EnvResources'
  | 'MS_HealthResearch'
  | 'MS_MassComm'
  | 'MS_NaturalResources'
  | 'MSI_LawInfo'
  | 'MSW_SocialWork'
  | 'MTS_Theological'
  | 'MUP_UrbanPlanning'
  | 'PhD_BioEng'
  | 'PhD_DevelopPsych'
  | 'PhD_Economics'
  | 'PhD_MgmtSciEng'
  | 'PhD_Philosophy'
  | 'PhD_Politics'
  | 'PhD_Psychology'
  | 'PhD_Sociology'

const DualDegrees: Array<TextValue<DualDegreeKey>> = [
  { text: '*OTHER / NOT LISTED (Please See Resume)', value: 'Other' },
  { text: 'J.D./LL.M. in Asian Legal Studies', value: 'LLM_AsianLegal' },
  { text: 'J.D./LL.M. in Banking and Financial Law', value: 'LLM_BankingFinancial' },
  { text: 'J.D./LL.M. in European Law (with University of Paris II)', value: 'LLM_EuroLaw' },
  { text: 'J.D./LL.M. in International & Comparative Law', value: 'LLM_ComparativeLaw' },
  { text: 'J.D./LL.M. in International & European Business Law', value: 'LLM_EuroBizLaw' },
  { text: 'J.D./LL.M. in International Law', value: 'LLM_IntLaw' },
  { text: 'J.D./LL.M. in Law & Entrepreneurship', value: 'LLM_Entrepreneur' },
  { text: 'J.D./LL.M. in Taxation', value: 'LLM_Taxation' },
  { text: 'J.D./M.A. in Economics', value: 'MA_Economics' },
  { text: 'J.D./M.A. in Education', value: 'MA_Education' },
  { text: 'J.D./M.A. in English', value: 'MA_English' },
  { text: 'J.D./M.A. in French Studies', value: 'MA_French' },
  { text: 'J.D./M.A. in Global Business Law', value: 'MA_GlobalBizLaw' },
  { text: 'J.D./M.A. in History', value: 'MA_History' },
  { text: 'J.D./M.A. in International Relations', value: 'MA_IntRelations' },
  { text: 'J.D./M.A. in Latin American & Caribbean Studies', value: 'MA_LatinAmerCarribbean' },
  { text: 'J.D./M.A. in Law & Chinese Studies', value: 'MA_Chinese' },
  { text: 'J.D./M.A. in Law & Easten European & Eurasian Studies', value: 'MA_EastEuroEurasia' },
  { text: 'J.D./M.A. in Law & Japanese Studies', value: 'MA_Japanese' },
  { text: 'J.D./M.A. in Law & North African & Middle Eastern Studies', value: 'MA_NorthAfricanMidEast' },
  { text: 'J.D./M.A. in Law & Preservation Studies', value: 'MA_Preservation' },
  { text: 'J.D./M.A. in Management Science & Engineering', value: 'MA_MgmtSciEng' },
  { text: 'J.D./M.A. in Philosophy', value: 'MA_Philosophy' },
  { text: 'J.D./M.A. in Politics', value: 'MA_Politics' },
  { text: "J.D./M.A. in Women's Studies", value: 'MA_Women' },
  { text: 'J.D./M.A. in World Politics', value: 'MA_WorldPolitics' },
  { text: 'J.D./M.B.A. in Law and Health Sector Management', value: 'MBA_HealthSector' },
  { text: 'J.D./M.B.A. in Business Administration', value: 'MBA_BizAdmin' },
  { text: 'J.D./M.B.A. in Management', value: 'MBA_Management' },
  { text: 'J.D./M.D. (Doctor of Medicine)', value: 'MD_DrMedicine' },
  { text: 'J.D./M.Div. in Divinity Studies', value: 'MDiv_Divinity' },
  { text: 'J.D./M.I.LR. In Industrial & Labor Relations', value: 'MILR_IndustryLabor' },
  { text: 'J.D./M.P.A in Public Administration & International Development', value: 'MPA_PublicAdminIntDev' },
  { text: 'J.D./M.P.A. in Public Administration', value: 'MPA_PublicAdmin' },
  { text: 'J.D./M.P.A. in Public Affairs', value: 'MPA_PublicAffairs' },
  { text: 'J.D./M.P.H. in Public Health', value: 'MPH_PublicHealth' },
  { text: 'J.D./M.P.P. in Public Policy', value: 'MPP_PublicPolicy' },
  { text: 'J.D./M.R.P. in Regional Planning', value: 'MRP_RegionalPlanning' },
  { text: 'J.D./M.S. in Bioengineering', value: 'MS_BioEng' },
  { text: 'J.D./M.S. in Computer Science', value: 'MS_CompSci' },
  { text: 'J.D./M.S. in Electrical Engeneering', value: 'MS_ElectricalEng' },
  { text: 'J.D./M.S. in Environmental Resources', value: 'MS_EnvResources' },
  { text: 'J.D./M.S. in Health Research & Policy', value: 'MS_HealthResearch' },
  { text: 'J.D./M.S. in Mass Communication', value: 'MS_MassComm' },
  { text: 'J.D./M.S. in Natural Resources', value: 'MS_NaturalResources' },
  { text: 'J.D./M.S.I. in Law and Information', value: 'MSI_LawInfo' },
  { text: 'J.D./M.S.W in Social Work', value: 'MSW_SocialWork' },
  { text: 'J.D./M.T.S. in Theological Studies', value: 'MTS_Theological' },
  { text: 'J.D./M.U.P. in Urban Planning', value: 'MUP_UrbanPlanning' },
  { text: 'J.D./Ph.D. in Bioengineering', value: 'PhD_BioEng' },
  { text: 'J.D./Ph.D. in Developmental Psychology', value: 'PhD_DevelopPsych' },
  { text: 'J.D./Ph.D. in Economics', value: 'PhD_Economics' },
  { text: 'J.D./Ph.D. in Management Science & Engineering', value: 'PhD_MgmtSciEng' },
  { text: 'J.D./Ph.D. in Philosophy', value: 'PhD_Philosophy' },
  { text: 'J.D./Ph.D. in Politics', value: 'PhD_Politics' },
  { text: 'J.D./Ph.D. in Psychology', value: 'PhD_Psychology' },
  { text: 'J.D./Ph.D. in Sociology', value: 'PhD_Sociology' }
]

type CandidateOpportunityStatus = 'Available' | 'Applied' | 'Shortlisted' | 'Interviewing'

export {
  Candidate,
	CandidateBirthday,
	ValidMonths,
	ValidDaysMapping,
	CandidateProfile,
	DiversityDisclosures,
  CandidateDiversityDisclosure,
	DiversityDisclosureUK,
	DiversityDisclosureUS,
	LanguageKey,
	Languages,
  StudentOrganizationKey,
  StudentOrganizations,
  CandidateWorkExperience,
  DreamJobData,
  PracticeAreas,
  PracticeAreaOptions,
  BarbriAffiliationKey,
  BarbriAffiliations,
  DualDegreeKey,
  DualDegrees,
  SchoolInformation,
  CandidateOpportunityStatus,
  LawReviewJournalExperience,
  ProgramType
}
