import { CandidateWorkExperience } from '@/types/candidate'
import { FalsyOrGreaterThan100Chars } from './genericValidation'

function IsValidWorkExperienceArray(workExperience?: Array<CandidateWorkExperience>): boolean {
  if (!workExperience || workExperience.length < 1 || workExperience.length > 5) {
    return false
  }
  return workExperience.every((we) => IsValidWorkExperience(we))
}

function IsValidWorkExperience(workExperience: CandidateWorkExperience | undefined): boolean {
  if (!workExperience) {
    return false
  }

  if (FalsyOrGreaterThan100Chars(workExperience.candidateEmployer) || FalsyOrGreaterThan100Chars(workExperience.positionTitle)) {
    return false
  }

  if(!workExperience.startYear) {
    return false
  }

  if(workExperience.endYear && workExperience.endYear < workExperience.startYear) {
    return false
  }

  return true
}

export {
  IsValidWorkExperienceArray,
  IsValidWorkExperience
}
