
import { defineComponent, onMounted, PropType, ref } from 'vue'
import { ColumnHeader, TableRowDropdownItem } from '@/components/Table/TableUtils/Table.interface'
import { ICandidateSearchRowViewModel, ICandidateSearchStore } from './CandidateSearch.interfaces'
import TableView from '@/components/Table/TableView.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import locales from './CandidateSearch.locales.en.json'
import { useRouter } from 'vue-router'
import { logErrorMessage } from '@/logging'
import InviteToApply from '@/components/InviteToApply/InviteToApply.vue'
import { JobOpportunity } from '@/types/opportunity'
import { GetOpportunitiesByEmployer } from '@/api/OpportunityApi'
import { SendInviteToApply } from '@/api/InvitationApi'
import { Candidate, CandidateProfile } from '@/types/candidate'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

const tableColumns: ColumnHeader[] = [
  {
    label: locales.header_candidate_picture,
    key: 'candidatePicture',
    srOnly: true,
    style: 'width: 108px;'
  },
  {
    label: locales.header_candidate_name,
    key: 'candidateFullName'
  },
  {
    label: locales.header_school_or_university,
    key: 'schoolOrUniversity'
  },
  {
    label: locales.header_invitation,
    key: 'invitations'
  },
  {
    label: locales.header_actions,
    key: 'actions',
    srOnly: true
  }
]

const dropdownOptions: TableRowDropdownItem[] = [
  {
    label: locales.option_view_profile,
    key: 'profile',
    icon: 'person-circle'
  },
  {
    label: locales.option_invite_to_apply,
    key: 'apply',
    icon: 'arrow-up-right-square'
  }
]

export default defineComponent({
  components: { TableView, DropdownOptions, InviteToApply, ProfilePicture },
  props: {
    store: {
      type: Object as PropType<ICandidateSearchStore>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const { showSuccessToast, showErrorToast } = useToast()
    const showInviteDialog = ref(false)
    const employerOpportunities = ref([] as Array<JobOpportunity>)
    const candidate = ref({} as Candidate)

    onMounted(async () : Promise<void> => {
      employerOpportunities.value = await GetOpportunitiesByEmployer('Published')
    })

    const handleOptionChange = (dropdownItem: TableRowDropdownItem, candidateRow: ICandidateSearchRowViewModel) => {
      candidate.value = {
        id: candidateRow.candidateId,
        profileData: {
          firstName: candidateRow.candidateFirstName
        } as CandidateProfile
      } as Candidate

      switch (dropdownItem.key) {
        case 'profile':
          goToProfile(candidateRow)
          break
        case 'apply':
          showInviteDialog.value = true
          break
      }
    }

    const goToProfile = (candidateRow: ICandidateSearchRowViewModel) => {
      props.store.actions.selectCandidate(candidateRow)
      router.push({
        name: 'Candidate Profile',
        params: { id: candidateRow.candidateId }
      })
    }

    const sendInvitation = async (data: any) => {
      if (!candidate.value.id) {
        logErrorMessage('Unable to send invite to candidate')
        showErrorToast({ message: locales.message_invite_candidate_error })
        return
      }
      if (!await SendInviteToApply(candidate.value.id, data.opportunityId, data.message)) {
        showErrorToast({ message: locales.message_invite_candidate_error })
      } else {
        showSuccessToast({ message: locales.message_invite_candidate_success })
        props.store.actions.addInvitationToCandidate(data.title, candidate.value.id)
      }
      showInviteDialog.value = false
    }

    return {
      locales,
      tableColumns,
      dropdownOptions,
      showInviteDialog,
      employerOpportunities,
      candidate,
      handleOptionChange,
      goToProfile,
      sendInvitation
    }
  }
})
