import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00fec1b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-saved-filters-segment" }
const _hoisted_2 = ["aria-expanded", "disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownOptions = _resolveComponent("DropdownOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownOptions, {
      id: "saved-items-dropdown",
      options: _ctx.dropdownOptions,
      onOptionChange: _ctx.handleOptionChange
    }, {
      "trigger-element": _withCtx(({ handleOpen, isOpen }) => [
        _createElementVNode("button", {
          id: "saved-items-btn",
          class: "btn btn-primary dropdown-toggle",
          "aria-expanded": isOpen,
          "aria-controls": "saved-items-dropdown",
          disabled: _ctx.disabled,
          onClick: handleOpen
        }, " Actions ", 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["options", "onOptionChange"])
  ]))
}