import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-999a43c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "card-segment" }
const _hoisted_3 = { class: "user-details-segment" }
const _hoisted_4 = { class: "profile-pic" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "designation" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultProfilePic = _resolveComponent("DefaultProfilePic")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.locales.subheading_meet_recruiter), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, [
          _createVNode(_component_DefaultProfilePic)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.name), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.designation), 1),
          _createElementVNode("a", {
            class: "email",
            href: 'mailto:' + _ctx.email
          }, _toDisplayString(_ctx.email), 9, _hoisted_7)
        ])
      ])
    ])
  ], 64))
}