import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e8d0dce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "applicant-list-segment" }
const _hoisted_2 = { class: "applicants-heading" }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = ["aria-label", "checked", "onChange"]
const _hoisted_5 = { class: "profile-pic-segment" }
const _hoisted_6 = {
  key: 0,
  class: "new-user-dot-alert"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "action-column" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableTopHeader = _resolveComponent("TableTopHeader")!
  const _component_SelectedHeader = _resolveComponent("SelectedHeader")!
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")!
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_UnstarIcon = _resolveComponent("UnstarIcon")!
  const _component_StarIcon = _resolveComponent("StarIcon")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_ApplicantListsFilter = _resolveComponent("ApplicantListsFilter")!
  const _component_view_applicant_modal = _resolveComponent("view-applicant-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableTopHeader, { onShowFilters: _ctx.showFilters }, {
        "left-content": _withCtx(() => [
          _createElementVNode("h2", _hoisted_2, " (" + _toDisplayString(_ctx.data.length) + ") " + _toDisplayString(_ctx.locales.applicants), 1)
        ]),
        _: 1
      }, 8, ["onShowFilters"]),
      _createVNode(_component_TableView, {
        "aria-label": "Applicants List",
        columns: _ctx.tableColumns,
        data: _ctx.data,
        "is-loading": _ctx.isLoading,
        "selected-items": _ctx.selectedItems
      }, _createSlots({
        "header-select": _withCtx(() => [
          _createVNode(_component_BsCheckbox, {
            modelValue: _ctx.isSelectedAll,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isSelectedAll) = $event)),
            onChange: _ctx.handleSelectAll
          }, null, 8, ["modelValue", "onChange"])
        ]),
        select: _withCtx(({ rowData }) => [
          _createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            "aria-label": 'select' + ' ' + rowData.title,
            checked: _ctx.selectedItems.includes(rowData.id),
            onChange: ($event: any) => (_ctx.handleSelectRow($event, rowData))
          }, null, 40, _hoisted_4)
        ]),
        "user-icon": _withCtx(({ rowData }) => [
          _createElementVNode("label", _hoisted_5, [
            _createVNode(_component_ProfilePicture, {
              class: "profile-pic-circle",
              "candidate-id": rowData.candidateId
            }, null, 8, ["candidate-id"]),
            (rowData.applicant.isNew)
              ? (_openBlock(), _createElementBlock("label", _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ]),
        title: _withCtx(({ rowData }) => [
          _createElementVNode("a", {
            class: "title",
            href: "#",
            onClick: ($event: any) => (_ctx.openApplicantModal(rowData))
          }, _toDisplayString(rowData.title), 9, _hoisted_7)
        ]),
        "action-column": _withCtx(({ rowData }) => [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.favouriteItems.includes(rowData.id))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.handleFavouriteItem(rowData))
                }, [
                  _createVNode(_component_UnstarIcon)
                ], 8, _hoisted_9))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.handleFavouriteItem(rowData))
                }, [
                  _createVNode(_component_StarIcon)
                ], 8, _hoisted_10)),
            _createElementVNode("label", {
              class: _normalizeClass({
              'status-segment': true,
              [_ctx.trimString(rowData.status)]: true
            })
            }, _toDisplayString(rowData.status), 3)
          ])
        ]),
        _: 2
      }, [
        (_ctx.selectedItems.length)
          ? {
              name: "header-alt",
              fn: _withCtx(() => [
                _createElementVNode("td", {
                  colspan: _ctx.tableColumns.length
                }, [
                  _createVNode(_component_SelectedHeader, {
                    quantity: _ctx.selectedItems.length,
                    onStatusChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleStatusChange($event)))
                  }, null, 8, ["quantity"])
                ], 8, _hoisted_3)
              ])
            }
          : undefined
      ]), 1032, ["columns", "data", "is-loading", "selected-items"])
    ]),
    _createVNode(_component_ApplicantListsFilter, {
      visible: _ctx.isFilterVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isFilterVisible) = $event)),
      onFilterApplied: _ctx.filterApplied
    }, null, 8, ["visible", "onFilterApplied"]),
    _createVNode(_component_view_applicant_modal, {
      ref: "modalRef",
      candidate: _ctx.candidate,
      applicant: _ctx.applicant
    }, null, 8, ["candidate", "applicant"])
  ], 64))
}