
import { defineComponent, onMounted, ref } from 'vue'
import { GetPendingEmployers, GetApprovedEmployers, GetRejectedEmployers } from '@/api/EmployerApi'
import { EmployerProfile } from '@/types/employer'
import BsTabComponents from '@/components/CustomBootstrap/BsTab'
import CounterCard from './CounterCard.vue'
import TableView from '@/components/Table/TableView.vue'
import EmployerVerificationForm from './EmployerVerificationForm.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import locales from './EmployerVerifications.locales.en.json'

export default defineComponent({
  components: {
    ...BsTabComponents,
    CounterCard,
    TableView,
    EmployerVerificationForm
  },
  setup() {
    const pendingEmployers = ref<EmployerProfile[]>([])
    const allEmployers = ref<EmployerProfile[]>([])
    const isLoading = ref(true)
    const verifyEmployerDialog = ref<InstanceType<typeof EmployerVerificationForm>>()
    const selectedEmployer = ref<EmployerProfile>()
    const { showErrorToast } = useToast()
    
    const columns = [
      { label: 'Employer', key: 'name' },
      { label: 'Status', key: 'status' }
    ]

    const verifyEmployer = (rowData: EmployerProfile) => {
      selectedEmployer.value = rowData
      verifyEmployerDialog.value?.toggleModal(true)
    }

    const getEmployers = async () => {
      try {
        const [pending, approved, rejected] = await Promise.all([GetPendingEmployers(), GetApprovedEmployers(), GetRejectedEmployers()])
        pendingEmployers.value = pending.map(employer => ({
          ...employer,
          status: 'Pending'
        }))
        const approvedWithStatus = approved.map(employer => ({
          ...employer,
          status: 'Approved'
        }))
        const rejectedWithStatus = rejected.map(employer => ({
          ...employer,
          status: 'Rejected'
        }))
        allEmployers.value = [...pendingEmployers.value, ...approvedWithStatus, ...rejectedWithStatus ] 
      } catch (err) {
        showErrorToast({
          message: locales.error_retrieve_employers,
          position: 'top-center'
        })
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => getEmployers())

    return { 
      columns,
      pendingEmployers,
      allEmployers,
      isLoading,
      verifyEmployerDialog,
      verifyEmployer,
      selectedEmployer,
      getEmployers,
      locales
    }
  }
})
