import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e0bd953"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title mb-4" }
const _hoisted_2 = {
  key: 1,
  class: "requests"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsSpinner = _resolveComponent("BsSpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: "card pt-4 pb-5",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handleRedirect && $setup.handleRedirect(...args)))
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($setup.locales.pending_requests), 1),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock(_component_BsSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.pendingEmployers.length), 1))
  ]))
}