import {
  BarbriAffiliationKey,
  Candidate,
  CandidateProfile,
  CandidateWorkExperience,
  DreamJobData,
  LawReviewJournalExperience
} from '@/types/candidate'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import axios, { AxiosResponse } from 'axios'
import {
  GetPropertyContainsValueInListConditions,
  GetPropertyEqualsValueInListConditions,
  GetSearchArraySubPropertyConditions,
  GetUrlAssembler,
  GetUrlString
} from './ApiBase'
import { GetCandidatesResponse } from '@/types/getCandidatesResponse'
import { CandidateFilter } from '@/types/candidateFilter'
import UrlAssembler from 'url-assembler'
import store from '@/store'
import { Models } from 'azure-maps-rest'
import { GetCandidatesFromSavedFilterResponse } from '@/types/getCandidatesFromSavedFilterResponse'
import { ExtendedUserClaims, GetCandidateClaimsFromToken } from '@/okta'
import { UserFriendlyError } from '@/utils/error'

async function PostCandidateFileUpload(candidateFile: File, fileDescription: FileDescription): Promise<string> {
  const formData = new FormData()
  formData.append('file', candidateFile)
  formData.append('fileDescription', fileDescription)
  const url = GetUrlString('candidates/self/files')

  const response = await axios({
    method: 'post',
    url: url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  return response.data
}

async function DeleteCandidateFile(fileMetadataId: string) {
  await axios.delete(GetUrlString('candidates/self/files/:fileMetadataId', { fileMetadataId }))
}

async function PutCandidateProfile(candidate: Candidate): Promise<string> {
  const response = await axios.put(
    GetUrlString('candidates/self'),
    { candidateProfileData: candidate.profileData })

  store.state.candidate = candidate
  return response.data
}

async function GetCandidate(): Promise<Candidate> {
  if (store.state.candidate) {
    return store.state.candidate
  }

  let apiCandidate: Candidate
  try {
    const response = await axios({
      method: 'get',
      url: GetUrlString('candidates/self')
    })

    if(response.data.profileData){
      apiCandidate = initializeSpecialValues(response.data as Candidate)
      setOktaNameOfUser(apiCandidate)
    } else {
      apiCandidate = await getNewCandidate()
    }

  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      apiCandidate = await getNewCandidate()
    } else {
      throw Error('Error retrieving Candidate Profile.')
    }
  }

  store.state.candidate = apiCandidate
  return apiCandidate
}

async function GetCandidateById(id: string): Promise<Candidate> {
  let apiCandidate: Candidate
  try {
    const response = await axios({
      method: 'get',
      url: GetUrlString('candidates/:candidateId', { candidateId: id})
    })

    apiCandidate = await initializeSpecialValues(response.data as Candidate)
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      apiCandidate = await getNewCandidate()
    } else {
      throw Error('Error retrieving Candidate Profile.')
    }
  }

  return apiCandidate
}

async function GetCandidateProfilePicture(candidateId: string): Promise<Blob | undefined> {
  if (!candidateId) {
    return undefined
  }
  try {
    const response = await axios.get(
      GetUrlString('candidates/:candidateId/files/profile-picture', { candidateId }),
      { responseType: 'blob' }
    )
    return response.data
  }
  catch {
    return undefined
  }
}

async function GetCandidateFileData(candidateId: string, fileMetadataId: string): Promise<Blob> {
  const urlString = GetUrlString('candidates/:candidateId/files/:fileMetadataId', { candidateId, fileMetadataId })
  return GetFileData(urlString)
}

async function GetApplicationFileData(opportunityId: string, applicationId: string, fileMetadataId: string): Promise<Blob> {
  const urlString = GetUrlString('opportunities/:opportunityId/applications/:applicationId/files/:fileMetadataId', { opportunityId, applicationId, fileMetadataId })
  return GetFileData(urlString)
}

async function GetFileData(urlString: string): Promise<Blob> {
  try {
    const response = await axios.get(urlString, { responseType: 'blob' })
    return response.data
  } catch (error) {
    let message = 'This file is not available for download'
    if (axios.isAxiosError(error) && error.response?.status === 423) {
      message = 'The file is being processed before making it available for download, this might take several minutes'
    }
    throw new UserFriendlyError(message, error)
  }
}

function initializeSpecialValues(response: Candidate): Candidate {

  if (isArrayNullOrEmpty(response.profileData.certifications)) {
    response.profileData.certifications = []
  }

  if (isArrayNullOrEmpty(response.profileData.honorsAwards)) {
    response.profileData.honorsAwards = []
  }

  if (isArrayNullOrEmpty(response.profileData.barbriAffiliations)) {
    response.profileData.barbriAffiliated = false
    response.profileData.barbriAffiliations = []
  } else {
    response.profileData.barbriAffiliated = true
  }

  if (isArrayNullOrEmpty(response.profileData.workExperience)) {
    response.profileData.workExperience = []
  }

  if (isArrayNullOrEmpty(response.opportunityInvitationNames)) {
    response.opportunityInvitationNames = []
  }

  if (!response.profileData.dreamJobData) {
    response.profileData.dreamJobData = {} as DreamJobData
  }

  if (response.profileData.schoolInformation) {
    response.profileData.schoolInformation.forEach(si => {
      si.startDate = new Date(si.startDate)
      if (si.endDate) {
        si.endDate = new Date(si.endDate)
      }
    })
  }

  response.candidateFileMetadata ??= []

  return response
}

async function setOktaNameOfUser(candidate: Candidate): Promise<Candidate> {
  if (!candidate.profileData){
    candidate.profileData =  (await getNewCandidate()).profileData
  } else {
    const tokenClaims: ExtendedUserClaims = await GetCandidateClaimsFromToken()
    candidate.profileData.firstName = tokenClaims?.firstName ?? ''
    candidate.profileData.lastName = tokenClaims?.lastName ?? ''
  }
  return candidate
}

async function getNewCandidate(): Promise<Candidate> {
  const tokenClaims: ExtendedUserClaims = await GetCandidateClaimsFromToken()

  return {
    id: tokenClaims?.sub,
    profileData: {
      firstName: tokenClaims?.firstName ?? '',
      lastName: tokenClaims?.lastName ?? '',
      emailAddress: tokenClaims?.email ?? '',
      phoneNumber: tokenClaims?.phoneNumber ?? '',
      certifications: [] as Array<string>,
      lawReviewJournalExperiences: [] as Array<LawReviewJournalExperience>,
      honorsAwards: [] as Array<string>,
      dreamJobData: {} as DreamJobData,
      barbriAffiliations: [] as Array<BarbriAffiliationKey>,
      workExperience: [] as Array<CandidateWorkExperience>
    } as CandidateProfile,
    candidateFileMetadata: []
  } as Candidate
}

async function GetCandidates(candidateFilter: CandidateFilter, currentPage = 1, perPage = 10): Promise<GetCandidatesResponse> {
  const assembler = ApplyCandidateFilter(GetUrlAssembler({ path:'candidates', perPage, currentPage }), candidateFilter)
  const response = await axios.get(assembler.toString())
  return {
    candidates: (response.data as Candidate[]).map(candidate => initializeSpecialValues(candidate)),
    totalCandidates: parseInt(response.headers['x-total-count'])
  } as GetCandidatesResponse
}


async function GetCandidatesFromSavedFilter(candidateFilterId: string, currentPage = 1, perPage = 10): Promise<GetCandidatesFromSavedFilterResponse> {
  const assembler = GetUrlAssembler({ path: 'candidates', perPage, currentPage}).query('candidateFilterId', candidateFilterId)
  const response = await axios.get(assembler.toString())
  return {
    candidates: response.data,
    rawFilterData: JSON.parse(response.headers['x-raw-filter-data']),
    totalCandidates: parseInt(response.headers['x-total-count'])
  } as GetCandidatesFromSavedFilterResponse
}

//todo: also a weird sport for these
async function ShortlistCandidate(
  opportunityId: string,
  candidateId: string
): Promise<AxiosResponse> {
  return axios.put(
    GetUrlString(
      'opportunities/:opportunityId/shortlisted-candidates/:candidateId',
      { opportunityId, candidateId }
    )
  )
}

async function UnshortlistCandidate(
  opportunityId: string,
  candidateId: string
): Promise<AxiosResponse> {
  return axios.delete(
    GetUrlString(
      'opportunities/:opportunityId/shortlisted-candidates/:candidateId',
      { opportunityId, candidateId }
    )
  )
}

async function GetShortlistedCandidates(
  opportunityId: string
): Promise<AxiosResponse> {
  return axios.get(
    GetUrlString('opportunities/:opportunityId/shortlisted-candidates', {
      opportunityId
    })
  )
}

function isArrayNullOrEmpty(s: any[] | undefined | null) {
  if (!s || s.length < 1) {
    return true
  }
  return false
}

function ApplyCandidateFilter(assembler: UrlAssembler, candidateFilter: CandidateFilter) : UrlAssembler {

  if (!candidateFilter || !Object.keys(candidateFilter).length) {
    return assembler
  }

  const filterQuery = GetFilterStringFromCandidateFilter(candidateFilter)
  if (filterQuery) {
    assembler = assembler.query('$filter', filterQuery)
  }

  // Some things like nullable dates aren't currently playing nice with odata
  // Any filters past this point are included as query strings
  // If anything changes should definitely try and make these odata queries
  if (candidateFilter.filterByGraduationYear) {
    assembler = assembler
      .query('graduationYearMin', candidateFilter.graduationYearMin ?? 1950)
      .query('graduationYearMax', candidateFilter.graduationYearMax ?? (new Date().getFullYear() + 3))
  }

  if (candidateFilter.genders?.length) {
    assembler = assembler
      .query('genders', `${candidateFilter.genders.join('|')}`)
  }

  if (candidateFilter.heritages?.length) {
    assembler = assembler
      .query('heritages', `${candidateFilter.heritages.join('|')}`)
  }

  if (candidateFilter.otherDiversityCriteria?.length) {
    assembler = assembler
      .query('otherDiversityCriteria', `${candidateFilter.otherDiversityCriteria.join('|')}`)
  }

  return assembler
}

function GetFilterStringFromCandidateFilter(candidateFilter: CandidateFilter): string {
  let oDataConditions: string[] = []
  oDataConditions = oDataConditions
    .concat(GetPropertyContainsValueInListConditions('profileData/dreamJobData/dreamJobTypes', candidateFilter.dreamJobTypes))
    .concat(GetPropertyContainsValueInListConditions('profileData/dreamJobData/practiceAreas', candidateFilter.practiceAreas))
    .concat(GetPropertyEqualsValueInListConditions('profileData/candidateType', candidateFilter.candidateTypes))
    .concat(GetPropertyContainsValueInListConditions('profileData/studentOrganizations', candidateFilter.studentOrganizations))
    .concat(GetSearchArraySubPropertyConditions(candidateFilter.schoolNames, 'profileData/schoolInformation', 'schoolName'))
    .concat(GetPreferredLocationsConditions(candidateFilter.preferredLocations))

  return oDataConditions.join(' and ')
}

function GetPreferredLocationsConditions(locations: Array<Models.SearchResultAddress>): Array<string> {
  const conditions: Array<string> = []

  if (!locations?.length) {
    return conditions
  }

  for (const location of locations) {
    conditions.push(`profileData/dreamJobData/preferredLocations/any(l: l/municipality eq '${location.municipality}' and l/countrySubdivisionName eq '${location.countrySubdivisionName}' and l/country eq '${location.country}')`)
  }
  return [`(${conditions.join(' or ')})`]
}

export {
  PostCandidateFileUpload,
  PutCandidateProfile,
  GetCandidate,
  GetCandidates,
  GetCandidatesFromSavedFilter,
  ShortlistCandidate,
  UnshortlistCandidate,
  GetShortlistedCandidates,
  ApplyCandidateFilter,
  DeleteCandidateFile,
  GetCandidateFileData,
  GetApplicationFileData,
  GetCandidateProfilePicture,
  GetFilterStringFromCandidateFilter,
  GetCandidateById
}
