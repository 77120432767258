import { inject, provide } from 'vue'
import { IEmployerJobPostBoardStore } from './EmployerJobPost.interfaces'
import { useEmployerJobPostBoardStore } from './EmployerJobPostBoard.store'

export const IEmployerJobPostBoardStoreSymbol = Symbol('IEmployerJobPostBoardStore')

export function injectEmployerJobPostBoardStore() {
  return inject<IEmployerJobPostBoardStore>(IEmployerJobPostBoardStoreSymbol, useEmployerJobPostBoardStore())
}

export function provideEmployerJobPostBoardStore(store: IEmployerJobPostBoardStore) {
  return provide(IEmployerJobPostBoardStoreSymbol, store)
}
