import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-126fe492"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CandidateSearchView" }
const _hoisted_2 = { class: "title mb-5" }
const _hoisted_3 = { class: "summary-content-card p-5 mb-3" }
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-6 text-start" }
const _hoisted_6 = { class: "col-6 text-end" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_saved_filters = _resolveComponent("list-saved-filters")!
  const _component_CandidateSearchTable = _resolveComponent("CandidateSearchTable")!
  const _component_pagination_bar = _resolveComponent("pagination-bar")!
  const _component_CandidateSearchFilter = _resolveComponent("CandidateSearchFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      (_ctx.state.isReady && !_ctx.state.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.state.totalCandidateQuantity), 1)
          ], 64))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.locales.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_list_saved_filters, {
            "saved-items": _ctx.state.savedFilters,
            store: _ctx.store
          }, null, 8, ["saved-items", "store"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "btn btn-outline-primary",
            type: "button",
            disabled: !_ctx.state.isReady || _ctx.state.isLoading,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFiltersDialog()))
          }, _toDisplayString(_ctx.locales.button_filters), 9, _hoisted_7)
        ])
      ]),
      _createVNode(_component_CandidateSearchTable, { store: _ctx.store }, null, 8, ["store"])
    ]),
    (_ctx.state.totalPages > 1)
      ? (_openBlock(), _createBlock(_component_pagination_bar, {
          key: 0,
          modelValue: _ctx.state.currentPage,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.currentPage) = $event)),
          "total-pages": _ctx.state.totalPages,
          spread: 2,
          disabled: _ctx.state.isLoading
        }, null, 8, ["modelValue", "total-pages", "disabled"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CandidateSearchFilter, {
      visible: _ctx.filterDialogIsOpened,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterDialogIsOpened) = $event)),
      "show-diversity-fields": _ctx.state.showDiversityFields,
      store: _ctx.store
    }, null, 8, ["visible", "show-diversity-fields", "store"])
  ]))
}