
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import { computed, defineComponent, inject, ref } from 'vue'
import { useEventListener } from '@vueuse/core'
import {
  IBsAccordionContainerStore,
  IBsAccordionStoreSymbol
} from './Accordion.interface'

export default defineComponent({
  props: {
    id: createAutoIncrementIdProp('Accordion'),
    keepContentOnClose: Boolean
  },
  setup(props) {
    const store = inject<IBsAccordionContainerStore>(IBsAccordionStoreSymbol)
    const expander = ref<HTMLElement>()

    const expandedState = computed<boolean>(() => {

      if(store?.state.isMultiple){
        return store.state.menuState?.includes(props.id || '') ?? false
      }
        return store?.state.menuState === props.id
    })

    useEventListener(expander, 'accordion-expand', (event: CustomEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (!expandedState.value) {
        store?.actions.selectAccordion(props.id || '')
      }
    }, { capture: true })

    function onContentStart(el: HTMLDivElement) {
      el.style.maxHeight = el.scrollHeight + el.offsetHeight + 'px'
    }

    function onAfterEnter(el: HTMLDivElement){
      el.style.maxHeight = 'initial'
    }

    function onBeforeEnd(el: HTMLDivElement){
      el.style.maxHeight = el.scrollHeight + 'px'
    }

    return {
      expander,
      onContentStart,
      expandedState,
      onAfterEnter,
      onBeforeEnd
    }
  }
})
