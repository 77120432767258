
import { JobOpportunity } from '@/types/opportunity'
import { PropType, defineComponent } from 'vue'
import router from '@/router'
import store from '@/store'
import TableView from '@/components/Table/TableView.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import { ColumnHeader } from '@/components/Table/TableUtils/Table.interface'
import { IJobOpportunityRowViewModel } from '@/views/JobOportunitiesSummary/JobOpportunitiesSummary.interface'
import { formatOpportunityAge } from '@/utils/opportunity'
import locales from './JobOpportunitiesTable.locales.en.json'

const tableColumns: ColumnHeader[] = [
  {
    label: locales.header_opportunity_company,
    key: 'title'
  },
  {
    label: locales.header_opportunity_type,
    key: 'opportunityType'
  },
  {
    label: locales.header_location_compensation,
    key: 'location'
  },
  {
    label: locales.header_date_posted,
    key: 'postedDate'
  },
  {
    label: locales.header_actions,
    key: 'actions',
    srOnly: true
  }
]

export default defineComponent({
  components: {
    TableView,
    ProfilePicture
  },
  props: {
    opportunities: {
      type: Object as PropType<IJobOpportunityRowViewModel[]>,
      required: true,
      default: null
    },
    loggedInAsCandidate: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: Boolean
  },
  emits: ['apply', 'favorite', 'unfavorite'],
  setup(props, { emit }) {

    const viewOpportunityPreviewRedirect = (opportunity: JobOpportunity) => {
      store.state.jobOpportunity = opportunity
      router.push({
        name: 'OpportunityPreview',
        params: { id: opportunity.id }
      })
    }

    const applyButtonText = (opportunity: IJobOpportunityRowViewModel) => {
      return opportunity.applyType === 'InternalManage' ?
        locales.button_apply :
        locales.button_external_apply
    }

    const viewEmployerProfileRedirect = (opportunity: JobOpportunity) => {
      router.push({
        name: 'View Employer Profile',
        params: { id: opportunity.employerId }
      })
    }

    const applyToOpportunity = (row: IJobOpportunityRowViewModel): void => {
      emit('apply', row)
    }

    const favoriteOpportunity = (row: IJobOpportunityRowViewModel): void => {
      emit('favorite', row)
    }

    const unfavoriteOpportunity = (row: IJobOpportunityRowViewModel): void => {
      emit('unfavorite', row)
    }

    return {
      locales,
      tableColumns,
      viewOpportunityPreviewRedirect,
      viewEmployerProfileRedirect,
      applyToOpportunity,
      favoriteOpportunity,
      unfavoriteOpportunity,
      formatOpportunityAge,
      applyButtonText
    }
  }
})
