
import { ref, watch } from 'vue'
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  setup(props: any) {
    const showRef = ref(false)

    watch(
      () => props.show,
      (newValue: boolean): void => {
        showRef.value = newValue
      }
    )

    return {
      showRef
    }
  }
}
