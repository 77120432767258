
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'
import BsPhoneNumber from '@/components/BsPhoneNumberDropdown/BsPhoneNumber.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import { EmployerProfile, EmployerTypes } from '@/types/employer'
import { onMounted, ref, watch } from 'vue'
import { TextValue } from '@/types'
import { Models } from 'azure-maps-rest'
import { debounce } from 'vue-debounce'
import { GetUniqueCityCountryResults } from '@/api/AzMapsApi'
import { RegisterEmployer } from '@/api/EmployerApi'
import { AgentProfile } from '@/types/agent'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { helpers, required } from '@vuelidate/validators'
import { IsValidEmail } from '@/types/validation/genericValidation'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { IsValidEmployerType, IsValidOfficeLocationList, IsValidWebsite } from '@/types/validation/employerValidation'
import { http, https } from '@/constants'
import { UpsertAgentProfile } from '@/api/AgentApi'
import locales from './NewAgent/NewAgent.locales.en.json'

export default {
  components: {
    BsAutoComplete,
    BsSelect,
    BsTextField,
    BsPhoneNumber
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      required: true
    },
    phoneNumber: {
      type: String,
      required: true
    },
    formSubmitted: {
      type: String,
      default: 'false'
    }
  },
  setup(props: any) {
    const { showErrorToast } = useToast()
    const agent = ref({} as AgentProfile)
    const employer = ref({} as EmployerProfile)
    const websiteUrl = ref('')
    const selectedCities = ref<TextValue<Models.SearchResultAddress>[]>([])
    const filteredCities = ref<TextValue<Models.SearchResultAddress>[]>([])
    const areFieldsValid = ref(true)
    const registrationRequestSent = ref(props.formSubmitted === 'true' ? true : false)

    const agentRules = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email: helpers.withMessage('Invalid email address', IsValidEmail)
      },
      phoneNumber: {
        required
      }
    } as ValidationArgs<any>

    const employerRules = {
      name: {
        required
      },
      website: {
        required,
        validUrl: helpers.withMessage('Invalid website', IsValidWebsite)
      },
      employerType: {
        required,
        validEmployerType: helpers.withMessage('Invalid employer type', IsValidEmployerType)
      },
      officeLocations: {
        required,
        validOfficeLocation: helpers.withMessage('Invalid office location', IsValidOfficeLocationList)
      }
    } as ValidationArgs<any>

    const agentValidity = useVuelidate(agentRules, agent)
    const employerValidity = useVuelidate(employerRules, employer)

    onMounted(() => {
      agent.value = {
        firstName: props.firstName,
        lastName: props.lastName,
        emailAddress: props.emailAddress,
        phoneNumber: props.phoneNumber
      } as AgentProfile
    })

    const handleLocationSearch = debounce(async (event: any) => {
      const resultDict = await GetUniqueCityCountryResults(event.query)
      filteredCities.value = Object.keys(resultDict).map((key) => ({
        text: key,
        value: resultDict[key]
      }))
    }, 300)

    async function sendRegistrationRequest() {
      agentValidity.value.$touch()
      employerValidity.value.$touch()
      const isValidAgent = await agentValidity.value.$validate()
      const isValidEmployer = await employerValidity.value.$validate()

      if (!isValidAgent || !isValidEmployer) {
        showErrorToast({ message: 'Please make sure all fields are filled in correctly' })
        return
      }

      try {
        const emailDomain = agent.value.emailAddress.split('@')[1]
        const employerId = (await RegisterEmployer(employer.value, emailDomain)).data as string
        await UpsertAgentProfile(agent.value, employerId)
        registrationRequestSent.value = true
      } catch {
        showErrorToast({ message: 'There was an error submitting your employer registration request. Please try again.' })
      }
    }

    watch(() => websiteUrl.value, (newVal: string) => {
      if (!newVal.startsWith(http) && !newVal.startsWith(https)) {
        employer.value.website = https.concat(newVal)
      }
    })

    watch(() => selectedCities.value, (newVal: TextValue<Models.SearchResultAddress>[]) => {
      employer.value.officeLocations = newVal.map(tvp => tvp.value)
    })

    return {
      agent,
      agentValidity,
      employer,
      employerValidity,
      websiteUrl,
      EmployerTypes,
      selectedCities,
      filteredCities,
      handleLocationSearch,
      areFieldsValid,
      registrationRequestSent,
      locales,
      sendRegistrationRequest
    }
  }
}
