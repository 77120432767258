import axios, { AxiosResponse } from 'axios'
import { EmployerProfile } from '@/types/employer'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { GetUrlString } from './ApiBase'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { Models } from 'azure-maps-rest'
import { GetEmployerId } from '@/okta'

async function GetEmployerProfile(empId?: string): Promise<EmployerProfile> {
  if(!empId) {
    empId = await GetEmployerId()
  }

  if(!empId) {
    return {
      officeLocations: [] as Array<Models.SearchResultAddress>
    } as EmployerProfile
  }

  try {
    const url = GetUrlString('employers/:empId', { empId })
    const response = await axios.get(url)

    const retVal = response.data as EmployerProfile
    if (!response.data.officeLocations) {
      retVal.officeLocations = []
    }
    return retVal
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return {
        officeLocations: [] as Array<Models.SearchResultAddress>
      } as EmployerProfile
    }

    throw Error('Error retrieving Employer Profile.')
  }
}

async function GetEmployerFiles(empId?: string): Promise<Array<FileMetadata>> {
  if(!empId) {
    empId = await GetEmployerId()
  }

  const url = GetUrlString('employers/:empId/files', { empId })
  const response = await axios.get(url)
  return response.data
}

async function GetEmployerLogo(employerId: string): Promise<Blob | undefined> {
  if (!employerId) {
    return undefined
  }
  try {
    const response = await axios.get(
      GetEmployerLogoUrl(employerId),
      { responseType: 'blob', headers: { 'Cache-Control': 'no-cache' } }
    )
    return response.data
  }
  catch {
    return undefined
  }
}

function GetEmployerLogoUrl(employerId: string): string {
  return GetUrlString('anon/employer/:employerId/files/logo', { employerId })
}

async function GetEmployersByDomain(domain: string): Promise<Array<EmployerProfile>> {
  const $filter = `emailDomain eq '${domain}'`
  const url = GetUrlString('employers', { $filter })
  const response = await axios.get(url)
  return response.data
}

async function GetEmployerNameById(id: string): Promise<string> {
  const idArray: string[] = [id]
  const nameArray = await GetEmployerNamesByIds(idArray)
  const employerName = nameArray[id as any]
  return employerName.toString()
}

async function GetEmployerNamesByIds(id: string[]): Promise<Array<Record<string, string>>> {
  const url = GetUrlString('anon/employer-names', { id })
  const response = await axios.get(url)
  return response.data
}

async function PostEmployerFileUpload(candidateFile: File, fileDescription: FileDescription): Promise<AxiosResponse> {
  const formData = new FormData()
  formData.append('file', candidateFile)
  formData.append('fileDescription', fileDescription)

  const empId = await GetEmployerId()
  const url = GetUrlString('employers/:empId/files', { empId })

  return axios({
    method: 'post',
    url: url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

async function DeleteEmployerFile(fileMetadataId: string) {
  const empId = await GetEmployerId()
  await axios.delete(GetUrlString('employers/:empId/files/:fileMetadataId', { empId, fileMetadataId }))
}

async function PutEmployerProfile(employerProfile: EmployerProfile): Promise<AxiosResponse> {
  const empId = await GetEmployerId()
  return axios.put(
    GetUrlString('employers/:empId', { empId }),
    { employerProfile: employerProfile })
}

async function RegisterEmployer(employerProfile: EmployerProfile, emailDomain: string): Promise<AxiosResponse> {
  return axios.post(GetUrlString('employers'), { employerProfile, emailDomain })
}

async function GetPendingEmployers(): Promise<Array<EmployerProfile>> {
  const $filter = 'not verifications/any()'
  const url = GetUrlString('employers', { $filter })
  const response = await axios.get(url)
  return response.data
}

async function GetApprovedEmployers(): Promise<Array<EmployerProfile>> {
  const $filter = "verifications/any(v:v/type eq 'Admin')"
  const url = GetUrlString('employers', { $filter })
  const response = await axios.get(url)
  return response.data
}

async function GetRejectedEmployers(): Promise<Array<EmployerProfile>> {
  const $filter = "verifications/any(v:v/type eq 'Rejection')"
  const url = GetUrlString('employers', { $filter })
  const response = await axios.get(url)
  return response.data
}

async function UpdateEmployerStatus(id: string, isApproved: boolean): Promise<AxiosResponse> {
  const status = isApproved ? 'admin' : 'rejection'
  const url = GetUrlString(`employers/${id}/verifications/${status}`)
  return axios.put(url)
}

export {
  GetEmployerProfile,
  GetEmployerFiles,
  GetEmployersByDomain,
  GetEmployerNameById,
  GetEmployerNamesByIds,
  PostEmployerFileUpload,
  DeleteEmployerFile,
  GetEmployerLogo,
  GetEmployerLogoUrl,
  PutEmployerProfile,
  RegisterEmployer,
  GetPendingEmployers,
  GetApprovedEmployers,
  GetRejectedEmployers,
  UpdateEmployerStatus
}
