import { TableRowDropdownItem } from './Applicants.interface'
import { ColumnHeader } from '@/components/Table/TableUtils/Table.interface'
import { ApplicationStatusKey } from '@/types/application'

export const tableColumns: ColumnHeader[] = [
  { label: '', key: 'select' },
  { label: '', key: 'user-icon' },
  { label: 'Applicant Name', key: 'title', width: 150 },
  { label: 'Location', key: 'location' },
  { label: 'School', key: 'school' },
  { label: '', key: 'action-column' }
]

export const dropdownOptions: TableRowDropdownItem[] = [
  { label: 'Edit' }
]

export const trimString = (val: string): string => {
  return (val || '').replace(/\s+/g, '-').toLowerCase()
}

export const formatApplicantStatus = (status: ApplicationStatusKey) => {
  if (status === 'NotReviewed') {
    return 'Not Reviewed'
  } else if (status === 'NotHired') {
    return 'Not Hired'
  }
  return status.toString()
}
