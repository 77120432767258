
import { defineComponent, ref, watch, computed } from 'vue'
import BsPhoneNumberDropdown from './BsPhoneNumberDropdown.vue'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import { IPhoneNumber } from '@/providers/CountryService/CountryService.interfaces'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import locales from './BsPhoneNumberDropdown.locales.en.json'

function createDefaultPhoneNumber() {
  return {
    countryCode: 'US',
    number: '',
    dialingCode: '1'
  }
}

export default defineComponent({
  components: { BsPhoneNumberDropdown },
  props: {
    modelValue: {
      type: String,
      default: () => ''
    },
    validationScope: {
      type: String,
      default: () => ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const countryService = injectCountryService()
    const phoneNumber = ref<IPhoneNumber>(createDefaultPhoneNumber())
    const countryCode = computed<string>(() => phoneNumber.value.countryCode)
    const placeholder = ref<string>('')

    const isPhoneNumber = helpers.withMessage(locales.invalid_phone_number, helpers.withAsync(async (_phoneNumber: IPhoneNumber) => {
      if (!_phoneNumber.number) {
        return true
      }
      return countryService.isValidPhoneNumber(_phoneNumber)
    }))
    const rules = {
      phoneNumber: { isPhoneNumber }
    } as ValidationArgs<any>

    const v$ = useVuelidate(rules, { phoneNumber }, { $scope: props.validationScope || false })

    watch(() => props.modelValue, async (_rawPhoneNumber) => {
      if (!_rawPhoneNumber) {
        return
      }
      try {
        phoneNumber.value = await countryService.parseRawPhoneNumber(_rawPhoneNumber)
      } catch(error) {
        // Do nothing
      }
    })

    watch(phoneNumber, (_phoneNumber) => {
      if (_phoneNumber.number) {
        emit('update:modelValue', '+' + _phoneNumber.dialingCode + _phoneNumber.number)
      } else {
        emit('update:modelValue', '')
      }
    })

    watch(countryCode, async (_countryCode) => {
      const phoneNumberExample = await countryService.getPhoneNumberExample(_countryCode)
      placeholder.value = locales.example_abbrev + ' ' + phoneNumberExample
    })

    return {
      phoneNumber,
      placeholder,
      v$
    }
  }
})
