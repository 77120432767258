import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55009578"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "BsSpinner d-flex justify-content-center" }
const _hoisted_2 = {
  class: "BsSpinner__spinner spinner-border",
  role: "status"
}
const _hoisted_3 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.locales.loading) + "... ", 1)
    ])
  ]))
}