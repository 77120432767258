import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e68c00a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "EmployerJobPostBoard__board" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTab = _resolveComponent("BsTab")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BsTabList = _resolveComponent("BsTabList")!
  const _component_EmployerJobPostGrid = _resolveComponent("EmployerJobPostGrid")!
  const _component_BsTabPanel = _resolveComponent("BsTabPanel")!
  const _component_JobOpportunitiesTableFilter = _resolveComponent("JobOpportunitiesTableFilter")!
  const _component_BsTabContainer = _resolveComponent("BsTabContainer")!

  return (_openBlock(), _createBlock(_component_BsTabContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: _ctx.id,
        class: "EmployerJobPostBoard"
      }, [
        _createVNode(_component_BsTabList, { class: "EmployerJobPostBoard__toolbar" }, {
          right: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _ctx.postJobOpportunityRoute,
              class: "btn btn-primary mx-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.locales.create), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              class: "btn btn-outline-primary",
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterDialogIsOpened = true))
            }, _toDisplayString(_ctx.locales.filters), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store?.state.jobStatuses, (jobStatus, index) => {
              return (_openBlock(), _createBlock(_component_BsTab, {
                id: _ctx.createTabId(_ctx.id, index),
                key: index,
                onSelected: ($event: any) => (_ctx.handleJobStatusSelected(jobStatus))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(jobStatus.displayName) + " ", 1),
                  (jobStatus.isReady)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" (" + _toDisplayString(jobStatus.posts.length) + ") ", 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["id", "onSelected"]))
            }), 128))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store?.state.jobStatuses, (jobStatus, index) => {
            return (_openBlock(), _createBlock(_component_BsTabPanel, {
              key: index,
              for: _ctx.createTabId(_ctx.id, index)
            }, {
              default: _withCtx(({ selected }) => [
                selected
                  ? (_openBlock(), _createBlock(_component_EmployerJobPostGrid, {
                      key: 0,
                      class: "mb-3",
                      "job-status": jobStatus,
                      onPostSelected: _ctx.handleJobPostSelected,
                      onEditPost: _ctx.handleEditJobPost
                    }, null, 8, ["job-status", "onPostSelected", "onEditPost"]))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("a", {
                  href: "#",
                  role: "button",
                  onClick: _withModifiers(($event: any) => (_ctx.handleShowMore(jobStatus)), ["prevent"])
                }, " Show More ", 8, _hoisted_3), [
                  [_vShow, !jobStatus.isLoading && jobStatus.hasAnotherPage]
                ])
              ]),
              _: 2
            }, 1032, ["for"]))
          }), 128))
        ])
      ], 8, _hoisted_1),
      _createVNode(_component_JobOpportunitiesTableFilter, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event)),
        visible: _ctx.filterDialogIsOpened,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterDialogIsOpened) = $event)),
        onResetFilter: _ctx.filterReset
      }, null, 8, ["modelValue", "visible", "onResetFilter"])
    ]),
    _: 1
  }))
}