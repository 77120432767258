
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'
import BsRadio from '@/components/BsRadio/BsRadio.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from '../CandidateProfile.locales.en.json'
import { DualDegrees, SchoolInformation, ProgramType } from '@/types/candidate'
import {
  EndDateInvalidGivenStartDate,
  IsValidSchoolInformation
} from '@/types/validation/schoolInformationValidation'
import {
  NotListed,
  SchoolNameArray,
  SchoolNameSet
} from '@/types/candidate/schoolNames'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { IsValidDate } from '@/types/validation/genericValidation'
import DateTimeInput from '@/components/DateTimeInput.vue'

const requiredDate = helpers.withMessage(
  locales.valid_mm_dd_yyyy_date_message,
  IsValidDate
)

const conditionalRequired = (condition: boolean) => condition ? required : true

export default defineComponent({
  components: {
    BsAutoComplete,
    BsRadio,
    BsSelect,
    BsTextField,
    DateTimeInput
  },
  props: {
    modelValue: {
      type: Object as PropType<SchoolInformation>,
      default: ()=> {}
    },
    validationScope: {
      type: String,
      default: () => ''
    },
    idIndex: {
      type: Number,
      default: 0
    }
  },
  emits:['Update:modelValue'],
  setup(props, { emit }) {
    const reactiveSchoolInfo = reactive<SchoolInformation>(props.modelValue)
    const autoSchoolName = ref(loadAutoSchoolName())
    const otherSchoolName = ref(SchoolNameSet.has(props.modelValue.schoolName) ? '' : props.modelValue.schoolName)
    const filteredSchoolNames = ref()
    const autoCompleteReturnMax = 10

    const lawDegreeOptions = [
      {
        text: locales.jd
      },
      {
        text: locales.dual_degree
      }
    ]

    const rules = computed<any>(() => {
      return {
        autoSchoolName: {
          required
        },
        otherSchoolName: {
          required: conditionalRequired(autoSchoolName.value === NotListed)
        },
        form: {
          country: {
            required: conditionalRequired(autoSchoolName.value === NotListed)
          },
          degree: {
            required
          },
          startDate: {
            requiredDate
          },
          endDate: {
            requiredDate,
            valid: helpers.withMessage(
              locales.graduation_before_attention_date,
              (value: Date, vm) => !EndDateInvalidGivenStartDate(vm.startDate, value)
            )
          }
        }
      }
    })

    const v$ = useVuelidate(rules, {
      autoSchoolName,
      otherSchoolName,
      form: reactiveSchoolInfo
    }, { $scope: props.validationScope || false })
    v$.value.$touch()

    function loadAutoSchoolName() {
      if (!props.modelValue.schoolName) {
        return ''
      }

      if (SchoolNameSet.has(props.modelValue.schoolName)) {
        return props.modelValue.schoolName
      }

      return NotListed
    }

    const searchSchoolNames = (event: any) => {
      const searchString = event.query.trim()?.toLowerCase()
      if (!searchString || !searchString.length) {
        filteredSchoolNames.value = []
      } else {
        filteredSchoolNames.value = [...filter(searchString), NotListed]
      }
    }

    function* filter(searchString: string) {
      let count = 0
      let i = 0
      while (count < autoCompleteReturnMax && i < SchoolNameArray.length) {
        if (SchoolNameArray[i].toLowerCase().startsWith(searchString)) {
          yield SchoolNameArray[i]
          count++
        }
        i++
      }
    }

    const currentSchoolInfo = computed((): SchoolInformation | undefined => {
      const calcSchoolInfo = reactiveSchoolInfo

      if (v$.value.$silentErrors.length > 0) {
        return undefined
      }

      calcSchoolInfo.isManualEntry = autoSchoolName.value === NotListed
      if (calcSchoolInfo.isManualEntry) {
        calcSchoolInfo.schoolName = otherSchoolName.value
      } else {
        calcSchoolInfo.schoolName = autoSchoolName.value
      }

      if (IsValidSchoolInformation(calcSchoolInfo)) {
        return calcSchoolInfo
      }

      return undefined
    })

    watch(autoSchoolName, (newVal, oldVal)=> {
      if(newVal !== oldVal){
         reactiveSchoolInfo.schoolName = newVal
      }
    })

    watch(currentSchoolInfo, (newValue) => {
      emit('Update:modelValue', newValue)
    })

    const isLawSchool = computed(() => {
      return reactiveSchoolInfo.programType?.toString() === ProgramType[ProgramType.Law]
    })

    return {
      locales,
      DualDegrees,
      reactiveSchoolInfo,
      filteredSchoolNames,
      searchSchoolNames,
      autoSchoolName,
      otherSchoolName,
      lawDegreeOptions,
      isLawSchool,
      ProgramType,
      v$
    }
  }
})
