
import { defineComponent, ref } from 'vue'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import locales from './EmployerProfile.locales.en.json'

type DialogInstance = InstanceType<typeof Dialog>

export default defineComponent({
  components: {
    Dialog
  },
  emits: ['agree'],
  setup(props, { emit }) {
    const dialogRef = ref<DialogInstance>()

    function handleSubmit() {
      emit('agree')
      dialogRef.value?.close()
    }

    function handleCancel() {
      dialogRef.value?.close()
    }

    function openModal() {
      dialogRef.value?.open()
    }

    return {
      dialogRef,
      handleSubmit,
      handleCancel,
      openModal,
      locales
    }
  }
})
