
import { computed, defineComponent, PropType, ref } from 'vue'
import DynamicInputGenerator from './DynamicInputGenerator.vue'
import locales from '../CandidateProfile.locales.en.json'

export default defineComponent({
  components: {
    DynamicInputGenerator
  },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showForm = ref(false)

    const certifications = computed({
      get: () => props.modelValue,
      set: (newValue) => emit('update:modelValue', newValue)
    })
    
    const toggleForm = () => showForm.value = !showForm.value

    return {
      locales,
      certifications,
      showForm,
      toggleForm
    }
  }
})
