import { GetEmployerNameById } from '@/api/EmployerApi'
import { formatDate, formatDateWithDefault } from '@/utils/date'
import { formatOpportunityCompensation, formatOpportunityLocation } from '@/utils/opportunity'
import { TextValue } from '..'
import { Candidate } from '../candidate'
import { FileDescriptionTextValues } from '../fileMetadata/fileDescription'
import { ApplyTypes, CandidateTypes, JobOpportunity, OpportunityTypes, RequirementsToApply } from '../opportunity'

interface Application {
  id: string
  opportunityId: string
  candidateId: string
  status: ApplicationStatusKey
}

interface Applicant {
  applicationId: string
  opportunityId: string
  candidateId: string
  candidateName: string
  resumeFileId: string
  transcriptFileId: string
  writingSampleFileId: string
  status: ApplicationStatusKey
  shortlisted: boolean
  isNew: boolean
}

interface GetApplicantsResponse {
  applicants: Array<Applicant>
  totalApplicants: number
}

type ApplicationStatusKey = 'Reviewed' | 'NotReviewed' | 'Interviewing' | 'NotHired'
const ApplicationStatuses: Array<TextValue<ApplicationStatusKey>> = [
  { text: 'Reviewed', value: 'Reviewed' },
  { text: 'Not Reviewed', value: 'NotReviewed' },
  { text: 'Interviewing', value: 'Interviewing'},
  { text: 'Not Hired', value: 'NotHired'}
]

export interface SummaryDetailAttachmentModel {
  fileName: string
  domainObjectId?: string
}

interface SummaryDetailsModel {
  opportunityId?: string
  employerId?: string
  profilePictureDomainObjectId?: string
  opportunityTitle: string
  companyName: string
  viewsCount: number
  status: string
  postedDate: string
  deadline: string
  startDate: string
  type: string
  location: string
  salary: string
  userType: string
  jobType: string
  description: string
  recruiter: {
    name: string
    designation: string
    email: string
    profilePic: null | string
  }
  requirements: string[]
  attachments: SummaryDetailAttachmentModel[]
  practiceAreas?: string[]
  youTubeVideoId: string | undefined
}

interface RowData {
  id: string
  candidateId: string
  opportunityId: string
  title: string
  location: string
  status: string
  school: string
  favorite: boolean
  candidate: Candidate
  applicant: Applicant
}

interface TableRowDropdownItem {
  label: string
}

async function mapOpportunityToSummaryDetails(jobOpportunity: JobOpportunity | undefined ): Promise<SummaryDetailsModel> {
  let returnDetails = { recruiter: {}} as SummaryDetailsModel
  if (!jobOpportunity) {
    return returnDetails
  }

  const { id, opportunityListing } = jobOpportunity
  if(!opportunityListing) {
    return returnDetails
  }
  const employerName = await GetEmployerNameById(jobOpportunity.employerId)

  const nonFileReqs = RequirementsToApply.filter(req => opportunityListing.requirementsToApply.includes(req.value)).map(req => req.text)
  const fileReqs = FileDescriptionTextValues.filter(fd => opportunityListing.fileRequirementsToApply.includes(fd.value)).map(fd => fd.text)

 returnDetails = {
    opportunityId: id,
    employerId: jobOpportunity.employerId,
    opportunityTitle: opportunityListing.title,
    companyName: employerName,
    viewsCount: jobOpportunity.views ?? 0,
    status: jobOpportunity.status,
    postedDate: formatDate(opportunityListing.postedDate),
    deadline: formatDateWithDefault(opportunityListing?.applicationDeadline),
    startDate: formatDateWithDefault(opportunityListing.startDate),
    type: ApplyTypes.find(type => type.value === opportunityListing.applyType)?.text ?? 'Undetermined',
    location: formatOpportunityLocation(opportunityListing),
    salary: formatOpportunityCompensation(opportunityListing) || 'Salary Not Available',
    userType: CandidateTypes.find(type => type.value === opportunityListing.audience)?.text ?? 'Undetermined',
    jobType: OpportunityTypes.find(option => option.value === opportunityListing.opportunityType)?.text ?? 'Undetermined',
    description: opportunityListing.description,
    recruiter: {
      name: opportunityListing.recruiterContact,
      designation: opportunityListing.recruiterTitle,
      email: opportunityListing.recruiterEmail,
      profilePic: null
    },
    requirements: [...fileReqs, ...nonFileReqs],
    attachments: jobOpportunity.supportingFileMetadata?.map(metadata => ({
      fileName: metadata.fileName,
      domainObjectId: metadata.domainObjectId
    })) ?? [],
    practiceAreas: opportunityListing.practiceAreas,
    youTubeVideoId: opportunityListing.youTubeVideoId
  }

  return returnDetails
}



export {
  Application,
  Applicant,
  GetApplicantsResponse,
  ApplicationStatusKey,
  ApplicationStatuses,
  SummaryDetailsModel,
  RowData,
  TableRowDropdownItem,
  mapOpportunityToSummaryDetails
}
