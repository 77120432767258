import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7672dbf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check-label bs-field-label" }
const _hoisted_2 = { class: "name-area" }
const _hoisted_3 = {
  id: "view-candidate-name",
  class: "title"
}
const _hoisted_4 = {
  id: "view-candidate-email",
  class: "email"
}
const _hoisted_5 = {
  id: "view-candidate-phone",
  class: "phone"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")!
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_BsButton = _resolveComponent("BsButton")!
  const _component_ProfilePictureUpload = _resolveComponent("ProfilePictureUpload")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")!
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_UploadFileContainer = _resolveComponent("UploadFileContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BsCheckbox, {
      id: "info-hide-profile",
      modelValue: _ctx.showProfile,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showProfile) = $event)),
      class: "mb-3 pull-right",
      "is-switch": true
    }, {
      left: _withCtx(() => [
        _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.locales.hide_my_profile), 1)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProfilePicture, {
        "candidate-id": _ctx.modelValue.id,
        "image-source": _ctx.profilePictureSourceRef,
        "revoke-object-url-on-unmounted": false,
        class: "profile-picture",
        alt: "profile picture",
        onProfilePicLoaded: _ctx.updateProfilePicture
      }, null, 8, ["candidate-id", "image-source", "onProfilePicLoaded"]),
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.candidateFullName), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.formFields.emailAddress), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formFields.phoneNumber), 1)
      ])
    ]),
    _createVNode(_component_BsButton, {
      label: _ctx.locales.add_profile_picture,
      class: "px-0 py-1",
      mode: "link",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.profilePictureFileInput.click()))
    }, null, 8, ["label"]),
    _createElementVNode("input", {
      ref: "profilePictureFileInput",
      class: "d-none",
      type: "file",
      accept: "image/*",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleChangeProfilePic && _ctx.handleChangeProfilePic(...args)))
    }, null, 544),
    _createVNode(_component_ProfilePictureUpload, {
      ref: "profilePictureUpload",
      "is-candidate": true,
      onCroppedPictureAsBlob: _ctx.handleCroppedPicture
    }, null, 8, ["onCroppedPictureAsBlob"]),
    _createVNode(_component_BsSelect, {
      id: "info-candidate-type",
      modelValue: _ctx.formFields.candidateType,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formFields.candidateType) = $event)),
      class: "my-3",
      label: _ctx.locales.candidate_type,
      validation: _ctx.validation.profileData.candidateType,
      options: [
      {
        text: _ctx.locales.select_candidate_type,
        value: '',
        disabled: true
      },
      ..._ctx.CandidateTypes
    ],
      "option-label": "text",
      "option-value": "value",
      "option-disabled": "disabled",
      "default-selected-value": ""
    }, null, 8, ["modelValue", "label", "validation", "options"]),
    _createVNode(_component_BsAutoComplete, {
      id: "info-dream-job-locations",
      modelValue: _ctx.computeDreamJobLocations,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computeDreamJobLocations) = $event)),
      class: "mb-3",
      suggestions: _ctx.filteredCities,
      label: _ctx.locales.dream_job_locations,
      multiple: true,
      field: "text",
      validation: _ctx.validation.profileData.dreamJobData.preferredLocations,
      onComplete: _ctx.handleLocationSearch
    }, null, 8, ["modelValue", "suggestions", "label", "validation", "onComplete"]),
    _createVNode(_component_BsMultiSelect, {
      id: "info-practice-areas",
      modelValue: (_ctx.formFields.dreamJobData || {}).practiceAreas,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (((_ctx.formFields.dreamJobData || {}).practiceAreas) = $event)),
      class: "mb-3",
      options: _ctx.PracticeAreaOptions,
      label: _ctx.locales.practice_areas,
      validation: _ctx.validation.profileData.dreamJobData.practiceAreas
    }, null, 8, ["modelValue", "options", "label", "validation"]),
    _createVNode(_component_BsMultiSelect, {
      id: "info-dream-job-types",
      modelValue: (_ctx.formFields.dreamJobData || {}).dreamJobTypes,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (((_ctx.formFields.dreamJobData || {}).dreamJobTypes) = $event)),
      class: "mb-3",
      options: _ctx.EmployerTypeOptions,
      label: _ctx.locales.job_types,
      validation: _ctx.validation.profileData.dreamJobData.dreamJobTypes
    }, null, 8, ["modelValue", "options", "label", "validation"]),
    _createVNode(_component_BsMultiSelect, {
      id: "info-languages",
      modelValue: _ctx.formFields.languages,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formFields.languages) = $event)),
      class: "mb-3",
      options: _ctx.LanguagesOptions,
      label: _ctx.locales.languages,
      validation: _ctx.validation.profileData.languages
    }, null, 8, ["modelValue", "options", "label", "validation"]),
    _createVNode(_component_BsTextField, {
      id: "info-linked-in-url",
      modelValue: _ctx.linkedInUrl,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.linkedInUrl) = $event)),
      class: "mb-3",
      label: _ctx.locales.linkedin,
      placeholder: _ctx.locales.linkedin,
      validation: _ctx.validation.profileData.linkedInLink
    }, null, 8, ["modelValue", "label", "placeholder", "validation"]),
    _createVNode(_component_TextEditor, {
      id: "info-description",
      modelValue: _ctx.formFields.description,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formFields.description) = $event)),
      label: _ctx.locales.describe_yourself,
      validation: _ctx.validation.profileData.description
    }, null, 8, ["modelValue", "label", "validation"]),
    _createVNode(_component_UploadFileContainer, { id: "candidateFileUploads" })
  ], 64))
}