import { oktaAuth } from '@/okta'
import axios from 'axios'

export function authInterceptor() {
  axios.interceptors.request.use((config) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const token = oktaAuth.getIdToken()

    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`
    }

    return config
  })
}
