
import { defineComponent, PropType, ref, computed } from 'vue'
import { DropdownOptionModel } from './Header.interface'
import { createAutoIncrementId } from '@/utils/VueTools'
import UserNameIcon from './UsernameIcon.vue'
import AgentProfile from '@/views/AgentProfileView.vue'
import { useOutsideClick } from '@/utils/HandleOutsideClick'
import { UserClaims } from '@okta/okta-auth-js'
import locales from './Header.locales.en.json'
import router from '@/router'

export default defineComponent({
  components: {
    UserNameIcon,
    AgentProfile
  },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('HeaderDropdown')
    },
    nameDetails: {
      type: [Object, null] as PropType<UserClaims>,
      required: true
    },
    options: {
      type: Array as PropType<DropdownOptionModel[]>,
      required: true
    },
    isAuthenticated: {
      type: Boolean,
      required: true
    },
    isCreatingSession: {
      type: Boolean,
      required: true
    }
  },
  emits: ['handle-user-login'],
  setup(props, { emit }) {
    const isAgentProfileOpen = ref<boolean>(false)
    const dropdownRef = ref<HTMLDivElement>()
    const { isOpen, handleOpen, handleClose } = useOutsideClick(dropdownRef)
    const menuButtonId = computed(() => props.id + '__menu-button')
    const menuId = computed(() => props.id + '__menu')

    function handleOptionClick(e: Event, val: DropdownOptionModel) {
      if(val.key === 'logout'){
        handleUserLogin()
      } else if(val.key === 'employerProfile'){
        router.push('/EmployerProfile')
      } else if(val.key === 'candidateProfile'){
        router.push('/CandidateProfile')
      } else if(val.key === 'notifications'){
        router.push('/CandidateDashboard')
      } else if(val.key === 'settings'){
        isAgentProfileOpen.value = true
      }

      handleClose()
    }

    function handleUserLogin(){
      emit('handle-user-login')
    }

    return {
      dropdownRef,
      isOpen,
      handleOpen,
      handleOptionClick,
      locales,
      handleUserLogin,
      isAgentProfileOpen,
      menuButtonId,
      menuId
    }
  }
})
