import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fc71737"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "education-segment" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "left-segment col-5" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "middle-segment col-5" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "right-segment col-2" }
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingWrapper = _resolveComponent("HeadingWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingWrapper, {
      "hide-options": _ctx.viewOnly,
      heading: _ctx.locales.education,
      "dropdown-options": _ctx.otherSubMenuOptions,
      onOptionChange: _ctx.handleOptionChange
    }, null, 8, ["hide-options", "heading", "dropdown-options", "onOptionChange"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.details.schoolInformation || []), (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "row-item"
      }, [
        item
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h6", {
                  id: `view-school-name-${i}`,
                  class: "font-bold-heading ellipsis"
                }, _toDisplayString(item.schoolName), 9, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", {
                  id: `view-school-degree-${i}`,
                  class: "content-label ellipsis"
                }, _toDisplayString(item.degree), 9, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h6", {
                  id: `view-school-years-attended-${i}`,
                  class: "content-label"
                }, _toDisplayString(_ctx.getGraduationPeriod(item).start.year) + " - " + _toDisplayString(_ctx.getGraduationPeriod(item).end.year), 9, _hoisted_8)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}