import axios from 'axios'
import { GetSessionUrlString } from './ApiBase'

async function createSession(redirectUrl: string, registerOnly: boolean): Promise<string> {
  const response = await axios.post(GetSessionUrlString('anon/sessions'), {
    redirectUrl: redirectUrl,
    registerOnly: registerOnly
  })
  return response.data.toString()
}

export { createSession }
