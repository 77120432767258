
import { defineComponent } from 'vue'
import locales from './BsSpinner.locales.en.json'

export const BsSpinner = defineComponent({
  name: 'BsSpinner',
  setup() {
    return { locales }
  }
})

export default BsSpinner
