
import { CandidateDiversityDisclosure, DiversityDisclosureUK, DiversityDisclosureUS } from '@/types/candidate'
import { computed, PropType } from 'vue'
import locales from '../CandidateProfile.locales.en.json'
import { containsValue } from '@/utils/general'

export default {
  props: {
    modelValue: {
      type: Object as PropType<CandidateDiversityDisclosure>,
      required: true
    }
  },
  setup(props: any) {

    const usDisclosure = computed(() => {
      return props.modelValue.usDisclosure ?? {} as DiversityDisclosureUS
    })

    const ukDisclosure = computed(() => {
      return props.modelValue.ukDisclosure ?? {} as DiversityDisclosureUK
    })

    return {
      locales,
      usDisclosure,
      ukDisclosure,
      containsValue
    }
  }
}
