import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ef5998"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "data-invalid", "data-disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_5 = {
  key: 1,
  class: "input-group-text"
}
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["BsSelect form-control-helper", { 'bs-invalid-field': _ctx.dirty && _ctx.invalid }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.selectId,
          class: "BsSelect__label form-label d-flex bs-field-label",
          "data-invalid": _ctx.dirty && _ctx.invalid,
          "data-disabled": _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.prepend)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.prepend), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_Dropdown, {
        id: _ctx.selectId,
        ref: "dropdown",
        class: _normalizeClass(["BsSelect__select form-select", {
          'is-invalid': _ctx.dirty && _ctx.invalid
        }]),
        options: _ctx.options,
        "option-label": _ctx.optionLabel,
        "option-value": _ctx.optionValue,
        "option-disabled": _ctx.optionDisabled,
        filter: _ctx.showFilter,
        disabled: _ctx.disabled,
        "aria-describedby": _ctx.invalidMessage && _ctx.invalidMessageId,
        "model-value": _ctx.modelValue,
        "append-to": _ctx.appendToSelector,
        onShow: _ctx.handleDropdownShow,
        onBeforeHide: _ctx.handleDropdownBeforeHide,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event)))
      }, null, 8, ["id", "class", "options", "option-label", "option-value", "option-disabled", "filter", "disabled", "aria-describedby", "model-value", "append-to", "onShow", "onBeforeHide"]),
      (_ctx.append)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.append), 1))
        : _createCommentVNode("", true),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            id: _ctx.invalidMessage && _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}