
import { computed, defineComponent, PropType, ref } from 'vue'
import { DialogInstance } from '../Shared/DialogWrapper.vue'
import { JobOpportunity, OpportunityListing } from '@/types/opportunity'
import OpportunityDetails from '@/components/ApplyToOpportunity/OpportunityDetails.vue'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'

const PreviewOpportunity = defineComponent({
  name: 'PreviewOpportunity',
  components: {
    OpportunityDetails
  },
  props: {
    id : {
      type: String,
      default: 'PreviewOpportunity'
    },
    opportunityListing: {
      type: Object as PropType<OpportunityListing>,
      required: true
    },
    files: {
      type: Array as PropType<Array<FileMetadata>>,
      required: true
    },
    employerName : {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dialog = ref<DialogInstance>()
    const opportunityDetails = ref<InstanceType<typeof OpportunityDetails>>()

    const jobOpportunity = computed(() => {
      const opportunityListing = {...props.opportunityListing}
      opportunityListing.postedDate = new Date()
      return {
        employerName: props.employerName,
        opportunityListing: opportunityListing,
        supportingFileMetadata: props.files
      } as JobOpportunity
    })

    function open() {
      opportunityDetails.value?.toggleModal(true)
    }

    function close() {
      opportunityDetails.value?.toggleModal(false)
    }

    return {
      opportunityDetails,
      jobOpportunity,
      dialog,
      open,
      close
    }
  }
})

export default PreviewOpportunity
export type PreviewOpportunityInstance = InstanceType<typeof PreviewOpportunity>
