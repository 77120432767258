import { GetEmployerId, GetUserId } from '@/okta'
import { ApplicationStatusKey, Application, GetApplicantsResponse } from '@/types/application'
import axios, { AxiosResponse } from 'axios'
import { GetUrlAssembler, GetUrlString } from './ApiBase'

async function SubmitApplication (opportunityId: string, resumeFileId: string, transcriptFileId: string, writingSampleFileId: string): Promise<void> {
  const candidateId = await GetUserId()
  return axios.put(GetUrlString('opportunities/:opportunityId/applications/:candidateId', {opportunityId, candidateId}), {resumeFileId, transcriptFileId, writingSampleFileId})
}

async function GetApplicationsForCandidate(): Promise<Application[]> {
  try {
    const response = await axios.get(
      GetUrlString('candidates/self/applications')
    )
    return response.data as Application[]
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return []
    }
    throw Error('Error retrieving applications for candidate')
  }
}

async function GetApplicantsByOpportunity(opportunityId: string, currentPage = 1, perPage = 10, status: ApplicationStatusKey | undefined = undefined): Promise<GetApplicantsResponse> {
  const employerId = await GetEmployerId()
  try {
    let assembler = GetUrlAssembler({ path: 'employers/:employerId/opportunities/:opportunityId/applicants', params: { employerId, opportunityId }, perPage, currentPage })
    if (status) {
      assembler = assembler.query('$filter', `Status eq '${status}'`)
    }
    const response = await axios.get(assembler.toString())
    return {
      applicants: response.data,
      totalApplicants: parseInt(response.headers['x-total-count'])
    } as GetApplicantsResponse
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return {
        applicants: [],
        totalApplicants: 0
      } as GetApplicantsResponse
    }
    throw Error('Error retrieving applications for opportunity')
  }
}

async function ChangeApplicationStatus(opportunityId: string, applicationId: string, status: ApplicationStatusKey): Promise<AxiosResponse> {
  const patchDoc = [{
    op: 'replace',
    path: '/status',
    value: status
  }]
  return axios.patch(GetUrlString('opportunities/:opportunityId/applications/:applicationId', {opportunityId, applicationId}), patchDoc)
}

async function MarkApplicationAsViewedByAgent(opportunityId: string, applicationId: string){
  await axios.put(GetUrlString('opportunities/:opportunityId/applications/:applicationId/agents/self', {opportunityId, applicationId}))
}

export {
  SubmitApplication,
  GetApplicationsForCandidate,
  GetApplicantsByOpportunity,
  ChangeApplicationStatus,
  MarkApplicationAsViewedByAgent
}
