import locales from './CandidateProfile.locales.en.json'
import { PreviewDropdownModel } from './CandidateProfile.interface'

export const formatDateForInput = (date: Date | undefined) => {
  if (!date) {
    return ''
  }

  let month: string | number = date.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day: string | number = date.getDate()
  day = day < 10 ? '0' + day : day
  const year = date.getFullYear()

  return year + '-' + month + '-' + day
}

export const stripHtmlTags = (html: string)=>{
  let plainText = ''

  if(html){
    const div = document.createElement('div')
    div.innerHTML = html
    plainText = div.textContent || div.innerText || ''
  }
  return plainText
}

export const candidateMenuOptions :PreviewDropdownModel[] = [
  { value: 'edit', label: locales.view_edit },
  { value: 'hide-profile', label: locales.hide_profile }
]

export const otherSubMenuOptions :PreviewDropdownModel[] = [
  { value: 'edit', label: locales.add_edit }
]