
import DiversityWrapper from '@/components/DiversityDisclosure/DiversityWrapper.vue'

export default {
  components: {
    DiversityWrapper
  },
  props: {
    viewOnly:  {
      type: String,
      default: 'false'
    }
  }
}
