
import { defineComponent, PropType, ref } from 'vue'
import DropdownOptions from '@/components/DropdownOptions/DropdownOptions.vue'
import Dialog, { DialogInstance } from '@/components/Shared/DialogWrapper.vue'
import locales from './Candidates.locales.en.json'
import { CandidateFilterMetadata } from '@/types/candidateFilterMetadata'
import { ICandidateSearchStore } from '@/views/CandidateSearchView/CandidateSearch.interfaces'

export default defineComponent({
  components: {
    DropdownOptions,
    Dialog
  },
  props: {
    savedItems: {
      type: Array as PropType<CandidateFilterMetadata[]>,
      required: true
    },
    store: {
      type: Object as PropType<ICandidateSearchStore>,
      required: true
    }
  },
  setup(props) {
    const dialogRef = ref<DialogInstance>()
    const deletableItem = ref<CandidateFilterMetadata | null>(null)

    function handleConfirmationModal(option: CandidateFilterMetadata, status: boolean) {
      if (status) {
        deletableItem.value = option
        dialogRef.value?.open()
      } else {
        deletableItem.value = null
        dialogRef.value?.close()
      }
    }

    function handleDelete() {
      //delete the item
      dialogRef.value?.close()
      props.store.actions.deleteFilter(deletableItem.value as CandidateFilterMetadata)
      deletableItem.value = null
    }

    function handleFilterApplied(candidateFilterMetadata: CandidateFilterMetadata) {
      props.store.actions.loadCandidatesByCandidateFilterId(candidateFilterMetadata.candidateFilterId)
    }

    return {
      handleConfirmationModal,
      handleFilterApplied,
      dialogRef,
      locales,
      handleDelete
    }
  }
})
