import { CustomEventTarget } from '../interfaces/common/CustomEventTarget'

export class StackedDialogCounter {
  private _emitter: CustomEventTarget<{
    'update-dialog-count': CustomEvent<number>
  }> = new EventTarget()

  private _count = 0

  get count() {
    return this._count
  }

  get emitter() {
    return this._emitter
  }

  increment() {
    this._count += 1
    this._dispatchUpdateCount()
    if (this._count !== 0) {
      document.documentElement.classList.add('host--dialog-opened')
      document.body.classList.add('host--dialog-opened')
    }
  }

  decrement() {
    if (this._count === 0) {
      return
    }
    this._count -= 1
    this._dispatchUpdateCount()
    if (this._count === 0) {
      document.documentElement.classList.remove('host--dialog-opened')
      document.body.classList.remove('host--dialog-opened')
    }
  }

  _dispatchUpdateCount() {
    this.emitter.dispatchEvent(
      new CustomEvent<number>('update-dialog-count', {
        detail: this._count
      })
    )
  }
}

export default StackedDialogCounter

document.head.insertAdjacentHTML(
  'beforeend',
  `
  <style>
    .host--dialog-opened {
      overflow: hidden;
    }
  </style>
`
)
