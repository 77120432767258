import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "DialogWrapper" }
const _hoisted_2 = ["aria-modal", "aria-label", "aria-labelledby", "aria-describedby", "aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FocusTrapWrapper = _resolveComponent("FocusTrapWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, {
        name: 'Dialog--' + _ctx.transitionMethod
      }, {
        default: _withCtx(() => [
          (_ctx.opened)
            ? (_openBlock(), _createBlock(_component_FocusTrapWrapper, _normalizeProps({
                key: 0,
                id: _ctx.id,
                ref: "wrapper",
                active: _ctx.isLastDialogOnStack,
                class: ["barbri-bs Dialog", _ctx.incomingClasses],
                [_ctx.parentScopedId || ""]: ''
              }), {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref: "backdrop",
                    class: _normalizeClass({
              Dialog__backdrop: true,
              'Dialog__backdrop--hidden': !_ctx.isLastDialogOnStack
            }),
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBackdropClick && _ctx.handleBackdropClick(...args)))
                  }, null, 2),
                  _createElementVNode("div", {
                    role: "dialog",
                    class: _normalizeClass({
              Dialog__dialog: true,
              'Dialog__dialog--behind': !_ctx.isLastDialogOnStack
            }),
                    "aria-modal": _ctx.ariaModal,
                    "aria-label": _ctx.ariaLabel,
                    "aria-labelledby": _ctx.ariaLabelledBy,
                    "aria-describedby": _ctx.ariaDescribedBy,
                    "aria-hidden": !_ctx.opened
                  }, [
                    _renderSlot(_ctx.$slots, "default")
                  ], 10, _hoisted_2)
                ]),
                _: 3
              }, 16, ["id", "active", "class"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["name"])
    ]))
  ]))
}