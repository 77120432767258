
import { defineComponent} from 'vue'
import locales from './EmployerProfile.locales.en.json'
import BannerImage from '~/icons/EmployerWelcome.svg'

export default defineComponent({
  components: {
    BannerImage
  },
  setup() {

    return { locales }
  }
})
