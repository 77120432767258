
import { Data } from '@/types'
import { JobOpportunity } from '@/types/opportunity'
import { ref } from 'vue'
import { GetPublishedOpportunitiesByCandidateFeedFilter } from '@/api/OpportunityApi'
import { onMounted } from '@vue/runtime-core'
import { CandidateFeedFilter } from '@/types/candidateFilter'
import { 
  GetCandidate, 
  PutCandidateProfile 
} from '@/api/CandidateApi'
import router from '@/router'
import { Candidate } from '@/types/candidate'
import ToggleButton from 'primevue/togglebutton'
import store from '@/store'

export default {
  components: {
    ToggleButton
  },
  setup(): Data {
    const opportunityFeed = ref<JobOpportunity[]>([])
    const candidate = ref<Candidate>({} as Candidate)

    const toggleCandidateFeed = async () => {
      if(!candidate.value) {
        return
      }

      await PutCandidateProfile(candidate.value)
    }

    const getOpportunityFeed = async () => {
      candidate.value = await GetCandidate()
      if(!candidate.value.profileData.isCandidateFeedEnabled) {
        return
      }

      const candidateFeedFilter: CandidateFeedFilter = {
        preferredLocations: candidate.value.profileData.dreamJobData.preferredLocations
      }
      const response = await GetPublishedOpportunitiesByCandidateFeedFilter(candidateFeedFilter)
      opportunityFeed.value = response.opportunities
    }

    const viewOpportunityPreviewRedirect = (opportunity: JobOpportunity) => {
      store.state.jobOpportunity = opportunity
      router.push({
        name: 'OpportunityPreview',
        params: { id: opportunity.id }
      })
    }

    onMounted(getOpportunityFeed)

    return {
      opportunityFeed,
      candidate,
      toggleCandidateFeed,
      viewOpportunityPreviewRedirect
    }
  }
}
