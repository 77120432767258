import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cb56054"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EmployerJobPostGrid" }
const _hoisted_2 = {
  key: 0,
  class: "row g-lg-3 g-2"
}
const _hoisted_3 = {
  key: 1,
  class: "EmployerJobPostGrid__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmployerJobPostCard = _resolveComponent("EmployerJobPostCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.jobStatus.posts.length || _ctx.jobStatus.isLoading || !_ctx.jobStatus.isReady)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([..._ctx.jobStatus.posts, ...Array.from({ length: 3 })], (post, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-xl-4 col-lg-6 col-sm-12"
                }, [
                  (post || _ctx.jobStatus.isLoading || !_ctx.jobStatus.isReady)
                    ? (_openBlock(), _createBlock(_component_EmployerJobPostCard, {
                        key: 0,
                        post: post,
                        onPostSelected: _ctx.handleJobPostSelected,
                        onEditPost: _ctx.handleEditJobPost
                      }, null, 8, ["post", "onPostSelected", "onEditPost"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.locales.no_posts_message), 1))
      ]),
      _: 1
    })
  ]))
}