import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { navigationGuard } from '@okta/okta-vue'
import { GetEmployerId, GetUserGroups } from '@/okta'
import { GetAgentProfile } from '@/api/AgentApi'
import { GetCandidate } from '@/api/CandidateApi'
import { IsValidCandidate } from '@/types/validation/candidateValidation'
import { GetEmployerProfile } from '@/api/EmployerApi'
import { IsValidEmployer } from '@/types/validation/employerValidation'
import store from '@/store'
import CandidateProfileView from '@/views/CandidateProfile/ViewCandidateProfile/ViewCandidateProfile.vue'
import CandidateSearchView from '@/views/CandidateSearchView/CandidateSearchView.vue'
import HomeView from '@/views/HomeView.vue'
import PostJobOpportunity from '@/views/PostJobOpportunity.vue'
import JobOpportunitiesSummary from '@/views/JobOportunitiesSummary/JobOpportunitiesSummary.vue'
import EmployerJobOpportunitiesSummary from '@/views/EmployerJobOpportunitiesSummary.vue'
import CandidateDashboardView from '@/views/CandidateDashboardView.vue'
import SelectUserType from '@/views/SelectUserType.vue'
import LoginRedirect from '@/views/LoginRedirect/LoginRedirect.vue'
import CreateRegisterSession from '@/views/LoginRedirect/CreateRegisterSession.vue'
import CreateSignInSession from '@/views/LoginRedirect/CreateSignInSession.vue'
import Applicants from '@/views/ApplicantsComponent.vue'
import Application from '@/views/ApplicationComponent.vue'
import DiversityDisclosureView from '@/views/DiversityDisclosureView.vue'
import OpportunityPreviewView from '@/views/OpportunityPreviewView.vue'
import AssociateAgent from '@/views/AssociateAgent.vue'
import RegisterEmployer from '@/views/RegisterEmployer.vue'
import ReverifyAgent from '@/views/ReverifyAgent.vue'
import EmployerProfileDisplayView from '@/views/EmployerProfileDisplayView.vue'
import InternalDashboard from '@/views/InternalDashboard.vue'
import EmployerVerificationsView from '@/views/EmployerVerifications/EmployerVerificationsView.vue'
import PrivacyPolicyContent from '@/views/PrivacyPolicyContent.vue'
import TermsOfServiceContent from '@/views/TermsOfServiceContent.vue'
import { Configuration } from '@/config/config'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresCompleteCandidateProfile: true
    }
  },
  {
    path: '/JobOpportunitiesSummary',
    name: 'Job Opportunities Summary',
    component: JobOpportunitiesSummary,
    meta: {
      requiresCompleteCandidateProfile: true
    }
  },
  {
    path: '/EmployerJobOpportunitiesSummary',
    name: 'Employer Job Opportunities Summary',
    component: EmployerJobOpportunitiesSummary,
    meta: {
      requiresAuth: true,
      agentsOnly: true
    }
  },
  {
    path: '/PostJobOpportunity',
    name: 'Post Job Opportunity',
    component: PostJobOpportunity,
    meta: {
      requiresAuth: true,
      agentsOnly: true
    }
  },
  {
    path: '/CandidateProfile/:id?',
    name: 'Candidate Profile',
    component: CandidateProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/CandidateSearch',
    name: 'Candidate Search',
    component: CandidateSearchView,
    meta: {
      requiresAuth: true,
      agentsOnly: true
    }
  },
  {
    path: '/CandidateDashboard',
    name: 'Candidate Dashboard',
    component: CandidateDashboardView,
    meta: {
      requiresAuth: true,
      requiresCompleteCandidateProfile: true
    }
  },
  {
    path: '/SelectUserType',
    name: 'Select User Type',
    component: SelectUserType,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/completeLogin',
    name: 'Login Redirect',
    component: LoginRedirect,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/initiateLogin',
    name: 'Reroute to Sign In',
    component: CreateSignInSession,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/initiateRegister',
    name: 'Reroute to Registration',
    component: CreateRegisterSession,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/Applicants',
    name: 'Applicants',
    component: Applicants,
    meta: {
      requiresAuth: true,
      agentsOnly: true
    }
  },
  {
    path: '/Application',
    name: 'Application',
    component: Application,
    meta: {
      requiresAuth: true,
      agentsOnly: true
    }
  },
  {
    path: '/DiversityDisclosure',
    name: 'Diversity Disclosure',
    component: DiversityDisclosureView,
    meta: {
      requiresAuth: true,
      requiresCompleteCandidateProfile: true
    },
    props: true
  },
  {
    path: '/OpportunityPreview/:id?',
    name: 'OpportunityPreview',
    component: OpportunityPreviewView
  },
  {
    path: '/AssociateAgent',
    name: 'Associate Agent',
    component: AssociateAgent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/RegisterEmployer',
    name: 'RegisterEmployer',
    component: RegisterEmployer,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/ReverifyAgent',
    name: 'Reverify Agent',
    component: ReverifyAgent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/EmployerProfile/:id?',
    name: 'View Employer Profile',
    component: EmployerProfileDisplayView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Dashboard',
    name: 'Admin Dashboard',
    component: InternalDashboard,
    meta: {
      requiresAuth: true,
      adminsOnly: true
    }
  },
  {
    path: '/EmployerVerifications',
    name: 'Employer Verifications',
    component: EmployerVerificationsView,
    meta: {
      requiresAuth: true,
      verificationAdminsOnly: true
    }
  },
  {
    path: '/PrivacyPolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicyContent,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/TermsOfService',
    name: 'Terms Of Service',
    component: TermsOfServiceContent,
    meta: {
      hideNavBar: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { el: 'body', top: 0 }
  },
  routes
})

router.beforeEach(navigationGuard)
router.beforeEach(to => CheckAdminsOnly(to))
router.beforeEach(to => CheckAgentsOnly(to))
router.beforeEach(to => CheckCompleteProfile(to))
router.beforeEach(to => CheckEmployerVerificationsAdminsOnly(to))

const CheckAgentsOnly = async (to: RouteLocationNormalized) => {
  if (!to.meta.agentsOnly) {
    return true
  }

  const employerId = await GetEmployerId()

  if (!employerId) {
    return false
  }

  let agent = store.state.agentProfile
  if (!agent) {
    agent = await GetAgentProfile()
    store.state.agentProfile = agent
  }

  if (!agent) {
    return false
  }

  if (!agent.isVerified && to.name !== 'Reverify Agent') {
    return '/ReverifyAgent'
  }

  let employer = store.state.employerProfile
  if(!employer) {
    employer = await GetEmployerProfile(employerId)
    store.state.employerProfile = employer
  }

  if(!employer) {
    return false
  }

  if(!IsValidEmployer(employer)) {
    return '/EmployerProfile'
  }

  return true
}

const CheckAdminsOnly = async (to: RouteLocationNormalized) => {
  if (!to.meta.adminsOnly) {
    return true
  }

  const userGroups: string[] = await GetUserGroups()

  return userGroups.includes(Configuration.ADMIN_GROUP)
}

const CheckEmployerVerificationsAdminsOnly = async (to: RouteLocationNormalized) => {
  if (!to.meta.verificationAdminsOnly) {
    return true
  }

  const userGroups: string[] = await GetUserGroups()

  return userGroups.includes(Configuration.EMPLOYER_VERIFICATION_GROUP)
}

const CheckCompleteProfile = async (to: RouteLocationNormalized) => {
  if (!to.meta.requiresCompleteCandidateProfile) {
    return true
  }
  if (!store.state.authenticated) {
    return true
  }
  const candidate = await GetCandidate()
  const isValidCandidate = IsValidCandidate(candidate)
  return isValidCandidate ? true : '/CandidateProfile'
}

export default router
