
import NavBar from '@/components/Header/HeaderNavbar.vue'
import ToastQueue from '@/components/ToastQueue/ToastQueue.vue'
import AppPage from '@/components/AppPage/AppPage.vue'
import { autoProvideToastQueueStore } from '@/components/ToastQueue/ToastQueue.store'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { Configuration } from '@/config/config'
import { autoProvideCountryService } from '@/providers/CountryService/CountryService'
import store from './store'
import { watch } from 'vue'
import { ExtendedUserClaims } from './okta'

export default {
  components: {
    NavBar,
    AppPage,
    ToastQueue
  },
  setup() {

    store.state.applicationInsights = new ApplicationInsights({
      config: {
        connectionString: Configuration.APPI_CONNECTION_STRING,
        enableAutoRouteTracking: true
      }
    })
    store.state.applicationInsights.loadAppInsights()

    watch(() => store.state.userDetails, (userClaims: ExtendedUserClaims | null) => {
      if (userClaims) {
        (window as any).Appcues.identify(
          userClaims.sub,
          {
            email: userClaims.email,
            firstName: userClaims.firstName,
            lastName: userClaims.lastName
          }
        )
      }
    })

    autoProvideToastQueueStore()
    autoProvideCountryService()
    return {}
  }
}
