import { logErrorMessage } from '@/logging'
import { GetEmployerId } from '@/okta'
import { AgentProfile } from '@/types/agent'
import { CandidateFilter } from '@/types/candidateFilter'
import { CandidateFilterMetadata } from '@/types/candidateFilterMetadata'
import { CreateCandidateFilterRequest } from '@/types/createCandidateFilterRequest'
import axios from 'axios'
import { GetUrlString } from './ApiBase'
import { GetFilterStringFromCandidateFilter } from './CandidateApi'

async function GetAgentProfile(pendingApproval: boolean = false): Promise<AgentProfile | undefined> {
  try {
    let response = undefined
    if (pendingApproval) {
      response = await (axios.get(GetUrlString('employers/pending/agents/self')))
    } else {
      const employerId = await GetEmployerId()
      const url = GetUrlString('employers/:employerId/agents/self', { employerId })
      response = await axios.get(url)
    }

    const retVal = response.data as AgentProfile
    if (!response.data.officeLocations) {
      retVal.officeLocations = []
    }
    return retVal
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return undefined
    }

    throw Error('Error retrieving Agent Profile.')
  }
}

async function UpsertAgentProfile(agentProfile: AgentProfile, employerId: string) {
  const url = GetUrlString('employers/:employerId/agents/self', { employerId })
  await axios.put(url, agentProfile)
}

async function SendOtp(employerId: string) {
  const url = GetUrlString('employers/:employerId/agents/self/verifications', { employerId })
  await axios.post(url)
}

async function VerifyOtp(code: string, employerId: string): Promise<boolean> {
  const url = GetUrlString('employers/:employerId/agents/self/verifications/email', { employerId })

  try {
    await axios.put(url, { code })
    return true
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      return false
    }
    throw error
  }
}

async function SaveCandidateFilter(candidateFilter: CandidateFilter, description: string, employerId: string): Promise<string> {
  const url = GetUrlString('employers/:employerId/agents/self/candidate-filters', { employerId })

  if (!candidateFilter.filterByGraduationYear) {
    candidateFilter.graduationYearMin = undefined
    candidateFilter.graduationYearMax = undefined
  }

  const request : CreateCandidateFilterRequest = {
    description: description,
    filterData: {
      graduationYearMin: candidateFilter.graduationYearMin,
      graduationYearMax: candidateFilter.graduationYearMax,
      genders: candidateFilter.genders,
      heritages: candidateFilter.heritages,
      otherDiversityCriteria: candidateFilter.otherDiversityCriteria,
      oDataFilter: GetFilterStringFromCandidateFilter(candidateFilter)
    },
    rawFilterData: candidateFilter
  }
  try {
    const response = await axios.post(url, request)
    return response.data as string
  } catch (e) {
    logErrorMessage('problem saving filter')
    throw e
  }
}

async function DeleteCandidateFilter(candidateFilterId: string, employerId: string): Promise<void> {
  const url = GetUrlString('employers/:employerId/agents/self/candidate-filters/:candidateFilterId', { candidateFilterId, employerId })
  try {
    await axios.delete(url)
  } catch (e) {
    logErrorMessage('problem deleting filter')
    throw e
  }
}

async function GetSavedFilters(employerId: string): Promise<CandidateFilterMetadata[]> {
  const url = GetUrlString('employers/:employerId/agents/self/candidate-filters', { employerId })
  const response = await axios.get(url)
  return response.data as CandidateFilterMetadata[]
}

export {
  GetAgentProfile,
  UpsertAgentProfile,
  SendOtp,
  VerifyOtp,
  SaveCandidateFilter,
  DeleteCandidateFilter,
  GetSavedFilters
}
