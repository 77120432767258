
import { defineComponent, provide, computed } from 'vue'
import {
  IBsTabContainerStoreSymbol,
  IBsTabContainerStore
} from './BsTab.interfaces'
import { createBsTabStore } from './BsTab.store'
import { createAutoIncrementId } from '@/utils/VueTools'

export const BsTabContainer = defineComponent({
  name: 'BsTabContainer',
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsTabContainer')
    }
  },
  setup() {
    const store = createBsTabStore()
    const selectedTabId = computed(() => store.state.selectedTabId)
    provide<IBsTabContainerStore>(IBsTabContainerStoreSymbol, store)

    return {
      selectedTabId
    }
  }
})

export default BsTabContainer
