
import { defineComponent } from 'vue'
import { BubbleMenu } from '@tiptap/vue-3'

enum ActionItems {
  bold = 'bold',
  italic = 'italic',
  strike = 'strike'
}

type ActionItem = ActionItems.bold | ActionItems.italic | ActionItems.strike

export default defineComponent({
  components: {
    BubbleMenu
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const commands = {
      [ActionItems.bold]: () => props.editor.chain().focus().toggleBold().run(),
      [ActionItems.italic]: () =>
        props.editor.chain().focus().toggleItalic().run(),
      [ActionItems.strike]: () =>
        props.editor.chain().focus().toggleStrike().run()
    }

    function handleActionClick(val: ActionItem) {
      commands[val]()
    }

    return {
      handleActionClick
    }
  }
})
