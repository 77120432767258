import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgentDetails = _resolveComponent("AgentDetails")!

  return (_openBlock(), _createBlock(_component_AgentDetails, {
    isOpen: _ctx.computeIsOpen,
    "onUpdate:isOpen": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computeIsOpen) = $event)),
    "is-loading": _ctx.loading,
    "is-error": _ctx.errorLoading,
    "office-locations": _ctx.employerOfficeLocations,
    "employer-profile": _ctx.employerProfile,
    "agent-profile": _ctx.agentProfile,
    "is-valid-profile": _ctx.validProfile,
    "is-saving": _ctx.isSaving,
    onHandleSubmit: _ctx.updateProfile
  }, null, 8, ["isOpen", "is-loading", "is-error", "office-locations", "employer-profile", "agent-profile", "is-valid-profile", "is-saving", "onHandleSubmit"]))
}