
import BsAutoComplete from '@/components/BsAutoComplete/BsAutoComplete.vue'
import { PropType, ref } from 'vue'
import { TextValue } from '@/types'

export default {
  components: { BsAutoComplete },
  props: {
    modelValue: {
      type: Object as PropType<TextValue<any>[]>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Object as PropType<TextValue<any>[]>,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Begin typing to search'
    },
    maxItems: {
      type: Number,
      default: null
    },
    completeOnFocus: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  //@ts-ignore no-implicit-any
  setup(props) {
    const suggestions = ref(props.options)
    const autoCompleteInput = ref<{focus: CallableFunction}>({focus: () => {}})

    function search(queryObject: {query: string}) {
      //@ts-ignore no-implicit-any
      suggestions.value = props.options.filter(option =>
      //@ts-ignore no-implicit-any
        !props.modelValue.some(selectedOption => selectedOption.value === option.value)
        && option.text.toLowerCase().includes(queryObject.query.toLowerCase()))
      if (props.maxItems) {
        suggestions.value = suggestions.value.slice(0, props.maxItems)
      }
    }

    return {
      search,
      suggestions,
      autoCompleteInput
    }
  }
}
