
import { computed, defineComponent, inject, onMounted } from 'vue'
import { IBsAccordionStoreSymbol, IBsAccordionContainerStore } from './Accordion.interface'
import { createAutoIncrementIdProp } from '@/utils/VueTools'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    id: createAutoIncrementIdProp('Accordion'),
    initialOpen: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props) {
    const store = inject<IBsAccordionContainerStore>(IBsAccordionStoreSymbol)

    onMounted(() => {
      if (props.initialOpen) {
        store?.actions.selectAccordion(props.id || '')
      }
    })

    function toggleAccordionState() {
      store?.actions.selectAccordion(props.id || '')
    }

    const isExpanded = computed<boolean>(() => {

      if(store?.state.isMultiple){
        return store.state.menuState?.includes(props.id || '') ?? false
      }
        return store?.state.menuState === props.id
    })

    return {
      toggleAccordionState,
      isExpanded
    }
  }
})
