
import { computed, ref, watch } from 'vue'

interface PageEntry {
  pageLabel: string
  class: string | null
  fillInType: string | null
}

export default {
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    //Spread is the number of pages above and below the current page that will be selectable from the page label
    //ie: say totalPages = 10, currentPage = 5, and spread = 2 => page label will look like 1...3 4 5 6 7...10
    spread: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props: any, context: any) {
    const internalPage = ref(props.modelValue)
    const showPrevious = computed<boolean>(
      (): boolean => internalPage.value > 1
    )
    const showNext = computed<boolean>(
      (): boolean => internalPage.value < props.totalPages
    )

    const entries = computed<Array<PageEntry>>((): Array<PageEntry> => {
      const retEntries = [] as Array<PageEntry>

      // first page not disabled if not selected
      if (internalPage.value !== 1) {
        retEntries.push({
          pageLabel: '1',
          class: 'hover-cursor',
          fillInType: null
        })
      }

      // preceding dots
      if (internalPage.value > props.spread + 2) {
        retEntries.push({
          pageLabel: '...',
          class: 'hover-cursor',
          fillInType: 'prev'
        })
      }

      // numbers spread behind current page
      let startIndex = Math.max(2, internalPage.value - props.spread)
      for (let i = startIndex; i < internalPage.value; i++) {
        retEntries.push({
          pageLabel: i.toString(),
          class: 'hover-cursor',
          fillInType: null
        })
      }

      // current page
      retEntries.push({
        pageLabel: internalPage.value.toString(),
        class: 'disabled',
        fillInType: null
      })

      // numbers spread ahead of current page
      const endIndex = Math.min(
        props.totalPages - 1,
        internalPage.value + props.spread
      )
      for (let i = internalPage.value + 1; i <= endIndex; i++) {
        retEntries.push({
          pageLabel: i.toString(),
          class: 'hover-cursor',
          fillInType: null
        })
      }

      // following dots
      if (internalPage.value < props.totalPages - props.spread - 1) {
        retEntries.push({
          pageLabel: '...',
          class: 'hover-cursor',
          fillInType: 'next'
        })
      }

      // final page not disabled if not on final page
      if (internalPage.value !== props.totalPages) {
        retEntries.push({
          pageLabel: props.totalPages.toString(),
          class: 'hover-cursor',
          fillInType: null
        })
      }

      return retEntries
    })

    const navigate = (entry: PageEntry): void => {
      let page = parseInt(entry.pageLabel)
      if (entry.fillInType === 'prev') {
        page = internalPage.value - props.spread - 1
      } else if (entry.fillInType === 'next') {
        page = internalPage.value + props.spread + 1
      }
      context.emit('update:modelValue', page)
    }

    const navigatePrevious = (): void => {
      context.emit('update:modelValue', internalPage.value - 1)
    }

    const navigateNext = (): void => {
      context.emit('update:modelValue', internalPage.value + 1)
    }

    watch(
      () => props.modelValue,
      (newVal: number): void => {
        internalPage.value = newVal
      }
    )

    return {
      internalPage,
      showPrevious,
      showNext,
      entries,
      navigate,
      navigatePrevious,
      navigateNext
    }
  }
}
