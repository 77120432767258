import postalAddressCountries from 'postal-address-field-names'
import { TextValue } from '@/types'
import { FileDescription } from '@/types/fileMetadata/fileDescription'
import { FileMetadata } from '@/types/fileMetadata/fileMetadata'
import { Models } from 'azure-maps-rest'

interface JobOpportunity {
  id: string
  employerId: string
  employerName: string | undefined
  opportunityListing?: OpportunityListing
  hasAppliedTo: boolean
  isFavorited: boolean
  supportingFileMetadata: Array<FileMetadata>
  views?: number
  status: 'Published' | 'Draft' | 'Expired'
  numberOfApplications: number
  numberOfApplicationsNotReviewed: number
  candidatesInvitedToApply: Array<string>
}

interface GetActiveOpportunitiesResponse {
  opportunities: JobOpportunity[]
  totalFilteredOpportunities: number
}

interface OpportunityListing {
  title: string
  description: string
  applyEmail: string
  includeSalaryInfo: boolean
  salaryType?: SalaryKey
  currency?: CurrencyKey
  salary?: number
  applyType: ApplyKey
  externalApplicationUrl: string
  applicationReceiveEmail: string
  startDate?: Date
  applicationDeadline?: Date
  expired?: boolean
  timezone: string
  recruiterEmail: string
  recruiterContact: string
  recruiterTitle: string
  audience: AudienceKey
  opportunityType: USOpportunityTypeKey | NonUSOpportunityTypeKey
  officeLocation?: Models.SearchResultAddress
  address?: AddressFields
  postedDate: Date
  requirementsToApply: Array<RequirementToApplyKey>
  fileRequirementsToApply: Array<FileDescription>
  remote: boolean,
  youTubeVideoId: string | undefined,
  practiceAreas?: Array<string>
}

interface AddressFields {
  country?: string
  addressLine1?: string
  addressLine2?: string
  administrativeArea?: string
  dependentLocality?: string
  locality?: string
  postalCode?: string
}

interface OpportunityFilter {
  postedWithinHours?: number
  opportunityTypes: (USOpportunityTypeKey | NonUSOpportunityTypeKey)[]
  requirementsToApply: (RequirementToApplyKey | FileDescription)[]
  remote: boolean
  countryCode: string
  locations: Models.SearchResultAddress[]
}

interface PreviewOpportunityInfo {
  opportunityTitle: string
  companyName: string
  location: string
  postDate: string
  salaryAmount: number
  startDate: string
  description: string
  requirements: Array<RequirementToApplyKey>
  recruiter: RecruiterInfo
}

interface RecruiterInfo {
  recruiterContact: string
  recruiterTitle: string
  recruiterEmail: string
}

type ApplyKey = 'InternalManage' | 'ExternalDirect' | 'EmailReceive'
const ApplyTypes: Array<TextValue<ApplyKey>> = [
  { text: 'Internal-Manage', value: 'InternalManage' },
  { text: 'External-Direct', value: 'ExternalDirect' },
  { text: 'Email-Receive', value: 'EmailReceive' }
]

type AudienceKey =
  | 'USLawStudents'
  | 'NonUSLawStudents'
  | 'USLawyers'
  | 'NonUSLawyers'
  | 'Paralegals'
  | 'EDiscoverySpecialist'
  | 'Other'
const AudienceTypes: Array<TextValue<AudienceKey>> = [
  { text: 'For US Law Students', value: 'USLawStudents' },
  { text: 'For Non-US Law Students', value: 'NonUSLawStudents' },
  { text: 'For US Lawyers', value: 'USLawyers' },
  { text: 'For Non-US Lawyers', value: 'NonUSLawyers' },
  { text: 'For Paralegals', value: 'Paralegals' },
  { text: 'eDiscovery Specialist', value: 'EDiscoverySpecialist' },
  { text: 'Other Legal Support Staff', value: 'Other' }
]
const CandidateTypes: Array<TextValue<AudienceKey>> = [
  { text: 'US Law Student', value: 'USLawStudents' },
  { text: 'Non-US Law Student', value: 'NonUSLawStudents' },
  { text: 'US Lawyer', value: 'USLawyers' },
  { text: 'Non-US Lawyer', value: 'NonUSLawyers' },
  { text: 'Paralegal', value: 'Paralegals' },
  { text: 'eDiscovery Specialist', value: 'EDiscoverySpecialist' },
  { text: 'Other Legal Support Staff', value: 'Other' }
]

const SalaryInclusionOptions = [
  { text: 'Include in posting', value: true },
  { text: "Don't display", value: false }
]

type SalaryKey = 'PerAnnum' | 'PerHour' | 'PerWeek' | 'Scholarship' | 'Unpaid'
const SalaryTypes: TextValue<SalaryKey>[] = [
  { text: 'Per Annum', value: 'PerAnnum' },
  { text: 'Per Hour', value: 'PerHour' },
  { text: 'Per Week', value: 'PerWeek' },
  { text: 'Scholarship', value: 'Scholarship' },
  { text: 'Unpaid', value: 'Unpaid' }
]

type CurrencyKey = 'USD' | 'GBP'
const Currencies: Array<TextValue<CurrencyKey>> = [
  { text: 'USD', value: 'USD' },
  { text: 'GBP', value: 'GBP' }
]

type RequirementToApplyKey =
  | 'CompleteFirmOnlineApplication'
  | 'Completed1l'
  | 'Completed2l'
  | 'LawSchoolGraduate'
  | 'LicensedAttorneyPassedBarExam'
  | 'LicensedForeignAttorneyLLM'
  | 'LexisNexisProfessionalResearchCertified'
  | 'PipelineToPracticeFellow'
const RequirementsToApply: Array<TextValue<RequirementToApplyKey>> = [
  { text: 'Complete Firm Online Application', value: 'CompleteFirmOnlineApplication' },
  { text: 'Completed 1L', value: 'Completed1l' },
  { text: 'Completed 2L', value: 'Completed2l' },
  { text: 'Law School Graduate', value: 'LawSchoolGraduate' },
  { text: 'Licensed Attorney Passed Bar Exam', value: 'LicensedAttorneyPassedBarExam' },
  { text: 'Licensed Foreign Attorney with LL.M', value: 'LicensedForeignAttorneyLLM' },
  { text: 'LexisNexis Professional Research Certified', value: 'LexisNexisProfessionalResearchCertified' },
  { text: 'Pipeline to Practice Fellow', value: 'PipelineToPracticeFellow' }
]

const CountryAddressDetails: {
  name: string
  isoCode: string
  addressFields: AddressFields
}[] = []
for (const country of postalAddressCountries) {
  CountryAddressDetails.push({
    name: country.name,
    isoCode: country.iso,
    addressFields: country.fields
  })
}

const USOpportunityTypeKeyList = [
  'OneLFellowshipScholarship',
  'OneLSummerAssociate',
  'TwoLFellowshipScholarship',
  'TwoLSummerAssociate',
  'AssociateLawyer',
  'ContractAttorney',
  'eDiscoverySpecialist',
  'EntryLawClerk',
  'InternshipOpportunity',
  'LegalSupport',
  'Paralegal',
  'StudentReceptionOrEvent'
] as const
type USOpportunityTypeKey = typeof USOpportunityTypeKeyList[number]
const isUSOpportunityTypeKey = (value: string): boolean => {
  const configKey = USOpportunityTypeKeyList.find(
    (validKey) => validKey === value
  )
  return !!configKey
}

const NonUSOpportunityTypeKeyList = [
  'eDiscoverySpecialist',
  'GraduateApprenticeship',
  'GraduateProgramme',
  'InternshipOpportunity',
  'LegalExecutive',
  'LegalSupport',
  'NQAssociate',
  'Paralegal',
  'QualifiedWorkExperience',
  'QWEEligible',
  'SolicitorApprenticeship',
  'TrainingContractQWE',
  'VacationScheme'
] as const
type NonUSOpportunityTypeKey = typeof NonUSOpportunityTypeKeyList[number]
const isNonUSOpportunityTypeKey = (value: string): boolean => {
  const configKey = NonUSOpportunityTypeKeyList.find(
    (validKey) => validKey === value
  )
  return !!configKey
}

const OpportunityTypes: Array<
  TextValue<USOpportunityTypeKey | NonUSOpportunityTypeKey>
> = [
  { text: '1L Fellowship/ Scholarship', value: 'OneLFellowshipScholarship' },
  { text: '1L Summer Associate', value: 'OneLSummerAssociate' },
  { text: '2L Fellowship/ Scholarship', value: 'TwoLFellowshipScholarship' },
  { text: '2L Summer Associate', value: 'TwoLSummerAssociate' },
  { text: 'Associate Lawyer', value: 'AssociateLawyer' },
  { text: 'Contract Attorney', value: 'ContractAttorney' },
  { text: 'eDiscovery Specialist', value: 'eDiscoverySpecialist' },
  { text: 'Entry Law Clerk', value: 'EntryLawClerk' },
  { text: 'Graduate Apprenticeship', value: 'GraduateApprenticeship' },
  { text: 'Graduate Programme', value: 'GraduateProgramme' },
  { text: 'Internship', value: 'InternshipOpportunity' },
  { text: 'Legal Executive', value: 'LegalExecutive' },
  { text: 'Legal Support', value: 'LegalSupport' },
  { text: 'NQ Associate', value: 'NQAssociate' },
  { text: 'Paralegal', value: 'Paralegal' },
  { text: 'Qualified Work Experience (QWE)', value: 'QualifiedWorkExperience'},
  { text: 'QWE Eligible', value: 'QWEEligible' },
  { text: 'Solicitor Apprenticeship', value: 'SolicitorApprenticeship' },
  { text: 'Student Reception or Event', value: 'StudentReceptionOrEvent' },
  { text: 'Training Contract-QWE', value: 'TrainingContractQWE' },
  { text: 'Vacation Scheme', value: 'VacationScheme' }
]

export {
  JobOpportunity, OpportunityListing, GetActiveOpportunitiesResponse, OpportunityFilter,
  ApplyKey, ApplyTypes,
  AudienceKey, AudienceTypes, CandidateTypes,
  SalaryInclusionOptions,
  SalaryKey,
  SalaryTypes,
  CurrencyKey,
  Currencies,
  CountryAddressDetails,
  AddressFields,
  RequirementToApplyKey,
  RequirementsToApply,
  USOpportunityTypeKey,
  NonUSOpportunityTypeKey,
  OpportunityTypes,
  isUSOpportunityTypeKey,
  isNonUSOpportunityTypeKey,
  PreviewOpportunityInfo
}
