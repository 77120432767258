
import { SortItem, TableRowItem } from './TableView.interfaces'
import { defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import TableLoader from './TableLoader.vue'
import locales from './TableView.locales.en.json'

export default defineComponent({
  components: {
    TableLoader
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array as PropType<TableRowItem[]>,
      required: true
    },
    initialSort: {
      type: Object as PropType<SortItem>,
      default: () => undefined
    },
    isLoading: {
      type: Boolean
    },
    selectedItems: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    ariaLabel: {
      type: String,
      default: () => undefined
    },
    clickableRow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['row-clicked'],
  setup(props) {
    const sortedElement = reactive<SortItem>({
      order: '',
      sortKey: ''
    })

    const currentData = ref<TableRowItem[]>([])

    const { isLoading, data } = toRefs(props)

    onMounted(() => {
      currentData.value = data.value
    })

    watch(data, () => {
      currentData.value = props.data
    })

    watch(isLoading, (loadingState) => {
      if (!loadingState) {
        if (props.initialSort) {
          sortedElement.order = props.initialSort.order
          sortedElement.sortKey = props.initialSort.sortKey
          handleSort(props.data, props.initialSort)
        } else {
          currentData.value = props.data
        }
      }
    })

    function handleSort(arr: TableRowItem[], value: SortItem) {
      sortedElement.sortKey = value.sortKey
      sortedElement.order = value.order
      const key = sortedElement.sortKey
      const reverse = sortedElement.order === 'asc' ? 1 : -1
      const compareByKey = (a: TableRowItem, b: TableRowItem) => {
        if (a[key] < b[key]) return -1 * reverse
        if (a[key] > b[key]) return reverse
        return 0
      }
      currentData.value = [...arr].sort(compareByKey)
    }

    function getAriaSort(columnKey: string): string {
      if (sortedElement.sortKey !== columnKey) {
        return 'none'
      }
      if (sortedElement.order === 'asc') {
        return 'ascending'
      }
      if (sortedElement.order === 'desc') {
        return 'descending'
      }
      return 'none'
    }

    return {
      sortedElement,
      handleSort,
      currentData,
      getAriaSort,
      locales
    }
  }
})
