export function formatDateToDays(date?: Date) {
  const time = date?.getTime() ?? 0
  return Math.ceil(time / (1000 * 60 * 60 * 24))
}

export const formatDate = (date: string | Date): string => {
  return new Date(date).toLocaleString(undefined, {
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  })
}

export function formatDateWithDefault(date?: Date | string) {
  if (typeof date === 'string') {
    return formatDate(date)
  }
  const formatter = Intl.DateTimeFormat('en-US' , { dateStyle: 'medium' })
  return date ? formatter.format(date) : 'Not available'
}
