
import { defineComponent, PropType } from 'vue'
import HeadingWrapper from './HeadingWrapper.vue'
import locales from '../CandidateProfile.locales.en.json'
import { otherSubMenuOptions } from '../CandidateProfile.util'
import { PreviewDropdownModel, ProfileCategories } from '../CandidateProfile.interface'
import { SchoolInformation, CandidateProfile, DualDegreeKey, DualDegrees } from '@/types/candidate'

export default defineComponent({
  components: {
    HeadingWrapper
  },
  props: {
    details: {
      type: Object as PropType<CandidateProfile>,
      required: true
    },
    viewOnly:  {
      type: Boolean,
      required: false
    }
  },
  emits: ['dropdown-change'],
  setup(_, { emit }) {
    function handleOptionChange(val: PreviewDropdownModel) {
      emit('dropdown-change', val, ProfileCategories.Education)
    }

    function getGraduationPeriod(schoolInfo: SchoolInformation) {
      let start = { year: '0' }
      let end = { year: locales.present }

      if (schoolInfo.startDate) {
        start.year = new Date(schoolInfo.startDate).getFullYear().toString()
      }
      if (schoolInfo.endDate) {
        end.year = new Date(schoolInfo.endDate).getFullYear().toString()
      }
      return {
        start,
        end
      }
    }

    function formatDualDegree(dualDegree: DualDegreeKey) {
      return DualDegrees.find(dd => dd.value === dualDegree)?.text ?? locales.none
    }

    return {
      locales,
      handleOptionChange,
      otherSubMenuOptions,
      getGraduationPeriod,
      formatDualDegree
    }
  }
})
