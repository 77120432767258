
import { defineComponent, ref, PropType } from 'vue'
import Dialog from '@/components/Shared/DialogWrapper.vue'
import EmployerProfileComponent from '@/components/EmployerProfile/EmployerProfileComponent.vue'
import { EmployerDetailsModel } from '@/components/EmployerProfile/EmployerProfile.interface'

export default defineComponent({
  components: {
    Dialog,
    EmployerProfileComponent
  },
  props: {
    employerDetails: {
      type: Object as PropType<EmployerDetailsModel>,
      required: true
    },
    logoImageSource: {
      type: String,
      default: () => undefined
    }
  },
  setup() {
    type DialogInstance = InstanceType<typeof Dialog>
    const dialogRef = ref<DialogInstance>()

    function toggleModal(status: boolean) {
      status ? dialogRef.value?.open() : dialogRef.value?.close()
    }

    return {
      dialogRef,
      toggleModal
    }
  }
})
