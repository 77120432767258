
import { defineComponent, PropType, ref, computed } from 'vue'
import locales from '@/locales/en/locales.json'
import DialogWrapper from '@/components/Shared/DialogWrapper.vue'
import ScrollLayout from '@/components/Shared/ScrollLayout.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import { Candidate, StudentOrganizations } from '@/types/candidate'
import { Applicant } from '@/types/application'
import { CandidateTypes } from '@/types/opportunity'
import { useDownload } from '@/utils/download'

export default defineComponent({
  components: {
    DialogWrapper,
    ScrollLayout,
    TextEditor
  },
  props: {
    candidate: {
      type: Object as PropType<Candidate>,
      required: true
    },
    applicant: {
      type: Object as PropType<Applicant>,
      required: true
    }
  },
  setup(props) {
    const { downloadFromApplicationFileMetadata: handleDownload } = useDownload()
    const dialogRef = ref<InstanceType<typeof DialogWrapper>>()

    const fileMetadataToDownload = computed(() => {
      return props.candidate.candidateFileMetadata.filter((fileMetadata) =>
        fileMetadata.domainObjectId === props.applicant.resumeFileId ||
        fileMetadata.domainObjectId === props.applicant.transcriptFileId ||
        fileMetadata.domainObjectId === props.applicant.writingSampleFileId
      )
    })

    const getStudentOrganizations = computed(()=>{
       let items : string[] = []

      StudentOrganizations.forEach(val=>
      {
        if((props.candidate.profileData.studentOrganizations || []).includes(val.value) && val.value !== 'Other' ) {
          items.push(val.text)
        }
      })

      if(props.candidate.profileData.otherStudentOrganization) {
        items.push(props.candidate.profileData.otherStudentOrganization)
      }

      return items
    })

    function toggleViewApplicant(status: boolean) {
      if (status) {
        dialogRef.value?.open()
      } else {
        dialogRef.value?.close()
      }
    }

    function formatLocation(candidate: Candidate): string {
      if (candidate?.profileData?.dreamJobData?.preferredLocations?.length) {
        const primaryLocation = candidate?.profileData?.dreamJobData?.preferredLocations[0]
        return `${primaryLocation.municipality}, ${primaryLocation.countrySubdivision} (${primaryLocation.countryCode})`
      }
      return 'Unavailable'
    }

    function formatAttendDate(theDate: Date): string {
      return theDate ? theDate.toLocaleString('default', { month: 'long' }) + ' ' + theDate.getFullYear() : 'Unknown'
    }

    function formatGradDate(theDate: Date): string {
      return theDate ? (theDate.getMonth()+1) + '/' + theDate.getFullYear() : locales.present
    }

    function formatCandidateType(candidateTypeValue: string) {
      return CandidateTypes.find(tvp => tvp.value === candidateTypeValue)?.text ?? ''
    }

    function formatEmploymentEnd(endYear: number) {
      if(!endYear) {
        return locales.present
      }

      return endYear
    }

    return {
      locales,
      dialogRef,
      toggleViewApplicant,
      formatLocation,
      formatAttendDate,
      formatGradDate,
      formatCandidateType,
      handleDownload,
      getStudentOrganizations,
      fileMetadataToDownload,
      formatEmploymentEnd
    }
  }
})
