
import { defineComponent, nextTick, onMounted, PropType, ref, watch } from 'vue'
import TableView from '@/components/Table/TableView.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import SelectedHeader from '@/components/Table/TableUtils/SelectedHeader.vue'
import TableTopHeader from '@/components/Table/TableUtils/TableTopHeader.vue'
import StarIcon from '~/icons/Applicants/Applicant-StarIcon.svg'
import UnstarIcon from '~/icons/Applicants/Applicant-UnstarIcon.svg'
import { tableColumns, dropdownOptions, trimString, formatApplicantStatus } from './Applicants.util'
import { FilterOptions, RowData } from './Applicants.interface'
import locales from '@/locales/en/locales.json'
import { filterTableValues } from '@/components/Table/TableFilterValues'
import { ChangeApplicationStatus } from '@/api/ApplicationApi'
import { Applicant, ApplicationStatusKey } from '@/types/application'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import ApplicantListsFilter from '@/views/Applicants/ApplicantListsFilter.vue'
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture.vue'
import ViewApplicantModal from '@/views/Applicants/ViewApplicantModal.vue'
import { Candidate } from '@/types/candidate'

let searchFields: string[] = []

export default defineComponent({
  components: {
    TableView,
    BsCheckbox,
    SelectedHeader,
    TableTopHeader,
    StarIcon,
    UnstarIcon,
    ProfilePicture,
    ApplicantListsFilter,
    ViewApplicantModal
  },
  props: {
    applicantLists: {
      type: Object as PropType<RowData[]>,
      required: true
    }
  },
  emits: ['applicantStatusChanged', 'applicantShortlisted'],
  setup(props, context) {
    const isSelectedAll = ref<boolean>(false)
    const selectedItems = ref<string[]>([])
    const data = ref<RowData[]>([])
    const allData = ref<RowData[]>([])
    const isLoading = ref<boolean>(true)
    const isFilterVisible = ref(false)
    const searchText = ref<string>('')
    const favouriteItems = ref<string[]>([])
    const { showErrorToast } = useToast()
    const modalRef = ref<InstanceType<typeof ViewApplicantModal>>()
    const candidate = ref<Candidate>({} as Candidate)
    const applicant = ref<Applicant>({} as Applicant)


    onMounted(() => {
      searchFields = tableColumns.map((val) => val.key)

      setTimeout(() => {
        isLoading.value = false
        data.value = props.applicantLists
        allData.value = props.applicantLists
        nextTick(getFavouriteItems)
      }, 1000)
    })

    watch(searchText, () => {
      data.value = filterTableValues(
        allData.value,
        searchFields,
        searchText.value
      )
    })

    watch(() => props.applicantLists, () => {
      data.value = props.applicantLists
      allData.value = props.applicantLists
    })

    function getFavouriteItems() {
      allData.value.forEach(
        (val) => val.favorite && favouriteItems.value.push(val.id)
      )
    }

    function handleSelectAll(e: Event) {
      isSelectedAll.value = (e.target as HTMLInputElement).checked
      if (isSelectedAll.value) {
        data.value.forEach((val) => {
          selectedItems.value.push(val.id)
        })
      } else {
        selectedItems.value = []
      }
    }

    function handleSelectRow(e: Event, rowItem: RowData) {
      if (selectedItems.value.includes(rowItem.id)) {
        selectedItems.value = selectedItems.value.filter((val) => val !== rowItem.id)
      } else {
        selectedItems.value.push(rowItem.id)
      }

      isSelectedAll.value = selectedItems.value.length === data.value.length
    }

    const showFilters = () => {
      isFilterVisible.value = true
    }

    const filterApplied = (filter: FilterOptions) => {
      isFilterVisible.value = !filter
      data.value = props.applicantLists.filter(app =>
        !(filter.notHired || filter.reviewed || filter.notReviewed || filter.interviewing)
        || (app.status === 'Not Hired' && filter.notHired)
        || (app.status === 'Reviewed' && filter.reviewed)
        || (app.status === 'Not Reviewed' && filter.notReviewed)
        || (app.status === 'Interviewing' && filter.interviewing)
        )
    }

    function handleFavouriteItem(favoriteData: RowData) {
      if (favouriteItems.value.includes(favoriteData.id)) {
        favouriteItems.value = favouriteItems.value.filter((val) => val !== favoriteData.id)
        favoriteData.favorite = false
      } else {
        favouriteItems.value.push(favoriteData.id)
        favoriteData.favorite = true
      }
      context.emit('applicantShortlisted', favoriteData)
    }

    async function openApplicantModal(modalData: RowData) {
      candidate.value = modalData.candidate
      applicant.value = modalData.applicant

      modalRef.value?.toggleViewApplicant(true)
    }


    const handleStatusChange = async (status: ApplicationStatusKey) => {
      for(const applicationId of selectedItems.value){
        const applicantIndex = data.value.findIndex(x => x.id === applicationId)
        updateApplicantStatus(applicantIndex, applicationId, status)
      }
    }

    const updateApplicantStatus = async (applicantIndex: number, applicationId: string, status: ApplicationStatusKey) => {
      if(data.value[applicantIndex]) {
          try {
            await ChangeApplicationStatus(data.value[applicantIndex].opportunityId, applicationId, status)
            data.value[applicantIndex].status = formatApplicantStatus(status)
          }
          catch {
            showErrorToast({
              message: 'Could not update status for one or more selected applicants.',
              position: 'top-center'
          })
        }
      }
    }

    return {
      tableColumns,
      data,
      handleSelectAll,
      handleSelectRow,
      isSelectedAll,
      selectedItems,
      isLoading,
      locales,
      showFilters,
      filterApplied,
      isFilterVisible,
      dropdownOptions,
      trimString,
      handleFavouriteItem,
      favouriteItems,
      handleStatusChange,
      modalRef,
      openApplicantModal,
      candidate,
      applicant
    }
  }
})
