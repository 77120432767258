import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0e4a564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "education-wrapper" }
const _hoisted_2 = { class: "checkbox-area mb-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 col-sm-12" }
const _hoisted_5 = { class: "col-md-6 col-sm-12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-6 col-sm-12" }
const _hoisted_11 = { class: "col-md-6 col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsRadio = _resolveComponent("BsRadio")!
  const _component_BsAutoComplete = _resolveComponent("BsAutoComplete")!
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_DateTimeInput = _resolveComponent("DateTimeInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsRadio, {
        modelValue: _ctx.reactiveSchoolInfo.programType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reactiveSchoolInfo.programType) = $event)),
        class: "mb-3",
        label: _ctx.locales.lawschool,
        value: _ctx.ProgramType[_ctx.ProgramType.Law]
      }, null, 8, ["modelValue", "label", "value"]),
      _createVNode(_component_BsRadio, {
        modelValue: _ctx.reactiveSchoolInfo.programType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reactiveSchoolInfo.programType) = $event)),
        class: "mb-3",
        label: _ctx.locales.university_graduate,
        value: _ctx.ProgramType[_ctx.ProgramType.Graduate]
      }, null, 8, ["modelValue", "label", "value"]),
      _createVNode(_component_BsRadio, {
        modelValue: _ctx.reactiveSchoolInfo.programType,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reactiveSchoolInfo.programType) = $event)),
        class: "mb-3",
        label: _ctx.locales.university_undergraduate,
        value: _ctx.ProgramType[_ctx.ProgramType.Undergraduate]
      }, null, 8, ["modelValue", "label", "value"])
    ]),
    _createVNode(_component_BsAutoComplete, {
      id: `school-name-${_ctx.idIndex}`,
      modelValue: _ctx.autoSchoolName,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.autoSchoolName) = $event)),
      class: "mb-3",
      label: _ctx.isLawSchool ? _ctx.locales.lawschool : _ctx.locales.university,
      suggestions: _ctx.filteredSchoolNames,
      "force-selection": "",
      placeholder: "Begin typing to search",
      validation: _ctx.v$.autoSchoolName,
      onComplete: _ctx.searchSchoolNames
    }, null, 8, ["id", "modelValue", "label", "suggestions", "validation", "onComplete"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.autoSchoolName === 'Not Listed/Other')
          ? (_openBlock(), _createBlock(_component_BsTextField, {
              key: 0,
              id: `school-other-school-name-${_ctx.idIndex}`,
              modelValue: _ctx.otherSchoolName,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.otherSchoolName) = $event)),
              class: "mb-3",
              label: _ctx.locales.other_school,
              maxlength: "100",
              validation: _ctx.v$.otherSchoolName
            }, null, 8, ["id", "modelValue", "label", "validation"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.autoSchoolName === 'Not Listed/Other')
          ? (_openBlock(), _createBlock(_component_BsTextField, {
              key: 0,
              id: `school-other-school-country-${_ctx.idIndex}`,
              modelValue: _ctx.reactiveSchoolInfo.country,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.reactiveSchoolInfo.country) = $event)),
              class: "mb-3",
              label: _ctx.locales.country,
              maxlength: "100",
              validation: _ctx.v$.form.country
            }, null, 8, ["id", "modelValue", "label", "validation"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BsSelect, {
          id: `law-degree-${_ctx.idIndex}`,
          modelValue: _ctx.reactiveSchoolInfo.degree,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reactiveSchoolInfo.degree) = $event)),
          class: "mb-3",
          label: _ctx.locales.degree,
          options: _ctx.lawDegreeOptions,
          validation: _ctx.v$.form.degree,
          "option-label": "text",
          "option-value": "text"
        }, null, 8, ["id", "modelValue", "label", "options", "validation"])
      ], 512), [
        [_vShow, _ctx.isLawSchool]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BsTextField, {
          id: `school-degree-${_ctx.idIndex}`,
          modelValue: _ctx.reactiveSchoolInfo.degree,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.reactiveSchoolInfo.degree) = $event)),
          class: "mb-3",
          label: _ctx.locales.degree,
          validation: _ctx.v$.form.degree
        }, null, 8, ["id", "modelValue", "label", "validation"])
      ], 512), [
        [_vShow, !_ctx.isLawSchool]
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_DateTimeInput, {
          id: `school-start-date-${_ctx.idIndex}`,
          modelValue: _ctx.reactiveSchoolInfo.startDate,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.reactiveSchoolInfo.startDate) = $event)),
          class: "mb-3",
          label: _ctx.locales.start_date,
          validation: _ctx.v$.form.startDate,
          "required-field": true,
          "disable-time": true
        }, null, 8, ["id", "modelValue", "label", "validation"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_DateTimeInput, {
          id: `school-end-date-${_ctx.idIndex}`,
          modelValue: _ctx.reactiveSchoolInfo.endDate,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.reactiveSchoolInfo.endDate) = $event)),
          class: "mb-3",
          label: _ctx.locales.end_date,
          validation: _ctx.v$.form.endDate,
          "required-field": true,
          "disable-time": true
        }, null, 8, ["id", "modelValue", "label", "validation"])
      ])
    ])
  ]))
}